import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import _ from 'lodash';
import { Table, Pagination, Input, Checkbox, Button } from 'antd';
import { withTranslation } from 'react-i18next';
import ReactResizeDetector from 'react-resize-detector';

import ItemView from 'components/ItemView';
import AppLoader from '../components/AppLoader';
import RejectCountBanner from './../components/RejectCountBanner';
import * as batchesActions from 'actions';
import { GDSN_VALUE } from 'util/Constants';
import { getValuesFromTwoFields, getValueBasedOnTheCondition, getBrandColumnSymbolAndTitle } from '../util/Util';

const { Search } = Input;

function ItemVisibilityView({
  actions,
  selectedItem,
  suvc,
  fieldComment,
  pendingComments,
  page,
  recordCount,
  search,
  list,
  visibilityChanges,
  fetchingList,
  updatingVisibility,
  permissions,
  forcedVisibleSupcs,
  forcedVisibilityBannerOn
}) {
  const [tableData, setTableData] = useState(list);

  useEffect(() => {
    actions.getForcedVisibleSupcs();
  }, [actions]);

  useEffect(() => {
    setTableData(list);
  }, [list]);

  if (getValuesFromTwoFields(fetchingList, updatingVisibility)) {
    return <AppLoader show />;
  }

  const onClickItem = item => {
    actions.itemDetails({ stepId: item.stepId, supc: item.supc, item, suvc: item.suvc });
    actions.itemCooDetails({ supc: item.supc, syscoBrand: item.syscoBrand, gdsn: item.gdsn });
  };

  const onChangeCheckbox = (supc, checked) => {
    updateTableData(supc, { isForcedVisible: checked });
    actions.changeItemVisibility({ payload: { supc, active: getValueBasedOnTheCondition(checked, 1, 0) } });
  };

  const onApprove = () => {
    actions.updateItemVisibility({ data: visibilityChanges });
  };

  const updateTableData = (supc, data) => {
    let clone = [...tableData];
    const index = _.findIndex(clone, obj => supc === obj.supc);

    if (index !== -1) {
      let item = { ...clone[index], ...data };
      clone[index] = item;
    }

    setTableData(clone);
  };

  const searchItems = search => {
    search = search.trim();
    if (!(search.length >= 4)) {
      return false;
    }

    actions.getPgmItemsList({
      page: 1,
      search,
      forcedVisibilityBannerOn: false
    });
  };

  const handlePagination = newPage => {
    actions.getPgmItemsList({
      page: newPage,
      search: search,
      supc: forcedVisibleSupcs,
      forcedVisibilityBannerOn
    });
  };

  const togglFilterBanner = () => {
    if (forcedVisibilityBannerOn) {
      actions.getPgmItemsList({
        page: 1,
        search: '',
        forcedVisibilityBannerOn: false,
        clearList: true
      });
      return false;
    }
    actions.getPgmItemsList({
      page: 1,
      search: '',
      supc: forcedVisibleSupcs,
      forcedVisibilityBannerOn: true
    });
  };

  const renderBrandsColumn = (brandName, item) => {
    const { symbol, symbolTitle } = getBrandColumnSymbolAndTitle(item);

    return (
      <div className="ellipsed brands-cell" title={brandName}>
        {!_.isEmpty(symbol) && (
          <div className={`brand-indicator ${symbol.toLowerCase()}brand`} title={symbolTitle}>
            {symbol}
          </div>
        )}
        <div className="brand-name">{brandName}</div>
      </div>
    );
  };

  const renderForcedVisibleCountBanner = () => {
    const count = forcedVisibleSupcs.length;
    if (count > 0) {
      return (
        <RejectCountBanner
          count={count}
          className="reject-filter-banner forced-visible-banner"
          isFilterOn={forcedVisibilityBannerOn}
          onToggle={togglFilterBanner}
          title={'PGM items count'}
          countMessage={`PGM item${getValueBasedOnTheCondition(count > 1, 's', '')}!`}
        />
      );
    }
  };

  const renderGridPanel = () => {
    return (
      <div className="grid-filter-panel">
        <div
          className="back-to-dashboard"
          onClick={() => {
            window.location.href = '/suite/dashboard';
          }}
        />
        <div className="bread-crum-seperator"></div>
        <div className="bread-crum-caption">PGM Items View</div>
        <div className="search-bar">
          {!_.isEmpty(visibilityChanges) && _.includes(permissions, 'ITMAPP.ITMVISI.UPDATE') && (
            <Button id="forced-items-approve-btn" onClick={onApprove}>
              Approve
            </Button>
          )}
          {renderForcedVisibleCountBanner()}
          <Search
            className="search-box"
            defaultValue={search}
            placeholder="Search Items..."
            onSearch={searchItems}
            enterButton
            id="id-search-box"
          />
        </div>
      </div>
    );
  };

  const renderTable = () => {
    const columns = [
      {
        title: '',
        key: 'isForcedVisible',
        render: (text, record) => {
          return (
            <Checkbox
              disabled={!_.includes(permissions, 'ITMAPP.ITMVISI.UPDATE')}
              checked={record.isForcedVisible}
              onChange={e => onChangeCheckbox(record.supc, e.target.checked)}
            ></Checkbox>
          );
        }
      },
      {
        title: 'SUPC',
        dataIndex: 'supc',
        key: 'supc',
        width: 100,
        render: (supc, record) => {
          return (
            <div className="supc-cell" onClick={() => onClickItem(record)}>
              {supc}
            </div>
          );
        }
      },
      {
        title: 'Description',
        dataIndex: 'materialDescription',
        key: 'materialDescription',
        width: 'auto',
        render: desc => (
          <div>
            <span>{desc && desc.toUpperCase()}</span>
          </div>
        )
      },
      {
        title: 'Brand',
        dataIndex: 'brandName',
        key: 'brandName',
        width: 130,
        render: (brandName, item) => renderBrandsColumn(brandName, item)
      },
      {
        title: 'True Vendor',
        dataIndex: 'suvc',
        key: 'suvc',
        width: 180,
        render: (suvc, item) => (
          <div className="ellipsed allcap" title={`${suvc} - ${item.trueVendorName}`}>
            {`${suvc} - ${item.trueVendorName}`}
          </div>
        )
      },
      {
        title: 'MPC',
        dataIndex: 'manufactProdCode',
        key: 'manufactProdCode',
        width: 160
      },
      {
        title: 'GDSN',
        dataIndex: 'gdsn',
        key: 'gdsn',
        width: 60,
        render: gdsn => <span>{gdsn === getValueBasedOnTheCondition(GDSN_VALUE, '✓', '')}</span>,
        align: 'center'
      },
      {
        title: 'GTIN',
        dataIndex: 'gtin',
        key: 'gtin',
        width: 160
      },
      {
        title: 'SDP Visible',
        dataIndex: 'sdpVisible',
        key: 'sdpVisible',
        width: 120
      },
      {
        title: 'Logically Active',
        dataIndex: 'logicallyActiveFlag',
        key: 'logicallyActiveFlag',
        width: 120
      },
      {
        title: 'Is Orderable',
        dataIndex: 'isorderable',
        key: 'isorderable',
        width: 120
      }
    ];

    if (!_.isEmpty(tableData)) {
      return (
        <Table
          rowKey={'supc'}
          className="pgm-items-tbl"
          columns={columns}
          dataSource={tableData}
          size="small"
          pagination={false}
        />
      );
    }
    return <div />;
  };

  selectedItem.readOnly = true;

  return (
    <React.Fragment>
      {selectedItem && selectedItem.supc && (
        <ItemView
          suvc={suvc}
          item={selectedItem}
          actions={actions}
          fieldComment={fieldComment}
          pendingComments={pendingComments}
        />
      )}
      {renderGridPanel()}

      <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => (
          <div className="grid-panel items-list-table">
            {renderTable()}
            <Pagination
              size="small"
              className="grid-pagination"
              onChange={handlePagination}
              total={parseInt(recordCount)}
              showTotal={total => `Total ${total} items`}
              current={page}
              defaultPageSize={50}
              hideOnSinglePage
            />
          </div>
        )}
      />
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    list: state.pgmItems.list,
    visibilityChanges: state.pgmItems.visibilityChanges,
    fetchingList: state.pgmItems.fetchingList,
    updatingVisibility: state.pgmItems.updatingVisibility,
    search: state.pgmItems.search,
    recordCount: state.pgmItems.recordCount,
    page: state.pgmItems.page,
    forcedVisibleSupcs: state.pgmItems.forcedVisibleSupcs,
    forcedVisibilityBannerOn: state.pgmItems.forcedVisibilityBannerOn,

    selectedItem: state.selectedItem,
    suvc: state.vendor.selectedSuvc,
    fieldComment: state.fieldComment,
    pendingComments: state.pendingComments,
    permissions: state.user.data.permissions
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(batchesActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ItemVisibilityView));
