import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import { connect } from 'react-redux';
import { Table, Input, Button, Tag, Pagination, Icon, Radio, DatePicker, TreeSelect, Modal } from 'antd';
import moment from 'moment';
import _ from 'lodash';

import CommentOriginalValue from './CommentOriginalValue';
import CommentExpanded from './CommentExpanded';
import CoreReviewConfirmationModal from './CoreDataExpanded/CoreReviewConfirmationModal';
import {
  REJECT,
  DENY,
  ITEM_FEEDBACK_STATUSES,
  ITEM_FEEDBACK_TYPES,
  CORE,
  ATRBYTUNI,
  CORE_DATA_ATTRIBUTE_STATUSES,
  OVERALL
} from '../util/Constants';
import {
  removeRejectedAttribyteFnbFeedbacks,
  handleNutritionFeedbackSubmit,
  getSearchValue,
  getFieldKeyOrAssignDefaultValue,
  getButtonDisableStatusOnFeedback,
  getImagesAndAtrbyteImageButtonTitleInFeedback,
  getIsFeedbackRejected,
  getValueBasedOnTheCondition,
  getButtonCaptionOnRejectedFeedbacks,
  getNutritionSubmitButtonCaption,
  getButtonColorOnFeedback,
  checkStatusWithTwoAndConditions,
  getFieldDisabledStatusByTwoParams,
  getNutritionFeedbackSubmitButtonTitle,
  isCommentAssignedToMe,
  addDefaultStatusFilters,
  formatDate,
  getOverallFeedbackButtonCaption,
  getOverallFeedbackButtonTitle,
  getRelevantOverallHistoryChangeForComment
} from '../util/Util';
import { isTempData } from '../util/ImageUtils';
import CoreAttributeHistory from './CoreAttributeHistory';
import OverallItemStatusFeedbackHistory from './OverallItemsStatusFeedbackHistory';

const RadioGroup = Radio.Group;
const { RangePicker } = DatePicker;
const dateFormat = 'YYYY-MM-DD';

class CommentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      searchedColumn: '',
      rejectedFnBComments: [],
      rejectedImageComments: [],
      tableSearchData: props.tableSearchData || [],
      historyReportDateRange: '',
      historyToggle: false,
      historyType: null
    };
  }

  componentDidMount() {
    this.setInitialRejectedFnBCommentIds();
    this.setInitialRejectedImageCommentIds();
  }

  setInitialRejectedFnBCommentIds = () => {
    const { fnbComments } = this.props;
    const rejectedFnBComments = [];

    _.forEach(fnbComments, comment => {
      const reviews = comment.review;

      const index = _.findIndex(reviews, review => {
        return review.status === REJECT || review.status === DENY;
      });

      if (index !== -1 && comment.pending) {
        rejectedFnBComments.push(comment.pending.comment_id);
      }
    });

    this.setState({ rejectedFnBComments });
  };

  setInitialRejectedImageCommentIds = () => {
    const { comments } = this.props;
    const rejectedImageComments = [];

    _.forEach(comments, comment => {
      if (['IMAGE', 'ATRBYTIMG'].includes(comment.type)) {
        if (comment.status === 10) {
          rejectedImageComments.push(comment.id);
        }
      }
    });

    this.setState({ rejectedImageComments });
  };

  getUpdatedFnBData = record => {
    if (record.id && ['FnB', 'ATRBYTFNB'].includes(record.type)) {
      const refName = this.generateRefName(record);

      if (this[refName]) {
        const data = this[refName].getUpdatedFnBData();
        return data;
      }
    }

    return [];
  };

  getUpdatedImageData = record => {
    const refName = this.generateRefName(record);

    if (this[refName]) {
      const data = this[refName].getUpdatedImageData();
      return data;
    }

    return [];
  };

  getTypeFilterOptions = () => {
    const { isSbsUser, isCoreDataManager } = this.props;

    if (isSbsUser) {
      return _.filter(ITEM_FEEDBACK_TYPES, obj => [4, 8].includes(obj.value));
    }
    if (!isCoreDataManager) {
      return _.filter(ITEM_FEEDBACK_TYPES, obj => ![0, 4, 8].includes(obj.value));
    }

    return ITEM_FEEDBACK_TYPES;
  };

  openCoreModal = row => {
    this.props.actions.toggleCoreModal({ isVisible: true, data: row });
  };

  addFnBCommentToRejectState = commentId => {
    const clone = [...this.state.rejectedFnBComments];

    if (!_.includes(clone, commentId)) {
      clone.push(commentId);
      this.setState({ rejectedFnBComments: clone });
    }
  };

  changeHistoryReportDateRange = newVal => {
    this.setState({ historyReportDateRange: newVal });
  };

  toggleCoreHistory = type => {
    this.setState({ historyReportDateRange: '', historyToggle: !this.state.historyToggle, historyType: type });
    // this.setState({ historyToggle: !this.state.historyToggle });
  };

  removeFnBCommentFromRejectState = commentId => {
    const clone = [...this.state.rejectedFnBComments];

    const result = _.filter(clone, item => commentId !== item);
    this.setState({ rejectedFnBComments: result });
  };

  addImageCommentToRejectState = commentId => {
    const clone = [...this.state.rejectedImageComments];

    if (!_.includes(clone, commentId)) {
      clone.push(commentId);
      this.setState({ rejectedImageComments: clone });
    }
  };

  removeImageCommentFromRejectState = commentId => {
    const clone = [...this.state.rejectedImageComments];

    const result = _.filter(clone, item => commentId !== item);
    this.setState({ rejectedImageComments: result });
  };

  getStatusIndicator = comment => {
    let indicator = '';

    if (['FnB', 'IMAGE', 'NUTRITION', 'ATRBYTFNB', 'ATRBYTIMG', ATRBYTUNI, 'OVERALL'].includes(comment.type)) {
      if (comment.status === 10) indicator = 'fixed-indicator-red';
      else if (comment.status === 20) indicator = 'fixed-indicator-green';
    }

    return indicator;
  };

  onSendComments = (commentId, row) => {
    if (['FnB', 'ATRBYTFNB'].includes(row.type)) {
      this.updateFnb(commentId, row);
    } else if (['IMAGE', 'ATRBYTIMG'].includes(row.type) && row.field !== 'Image Feedback') {
      this.updateImage(commentId, row);
    } else if (row.type === 'NUTRITION') {
      this.updateNutritions(commentId, row);
    } else if (row.type === CORE) {
      this.openCoreModal(row);
    } else if (row.type === ATRBYTUNI) {
      this.updateAttribUni(commentId, row);
    } else if (row.type === OVERALL) {
      this.updateOverallFeedback(commentId, row);
    } else {
      this.props.actions.updateDashComments({ commentId });
    }
  };

  updateFnb = (commentId, row) => {
    const updatedData = this.getUpdatedFnBData(row);
    let status = this.getOverallFnBStatus(commentId);

    status = removeRejectedAttribyteFnbFeedbacks(status, row.type, 'ATRBYTFNB');

    if (this.isValidFnBComment(updatedData)) {
      this.props.actions.updateFnBComments(commentId, row.comment, updatedData, status);
      return;
    }
    this.props.actions.updateFnBComments(commentId, row.comment, updatedData, status, true);
  };

  updateImage = (commentId, row) => {
    const updatedData = this.getUpdatedImageData(row);
    let statusCode = 30;

    if (updatedData.status === REJECT) {
      statusCode = 10;
    }

    statusCode = removeRejectedAttribyteFnbFeedbacks(statusCode, row.type, 'ATRBYTIMG');

    const preferredImgData = {
      changeType: row?.imageComment?.changeType,
      style: row?.imageComment?.style,
      suvc: row?.suvc,
      brand: row?.brand,
      supc: row?.supc,
      assetId: row?.imageComment?.otmmAssetId,
      stepId: row?.stepId,
      currentStyle: row?.originalValue
    };

    this.props.actions.updateImageComments({
      commentId,
      comment: row.comment,
      updatedData,
      status: statusCode,
      preferredImgData
    });
  };

  updateAttribUni = (commentId, { comment }) => {
    this.props.actions.updateAttribUniComments(commentId, comment);
  };

  updateNutritions = (commentId, row) => {
    const { updatedData, status, taxonomyChanges } = handleNutritionFeedbackSubmit(row);
    const { actions } = this.props;
    const { supc, suvc, comment, gtin, supplierName, materialDescription } = row;
    actions.updateEnutritionData({
      commentId,
      comment: updatedData,
      previousComment: comment,
      status,
      supc,
      suvc,
      gtin,
      supplierName,
      materialDescription,
      taxonomyChanges
    });
  };

  updateOverallFeedback = (commentId, commentData) => {
    const currentChanges = getRelevantOverallHistoryChangeForComment(
      commentId,
      this.props.overallFeedbackHistoryChanges,
      true
    );
    this.props.actions.submitOverallFeedback({ currentChanges, commentData });
  };

  updateCoreData = row => {
    let parsedComment = JSON.parse(row.comment);

    for (const [key] of Object.entries(parsedComment)) {
      let newFeedback = _.get(parsedComment, `[${key}].newFeedback`, false);
      let feedback = _.get(parsedComment, `[${key}].feedback`, []);

      let newInternalReview = _.get(parsedComment, `[${key}].newInternalReview`, false);
      let internalReview = _.get(parsedComment, `[${key}].internalReview`, []);

      if (parsedComment[key]?.isRejected && newFeedback && newFeedback.length) {
        const initFeedback = { date: moment().format('YYYY-MM-DD') };
        if (!feedback.push) {
          feedback = feedback ? [{ ...initFeedback, text: feedback }] : [];
        }
        feedback.push({ ...initFeedback, text: newFeedback });
      }

      if (parsedComment[key]?.isInternalReview && newInternalReview && newInternalReview.length) {
        const initFeedback = { date: moment().format('YYYY-MM-DD') };
        if (!internalReview.push) {
          internalReview = internalReview ? [{ ...initFeedback, text: internalReview }] : [];
        }
        internalReview.push({ ...initFeedback, text: newInternalReview });
      }

      parsedComment[key] = { ...parsedComment[key], feedback, internalReview };
      delete parsedComment[key].newFeedback;
      delete parsedComment[key].newInternalReview;
    }

    this.props.actions.updateCoreData({
      id: row.id,
      comment: JSON.stringify(parsedComment),
      supc: row.supc,
      suvc: row.suvc,
      stepId: row.stepId
    });
  };

  isValidFnBComment = updatedData => {
    let isValid = true;

    _.forEach(updatedData, item => {
      if (
        checkStatusWithTwoAndConditions(
          _.isEmpty(item.comment) && getFieldDisabledStatusByTwoParams(item.status === 'REJECT', item.status === 'DENY')
        )
      ) {
        isValid = false;
      }
    });

    return isValid;
  };

  getOverallFnBStatus = commentId => {
    let status = 30;

    if (_.includes(this.state.rejectedFnBComments, commentId)) {
      status = 10;
    }

    return status;
  };

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex
    });
  };

  handleReset = clearFilters => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  searchFeedback = newSearch => {
    const {
      fType,
      fStatus,
      actions,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    } = this.props;
    actions.loadDashComments({
      page: 1,
      search: newSearch,
      fStatus,
      fType,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    });
  };

  handlePagination = page => {
    const {
      search,
      fType,
      fStatus,
      actions,
      brandGroupFilters,
      orderBy,
      tableFilters,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    } = this.props;
    const { tableSearchData } = this.state;
    actions.loadDashComments({
      page,
      search,
      fStatus,
      fType,
      tableSearchData,
      brandGroupFilters,
      tableFilters,
      orderBy,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    });
  };

  generateRefName = record => {
    if (['FnB', 'ATRBYTFNB'].includes(record.type)) {
      const refName = `${record.id}-fnb-ref`;
      return refName;
    }
    if (
      ['ATRBYTIMG', 'IMAGE'].includes(record.type) &&
      record.field !== 'Image Feedback' &&
      record.imageComment &&
      record.imageComment.changeType
    ) {
      const { changeType } = record.imageComment;
      const refName = `${record.id}-img-${changeType}-ref`;
      return refName;
    }
    if (record.type === 'NUTRITION') {
      const refName = `${record.id}-nutrition-ref`;
      return refName;
    }
    if (record.type === CORE) {
      const refName = `${record.id}-core-ref`;
      return refName;
    }
    if (record.type === ATRBYTUNI) {
      const refName = `${record.id}-attruni-ref`;
      return refName;
    }
  };

  handlePostStyleChange = comment => {
    const { actions } = this.props;
    actions.updateImageStyle({ comment });
  };

  getSortOrder = columnName => {
    const { orderBy } = this.props;
    const field = _.get(orderBy, 'param', null);
    let order = _.get(orderBy, 'val', false);
    if (order === 'asc') {
      order = 'ascend';
    } else if (order === 'desc') {
      order = 'descend';
    }
    if (field && field === columnName) {
      return order;
    }
    return false;
  };

  onTableSearch = () => {
    const {
      actions,
      tableFilters,
      brandGroupFilters,
      orderBy,
      search,
      fStatus,
      newFType,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    } = this.props;
    const { tableSearchData } = this.state;

    actions.loadDashComments({
      search,
      fStatus,
      fType: newFType, // TODO: looks like never used
      tableFilters,
      tableSearchData,
      brandGroupFilters,
      orderBy,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    });
  };

  onTableSearchInputChange = (columnName, value) => {
    const { tableSearchData } = this.state;
    let clone = [...tableSearchData];
    const target = _.find(clone, obj => columnName === obj.param);

    if (!_.isEmpty(value)) {
      if (target) {
        target.val = [value];
      } else {
        clone.push({ param: columnName, val: [value], operator: 'like' });
      }
    } else {
      clone = _.filter(clone, obj => columnName !== obj.param);
    }
    this.setState({ tableSearchData: clone });
  };

  onClearTableSearch = columnName => {
    const {
      actions,
      tableFilters,
      brandGroupFilters,
      orderBy,
      newFType,
      search,
      fStatus,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    } = this.props;
    const { tableSearchData } = this.state;
    const result = _.filter([...tableSearchData], obj => columnName !== obj.param);

    actions.loadDashComments({
      search,
      fStatus,
      fType: newFType,
      tableSearchData: result,
      brandGroupFilters,
      tableFilters,
      orderBy,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    });
  };

  onDateRangeChange = (dateRange, dateString) => {
    const {
      search,
      fType,
      fStatus,
      actions,
      brandGroupFilters,
      orderBy,
      tableFilters,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    } = this.props;
    const { tableSearchData } = this.state;
    if (dateRange[0] && dateRange[1]) {
      const toDate = new Date(dateRange[1].format(dateFormat));
      toDate.setDate(toDate.getDate() + 1);

      const dateRangeFilter = {
        param: 'createdAt',
        val: [dateRange[0].format(dateFormat), new Date(toDate).toISOString().slice(0, 10)],
        operator: 'range'
      };

      actions.loadDashComments({
        search,
        fStatus,
        fType,
        tableSearchData,
        brandGroupFilters,
        tableFilters,
        orderBy,
        dateRangeFilter,
        isSbsUser,
        isCoreDataManager,
        isHidingProprietaryFeedbacksPermission
      });
    }
  };

  renderTableSearch = columnName => {
    const refName = `ref-${columnName}`;
    const { tableSearchData } = this.state;
    const target = _.find(tableSearchData, obj => columnName === obj.param);
    const value = _.get(target, 'val[0]', '');

    return (
      <div className="table-search-dropdown">
        <Input
          className="table-search-input"
          ref={ref => (this[refName] = ref)}
          value={value}
          onChange={e => this.onTableSearchInputChange(columnName, e.target.value)}
          onPressEnter={this.onTableSearch}
        />
        <div className="table-search-btn-wrapper">
          <Button className="table-search-btn" type="primary" onClick={() => this.onTableSearch(columnName)}>
            Search
          </Button>
          <Button className="table-search-btn-reset" onClick={() => this.onClearTableSearch(columnName)}>
            Reset
          </Button>
        </div>
      </div>
    );
  };

  onChangeBrandGroupdFilters = brandGroup => {
    const { tableSearchData } = this.state;
    const {
      actions,
      tableFilters,
      orderBy,
      newFType,
      search,
      fStatus,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    } = this.props;

    actions.loadDashComments({
      search,
      fStatus,
      fType: newFType,
      tableSearchData,
      brandGroupFilters: brandGroup ? [brandGroup] : [],
      tableFilters,
      orderBy,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    });
  };

  onClearDateRange = () => {
    const {
      search,
      fType,
      fStatus,
      actions,
      brandGroupFilters,
      orderBy,
      tableFilters,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    } = this.props;
    const { tableSearchData } = this.state;

    actions.loadDashComments({
      search,
      fStatus,
      fType,
      tableSearchData,
      brandGroupFilters,
      tableFilters,
      orderBy,
      dateRangeFilter: null,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    });
  };

  handleMassSearch = () => {
    const {
      actions,
      brandGroupFilters,
      newFType,
      fStatus,
      search,
      orderBy,
      dateRangeFilter,
      tableFilters: allTableFilters,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    } = this.props;
    const { tableSearchData } = this.state;
    const tableFilters = [];
    _.forIn(allTableFilters, value => {
      if (!_.isEmpty(value)) {
        const { param, val } = value;
        tableFilters.push({ param, val });
      }
    });

    actions.loadDashComments({
      search,
      fStatus,
      fType: newFType,
      tableSearchData,
      brandGroupFilters,
      tableFilters: _.isEmpty(tableFilters) ? null : tableFilters,
      orderBy,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    });
  };

  handleFilterChanges = (pagination, filters, sorter) => {
    const {
      actions,
      brandGroupFilters,
      newFType,
      fStatus,
      search,
      dateRangeFilter,
      tableFilters: allTableFilters,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    } = this.props;
    const { tableSearchData } = this.state;
    const field = _.get(sorter, 'field', null);
    let order = _.get(sorter, 'order', false);
    let tableFilters = [];
    let isNewFilterAdded = false;

    if (order === 'ascend') {
      order = 'asc';
    } else if (order === 'descend') {
      order = 'desc';
    }

    _.forIn(allTableFilters, value => {
      if (!_.isEmpty(value)) {
        const { param, val } = value;
        if (param === Object.keys(filters)[0] && Object.values(filters)[0].length > 0) {
          tableFilters.push({ param, val: Object.values(filters)[0] });
          isNewFilterAdded = true;
        } else if (param !== Object.keys(filters)[0]) {
          tableFilters.push({ param, val });
        }
      }
    });
    if (!isNewFilterAdded && Object.values(filters)[0].length > 0) {
      tableFilters.push({ param: Object.keys(filters)[0], val: Object.values(filters)[0] });
    }

    tableFilters = addDefaultStatusFilters(filters, allTableFilters, tableFilters);

    actions.loadDashComments({
      search,
      fStatus,
      fType: newFType,
      tableSearchData,
      brandGroupFilters,
      tableFilters: _.isEmpty(tableFilters) ? null : tableFilters,
      orderBy: field ? { param: field, val: order } : null,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    });
  };

  handleMyFeedbackFilter = () => {
    const {
      search,
      actions,
      brandGroupFilters,
      orderBy,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    } = this.props;
    const { tableSearchData } = this.state;
    let tableFilters = this.props.tableFilters || [];
    const assigneeFilter = _.find(tableFilters, obj => obj.param === 'assignee');

    if (assigneeFilter) {
      tableFilters = _.filter(tableFilters, obj => obj.param !== 'assignee');
    } else {
      tableFilters = [...tableFilters, { param: 'assignee', val: [this.props.userId] }];
    }

    actions.loadDashComments({
      search,
      tableSearchData,
      brandGroupFilters,
      tableFilters,
      orderBy,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    });
  };

  clearAllFilters = () => {
    const {
      actions,
      newFType,
      search,
      fStatus,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    } = this.props;
    actions.loadDashComments({
      search,
      fStatus,
      fType: newFType,
      tableSearchData: null,
      brandGroupFilters: [],
      tableFilters: null,
      orderBy: null,
      dateRangeFilter: null,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    });

    this.setState({ tableSearchData: [] });
  };

  getSpecificSearchTermValue = (searchData, key) => {
    return _.find(searchData, obj => obj.param === key);
  };

  renderDateRangeFilterDropDown = () => {
    let dateRangeFilter = this.props.dateRangeFilter;
    let fromDate = _.get(dateRangeFilter, 'val[0]', null);
    let toDate = _.get(dateRangeFilter, 'val[1]', null);

    return (
      <div className="center-date-range-content">
        <RangePicker
          className="feedback-date-range-filter"
          style={{ width: '220px' }}
          onFocus={e => e.target.blur()}
          format={'MM/DD/YYYY'}
          onChange={this.onDateRangeChange}
          value={!_.isNull(fromDate) ? [moment(fromDate), moment(toDate).subtract(1, 'days')] : null}
          allowClear={false}
        />
        <Button className="date-rangeh-reset-btn" onClick={this.onClearDateRange}>
          Reset
        </Button>
      </div>
    );
  };

  handleStatusOnChangeFilter = status => {
    const {
      actions,
      brandGroupFilters,
      newFType,
      fStatus,
      search,
      dateRangeFilter,
      orderBy,
      tableFilters: allTableFilters,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    } = this.props;
    const { tableSearchData } = this.state;
    const tableFilters = [];
    const statusFilters = this.getSpecificSearchTermValue(allTableFilters, 'status');
    if (!statusFilters) {
      tableFilters.push({ param: 'status', val: status });
    }
    _.forIn(allTableFilters, value => {
      if (!_.isEmpty(value)) {
        const { param, val } = value;
        if (value.param !== 'status') {
          tableFilters.push({ param, val });
        } else if (status.length > 0) {
          tableFilters.push({ param, val: status });
        }
      }
    });
    actions.loadDashComments({
      search,
      fStatus,
      fType: newFType,
      tableSearchData,
      brandGroupFilters,
      tableFilters: _.isEmpty(tableFilters) ? null : tableFilters,
      orderBy,
      dateRangeFilter,
      isSbsUser,
      isCoreDataManager,
      isHidingProprietaryFeedbacksPermission
    });
  };

  renderDescriptionWithBrandsTitle = (description, { brand }) => {
    let symbolTitle = 'National Brand';

    if (brand === 'P') {
      symbolTitle = 'Packer Brand';
    } else if (brand === 'S') {
      symbolTitle = 'Sysco Brand';
    }

    return (
      <div className="ellipsed brands-cell">
        {!_.isEmpty(brand) && (
          <div className={`brand-indicator ${brand.toLowerCase()}brand`} title={symbolTitle}>
            {brand}
          </div>
        )}
        <div className="brand-name">{description}</div>
      </div>
    );
  };

  renderCoreDataHistoryReport = () => {
    if (this.state.historyReportDateRange && this.state.historyReportDateRange.includes('|')) {
      if (this.state.historyType === CORE) {
        return (
          <CoreAttributeHistory
            supc={'x'}
            type={'ITEM_HISTORY'}
            range={this.state.historyReportDateRange}
            caption={'Generate Report'}
            buttonClasses={'ant-btn footer-btn btn-confirm core-data-report-button'}
          />
        );
      } else if (this.state.historyType === OVERALL) {
        return (
          <OverallItemStatusFeedbackHistory
            supc={'x'}
            // historyData={[]}
            caption={'Generate Report'}
            range={this.state.historyReportDateRange}
            buttonClasses={'ant-btn footer-btn btn-confirm core-data-report-button'}
            type={'ITEM_HISTORY'}
          />
        );
      }
    }
  };

  renderHistoryDateSelector = () => {
    return (
      <div>
        <div style={{ marginBottom: '13px' }}>Select a date range to generate the report.</div>
        <RangePicker
          format={'MM/DD/YYYY'}
          onChange={dates => {
            this.changeHistoryReportDateRange(`${dates[0].format('YYYY-MM-DD')}|${dates[1].format('YYYY-MM-DD')}`);
          }}
          allowClear={false}
        />
      </div>
    );
  };

  coreHistoryReport = () => {
    if (this.props.isCoreDataManager) {
      return (
        <div className="core-report-wrapper">
          <div className={'report-toggle'} onClick={() => this.toggleCoreHistory(CORE)}>
            Core Data History
          </div>
          <span> </span>
          <div className={'report-toggle'} onClick={() => this.toggleCoreHistory(OVERALL)}>
            Overall Item Feedback History
          </div>
          {
            <Modal
              visible={this.state.historyToggle}
              maskClosable={true}
              closable={true}
              onCancel={this.toggleCoreHistory}
              footer={false}
            >
              {this.renderHistoryDateSelector()}
              {this.renderCoreDataHistoryReport()}
            </Modal>
          }
        </div>
      );
    }
  };

  renderAssigneeFilterButton = () => {
    const assigneeFilter = _.find(this.props.tableFilters, obj => obj.param === 'assignee');
    if (this.props.isCoreDataManager) {
      return (
        <div
          className={`assigned-to-me-banner ${assigneeFilter ? 'active' : ''}`}
          onClick={this.handleMyFeedbackFilter}
        >
          <div className="banner-label">Only my Feedback</div>
        </div>
      );
    }
  };

  getPreferredImageReview = record => {
    const refName = this.generateRefName(record);

    if (this[refName]) {
      const reason = this[refName].getRejectReason();
      return reason;
    }

    return '';
  };

  updatePreferredImage = (commentId, row, data) => {
    console.log({ commentId, row, data });
    const { supc, prefferedImage, styleBucket, associatePreferred, additionalImages } = data;
    const otherImages = _.map(additionalImages, obj => {
      return { assetId: obj.assetId, renditions: obj.renditions, isPreferred: obj.isPreferred };
    });

    if (data) {
      if (data.isReject) {
        const rejectReason = this.getPreferredImageReview(row);
        const review = [
          {
            status: 'REJECT',
            code: '100',
            comment: rejectReason
          }
        ];

        this.props.actions.rejectPreferredImage(
          {
            commentId,
            supc,
            review,
            loading: true,
            associatePreferred,
            styleBucket
          },
          { suvc: row.suvc, additionalImages: otherImages }
        );
      } else {
        this.props.actions.approvePreferredImage(
          {
            supc,
            prefferedImage,
            styleBucket,
            commentId,
            loading: true
          },
          { suvc: row.suvc, additionalImages: otherImages }
        );
      }
    }
  };

  renderPreferredImageActionBtns = comment => {
    const data = _.find(this.props.preferredImages, obj => comment.id === obj.commentId) || {};
    const isTemp = isTempData(data);

    const color = data.isReject ? 'yellow-action-btn' : 'green-action-btn';
    const loading = data.loading;
    const disabled = _.isEmpty(data.additionalImages) || data.loading || isTemp;
    const text = data.isReject ? 'SEND FEEDBACK' : 'APPROVE';
    let tooltip = 'Update the Preferred Image in OTMM';

    if (data.isReject && !_.isEmpty(data.associatePreferred)) {
      tooltip = 'Reject supplier preferred image and approve associate preferred image';
    } else if (data.isReject) {
      tooltip = 'Reject preferred image';
    }

    if (comment.status === 10) {
      return (
        <div className="action-list">
          <div className="action-text rejected" title="This has marked as rejected.">
            REJECTED
          </div>
        </div>
      );
    }

    return (
      <Button
        type="primary"
        className={`btn in-grid title-bar-button ${color}`}
        loading={loading}
        disabled={disabled}
        onClick={e => {
          e.stopPropagation();
          this.updatePreferredImage(comment.id, comment, data);
        }}
        title={tooltip}
      >
        {text}
      </Button>
    );
  };

  render() {
    const {
      comments,
      page,
      recordCount,
      loading,
      actions,
      tableSearchData,
      tableFilters,
      dateRangeFilter,
      permissions,
      roles
    } = this.props;

    const { Search } = Input;
    const tagColors = {
      COREDATA: 'green',
      IMAGE: 'blue',
      FnB: 'orange',
      TAXONOMY: 'purple',
      OVERALL: 'red',
      NUTRITION: 'darkgreen'
    };

    const { tableSearchData: localTableSearchData } = this.state;

    const typeFilters = this.getSpecificSearchTermValue(tableFilters, 'type');
    const typeFilteredValues = getFieldKeyOrAssignDefaultValue(typeFilters, 'val', []);

    const statusFilters = this.getSpecificSearchTermValue(tableFilters, 'status');
    const statusFilteredValues = getFieldKeyOrAssignDefaultValue(statusFilters, 'val', []);

    const suvcSearch = this.getSpecificSearchTermValue(tableFilters, 'suvc');
    const localSuvcSearch = this.getSpecificSearchTermValue(localTableSearchData, 'suvc');

    const gtinSearch = this.getSpecificSearchTermValue(tableFilters, 'gtin');
    const localGtinSearch = this.getSpecificSearchTermValue(localTableSearchData, 'gtin');

    const mpcSearch = this.getSpecificSearchTermValue(tableFilters, 'mpc');
    const localMpcSearch = this.getSpecificSearchTermValue(localTableSearchData, 'mpc');

    // make this enable in the internal release
    const imageDisable = false;

    const getSearchTextValue = (propsSearchText, localSearchText) => {
      return getSearchValue(propsSearchText, localSearchText);
    };

    const renderBrandFilters = () => {
      const brandGroupFilter = _.get(this.props, 'brandGroupFilters[0]', null);
      return (
        <div className="brand-filters-wrapper">
          {<CoreReviewConfirmationModal onConfirm={this.updateCoreData} />}
          {this.coreHistoryReport()}
          <div className="comments-filters multiple-search-boxes">
            {this.renderAssigneeFilterButton()}
            <TreeSelect
              treeData={this.props.isCoreDataManager ? CORE_DATA_ATTRIBUTE_STATUSES : ITEM_FEEDBACK_STATUSES}
              value={statusFilteredValues}
              onChange={this.handleStatusOnChangeFilter}
              treeCheckable
              placeholder="Select status..."
              className="feedback-filter-multisearch"
              size="small"
            />
            <Search
              className="feedback-filter-search"
              value={getSearchTextValue(suvcSearch, localSuvcSearch)}
              placeholder="Search by SUVC..."
              onChange={e => this.onTableSearchInputChange('suvc', e.target.value)}
              onSearch={this.handleMassSearch}
              enterButton
              size="small"
            />
            <Search
              className="feedback-filter-search"
              value={getSearchTextValue(gtinSearch, localGtinSearch)}
              placeholder="Search by GTIN..."
              onChange={e => this.onTableSearchInputChange('gtin', e.target.value)}
              onSearch={this.handleMassSearch}
              enterButton
              size="small"
            />
            <Search
              className="feedback-filter-search"
              value={getSearchTextValue(mpcSearch, localMpcSearch)}
              placeholder="Search by MPC..."
              onChange={e => this.onTableSearchInputChange('mpc', e.target.value)}
              onSearch={this.handleMassSearch}
              enterButton
              size="small"
            />
          </div>
          <RadioGroup
            className="table-brand-filter comments-brand-filter-set"
            value={brandGroupFilter}
            onChange={e => this.onChangeBrandGroupdFilters(e.target.value)}
          >
            <Radio value="S">Sysco</Radio>
            <Radio value="P">Packer</Radio>
            <Radio value="N">National</Radio>
            <Radio value={null}>All</Radio>
          </RadioGroup>

          <Button className="clear-all-filters" onClick={this.clearAllFilters}>
            Clear Filters
          </Button>
        </div>
      );
    };

    const getButtonTitle = (comment, overallFeedbackHistoryChanges) => {
      let buttonTitle = 'Please make the requested change done, and mark this as complete.';
      switch (comment.type) {
        case 'IMAGE':
          buttonTitle = getImagesAndAtrbyteImageButtonTitleInFeedback(
            imageDisable,
            comment,
            this.state.rejectedFnBComments,
            getButtonDisable
          );
          break;
        case 'ATRBYTIMG':
          buttonTitle = getImagesAndAtrbyteImageButtonTitleInFeedback(
            imageDisable,
            comment,
            this.state.rejectedFnBComments,
            getButtonDisable
          );
          break;
        case 'FnB':
          buttonTitle = getIsFeedbackRejected(
            this.state.rejectedFnBComments,
            comment,
            'This will auto-update the F&B statement in Stibo.'
          );
          break;
        case 'NUTRITION':
          buttonTitle = getNutritionFeedbackSubmitButtonTitle(comment, roles);
          break;
        case 'CORE':
          buttonTitle = 'Process each attribute';
          break;
        case OVERALL:
          buttonTitle = getOverallFeedbackButtonTitle(permissions, roles, comment, overallFeedbackHistoryChanges);
          break;
        default:
          break;
      }
      return buttonTitle;
    };

    const getButtonCaption = (comment, feedbackChangesHistory) => {
      let caption = 'Mark as Complete';
      if (getButtonDisable(comment)) caption = '...';
      switch (comment.type) {
        case 'FnB':
        case 'ATRBYTFNB':
          caption = 'APPROVE';
          caption = getButtonCaptionOnRejectedFeedbacks(this.state.rejectedFnBComments, comment, caption);
          break;
        case 'IMAGE':
        case 'ATRBYTIMG':
          caption = 'APPROVE';
          caption = getButtonCaptionOnRejectedFeedbacks(this.state.rejectedImageComments, comment, caption);
          break;
        case 'NUTRITION':
          caption = getNutritionSubmitButtonCaption(comment);
          break;
        case 'CORE':
          caption = 'PROCESS';
          break;
        case 'ATRBYTUNI':
          caption = 'PROCESS';
          break;
        case OVERALL:
          caption = 'PROCESS';
          caption = getOverallFeedbackButtonCaption(comment, feedbackChangesHistory);
        default:
          break;
      }
      return caption;
    };

    const getButtonDisable = (comment, overallFeedbackHistoryChanges) => {
      return getButtonDisableStatusOnFeedback(comment, imageDisable, permissions, roles, overallFeedbackHistoryChanges);
    };

    const getButtonColor = ({ id, type, status, comment }, feedbackChangesHistory) => {
      return getButtonColorOnFeedback(id, type, status, comment, this.state, feedbackChangesHistory);
    };

    const columns = [
      {
        title: 'Date',
        dataIndex: 'createdAt',
        filterDropdown: this.renderDateRangeFilterDropDown(),
        key: 'createdAt',
        filterIcon: (
          <Icon
            type="search"
            style={{
              color: getValueBasedOnTheCondition(!_.isEmpty(_.get(dateRangeFilter, 'val', null)), '#108ee9', '#aaa')
            }}
          />
        ),
        sorter: true,
        sortOrder: this.getSortOrder('createdAt'),
        width: 100,
        render: (status, row) => <span>{formatDate(status)}</span>
      },
      {
        title: 'Supplier',
        dataIndex: 'supplierName',
        key: 'supplierName',
        filterIcon: (
          <Icon
            type="search"
            style={{
              color: getValueBasedOnTheCondition(
                !_.isEmpty(this.getSpecificSearchTermValue(tableSearchData, 'supplierName')),
                '#108ee9',
                '#aaa'
              )
            }}
          />
        ),
        sorter: true,
        sortOrder: this.getSortOrder('supplierName'),
        filterDropdown: this.renderTableSearch('supplierName'),
        width: 150
      },
      {
        title: 'SUPC',
        dataIndex: 'supc',
        key: 'supc',
        filterIcon: (
          <Icon
            type="search"
            style={{
              color: getValueBasedOnTheCondition(
                !_.isEmpty(this.getSpecificSearchTermValue(tableSearchData, 'supc')),
                '#108ee9',
                '#aaa'
              )
            }}
          />
        ),
        sorter: true,
        sortOrder: this.getSortOrder('supc'),
        filterDropdown: this.renderTableSearch('supc'),
        width: 100,
        render: (status, row) => (
          <span
            className="comment-supc"
            onClick={e => {
              actions.loadSupc({ supc: row.supc, suvc: row.suvc });
              e.stopPropagation();
            }}
          >
            {row.supc}
          </span>
        )
      },
      {
        title: 'Description',
        dataIndex: 'itemDescription',
        key: 'itemDescription',
        filterIcon: (
          <Icon
            type="search"
            style={{
              color: getValueBasedOnTheCondition(
                !_.isEmpty(this.getSpecificSearchTermValue(tableSearchData, 'itemDescription')),
                '#108ee9',
                '#aaa'
              )
            }}
          />
        ),
        sorter: true,
        sortOrder: this.getSortOrder('itemDescription'),
        filterDropdown: this.renderTableSearch('itemDescription'),
        width: 150,
        render: (description, item) => this.renderDescriptionWithBrandsTitle(description, item)
      },
      {
        title: 'Type',
        key: 'type',
        dataIndex: 'type',
        filters: this.getTypeFilterOptions(),
        filteredValue: typeFilteredValues,
        align: 'center',
        width: 100,
        ellipsis: true,
        render: (type, row) => (
          <span>
            <Tag color={getValueBasedOnTheCondition(tagColors[type], tagColors[type], 'grey')}>
              {type.toUpperCase()}
            </Tag>
          </span>
        )
      },
      {
        title: 'Field Name',
        dataIndex: 'field',
        key: 'field',
        filterIcon: (
          <Icon
            type="search"
            style={{
              color: getValueBasedOnTheCondition(
                !_.isEmpty(this.getSpecificSearchTermValue(tableSearchData, 'field')),
                '#108ee9',
                '#aaa'
              )
            }}
          />
        ),
        sorter: true,
        sortOrder: this.getSortOrder('field'),
        filterDropdown: this.renderTableSearch('field'),
        width: 200,
        render: (field, row) => <span>{field.replace('syy_itm_a_', '').replace('_', ' ')}</span>
      },
      {
        title: 'Value',
        dataIndex: 'originalValue',
        key: 'originalValue',
        width: 180,
        filterIcon: (
          <Icon
            type="search"
            style={{
              color: getValueBasedOnTheCondition(
                !_.isEmpty(this.getSpecificSearchTermValue(tableSearchData, 'originalValue')),
                '#108ee9',
                '#aaa'
              )
            }}
          />
        ),
        sorter: true,
        sortOrder: this.getSortOrder('originalValue'),
        filterDropdown: this.renderTableSearch('originalValue'),
        render: (field, row) => <CommentOriginalValue field={field} row={row} />
      },
      {
        title: 'Change Comment',
        dataIndex: 'comment',
        key: 'comment',
        width: 'auto',
        filterIcon: (
          <Icon
            type="search"
            style={{
              color: getValueBasedOnTheCondition(
                !_.isEmpty(this.getSpecificSearchTermValue(tableSearchData, 'comment')),
                '#108ee9',
                '#aaa'
              )
            }}
          />
        ),
        sorter: true,
        sortOrder: this.getSortOrder('comment'),
        filterDropdown: this.renderTableSearch('comment'),
        render: (field, row) => {
          switch (row.type) {
            case 'FnB':
              return <span style={{ color: '#008ace' }}>Click to see FnB...</span>;
            case 'NUTRITION':
              return <span style={{ color: '#008ace' }}>Click to see NUTRITION...</span>;
            case 'CORE':
              return <span style={{ color: '#008ace' }}>Click to see CORE DATA...</span>;
            case ATRBYTUNI:
              return <span style={{ color: '#008ace' }}>Click to see data...</span>;
            default:
              return <span>{field && field.length > 100 ? `${field.substr(0, 99)} ...` : field}</span>;
          }
        }
      },
      {
        title: 'Created by',
        dataIndex: 'createdBy',
        key: 'createdBy',
        width: 'auto',
        filterIcon: (
          <Icon
            type="search"
            style={{
              color: getValueBasedOnTheCondition(
                !_.isEmpty(this.getSpecificSearchTermValue(tableSearchData, 'createdBy')),
                '#108ee9',
                '#aaa'
              )
            }}
          />
        ),
        sorter: true,
        sortOrder: this.getSortOrder('createdBy'),
        filterDropdown: this.renderTableSearch('createdBy')
      },
      {
        title: '',
        key: 'status',
        dataIndex: 'status',
        align: 'right',
        width: 180,
        render: (status, row) => {
          if (row.status !== 30 && row.field === 'PREFERRED IMAGE') {
            return this.renderPreferredImageActionBtns(row);
          }

          let disabled = getFieldDisabledStatusByTwoParams(
            getButtonDisable(row, this.props.overallFeedbackHistoryChanges),
            loading
          );

          if (row.type === 'CORE') {
            disabled = disabled || !isCommentAssignedToMe(this.props.userId, row.assignee);
          }

          return (
            <div className="action-list">
              {row.type === 'COREDATA' && (
                <Button
                  className="btn yellown-action-btn in-grid title-bar-button stibo-btn"
                  onClick={() =>
                    window.open(
                      `https://sysco-prod.scloud.stibo.com/webui/syy_itm_vnd_ui_master#contextID=Context1&workspaceID=Main&screen=Item_Master&selection=${row.stepId}&nodeType=product&selectedTab=536232147.1`,
                      '_blank'
                    )
                  }
                  title="You can find this item in Stibo, But you'll have to be signed in first."
                />
              )}

              {row.status === 30 ? (
                <div className="action-text completed" title="This has marked as complete.">
                  COMPLETED
                </div>
              ) : (
                <Button
                  type="primary"
                  className={`btn in-grid title-bar-button ${getButtonColor(
                    row,
                    this.props.overallFeedbackHistoryChanges
                  )}`}
                  loading={loading === row.id}
                  disabled={disabled}
                  onClick={e => {
                    e.stopPropagation();
                    this.onSendComments(row.id, row);
                  }}
                  title={getButtonTitle(row, this.props.overallFeedbackHistoryChanges)}
                >
                  {getValueBasedOnTheCondition(
                    loading === row.id,
                    '',
                    getButtonCaption(row, this.props.overallFeedbackHistoryChanges)
                  )}
                </Button>
              )}
            </div>
          );
        }
      }
    ];
    return (
      <span>
        <div className="grid-filter-panel">
          <div
            className="back-to-dashboard"
            onClick={() => {
              window.location.href = '/suite/dashboard';
            }}
          ></div>
          <div className="bread-crum-seperator"></div>
          <div className="bread-crum-caption">Item Feedback</div>

          {/* <div className="comments-filters">
            <Search
              className="search-box"
              defaultValue={search}
              placeholder={'Search by SUPC, "Created By", SUVC, GTIN, MPC and Comment...'}
              onSearch={this.searchFeedback}
              enterButton
            />
          </div> */}
        </div>
        <div className="items-list-table">{renderBrandFilters()}</div>
        <ReactResizeDetector
          handleWidth
          handleHeight
          render={({ width, height }) => (
            <div className="grid-panel feedback-grid-panel">
              <Table
                rowKey={'id'}
                className="comments-table items-list-tbl"
                columns={columns}
                dataSource={comments}
                size="small"
                onChange={this.handleFilterChanges}
                expandedRowRender={record => {
                  switch (record.type) {
                    case 'ATRBYTFNB':
                      const refAtr = this.generateRefName(record);
                      return (
                        <CommentExpanded
                          comment={record}
                          ref={element => {
                            this[refAtr] = element;
                          }}
                          isUpdating={loading === record.id}
                          addFnBCommentToRejectState={this.addFnBCommentToRejectState}
                          removeFnBCommentFromRejectState={this.removeFnBCommentFromRejectState}
                        />
                      );
                    case 'FnB':
                      const refFnb = this.generateRefName(record);
                      return (
                        <CommentExpanded
                          comment={record}
                          ref={element => {
                            this[refFnb] = element;
                          }}
                          isUpdating={loading === record.id}
                          addFnBCommentToRejectState={this.addFnBCommentToRejectState}
                          removeFnBCommentFromRejectState={this.removeFnBCommentFromRejectState}
                        />
                      );
                    case 'NUTRITION':
                      const refNut = this.generateRefName(record);
                      return (
                        <CommentExpanded
                          comment={record}
                          ref={element => {
                            this[refNut] = element;
                          }}
                          isUpdating={loading === record.id}
                          handlePostStyleChange={this.handlePostStyleChange}
                        />
                      );
                    case 'CORE':
                      const refCore = this.generateRefName(record);
                      return (
                        <CommentExpanded
                          comment={record}
                          ref={element => {
                            this[refCore] = element;
                          }}
                          isUpdating={loading === record.id}
                          handlePostStyleChange={this.handlePostStyleChange}
                        />
                      );
                    case ATRBYTUNI:
                      const refUni = this.generateRefName(record);
                      return (
                        <CommentExpanded
                          comment={record}
                          ref={element => {
                            this[refUni] = element;
                          }}
                          isUpdating={loading === record.id}
                          handlePostStyleChange={this.handlePostStyleChange}
                        />
                      );
                    case 'OVERALL':
                      const refOverall = this.generateRefName(record);
                      return (
                        <CommentExpanded
                          comment={record}
                          ref={element => {
                            this[refOverall] = element;
                          }}
                          isUpdating={loading === record.id}
                          handlePostStyleChange={this.handlePostStyleChange}
                        />
                      );
                    default:
                      break;
                  }
                  if (
                    ['ATRBYTIMG', 'IMAGE'].includes(record.type) &&
                    record.field !== 'Image Feedback' &&
                    record.imageComment &&
                    record.imageComment.changeType
                  ) {
                    const refName = this.generateRefName(record);

                    return (
                      <CommentExpanded
                        comment={record}
                        ref={element => {
                          this[refName] = element;
                        }}
                        isUpdating={loading === record.id}
                        addImageCommentToRejectState={this.addImageCommentToRejectState}
                        removeImageCommentFromRejectState={this.removeImageCommentFromRejectState}
                        handlePostStyleChange={this.handlePostStyleChange}
                      />
                    );
                  }
                  return <CommentExpanded comment={record} />;
                }}
                expandRowByClick
                pagination={false}
                // className="comments-table"
                rowClassName={record => this.getStatusIndicator(record)}
              />
              <Pagination
                size="small"
                className="grid-pagination"
                onChange={this.handlePagination}
                total={recordCount}
                showTotal={total => `${total} rows`}
                current={page}
                defaultPageSize={50}
                hideOnSinglePage
                showQuickJumper
              />
            </div>
          )}
        />
      </span>
    );
  }
}

function mapStateToProps(state) {
  return {
    fnbComments: state.comments.fnbComments,
    permissions: state.user.data.permissions,
    roles: state.user.data.roles,
    userId: state.user.data.contactId,
    preferredImages: state.comments.preferredImages,
    overallFeedbackHistoryChanges: state.comments.overallItemFeedbackHistory.singleComments
  };
}

export default connect(mapStateToProps)(CommentList);
