import React from 'react';
import { Tooltip } from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import TaxonomyFilter from '../TaxonomyFilter';
import * as actionCreators from '../../actions';
import { isValidHierarchyName, isPartialHierarchy, isNonFoodBc } from '../../util/Util';
import { PFAS_MASS_EDIT_BC_IG_AG_SELECTION_TOOLTIP } from '../../util/Constants';

export default function PfasMassEditTaxonomyFilter({ hasUnsubmittedData }) {
  const dispatch = useDispatch();
  const { handlePfasMassEditFilters } = bindActionCreators(actionCreators, dispatch);

  const fetchingAttributes = useSelector(state => _.get(state.massUpdate.pfas, 'fetchingTaxonomy', false));
  const bc = useSelector(state => _.get(state.massUpdate.pfas, 'filters.bc', null));
  const ig = useSelector(state => _.get(state.massUpdate.pfas, 'filters.ig', null));
  const ag = useSelector(state => _.get(state.massUpdate.pfas, 'filters.ag', null));
  const bcs = useSelector(state => _.get(state.scorecards, 'taxonomy', {}));
  const completeTaxonomy = useSelector(state => _.get(state.massUpdate.pfas, 'completeTaxonomy', []));
  const attributeFilters = useSelector(state => _.get(state.massUpdate.pfas, 'filters.attributeFilters', []));

  const igs = bcs[bc] || {};
  const ags = igs[ig] || {};
  const isNonFood = isNonFoodBc(completeTaxonomy, bc);

  const onChangeFilter = taxoFilters => {
    if (!hasUnsubmittedData()) {
      handlePfasMassEditFilters(taxoFilters);
    }
  };

  const TooltipInfo = () => {
    return (
      <Tooltip
        overlayClassName="mass-attr-tooltip"
        placement="bottom"
        title={<div className="mass-attr-tooltip-text">{PFAS_MASS_EDIT_BC_IG_AG_SELECTION_TOOLTIP}</div>}
      >
        <ExclamationCircleOutlined className="mass-attr-exclamation-icon" />
      </Tooltip>
    );
  };

  const RequiredMessage = ({ bc, fetchingAttributes }) => {
    if (!isValidHierarchyName(bc) && !fetchingAttributes) {
      return <div className="taxo-message">* Required</div>;
    }
    return null;
  };

  return (
    <div className="attr-mass-taxo-filter-wrapper">
      <div>
        <TaxonomyFilter
          className="attr-mass-taxo-filter"
          bc={bc}
          ig={ig}
          ag={ag}
          businessCenters={bcs}
          itemGroups={igs}
          attributeGroups={ags}
          onChangeFilter={onChangeFilter}
          attributeFilters={attributeFilters}
        />
        <RequiredMessage fetchingAttributes={fetchingAttributes} bc={bc} />
      </div>
      <TooltipInfo />
    </div>
  );
}
