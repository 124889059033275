import React from 'react';
import _ from 'lodash';
import { Tooltip } from 'antd';
import {
  getAveragePercentageOfAttributeCompletion,
  getAveragePercentageOfImageCompletion,
  getAverageFeaturesAndBenifitsCompletion,
  getCompletionOfPercentageStyleColor,
  getCompletionText
} from '../lib/helpers';

class ItemScore extends React.Component {
  render() {
    let { supc, summaryItems } = this.props;

    const supcScores = _.find(summaryItems, item => {
      return item.supc === supc;
    });

    if (!supcScores) {
      return <div></div>;
    }

    let attributeCompletionPercentage = 0,
      imageCompletionPercentage = 0,
      fnbCompletionPercentage = 0;
    try {
      attributeCompletionPercentage = getAveragePercentageOfAttributeCompletion(supcScores.dim.attr.at);
    } catch (e) {}
    try {
      fnbCompletionPercentage = getAverageFeaturesAndBenifitsCompletion(supcScores.prm);
    } catch (e) {}
    try {
      imageCompletionPercentage = getAveragePercentageOfImageCompletion(supcScores.prm);
    } catch (e) {}
    let isAttributionCompleteText = getCompletionText(attributeCompletionPercentage);
    let isFnbCompleteText = getCompletionText(fnbCompletionPercentage);
    let isImgCompleteText = getCompletionText(imageCompletionPercentage);

    let overallAverage = Math.round(
      (attributeCompletionPercentage + imageCompletionPercentage + fnbCompletionPercentage) / 3
    );
    let overallAverageCompletionText = getCompletionText(overallAverage);

    return (
      <div className="list-item-scores">
        <Tooltip
          className="list-item-score overall"
          title={
            <div style={{ paddingLeft: '10px' }}>
              Overall: {overallAverageCompletionText} ({overallAverage}%)
            </div>
          }
        >
          <div className={`${getCompletionOfPercentageStyleColor(overallAverage)}`}>{overallAverage}%</div>
        </Tooltip>
        <Tooltip
          className="list-item-score"
          title={
            <div style={{ paddingLeft: '10px' }}>
              Attribution: {isAttributionCompleteText} ({attributeCompletionPercentage}%)
            </div>
          }
        >
          <div className={`${getCompletionOfPercentageStyleColor(attributeCompletionPercentage)}`}>
            {attributeCompletionPercentage}%
          </div>
        </Tooltip>
        <Tooltip
          className="list-item-score"
          title={
            <div style={{ paddingLeft: '10px' }}>
              Fnb: {isFnbCompleteText} ({fnbCompletionPercentage}%)
            </div>
          }
        >
          <div className={`${getCompletionOfPercentageStyleColor(fnbCompletionPercentage)}`}>
            {fnbCompletionPercentage}%
          </div>
        </Tooltip>
        <Tooltip
          className="list-item-score"
          title={
            <div style={{ paddingLeft: '10px' }}>
              Image: {isImgCompleteText} ({imageCompletionPercentage}%)
            </div>
          }
        >
          <div className={`${getCompletionOfPercentageStyleColor(imageCompletionPercentage)}`}>
            {imageCompletionPercentage}%
          </div>
        </Tooltip>
      </div>
    );
  }
}

export default ItemScore;
