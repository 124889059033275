import React from 'react';
import { Input, Button, Upload, Select, Modal, Icon } from 'antd';
import ReactResizeDetector from 'react-resize-detector';
import ImageGuide from './ImageGuide';
import ItemDatesBar from './ItemDatesBar';
import _ from 'lodash';
import {
  imageDeleteReasons,
  imageNameTypes,
  imageNameFacings,
  imageNameAngles,
  imageStyles,
  nOneFacingOptions,
  imageFoodStyles,
  imageNonFoodStyles,
  imageCategories,
  mainCategoriesList
} from '../util/Data';
import {
  getFileNamePart,
  getStyleCode,
  getFormat,
  isNonFood,
  isValidImageName,
  checkObjectAndKeyExists,
  checkStatusWithTwoAndConditions,
  getValueBasedOnTheCondition,
  getFieldKeyOrAssignDefaultValue,
  getValuesFromTwoFields
} from '../util/Util';
import { isFirstImage } from '../util/ImageUtils';

class ImageUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      invalidStyle: false,
      invalidImage: false,
      invalidReason: false,
      invalidType: false,
      invalidFacing: false,
      invalidAngle: false,
      comment: '',
      imageComment: {
        image: {
          fileList: '',
          value: '',
          error: ''
        },
        givenName: '',
        imageStyle: '',
        imageType: '',
        imageFacing: '',
        imageAngle: '',
        reason: ''
      }
    };
    this.sendFieldComments = this.sendFieldComments.bind(this);
    this.collectComments = this.collectComments.bind(this);
  }

  checkAndSetImageStatuses = (imageComment, keyToCheck, keyToSet, isInvalid, value = true) => {
    let formValidity = isInvalid;
    if (!imageComment[keyToCheck]) {
      this.setState({ [keyToSet]: value });
      formValidity = true;
    }
    return formValidity;
  };

  handleImageFacingCondition = (imageComment, formInvalid, logoStyle) => {
    let logo = logoStyle;
    let invalidStatus = formInvalid;
    if (imageComment.imageFacing && imageComment.imageFacing.indexOf('L') !== -1) {
      // styles, types, and angles are not nessosorry if facing is an N1
      this.setState({ invalidStyle: false });
      this.setState({ invalidType: false });
      this.setState({ invalidAngle: false });
      logo = 'LOGO';
    } else {
      invalidStatus = this.checkAndSetImageStatuses(imageComment, 'imageStyle', 'invalidStyle', formInvalid);
      invalidStatus = this.checkAndSetImageStatuses(imageComment, 'imageType', 'invalidType', formInvalid);
      invalidStatus = this.checkAndSetImageStatuses(imageComment, 'imageAngle', 'invalidAngle', formInvalid);
    }
    return { logo, invalidStatus };
  };

  checkCommentLengthAndValidateMessage = (comment, fieldComment, imgStyle, item) => {
    if (comment.comment.length === 0) {
      return {
        ...comment,
        comment: getValueBasedOnTheCondition(
          fieldComment.type === 'IMAGE_REPLACE',
          `${imgStyle} image replacement for ${item.supc}.`,
          `New ${imgStyle} image for ${item.supc}.`
        )
      };
    }
    return comment;
  };

  setComment = (comment, supc) => {
    if (comment.comment.length === 0) {
      return { ...comment, comment: `Image style change for ${supc}.` };
    }
    return comment;
  };

  checkImageFacingStyles = (imageComment, isFormInvalid) => {
    let formInvalid = isFormInvalid;
    if (imageComment.imageFacing && imageComment.imageFacing.indexOf('L') !== -1) {
      // styles, types, and angles are not nessosorry if facing is an N1
      this.setState({ invalidStyle: false });
      this.setState({ invalidType: false });
      this.setState({ invalidAngle: false });
    } else {
      formInvalid = this.checkAndSetImageStatuses(imageComment, 'imageStyle', 'invalidStyle', formInvalid);
      formInvalid = this.checkAndSetImageStatuses(imageComment, 'imageType', 'invalidType', formInvalid);
      formInvalid = this.checkAndSetImageStatuses(imageComment, 'imageAngle', 'invalidAngle', formInvalid);
    }
    return formInvalid;
  };

  setInvalidReasonAndStatus = (comment, imageComment, isInvalid) => {
    let formInvalid = isInvalid;
    if (imageComment.reason === 'Other' && comment.comment.trim().length === 0) {
      this.setState({ invalidReason: 'Please mention the reason in comments.' });
      formInvalid = true;
    }
    return formInvalid;
  };

  getDataForDefaultPreferredImageRequest = (imgStyle, fieldComment, item) => {
    const isFirst = isFirstImage(imgStyle, fieldComment.existingStyles, this.props.pendingComments);
    const syscoBrand = _.get(item, 'basicData.syscoBrand', null);
    const isNational = _.isNull(syscoBrand) || syscoBrand !== 'Y'; // Same as the logic in the otmm-national-auto-approval job

    return {
      isFirstImage: isFirst,
      isPreferred: isFirst || isNational ? true : false
    };
  };

  checkFormValidationWithNewOrDeleteReplace = (
    formInvalid,
    fieldComment,
    item,
    imgStyle,
    imageComment,
    comment,
    actions
  ) => {
    if (formInvalid) {
      return;
    }
    let deletionDetails = {
      image: fieldComment.image,
      isDeletion: fieldComment.type === 'IMAGE_REPLACE'
    };
    const {
      invalidStyle: stateInvalidStyle,
      invalidType: stateInvalidType,
      invalidFacing: stateInvalidFacing,
      invalidAngle: stateInvalidAngle
    } = this.state;
    if (!stateInvalidStyle && !stateInvalidType && !stateInvalidFacing && !stateInvalidAngle) {
      const preferredImageData = this.getDataForDefaultPreferredImageRequest(imgStyle, fieldComment, item);

      actions.sendImageComment(
        item.supc,
        item.suvc,
        imageComment.image.value,
        imgStyle,
        imageComment.givenName,
        {
          ...comment
        },
        deletionDetails,
        preferredImageData
      );
    }
  };

  checkFormValidationWithDelete = (formInvalid, item, fieldComment, comment, imageComment, actions) => {
    if (formInvalid) {
      return;
    }
    actions.sendDeleteImageComment(item.supc, item.suvc, fieldComment.image, { ...comment }, imageComment.reason);
  };

  checkFormValidationWithStyleChange = (formInvalid, item, fieldComment, imageComment, comment, actions) => {
    if (formInvalid) {
      return;
    }
    actions.sendImageStyle(
      item.supc,
      item.suvc,
      { ...fieldComment.image, name: this.state.imageComment.givenName },
      imageComment.imageStyle,
      { ...comment },
      true
    );
  };

  handleCommentSend = (commentForImage, actions, fieldComment, item) => {
    let comment = { ...commentForImage };
    const { imageComment } = this.state;
    switch (fieldComment.type) {
      case 'IMAGE_NEW':
      case 'IMAGE_REPLACE': {
        let imgStyle = '';
        const invalid = this.checkAndSetImageStatuses(imageComment, 'imageFacing', 'invalidFacing', false);
        let { logo: logoStyle, invalidStatus: formInvalid } = this.handleImageFacingCondition(
          imageComment,
          invalid,
          false
        );
        formInvalid = this.checkAndSetImageStatuses(imageComment.image, 'value', 'invalidImage', formInvalid);
        imgStyle = getValuesFromTwoFields(logoStyle, imageComment.imageStyle);
        comment = { ...this.checkCommentLengthAndValidateMessage(comment, fieldComment, imgStyle, item) };
        this.checkFormValidationWithNewOrDeleteReplace(
          formInvalid,
          fieldComment,
          item,
          imgStyle,
          imageComment,
          comment,
          actions
        );
        break;
      }
      case 'IMAGE_DELETE': {
        let formInvalid = this.checkAndSetImageStatuses(
          imageComment,
          'reason',
          'invalidReason',
          false,
          'Please select the reason.'
        );
        formInvalid = this.setInvalidReasonAndStatus(comment, imageComment, formInvalid);
        if (formInvalid) {
          return;
        }
        actions.sendDeleteImageComment(item.supc, item.suvc, fieldComment.image, { ...comment }, imageComment.reason);
        break;
      }
      case 'IMAGE_STYLE': {
        let formInvalid = false;
        comment = { ...this.setComment(comment, item.supc) };
        if (isValidImageName(fieldComment.image.name)) {
          formInvalid = this.checkAndSetImageStatuses(imageComment, 'imageStyle', 'invalidStyle', formInvalid);
          this.checkFormValidationWithStyleChange(formInvalid, item, fieldComment, imageComment, comment, actions);
        } else if (
          checkStatusWithTwoAndConditions(
            checkObjectAndKeyExists(fieldComment, 'image'),
            !isValidImageName(fieldComment.image.name)
          )
        ) {
          formInvalid = this.checkAndSetImageStatuses(imageComment, 'imageFacing', 'invalidFacing', formInvalid);
          formInvalid = this.checkImageFacingStyles(imageComment, formInvalid);
          this.checkFormValidationWithStyleChange(formInvalid, item, fieldComment, imageComment, comment, actions);
        }
        break;
      }
      case 'IMAGE_FEEDBACK': {
        if (comment.comment.length === 0) {
          return;
        }
        actions.sendFeedbackImageComment(item.supc, item.suvc, fieldComment.image, { ...comment });
        break;
      }
      default:
        break;
    }
  };

  sendFieldComments() {
    let { fieldComment, actions } = this.props;
    const item = fieldComment.item;

    if (fieldComment.loading) {
      return;
    }

    let comment = {
      field: fieldComment.field,
      originalValue: fieldComment.currentValue,
      type: fieldComment.type,
      comment: this.state.comment,
      supc: fieldComment.item.supc,
      suvc: fieldComment.item.suvc,
      stepId: fieldComment.item.basicData.stepId,
      id: getFieldKeyOrAssignDefaultValue(fieldComment.existingComment, 'id', null),
      previousCommentId: fieldComment.previousCommentId
    };

    this.handleCommentSend(comment, actions, fieldComment, item);
  }

  collectComments(comment) {
    this.setState({
      comment: comment
    });
  }

  updateImageName = () => {
    const { imageComment } = this.state;
    const { fieldComment } = this.props;
    const { imageStyle, imageType, imageFacing, imageAngle, image } = imageComment;
    let newName = '';
    if (
      imageFacing !== '' &&
      fieldComment.item &&
      fieldComment.item.basicData &&
      ((image && image.value && image.value.type) || (fieldComment && fieldComment.image && fieldComment.image.mime))
    ) {
      const itemData = fieldComment.item.basicData;
      const format = getFormat(image.value.type || fieldComment.image.mime);
      if (imageFacing.indexOf('L') !== -1) {
        newName = `${itemData.gtin}_${imageFacing}.${format}`;
      } else if (imageStyle !== '' && imageType !== '' && imageAngle !== '') {
        newName = `${itemData.gtin}_${imageType}${imageFacing}${imageAngle}${getStyleCode(imageStyle)}.${format}`;
      }
    }
    this.setState({
      imageComment: {
        ...imageComment,
        givenName: newName
      }
    });
  };

  handleImageupload = info => {
    const { imageComment } = this.state;

    if (this.handleImageValidation(info.file)) {
      let fileList = [...info.fileList];
      fileList = fileList.slice(-1);

      fileList = fileList.map(file => {
        file.status = 'done';
        return file;
      });

      this.setState(
        {
          imageComment: {
            ...imageComment,
            image: {
              fileList: fileList,
              value: info.file,
              error: ''
            }
          },
          invalidImage: false
        },
        this.updateImageName
      );
    }
  };

  updateDropdownStore = (newValObj, validationObj) => {
    const { imageComment } = this.state;
    this.setState(
      {
        imageComment: {
          ...imageComment,
          ...newValObj
        },
        ...validationObj
      },
      this.updateImageName
    );
  };

  handleReasonSelect = value => {
    this.updateDropdownStore({ reason: value }, { invalidReason: false });
  };

  handleTypeSelect = value => {
    this.updateDropdownStore({ imageType: value }, { invalidType: false });
  };

  handleFacingSelect = value => {
    this.updateDropdownStore({ imageFacing: value }, { invalidFacing: false });
  };

  handleAngleSelect = value => {
    this.updateDropdownStore({ imageAngle: value }, { invalidAngle: false });
  };

  handleImageStyleSelect = value => {
    this.updateDropdownStore({ imageStyle: value, imageFacing: '' }, { invalidStyle: false });
  };

  handleImageValidation(file) {
    var flag = true;
    if (['image/png', 'image/jpg', 'image/jpeg'].indexOf(file.type) === -1) {
      flag = false;
      let secondsToGo = 5;
      const modal = Modal.error({
        title: 'Invalid File Type',
        content: `${file.name} is not a png file.`
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        modal.destroy();
      }, secondsToGo * 1000);
    }
    return flag;
  }

  filterAllowedStyles = (fieldComment, imageStylesAllowed) => {
    let allowedStyles = imageStylesAllowed;
    if (checkStatusWithTwoAndConditions(fieldComment.type === 'IMAGE_STYLE', fieldComment.currentValue)) {
      allowedStyles = _.filter(imageStylesAllowed, style => {
        return style.toLowerCase() !== fieldComment.currentValue.toLowerCase();
      });
    }
    return allowedStyles;
  };

  getAllowedImageStyles = fieldComment => {
    return fieldComment && fieldComment.imageCategory && fieldComment.imageCategory.styles
      ? fieldComment.imageCategory.styles
      : imageStyles;
  };

  getBasicData = (fieldComment, data) => {
    if (checkObjectAndKeyExists(fieldComment.item, 'basicData')) {
      return { ...fieldComment.item.basicData };
    }
    return data;
  };

  getImageCommentGivenName = () => {
    return (
      this.state.imageComment.givenName.length > 0 && (
        <div className="image-attr-name-select file-name-green">
          Formatted Name:&nbsp;<span>{this.state.imageComment.givenName}</span>
        </div>
      )
    );
  };

  getFieldCommentHint = fieldComment => {
    if (fieldComment.hint && fieldComment.hint !== '') {
      return <div className="comments-hint">{fieldComment.hint}</div>;
    }
    return <div className="comments-seperator"></div>;
  };

  setBcIgAg = (bc, ig, ag) => {
    return (
      bc &&
      ig &&
      ag && (
        <div className="taxo-label-wrapper">
          <div title="Business Center">{bc}</div>
          <Icon className="taxo-label-arrow" type="right" />
          <div title="Item Group">{ig}</div>
          <Icon className="taxo-label-arrow" type="right" />
          <div title="Attribute Group">{ag}</div>
        </div>
      )
    );
  };

  setCurrentStylesDisplayed = fieldComment => {
    return (
      fieldComment.type === 'IMAGE_STYLE' &&
      fieldComment.currentValue && (
        <div style={{ marginBottom: '25px' }}>
          <div className="comments-attr-lbl">Current style</div>
          <div className="comments-attr-value">{fieldComment.currentValue}</div>
        </div>
      )
    );
  };

  setImageStyleDisplayed = (fieldComment, imageSrc) => {
    return fieldComment.type === 'IMAGE_STYLE' && imageSrc(fieldComment.image.url);
  };

  isFacingOptionDisabled = option => {
    const { primary, inPacking, openCase, lifeStyle } = imageCategories;
    const currentImageStyle = _.get(this.state, 'imageComment.imageStyle', null);
    const condition1 = _.includes(
      [...imageCategories.case.styles, ...openCase.styles, ...inPacking.styles],
      currentImageStyle
    );
    const condition2 = option != '1: Front';
    const condition3 = _.includes([...primary.styles, ...lifeStyle.styles], currentImageStyle);
    const condition4 = !_.includes(['1: Front', '3: Top'], option);
    return !currentImageStyle || (condition1 && condition2) || (condition3 && condition4);
  };

  render() {
    const { TextArea } = Input;
    const { Option, OptGroup } = Select;

    let { fieldComment, actions } = this.props;

    let data = {
      materialDescription: '',
      supc: '',
      brandName: '',
      manufactProdCode: ''
    };

    data = this.getBasicData(fieldComment, data);

    const closePopup = () => {
      actions.closeCommentsPopup();
    };

    const collectCommentsFn = event => {
      this.collectComments(event.target.value);
    };

    const sendFieldCommentsFn = () => {
      this.sendFieldComments();
    };

    // loading styles depend on the context
    let imageStylesAllowed = this.getAllowedImageStyles(fieldComment);

    if (fieldComment.type === 'IMAGE_REPLACE') {
      let imgCat = mainCategoriesList.find(imgCatId =>
        imageCategories[imgCatId].styles.includes(fieldComment.image.imageStyle.toLowerCase())
      );
      fieldComment.existingStyles = [];
      imageStylesAllowed = imageCategories[imgCat].styles.filter(ob => ob.toLowerCase() !== 'primary');
    }

    // removing food vs. non-food dependent styles
    imageStylesAllowed = _.intersection(
      imageStylesAllowed,
      getValueBasedOnTheCondition(isNonFood(fieldComment.item), imageNonFoodStyles, imageFoodStyles)
    );

    let explanation = '';
    if (checkStatusWithTwoAndConditions(fieldComment.type === 'IMAGE_NEW', fieldComment.imageCategory)) {
      let upperCaseName = fieldComment.imageCategory.caption.toUpperCase();
      explanation = (
        <div className="image-explanation">
          <div className="image-explanation-title">{`Upload an image for ${upperCaseName}.`}</div>
          <div className="image-explanation-wtf">{`What is ${upperCaseName}?`}</div>
          <div className="image-explanation-des">
            {getValueBasedOnTheCondition(
              fieldComment.imageCategory.explanation,
              fieldComment.imageCategory.explanation,
              `${upperCaseName} is a mandatory product image type. An image of following styles will be considered as a ${upperCaseName} image.`
            )}
          </div>
          <div className="image-explanation-wtf">Image styles</div>
          <ul>
            {imageStylesAllowed.map(imageStyleAllowed => (
              <li key={imageStyleAllowed}>{imageStyleAllowed}</li>
            ))}
          </ul>
        </div>
      );
    }
    switch (fieldComment.type) {
      case 'IMAGE_DELETE':
        explanation = (
          <div className="image-explanation">
            <div className="image-explanation-title">Delete image</div>
            <div className="image-explanation-des">
              Please indicate the reason for delete before sending the request.
            </div>
          </div>
        );
        break;
      case 'IMAGE_REPLACE':
        explanation = (
          <div className="image-explanation">
            <div className="image-explanation-title">Delete & Replace</div>
            <div className="image-explanation-des" style={{ marginBottom: '25px' }}>
              You can replace the image above with a more suitable image.
            </div>
          </div>
        );
        break;
      case 'IMAGE_STYLE':
        explanation = (
          <div className="image-explanation">
            <div className="image-explanation-title">Change image style</div>
          </div>
        );
        break;
      default:
        break;
    }

    let submitCaption = getValueBasedOnTheCondition(fieldComment.loading, 'SENDING...', 'SUBMIT FOR REVIEW');

    const imageSrc = url => <img src={url} alt={url} style={{ width: '150px', marginBottom: '25px' }} />;

    imageStylesAllowed = this.filterAllowedStyles(fieldComment, imageStylesAllowed);

    let bc = _.get(fieldComment, 'item.taxonomy.bc.name', null);
    let ig = _.get(fieldComment, 'item.taxonomy.ig.name', null);
    let ag = _.get(fieldComment, 'item.taxonomy.ag.name', null);

    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => (
          <div
            className="comments-wrapper"
            style={{
              transform: getValueBasedOnTheCondition(fieldComment.show, 'translateX(0vw)', 'translateX(100vw)')
            }}
          >
            <div style={{ height: window.innerHeight - 100 + 'px', overflow: 'auto' }}>
              <div className="comments-title-bar">
                <div className="back-button" onClick={closePopup}></div>
                <div className="item-view-title-bar">
                  <div className="item-view-title-name">
                    <div title={data.materialDescription}>{data.materialDescription}&nbsp;</div>
                    <div className="supc" title="SUPC. This is the product code used in Sysco">
                      &nbsp;({data.supc})
                    </div>
                  </div>
                  <div className="item-view-title-name sub-title">
                    <div title="Brand name">{data.brandName}&nbsp;</div>
                    <div title="Manufacturer product code">
                      {getValueBasedOnTheCondition(
                        checkStatusWithTwoAndConditions(data.manufactProdCode, data.manufactProdCode !== ''),
                        ' | ' + data.manufactProdCode,
                        ''
                      )}
                    </div>
                  </div>
                  {this.setBcIgAg(bc, ig, ag)}
                  <ItemDatesBar />
                </div>
              </div>
              <div className="comments-body image-comment-body">
                <div>
                  <div className="comments-attr">
                    {['IMAGE_FEEDBACK', 'IMAGE_DELETE', 'IMAGE_REPLACE'].indexOf(fieldComment.type) !== -1 &&
                      imageSrc(fieldComment.currentValue)}
                    {this.setImageStyleDisplayed(fieldComment, imageSrc)}
                    <div className="comments-attr-lbl">{explanation}</div>
                    {this.setCurrentStylesDisplayed(fieldComment)}
                    {['IMAGE_NEW', 'IMAGE_REPLACE'].includes(fieldComment.type) && (
                      <div className="comments-attr">
                        <div style={{ display: 'flex' }}>
                          <div>
                            <div className="comments-attr-lbl">Select image style</div>
                            <Select
                              style={{ width: '250px' }}
                              className="image-styles-list"
                              onChange={this.handleImageStyleSelect}
                              placeholder={'Please select...'}
                            >
                              {imageStylesAllowed.map(imageStyle => (
                                <Option
                                  key={imageStyle}
                                  value={imageStyle}
                                  // disabled={
                                  //   fieldComment.existingStyles &&
                                  //   fieldComment.existingStyles.indexOf(imageStyle) !== -1
                                  // }
                                >
                                  {imageStyle.toUpperCase()}
                                </Option>
                              ))}
                            </Select>
                            <div
                              className={`data-error ${getValueBasedOnTheCondition(
                                this.state.invalidStyle,
                                'show',
                                ''
                              )}`}
                            >
                              Style is required.
                            </div>
                          </div>
                          <div style={{ marginTop: '20px' }}>
                            <Upload
                              className="img-select-input"
                              name="new_image"
                              customRequest={this.onCustomRequest}
                              multiple={false}
                              beforeUpload={this.handleImageBeforeupload}
                              onChange={this.handleImageupload}
                              fileList={this.state.imageComment.image.fileList}
                            >
                              <Button>Select Image</Button>
                            </Upload>
                            <div
                              className={`data-error ${getValueBasedOnTheCondition(
                                this.state.invalidImage,
                                'show',
                                ''
                              )}`}
                            >
                              Select an image.
                            </div>
                          </div>
                        </div>
                        <div className="image-attr-name-select">
                          <div>
                            <Select
                              dropdownMatchSelectWidth={false}
                              title={'Image type'}
                              className="image-styles-list"
                              onChange={this.handleTypeSelect}
                              placeholder={'Select image type...'}
                            >
                              {imageNameTypes.map(imageNameType => (
                                <Option key={imageNameType} value={getFileNamePart(imageNameType)}>
                                  {imageNameType}
                                </Option>
                              ))}
                            </Select>
                            <div
                              className={`data-error ${getValueBasedOnTheCondition(
                                this.state.invalidType,
                                'show',
                                ''
                              )}`}
                            >
                              Type is required.
                            </div>
                          </div>
                          <div>
                            <Select
                              dropdownMatchSelectWidth={false}
                              title={'Image facing'}
                              value={
                                this.state?.imageComment?.imageFacing === ''
                                  ? undefined
                                  : this.state?.imageComment?.imageFacing
                              }
                              className="image-styles-list"
                              onChange={this.handleFacingSelect}
                              placeholder={'Select image facing...'}
                            >
                              {imageNameFacings.map(imageNameFacing => (
                                <Option
                                  key={imageNameFacing}
                                  value={getFileNamePart(imageNameFacing)}
                                  disabled={this.isFacingOptionDisabled(imageNameFacing)}
                                >
                                  {imageNameFacing}
                                </Option>
                              ))}
                              {!fieldComment.imageCategory && (
                                <OptGroup label="N1 Options">
                                  {nOneFacingOptions.map(imageNameFacing => (
                                    <Option
                                      key={imageNameFacing}
                                      value={getFileNamePart(imageNameFacing)}
                                      disabled={this.isFacingOptionDisabled(imageNameFacing)}
                                    >
                                      {imageNameFacing}
                                    </Option>
                                  ))}
                                </OptGroup>
                              )}
                            </Select>
                            <div
                              className={`data-error ${getValueBasedOnTheCondition(
                                this.state.invalidFacing,
                                'show',
                                ''
                              )}`}
                            >
                              Facing is required.
                            </div>
                          </div>
                          <div>
                            <Select
                              dropdownMatchSelectWidth={false}
                              title={'Image angle'}
                              className="image-styles-list"
                              onChange={this.handleAngleSelect}
                              placeholder={'Select image angle...'}
                            >
                              {imageNameAngles.map(imageNameAngle => (
                                <Option key={imageNameAngle} value={getFileNamePart(imageNameAngle)}>
                                  {imageNameAngle}
                                </Option>
                              ))}
                            </Select>
                            <div
                              className={`data-error ${getValueBasedOnTheCondition(
                                this.state.invalidAngle,
                                'show',
                                ''
                              )}`}
                            >
                              Angle is required.
                            </div>
                          </div>
                        </div>
                        {this.getImageCommentGivenName()}
                      </div>
                    )}
                    {fieldComment.type === 'IMAGE_STYLE' && (
                      <div className="comments-attr">
                        <div style={{ display: 'flex' }}>
                          <div>
                            <div className="comments-attr-lbl">New image style</div>
                            <Select
                              className="image-styles-list"
                              onChange={this.handleImageStyleSelect}
                              placeholder={'Please select...'}
                            >
                              {imageStylesAllowed.map(imageStyle => (
                                <Option
                                  key={imageStyle}
                                  value={imageStyle}
                                  // disabled={
                                  //   fieldComment.existingStyles &&
                                  //   fieldComment.existingStyles.indexOf(imageStyle) !== -1
                                  // }
                                >
                                  {imageStyle.toUpperCase()}
                                </Option>
                              ))}
                            </Select>
                            <div
                              className={`data-error ${getValueBasedOnTheCondition(
                                this.state.invalidStyle,
                                ' show',
                                ''
                              )}`}
                            >
                              Style is required.
                            </div>
                            {fieldComment && fieldComment.image && !isValidImageName(fieldComment.image.name) && (
                              <div>
                                <div className="comments-attr-lbl top-space">
                                  {`*Current image name, ${fieldComment.image.name}, doesn't follow the GDSN naming guidelines. Please
                                  select the below values to correct the name.`}
                                </div>
                                <div className="image-attr-name-select removed-top-margin">
                                  <div>
                                    <Select
                                      dropdownMatchSelectWidth={false}
                                      title={'Image type'}
                                      className="image-styles-list"
                                      onChange={this.handleTypeSelect}
                                      placeholder={'Select image type...'}
                                    >
                                      {imageNameTypes.map(imageNameType => (
                                        <Option key={imageNameType} value={getFileNamePart(imageNameType)}>
                                          {imageNameType}
                                        </Option>
                                      ))}
                                    </Select>
                                    <div
                                      className={`data-error ${getValueBasedOnTheCondition(
                                        this.state.invalidType,
                                        'show',
                                        ''
                                      )}`}
                                    >
                                      Type is required.
                                    </div>
                                  </div>
                                  <div>
                                    <Select
                                      dropdownMatchSelectWidth={false}
                                      title={'Image facing'}
                                      value={
                                        this.state?.imageComment?.imageFacing === ''
                                          ? undefined
                                          : this.state?.imageComment?.imageFacing
                                      }
                                      className="image-styles-list"
                                      onChange={this.handleFacingSelect}
                                      placeholder={'Select image facing...'}
                                    >
                                      {imageNameFacings.map(imageNameFacing => (
                                        <Option
                                          key={imageNameFacing}
                                          value={getFileNamePart(imageNameFacing)}
                                          disabled={this.isFacingOptionDisabled(imageNameFacing)}
                                        >
                                          {imageNameFacing}
                                        </Option>
                                      ))}
                                      {!fieldComment.imageCategory && (
                                        <OptGroup label="N1 Options">
                                          {nOneFacingOptions.map(imageNameFacing => (
                                            <Option
                                              key={imageNameFacing}
                                              value={getFileNamePart(imageNameFacing)}
                                              disabled={this.isFacingOptionDisabled(imageNameFacing)}
                                            >
                                              {imageNameFacing}
                                            </Option>
                                          ))}
                                        </OptGroup>
                                      )}
                                    </Select>
                                    <div
                                      className={`data-error ${getValueBasedOnTheCondition(
                                        this.state.invalidFacing,
                                        'show',
                                        ''
                                      )}`}
                                    >
                                      Facing is required.
                                    </div>
                                  </div>
                                  <div>
                                    <Select
                                      dropdownMatchSelectWidth={false}
                                      title={'Image angle'}
                                      className="image-styles-list"
                                      onChange={this.handleAngleSelect}
                                      placeholder={'Select image angle...'}
                                    >
                                      {imageNameAngles.map(imageNameAngle => (
                                        <Option key={imageNameAngle} value={getFileNamePart(imageNameAngle)}>
                                          {imageNameAngle}
                                        </Option>
                                      ))}
                                    </Select>
                                    <div
                                      className={`data-error ${getValueBasedOnTheCondition(
                                        this.state.invalidAngle,
                                        'show',
                                        ''
                                      )}`}
                                    >
                                      Angle is required.
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                            {this.getImageCommentGivenName()}
                          </div>
                        </div>
                      </div>
                    )}
                    {fieldComment.type === 'IMAGE_DELETE' && (
                      <div className="comments-attr">
                        <div className="comments-attr-lbl">Select Reason</div>
                        <Select
                          className="image-styles-list"
                          onChange={this.handleReasonSelect}
                          placeholder={'Please select...'}
                        >
                          {imageDeleteReasons.map(imageStyle => (
                            <Option key={imageStyle}>{imageStyle}</Option>
                          ))}
                        </Select>
                        <div
                          className={`data-error ${getValueBasedOnTheCondition(this.state.invalidReason, ' show', '')}`}
                        >
                          {getValueBasedOnTheCondition(this.state.invalidReason, this.state.invalidReason, '')}
                        </div>
                      </div>
                    )}
                  </div>
                  {this.getFieldCommentHint(fieldComment)}
                  <div className="comments-text">
                    <div className="comments-attr-lbl">Comments</div>
                    {fieldComment.show && (
                      <TextArea
                        className="comments-value"
                        defaultValue={getFieldKeyOrAssignDefaultValue(fieldComment.existingComment, 'comment', '')}
                        rows={4}
                        onBlur={collectCommentsFn}
                        onKeyUp={collectCommentsFn}
                      />
                    )}
                  </div>
                </div>
                {fieldComment.type === 'IMAGE_NEW' && <ImageGuide expanded={true} />}
              </div>
              <div className="btn-footer">
                <Button className="footer-btn btn-cancel" onClick={closePopup}>
                  CANCEL
                </Button>
                <Button className="footer-btn btn-confirm" onClick={sendFieldCommentsFn}>
                  {submitCaption}
                </Button>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

export default ImageUpload;
