import React from 'react';
import { Checkbox, Select } from 'antd';
import _ from 'lodash';
import {
  parseJson,
  getFilterGraphParameters,
  appendToArrayWithSpread,
  stringifyJson,
  sortArray,
  checkStatusWithTwoAndConditions,
  getValueBasedOnTheCondition
} from '../util/Util';

class OverallItemScores extends React.Component {
  summaryCards = ({ totalItems, completeness, noEnrichment, withEnrichment }) => {
    return (
      <div className="summary-cards-list">
        <div className="summary-card">
          <div>
            <div className="summary-card-title">Total Items</div>
            <div className="sc-score">{totalItems.count}</div>
            <div>{`Last Updated: ${new Date(totalItems.lastUpdated).toLocaleDateString()}`}</div>
          </div>
        </div>

        <div className="summary-card">
          <div>
            <div className="summary-card-title">Completeness %</div>
            <div className="sc-score">{`${completeness}%`}</div>
            <div>{completeness === 100 ? 'Compliant' : 'Not Compliant'}</div>
          </div>
        </div>

        <div className="summary-card">
          <div>
            <div className="summary-card-title">Incomplete Enrichment</div>
            <div>
              <div className="menuicon fi flaticon-caret-down" />
              <div className="sc-score sc-enrich">{noEnrichment.sysco + noEnrichment.national}</div>
            </div>
            <div className="sc-enrich-wrap">
              <div className="sysco-nat-left">
                <div>Sysco</div>
                <div>{noEnrichment.sysco}</div>
              </div>
              <div className="sysco-nat-right">
                <div>National</div>
                <div>{noEnrichment.national}</div>
              </div>
            </div>
          </div>
        </div>

        <div className="summary-card">
          <div>
            <div className="summary-card-title">Complete Enrichment</div>
            <div>
              <div className="menuicon fi flaticon-caret-down caret-up " />
              <div className="sc-score sc-enrich">{withEnrichment.sysco + withEnrichment.national}</div>
            </div>
            <div className="sc-enrich-wrap">
              <div className="sysco-nat-left">
                <div>Sysco</div>
                <div>{withEnrichment.sysco}</div>
              </div>
              <div className="sysco-nat-right">
                <div>National</div>
                <div>{withEnrichment.national}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      summaryItems,
      actions,
      suvc,
      graphFilter,
      filterForGraphs,
      taxonomy,
      brands,
      fetchingOverallSummary,
      tableFilters,
      orderBy,
      brandGroupFilters,
      tableSearchData,
      isHiddingFilters
    } = this.props;
    const { Option } = Select;

    const stripFromFilter = strip => {
      const existingGraphFilter = JSON.parse(graphFilter);
      const strippedFilters = _.filter(existingGraphFilter, filterObj => {
        return strip.indexOf(filterObj.param) === -1;
      });
      return strippedFilters;
    };

    const filterGraph = (add, remove) => {
      let filterStr = null;

      let currentGraphFilter = parseJson(graphFilter);

      currentGraphFilter = stripFromFilter(remove);

      currentGraphFilter = appendToArrayWithSpread(currentGraphFilter, add);

      filterStr = stringifyJson(currentGraphFilter);

      actions.getOverallSummary({ suvc, graphFilter: filterStr });

      actions.loadItemList({
        suvc,
        page: 1,
        search: '',
        subSuvc: null,
        graphfilter: filterStr,
        tableFilters,
        orderBy,
        brandGroupFilters,
        tableSearchData
      });
    };

    const applyFilterForGraph = filter => {
      const updatedFilter = { ...filterForGraphs, ...filter };
      actions.changeFilterForGraph({ filter: { ...updatedFilter } });
    };

    const applyBrandFilterForGraph = brandFilter => {
      applyFilterForGraph({
        sysco: brandFilter
      });
      const { add, remove } = getFilterGraphParameters('sysco', brandFilter, ['sysco']);
      filterGraph(add, remove);
    };

    const applyBrandFilterForGraphSysco = e => {
      let brandFilter = null;
      const isChecked = e.target.checked;
      if (checkStatusWithTwoAndConditions(filterForGraphs.sysco !== '0', filterForGraphs.sysco !== '1')) {
        brandFilter = getValueBasedOnTheCondition(isChecked, '1', '0');
      }
      applyBrandFilterForGraph(brandFilter);
    };

    const applyBrandFilterForGraphNational = e => {
      let brandFilter = null;
      const isChecked = e.target.checked;
      if (checkStatusWithTwoAndConditions(filterForGraphs.sysco !== '0', filterForGraphs.sysco !== '1')) {
        brandFilter = getValueBasedOnTheCondition(isChecked, '0', '1');
      }
      applyBrandFilterForGraph(brandFilter);
    };

    const applyBrandNameFilterForGraph = newBrandName => {
      applyFilterForGraph({
        brand: newBrandName
      });
      const { add, remove } = getFilterGraphParameters('brand', newBrandName, ['brand']);
      filterGraph(add, remove);
    };

    const applyBcFilterForGraph = newBc => {
      applyFilterForGraph({
        bc: newBc,
        ig: null,
        ag: null
      });
      const { add, remove } = getFilterGraphParameters('bc', newBc, ['bc', 'ig', 'ag']);
      filterGraph(add, remove);
    };

    const applyIgFilterForGraph = newIg => {
      applyFilterForGraph({
        bc: filterForGraphs.bc,
        ig: newIg,
        ag: null
      });
      const { add, remove } = getFilterGraphParameters('ig', newIg, ['ig', 'ag']);
      filterGraph(add, remove);
    };

    const applyAgFilterForGraph = newAg => {
      applyFilterForGraph({
        bc: filterForGraphs.bc,
        ig: filterForGraphs.ig,
        ag: newAg
      });

      const { add, remove } = getFilterGraphParameters('ag', newAg, ['ag']);
      filterGraph(add, remove);
    };

    const emptyFilters = () => {
      applyFilterForGraph({ brand: null, bc: null, ig: null, ag: null, sysco: null });

      actions.getOverallSummary({ suvc, graphFilter: null });

      actions.loadItemList({
        suvc,
        page: 1,
        search: '',
        subSuvc: null,
        graphfilter: null,
        tableFilters,
        orderBy,
        brandGroupFilters,
        tableSearchData
      });
    };

    let businessCenters = Object.getOwnPropertyNames(taxonomy);
    let itemGroups = [];
    let attributeGroups = [];

    if (filterForGraphs.bc !== null) {
      itemGroups = Object.getOwnPropertyNames(taxonomy[filterForGraphs.bc]);
      if (filterForGraphs.ig !== null) {
        attributeGroups = Object.getOwnPropertyNames(taxonomy[filterForGraphs.bc][filterForGraphs.ig]);
      }
    }

    businessCenters = sortArray(businessCenters);
    itemGroups = sortArray(itemGroups);
    attributeGroups = sortArray(attributeGroups);

    if (checkStatusWithTwoAndConditions(!_.isEmpty(summaryItems), !fetchingOverallSummary)) {
      return (
        <div className="overall-summary-wrapper">
          {!isHiddingFilters && (
            <div className="score-filters-row">
              <Select
                defaultValue={filterForGraphs.brand}
                onChange={applyBrandNameFilterForGraph}
                className="graph-brand-filter"
              >
                <Option value={null} key="0">
                  All Brands
                </Option>
                {brands.map(brandOption => {
                  return (
                    <Option value={brandOption} key={brandOption}>
                      {brandOption}
                    </Option>
                  );
                })}
              </Select>
              <Select defaultValue={filterForGraphs.bc} onChange={applyBcFilterForGraph}>
                <Option value={null} key="0">
                  All Business Centers
                </Option>
                {businessCenters.map(bcOption => {
                  return (
                    <Option value={bcOption} key={bcOption}>
                      {bcOption}
                    </Option>
                  );
                })}
              </Select>
              <Select
                defaultValue={filterForGraphs.ig}
                disabled={itemGroups.length === 0}
                onChange={applyIgFilterForGraph}
              >
                <Option value={null} key="0">
                  All Item Groups
                </Option>
                {itemGroups.map(igOption => {
                  return (
                    <Option value={igOption} key={igOption}>
                      {igOption}
                    </Option>
                  );
                })}
              </Select>
              <Select
                defaultValue={filterForGraphs.ag}
                disabled={attributeGroups.length === 0}
                onChange={applyAgFilterForGraph}
              >
                <Option value={null} key="0">
                  All Attribute Groups
                </Option>
                {attributeGroups.map(agOption => {
                  return (
                    <Option value={agOption} key={agOption}>
                      {agOption}
                    </Option>
                  );
                })}
              </Select>
              <Checkbox defaultChecked={filterForGraphs.sysco === '1'} onChange={applyBrandFilterForGraphSysco}>
                Sysco
              </Checkbox>
              <Checkbox defaultChecked={filterForGraphs.sysco === '0'} onChange={applyBrandFilterForGraphNational}>
                National
              </Checkbox>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                className={`filter-fanel${getValueBasedOnTheCondition(graphFilter, ' active', '')}`}
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                style={{ enableBackground: 'new -100 -100 700 700' }}
                fill="#abac92"
                viewBox="-100 -120 700 700"
                onClick={emptyFilters}
              >
                <g>
                  <path d="M241.7,512H192V253.7L12.8,40.3V0h486.4v40.1L328.5,253.5v171.8L241.7,512z M226.1,477.9h1.5l66.8-66.8V241.5L460.3,34.1   H52.2l174,207.1L226.1,477.9z" />
                </g>
              </svg>
            </div>
          )}

          {this.summaryCards(summaryItems)}

          {/* <BrandComplianceChart
            brandCompliance={summaryItems.brandCompliance}
            noEnrichment={summaryItems.noEnrichment}
            withEnrichment={summaryItems.withEnrichment} /> */}
        </div>
      );
    }
    return (
      <div className="overall-summary-wrapper">
        <div className="loading-msg">Loading....</div>
      </div>
    );
  }
}

export default OverallItemScores;
