import React, { Component } from 'react';
import 'antd/dist/antd.css';
import { Button, Checkbox, Tooltip } from 'antd';
import { QuestionCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import _ from 'lodash';
import osdNutrition from '../../util/osdNutrition';

import NutritionPendingsIndicationBar from './NutritionPendingsIndicationBar';
import { SUBMIT_FOR_REVIEW_BUTTON_TITLE, NUTRITION_SYNCHED_TAXONOMY_ATTRIBUTE_NAMES_LIST } from '../../util/Constants';
import {
  isAllergenAttribute,
  isClaimAttribute,
  isDietTypeAttribute,
  getTaxonomyDisabledStatusOnChildNutritionValue,
  isDataSourcedFromQaSpecOrGdsn,
  getNutritionSourceOid,
  checkValidationForThreeNutrientsInRequiredFields,
  checkPortionSizeValidation,
  seperateServingDescription,
  checkIfStartsWithNumberic,
  validateServingDescriptionQuantity
} from '../../util/Util';
import IngredientStatement from './IngredientStatement';
import ChildNutritions from './ChildNutritions';
import RequiredNutritions from './NutritionHeaderDetails/RequiredNutritions';
import OptionalNutritions from './NutritionHeaderDetails/OptionalNutritions';
import DisabledNutritionMessage from './DisabledNutritionMessage';
import RejectFeedback from './RejectFeedback';

const NUTRITION_STATUS_WITH_MESSAGES = {
  23: (
    <p className="title-message">
      The following product information was submitted to Sysco via QA Specs by someone at your company.This{' '}
      <u>information will be displayed to our mutual customers - please review for accuracy.</u>
      To make changes, please update your product data in the Sysco Stibo QA specification database.For access
      questions, contact your Program Quality Manager or email fnc_ssdatabase_000 @corp.sysco.com.
    </p>
  ),
  31: (
    <p className="title-message">
      The following product information was published via GDSN by someone at your company.{' '}
      <u>This information will be displayed to our mutual customers - please review for accuracy.</u> To make changes,
      please update your product data with your data pool partner and be sure to publish your changes to Sysco (GLN
      0074865000000).
    </p>
  ),
  OTHER: (
    <p className="title-message">
      The following product information was submitted to Sysco by someone at your company.{' '}
      <u>This information will be displayed to our mutual customers - please review for accuracy.</u> To make changes,
      navigate to the Product Attributes tab.
    </p>
  )
};

const DisabledTitleMessage = ({ nutritionSourceOid, syscoBrand, gdsn }) => {
  const updatedNutritionSourceId = getNutritionSourceOid(nutritionSourceOid, gdsn === 'Y', syscoBrand === 'Y');
  if (updatedNutritionSourceId) {
    if (isDataSourcedFromQaSpecOrGdsn(updatedNutritionSourceId))
      return NUTRITION_STATUS_WITH_MESSAGES[updatedNutritionSourceId];
    return NUTRITION_STATUS_WITH_MESSAGES.OTHER;
  }
  return null;
};

const DisplayMessages = ({ message }) => {
  return (
    <div className="nutrition-tab not-applicable-msg-wrapper">
      <div className="not-applicable-msg">{message}</div>
    </div>
  );
};

const getUpdatedOrganicStatusChangedFields = (organicTradeItemCodes, nutritions, updatedChangedFields) => {
  if (
    organicTradeItemCodes &&
    nutritions.organicTradeItemCodes &&
    organicTradeItemCodes[0] !== nutritions.organicTradeItemCodes[0]
  ) {
    return [...new Set([...updatedChangedFields, 'organicTradeItemCodes'])];
  }
  return updatedChangedFields;
};

export class NutritionsForm extends Component {
  inquiriesTooltip = (<div className="enut-tooltip">Please contact NBNutritioninfo@sysco.com for questions.</div>);

  submitNutritions = () => {
    const {
      actions,
      pendingNutrition,
      suvc,
      supc,
      stepId,
      trueVendorName,
      gln,
      gtin,
      materialDescription,
      item
    } = this.props;
    const { taxonomy, taxonomyChanges, hasNutritionSynchedTaxonomyAttributeChanges, nutritions } = item;
    const pendingNutritionOsdChanges = this.getPendingOsdNutritionChanges();
    const mergedChangedOsdAttributes = this.mergePendingAndOsdTaxonomyChanges(
      pendingNutritionOsdChanges,
      taxonomyChanges
    );

    const mergedChangedTaxonomy = taxonomy.attributes.map(eachAttribute => {
      const item2 = mergedChangedOsdAttributes.find(i2 => Number(i2.attrId) === eachAttribute.attrId);
      return item2 ? { ...eachAttribute, selected: Number(item2.selected) || null } : eachAttribute;
    });
    let { traits, nutritionalClaimsDetails, organicTradeItemCodes } = osdNutrition.changeTaxonomyToNutrition({
      nutritions,
      taxonomy: { attributes: mergedChangedTaxonomy }
    });

    let updatedChangedFields = [];
    if (pendingNutrition.changedFields) {
      updatedChangedFields = [...pendingNutrition.changedFields];
    }
    updatedChangedFields = [
      ...getUpdatedOrganicStatusChangedFields(organicTradeItemCodes, nutritions, updatedChangedFields)
    ];

    let updatedData = {
      ...pendingNutrition,
      nutritionalClaimsDetails,
      traits,
      organicTradeItemCodes,
      manufacturer: {
        name: trueVendorName,
        gln,
        alternateIdentifier: `${suvc}01` // suvc + sequence Number or 01
      },
      changedFields: [...updatedChangedFields]
    };

    if (hasNutritionSynchedTaxonomyAttributeChanges) {
      updatedData = { ...updatedData, taxonomyChanges: [...mergedChangedOsdAttributes] };
    }

    const { sleHasProductFormulationStmt } = pendingNutrition;
    if (sleHasProductFormulationStmt && !nutritions.sleHasProductFormulationStmt) {
      updatedData = {
        ...updatedData,
        sleProductId: null,
        changedFields: [...new Set([...updatedData.changedFields, 'sleProductId'])]
      };
    }
    if (!pendingNutrition.oid) {
      updatedData = {
        ...updatedData,
        gtin,
        gln,
        alternateIdentifier: supc,
        name: materialDescription
      };
    }
    actions.sendNutritions({ suvc, supc, stepId, updatedData, item });
  };

  checkSyscoOrGdsnItem = (syscoBrand, gdsn) => {
    return syscoBrand === 'Y' || gdsn === 'Y';
  };

  toggleNotApplicable = () => {
    const { actions, isNutritionDetailsNotApplicable, supc } = this.props;
    if (isNutritionDetailsNotApplicable) {
      actions.updateNutritionApplicableStatus({ supc, status: false });
    } else {
      actions.updateNutritionApplicableStatus({ supc, status: true });
    }
  };

  renderRejectedNutritions = () => {
    const {
      pendingNutrition: { feedback }
    } = this.props;
    return (
      <div>{feedback && feedback.status === 'REJECT' && <NutritionPendingsIndicationBar feedback={feedback} />}</div>
    );
  };

  checkEmptyOrNullValues = value => value === '' || value === null;

  checkRequiredNutritionsAreNotValid = nutritionAnalysisDetail => {
    let status = false;
    // eslint-disable-next-line no-restricted-syntax
    for (const { isRequiredNutrition, quantityContained, hasSelected, shortName } of nutritionAnalysisDetail) {
      if (
        (isRequiredNutrition || (!isRequiredNutrition && hasSelected)) &&
        checkValidationForThreeNutrientsInRequiredFields(shortName, quantityContained) &&
        this.checkEmptyOrNullValues(quantityContained)
      ) {
        status = true;
        break;
      }
    }
    return status;
  };

  checkNutritionsAreNotValidated = pendingNutrition => {
    const {
      nutritionAnalysisHeader: [
        { servingSizeDescription, servingSizeQuantity, nutritionAnalysisDetail } = {
          servingSizeDescription: null,
          servingSizeQuantity: null,
          nutritionAnalysisDetail: []
        }
      ],
      ingredientStatement
    } = pendingNutrition;

    const [servingSizeDescriptionQuantity, servingSizeDescriptionValue] = seperateServingDescription(
      servingSizeDescription
    );
    return (
      this.checkEmptyOrNullValues(servingSizeDescription) ||
      // this.checkEmptyOrNullValues(servingSizeDescriptionQuantity) ||
      this.checkEmptyOrNullValues(servingSizeDescriptionValue) ||
      !validateServingDescriptionQuantity(servingSizeDescriptionQuantity) ||
      this.checkEmptyOrNullValues(servingSizeQuantity) ||
      this.checkRequiredNutritionsAreNotValid(nutritionAnalysisDetail) ||
      this.checkEmptyOrNullValues(ingredientStatement)
    );
  };

  getSaveButtonDisableStatus = () => {
    let isDisabled = false;
    const {
      nutritions: { isReadOnly },
      pendingNutrition,
      isItemReadOnly,
      nutritionErrors,
      item
    } = this.props;
    const { updatingNutritions } = item;
    if (
      isReadOnly ||
      isItemReadOnly ||
      updatingNutritions ||
      Object.keys(nutritionErrors).length ||
      getTaxonomyDisabledStatusOnChildNutritionValue(item, true) ||
      this.checkNutritionsAreNotValidated(pendingNutrition) ||
      checkPortionSizeValidation(pendingNutrition)
    )
      isDisabled = true;

    return isDisabled;
  };

  getDisabledTitleMessage = () => {
    const { gdsn, syscoBrand } = this.props;
    if (gdsn === 'Y') return NUTRITION_STATUS_WITH_MESSAGES.GDSN;
    if (syscoBrand === 'Y') return NUTRITION_STATUS_WITH_MESSAGES.SYSCO;
    return NUTRITION_STATUS_WITH_MESSAGES.OTHER;
  };

  getEachFieldValue = field => {
    const { pendingNutrition } = this.props;
    return pendingNutrition[field] || null;
  };

  getSubmitButtonTitle = () => {
    const {
      item: { updatingNutritions }
    } = this.props;
    if (updatingNutritions) return 'SUBMITTING...';
    return SUBMIT_FOR_REVIEW_BUTTON_TITLE;
  };

  renderNutritionTabBody = () => {
    const {
      updatingNutritionsApplicableStatus,
      isNutritionDetailsNotApplicable,
      pendingNutrition,
      nutritionValuesChanged,
      pendingNutritions,
      updateNutritionErrors,
      nutritionErrors,
      item,
      isItemReadOnly,
      nutritions: { isReadOnly, nutritionSourceOid },
      actions,
      hasNutritionSynchedTaxonomyAttributeChanges
    } = this.props;

    const { basicData: { syscoBrand, gdsn } = {} } = item;

    const inquiriesTooltip = (
      <div className="enut-tooltip">Please contact NBNutritioninfo@sysco.com for questions.</div>
    );

    if (updatingNutritionsApplicableStatus) {
      return <DisplayMessages message="Loading..." />;
    }
    if (isNutritionDetailsNotApplicable) {
      return <DisplayMessages message="Nutrition data is not applicable for this item" />;
    }
    return (
      <React.Fragment>
        <Tooltip placement="left" title={inquiriesTooltip}>
          <QuestionCircleOutlined className="enut-help-icon" />
        </Tooltip>

        <div className="nutrition-tab-body">
          <div className="top-container">
            <div className="nutrition-fact-table">
              <RejectFeedback field="nutritionAnalysisHeader" nutritionData={pendingNutrition} />
              <RequiredNutritions
                nutritionData={pendingNutrition}
                isReadOnly={isReadOnly}
                isItemReadOnly={isItemReadOnly}
                onFieldChange={nutritionValuesChanged}
                nutritionErrors={nutritionErrors}
                updateNutritionErrors={updateNutritionErrors}
                pendingNutritions={pendingNutritions}
                isDisabledBySyscoOrGdsnItemFlag={this.checkSyscoOrGdsnItem(syscoBrand, gdsn)}
              />
              <OptionalNutritions
                nutritionData={pendingNutrition}
                isReadOnly={isReadOnly}
                isItemReadOnly={isItemReadOnly}
                onFieldChange={nutritionValuesChanged}
                nutritionErrors={nutritionErrors}
                updateNutritionErrors={updateNutritionErrors}
                pendingNutritions={pendingNutritions}
                isDisabledBySyscoOrGdsnItemFlag={this.checkSyscoOrGdsnItem(syscoBrand, gdsn)}
              />
            </div>
            <div className="osd-tab-info">
              <DisabledTitleMessage nutritionSourceOid={nutritionSourceOid} syscoBrand={syscoBrand} gdsn={gdsn} />
              <div className="allergen-diets-claims">
                <div>
                  <div className="each-section-title">Allergens</div>
                  <RejectFeedback field="allergen" nutritionData={pendingNutrition} />
                  <div className="each-content-osd">{this.getOsdDetails('Allergens')}</div>
                </div>
                <div>
                  <div className="each-section-title">Diet Types</div>
                  <RejectFeedback field="diet_type" nutritionData={pendingNutrition} />
                  <div className="each-content-osd">{this.getOsdDetails('Diet Types')}</div>
                </div>
                <div>
                  <div className="each-section-title">Claims</div>
                  <RejectFeedback field="nutritionalClaimsDetails" nutritionData={pendingNutrition} />
                  <RejectFeedback field="organicTradeItemCodes" nutritionData={pendingNutrition} />
                  <div className="each-content-osd">{this.getOsdDetails('Claims')}</div>
                </div>
              </div>
            </div>
          </div>
          <IngredientStatement
            onFieldChange={nutritionValuesChanged}
            nutritionData={pendingNutrition}
            value={this.getEachFieldValue('ingredientStatement')}
            isReadOnly={isReadOnly}
            isItemReadOnly={isItemReadOnly}
            changedFields={pendingNutrition.changedFields}
            pendingNutritions={pendingNutritions}
            isDisabledBySyscoOrGdsnItemFlag={this.checkSyscoOrGdsnItem(syscoBrand, gdsn)}
          />
          <ChildNutritions
            onFieldChange={nutritionValuesChanged}
            isReadOnly={isReadOnly}
            isItemReadOnly={isItemReadOnly}
            nutritionData={pendingNutrition}
            updateNutritionErrors={updateNutritionErrors}
            nutritionErrors={nutritionErrors}
            item={item}
            pendingNutritions={pendingNutritions}
            actions={actions}
            isDisabledBySyscoOrGdsnItemFlag={this.checkSyscoOrGdsnItem(syscoBrand, gdsn)}
          />
        </div>
        {(pendingNutrition.changedFields.length || hasNutritionSynchedTaxonomyAttributeChanges) && (
          <div className="btn-footer nutrition-footer">
            <Button
              className="footer-btn btn-navigate"
              onClick={() => actions.tabChanged({ tab: 4 })}
              title="Go to Product Attributes Tab"
            >
              <ArrowLeftOutlined />
            </Button>
            <Button
              disabled={this.getSaveButtonDisableStatus()}
              className="footer-btn btn-confirm"
              onClick={this.submitNutritions}
            >
              {this.getSubmitButtonTitle()}
            </Button>
          </div>
        )}
      </React.Fragment>
    );
  };

  getSelectedPickListValue = (picklist, selectedValue) => picklist.filter(({ id }) => id === selectedValue);

  getRelevantFilter = type => {
    if (type === 'Allergens') return isAllergenAttribute;
    if (type === 'Diet Types') return isDietTypeAttribute;
    if (type === 'Claims') return isClaimAttribute;
  };

  setDisplayNames = (value, name) => {
    if (['Not Applicable', 'Undeclared'].includes(value)) return `${name} -> ${value}`;
    return value;
  };

  getPendingOsdNutritionChanges = () => {
    const { pendingNutrition } = this.props;
    let pendingNutritionOsdChanges = [];
    if (pendingNutrition.taxonomyChanges) {
      pendingNutritionOsdChanges = [...pendingNutrition.taxonomyChanges];
    }
    return pendingNutritionOsdChanges;
  };

  mergePendingAndOsdTaxonomyChanges = (pendingNutritionOsdChanges, taxonomyChanges) => {
    if (pendingNutritionOsdChanges.length) {
      const mergedDataObject = _.merge(
        _.keyBy(pendingNutritionOsdChanges, 'attrId'),
        _.keyBy(taxonomyChanges, 'attrId')
      );
      return Object.keys(mergedDataObject).map(key => {
        return { ...mergedDataObject[key] };
      });
    }
    return [...taxonomyChanges];
  };

  getOsdDetails = type => {
    const { attributes, taxonomyChanges } = this.props;
    const pendingNutritionOsdChanges = this.getPendingOsdNutritionChanges();
    const dataArray = [];
    const notApplicableDataArray = [];
    const getSelectedValue = (id, existingSelectedValue) => {
      const result = this.mergePendingAndOsdTaxonomyChanges(pendingNutritionOsdChanges, taxonomyChanges);
      if (result && result.length) {
        const selectedAttribute = result.filter(({ attrId }) => Number(attrId) === id);
        if (selectedAttribute && selectedAttribute[0]) {
          if (selectedAttribute[0].selected) {
            return Number(selectedAttribute[0].selected);
          }
          if (selectedAttribute[0].selected === null) {
            return null;
          }
        }
      }
      return Number(existingSelectedValue);
    };
    attributes.filter(this.getRelevantFilter(type)).forEach(({ attrId: id, name: eachName, picklist, selected }) => {
      const selectedValue = getSelectedValue(id, selected);
      if (selectedValue || selectedValue === null) {
        const selectedPicklist = this.getSelectedPickListValue(picklist, selectedValue);
        if (selectedPicklist && selectedPicklist.length) {
          const [{ value }] = selectedPicklist;
          const textToDisplay = this.setDisplayNames(value, eachName);
          if (NUTRITION_SYNCHED_TAXONOMY_ATTRIBUTE_NAMES_LIST.includes(eachName)) {
            if (textToDisplay.includes('Not Applicable')) {
              notApplicableDataArray.push(textToDisplay);
            } else {
              dataArray.push(textToDisplay);
            }
          }
        }
      }
    });

    return [...dataArray.sort(), ...notApplicableDataArray.sort()].map((eachText, index) => (
      <li key={`${index + 1} - 01`}>{eachText}</li>
    ));
  };

  render() {
    const {
      nutritions,
      isItemReadOnly,
      isNutritionDetailsNotApplicable,
      item: { basicData: { gdsn, syscoBrand } = {} } = { basicData: {} }
    } = this.props;
    const { isReadOnly } = nutritions;
    return (
      <div className="nutrition-tab">
        <DisabledNutritionMessage nutritions={nutritions} gdsn={gdsn === 'Y'} syscoBrand={syscoBrand === 'Y'} />
        {this.renderRejectedNutritions()}
        <Checkbox
          className="enut-not-applicable-checkbox"
          checked={isNutritionDetailsNotApplicable}
          disabled={isReadOnly || isItemReadOnly || this.checkSyscoOrGdsnItem(syscoBrand, gdsn)}
          onChange={this.toggleNotApplicable}
        >
          Mark as not applicable
        </Checkbox>

        {this.renderNutritionTabBody()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    attributes: _.get(state.selectedItem, 'taxonomy.attributes', []),
    nutritionErrors: state.selectedItem.nutritionErrors,
    hasNutritionSynchedTaxonomyAttributeChanges: state.selectedItem.hasNutritionSynchedTaxonomyAttributeChanges,
    taxonomyChanges: state.selectedItem.taxonomyChanges,
    nutritions: state.selectedItem.nutritions
  };
}

export default connect(mapStateToProps)(NutritionsForm);
