export const REJECT = 'REJECT';
export const DENY = 'DENY';
export const FIXED = 'FIXED';
export const DEFAULT_STATUS = 'Select...';

// Nutritions Tab
export const LEVEL_OF_CONTAINMENT = [
  { id: 'FREE_FROM', value: 'Free from' },
  { id: 'CONTAINS', value: 'Contains' },
  { id: 'MAY_CONTAIN', value: 'May contain' }
];

export const OVERALL_REJECT_STATUSES = [
  { key: 0, value: 'PENDING' },
  { key: 10, value: 'REJECT' },
  { key: 20, value: 'FIXED' },
  { key: 30, value: 'APPROVE' }
];

export const ALLERGENS = [
  { id: 1, name: 'Eggs' },
  { id: 2, name: 'Fish' },
  { id: 3, name: 'Milk' },
  { id: 4, name: 'Peanuts' },
  { id: 5, name: 'Soy' },
  { id: 6, name: 'Tree Nuts' },
  { id: 7, name: 'Wheat' },
  { id: 8, name: 'Crustaceans' }
];

export const DIET_TYPES = [
  { dietTypeCode: 'HALAL', description: 'Halal Diet Type', values: ['Yes', 'No', 'Not Applicapble'] },
  { dietTypeCode: 'KOSHER', description: 'Kosher Diet Type', values: ['Yes', 'No', 'Not Applicapble'] },
  { dietTypeCode: 'VEGAN', description: 'Vegan Diet Type', values: ['Yes', 'No', 'Not Applicapble'] },
  { dietTypeCode: 'VEGETARIAN', description: 'Vegetarian Diet Type', values: ['Yes', 'No', 'Not Applicapble'] },
  { dietTypeCode: 'COELIAC', description: 'Gluten-Free', values: ['Yes', 'No', 'Not Applicapble'] },
  { dietTypeCode: 'ORGANIC', description: 'Organic', values: ['Yes', 'No', 'Not Applicapble'] },
  { dietTypeCode: 'WITHOUT_BEEF', description: 'Without Beef', values: ['Yes', 'No', 'Not Applicapble'] },
  { dietTypeCode: 'WITHOUT_PORK', description: 'Without Pork', values: ['Yes', 'No', 'Not Applicapble'] },
  { dietTypeCode: 'DIEBETIC', description: 'Diebetic', values: ['Yes', 'No', 'Not Applicapble'] }
];

export const ORGANIC_STATUS_MAPPING = [
  {
    value: 1,
    des: '100 % Organic'
  },
  {
    value: 2,
    des: 'Organic (At Least 95% By Weight)'
  },
  {
    value: 3,
    des: 'Made With Organic Ingredients (At Least 70% By Weight)'
  },
  {
    value: 4,
    des: 'Some Organic Ingredients (Less Than 70% By Weight)'
  },
  {
    value: 5,
    des: 'Not Organic'
  },
  {
    value: 6,
    des: 'In Conversion'
  },
  {
    value: 7,
    des: 'Bio Dynamic'
  },
  {
    value: 8,
    des: 'Disqualified'
  }
];

export const CHILD_NUTRITIONS_OR_PFS = [
  { code: 'sleProductId', des: 'CN Identification Number' },
  { code: 'slePortionSize', des: 'Portion Size' },
  { code: 'sleNSLPmeatQuantity', des: 'Meat/Meat Alt (oz eq)', type: 'number' },
  { code: 'sleNSLPbreadQuantity', des: 'Grain/Bread (oz eq)', type: 'number' },
  { code: 'sleNSLPfruitQuantity', des: 'Fruit (cup)', type: 'number' },
  { code: 'sleRedOrangeVegetableQuantity', des: 'Vegetable - Red/Orange (cup)', type: 'number' },
  { code: 'sleDarkGreenVegetableQuantity', des: 'Vegetable - Dark Green (cup)', type: 'number' },
  { code: 'sleStarchyVegetableQuantity', des: 'Vegetable - Starchy (cup)', type: 'number' },
  { code: 'sleBeansPeasVegetableQuantity', des: 'Vegetable - Beans/Peas (cup)', type: 'number' },
  { code: 'sleOtherQuantity', des: 'Vegetable - Other (cup)', type: 'number' },
  { code: 'sleNotes', des: 'School Nutrition Note' }
];

export const REQUIRED_NUTRITIONS = [
  {
    code: 'FAT',
    name: 'Total Fat',
    uom: 'GRM'
  },
  {
    code: 'SFA',
    name: 'Saturated Fat',
    uom: 'GRM'
  },
  {
    code: 'FATRN',
    name: 'Trans Fat',
    uom: 'GRM'
  },
  {
    code: 'CHOL',
    name: 'Cholesterol',
    uom: 'MGM'
  },
  {
    code: 'NA',
    name: 'Sodium',
    uom: 'MGM'
  },
  {
    code: 'CHO',
    name: 'Total Carbohydrate',
    uom: 'GRM'
  },
  {
    code: 'TDFB',
    name: 'Dietary Fiber',
    uom: 'GRM'
  },
  {
    code: 'SUGAR',
    name: 'Total Sugars',
    uom: 'GRM'
  },
  {
    code: 'ADDSGR',
    name: 'Added Sugar',
    uom: 'GRM'
  },
  {
    code: 'PRO',
    name: 'Protein',
    uom: 'GRM'
  },
  {
    code: 'CA',
    name: 'Calcium',
    uom: 'MGM'
  },
  {
    code: 'FE',
    name: 'Iron',
    uom: 'MGM'
  },
  {
    code: 'K',
    name: 'Potassium',
    uom: 'MGM'
  },
  {
    code: 'VITD',
    name: 'Vitamin D',
    uom: 'MC'
  },
  {
    code: 'FIBTG',
    name: 'Fiber Total Dietary',
    uom: 'GRM'
  }
];

export const ADDITIONAL_NUTRITIONS = [
  {
    code: 'P',
    name: 'Phosphorus',
    uom: 'MGM'
  },
  {
    code: 'Zn',
    name: 'Zinc',
    uom: 'MGM'
  },
  {
    code: 'Thiamin',
    name: 'Thiamin',
    uom: 'MGM'
  },
  {
    code: 'Riboflavin',
    name: 'Riboflavin',
    uom: 'MGM'
  },
  {
    code: 'Niacin',
    name: 'Niacin',
    uom: 'MGM'
  },
  {
    code: 'Folate',
    name: 'Folate, Total',
    uom: 'MGM'
  },
  {
    code: 'VitB6',
    name: 'Vitamin B-6',
    uom: 'MGM'
  },
  {
    code: 'VitB12',
    name: 'Vitamin B-12',
    uom: 'MGM'
  },
  {
    code: 'VitD',
    name: 'Vitamin D',
    uom: 'MC'
  },
  {
    code: 'PolyFat',
    name: 'Polyunsaturated fat',
    uom: 'GRM'
  },
  {
    code: 'MonoFat',
    name: 'Monounsaturated fat',
    uom: 'GRM'
  },
  {
    code: 'VitA',
    name: 'Vitamin A',
    uom: 'MC'
  },
  {
    code: 'VitC',
    name: 'Vitamin C',
    uom: 'MGM'
  }
];

export const SERVING_UOM_OPTIONS = [
  { code: 'GRM', des: 'g (grams)' },
  // { code: 'ONZ', des: 'oz (ounce)' },
  { code: 'MLT', des: 'ml (milliliter)' }
  // { code: 'OZA', des: 'fl oz (fluid ounces)' }
];

export const SERVING_DESCRIPTION_OPTIONS = [
  { code: 'cup', des: 'cup' },
  { code: 'each', des: 'each' },
  { code: 'fluid ounce', des: 'fluid ounce' },
  { code: 'liter', des: 'liter' },
  { code: 'ounce', des: 'ounce' },
  { code: 'packet', des: 'packet' },
  { code: 'piece', des: 'piece' },
  { code: 'pint', des: 'pint' },
  { code: 'pound', des: 'pound' },
  { code: 'quart', des: 'quart' },
  { code: 'serving', des: 'serving' },
  { code: 'slice', des: 'slice' },
  { code: 'tablespoon', des: 'tablespoon' },
  { code: 'teaspoon', des: 'teaspoon' }
];

export const DEFAULT_SELECTED_SERVING_UOM_CODE = 'GRM';

export const UOM_TYPES = [
  { code: 'GRM', des: 'g' },
  { code: 'MGM', des: 'mg' },
  { code: 'MC', des: 'mcg' },
  { code: 'E14', des: 'kcal' }
];

export const ENUT_QUESTION_CATEGORIES = [
  { id: 0, text: ' Nutrient Header' },
  { id: 1, text: ' Nutrient Detail' },
  { id: 2, text: 'Ingredient Statement' },
  { id: 3, text: 'Allergens' },
  { id: 4, text: 'Diet Types' },
  { id: 5, text: 'Child Nutrition' },
  { id: 6, text: 'Organic Status' },
  { id: 7, text: 'Others' }
];

export const REJECTED_REASON_CODES = [
  { code: '1', name: 'Duplicate Image' },
  { code: '2', name: 'Incorrect Product Image' },
  { code: '3', name: 'Incorrect Image Style' },
  { code: '4', name: 'Incorrect Size' },
  { code: '5', name: 'Poor Quality' },
  { code: '6', name: 'Supplier no longer associated with initial vendor' },
  { code: '7', name: 'Not a duplicate image' },
  { code: '8', name: 'Request submitted more than once' },
  { code: '9', name: 'Supplier requested deletion recall' },
  { code: '10', name: 'Request complete following submitted feedback' }
];

export const ITEM_FEEDBACK_STATUSES = [
  {
    title: 'Complete',
    value: 30,
    key: '30'
  },
  {
    title: 'Fixed',
    value: 20,
    key: '20'
  },
  {
    title: 'Pending',
    value: 0,
    key: '0'
  },
  {
    title: 'Rejected',
    value: 10,
    key: '10'
  }
];

export const CORE_DATA_ATTRIBUTE_STATUSES = [
  {
    title: 'Pending',
    value: 0,
    key: '0'
  },
  {
    title: 'Rejected',
    value: 10,
    key: '10'
  },
  {
    title: 'Fixed',
    value: 20,
    key: '20'
  },
  {
    title: 'Approved',
    value: 30,
    key: '30'
  },
  {
    title: 'Review',
    value: 40,
    key: '40'
  }
];

export const ITEM_FEEDBACK_TYPES = [
  {
    text: 'Core',
    value: 8
  },
  {
    text: 'Fnb',
    value: 2
  },
  {
    text: 'Image',
    value: 1
  },
  {
    // comment this commented lines when disabling enut application
    text: 'Nutrition',
    value: 5
  },
  {
    text: 'Overall',
    value: 4
  },
  {
    text: 'Taxonomy',
    value: 3
  },
  {
    text: 'Attribytes Fnb',
    value: 6
  },
  {
    text: 'Attribytes Image',
    value: 7
  },
  {
    text: 'Attribytes Universal',
    value: 9
  },
  {
    text: 'Coredata (stale)',
    value: 0
  }
];

export const FNB_CHARACTER_LIMIT = 1000;
export const PRODUCT_DESCRIPTOR_CHARACTER_LIMIT = 2000;
export const OVERALL_FNB_CHARACTER_LIMIT = 3500;

export const DISABLED_SOURCE_IDS = [23, 31];

export const IMAGE_REGEX_ALL = /^\d{14}[_](A|B|C|D|E|F)(0|1|2|3|7|8|9)(C|L|N|R)(0|1|A|B|C|D|E|F|G|H|J|K|L|M|P)(.)(jpg|png|[a-z]{3})$/;

export const IMAGE_REGEX_FACE = /^\d{14}[_](L1|L2|L3|L4|L5|L6)(.)(jpg|[a-z]{3})$/;

export const GDSN_VALUE = 'Y';
export const FnB_TYPE = 'FnB';
export const FnB_TAB = 2;

export const SEND_FEEDBACK_BUTTON_TITLE = 'SEND FEEDBACK';
export const SUBMIT_FOR_REVIEW_BUTTON_TITLE = 'SUBMIT FOR REVIEW';
export const THREE_DOTS = '...';
export const IGNORING_GDSN_FNB_ATTRIBUTES = ['culinary', 'marketing'];

export const YES_VALUE = 'Y';
export const NO_VALUE = 'N';
export const PRODUCT_ATTRIBUTE_DISABLED_MESSAGES = {
  gdsn: {
    allergens:
      'Updates may not be made in this portal to disabled fields. This product is a GDSN-published, national brand item, please update your product data with your data pool partner and be sure to publish your changes to Sysco (GLN 0074865000000).',
    diet_types:
      'Updates may not be made in this portal to disabled fields. This product is a GDSN-published, national brand item, please update your product data with your data pool partner and be sure to publish your changes to Sysco (GLN 0074865000000).',
    claims:
      'Updates may not be made in this portal to disabled fields. This product is a GDSN-published, national brand item, please update this attribute on the NutritionalClaimNutrientElementCode and NutritionalClaimTypeCode GS1 attributes with your data pool partner and be sure to publish your changes to Sysco (GLN 0074865000000).'
  },
  sysco: {
    allergens:
      'Updates may not be made in this portal to disabled fields. This product is a  Sysco Brand private label item, please update your product data in the Sysco Stibo QA specification database. For access questions, contact your Program Quality Manager or email fnc_ssdatabase_000@corp.sysco.com.',
    diet_types:
      'Updates may not be made in this portal to disabled fields. This product is a  Sysco Brand private label item, please update your product data in the Sysco Stibo QA specification database. For access questions, contact your Program Quality Manager or email fnc_ssdatabase_000@corp.sysco.com.',
    claims:
      'Updates may not be made in this portal to disabled fields. This product is a  Sysco Brand private label item, please update your product data in the Sysco Stibo QA specification database. For access questions, contact your Program Quality Manager or email fnc_ssdatabase_000@corp.sysco.com.'
  }
};

export const CORE = 'CORE';

export const ATRBYTUNI = 'ATRBYTUNI';

export const OVERALL = 'OVERALL';

export const DEFAULT_OPTION = 'Select Status';

export const COMMENT_TYPES = ['FnB', 'IMAGE', 'ATRBYTIMG', 'ATRBYTFNB', 'NUTRITION'];

export const COMMENT_IMAGE_VIRUS_STATUS = {
  0: 'Please wait. Malware status loading...',
  1: 'Malware scan is in progress. Please refresh the page in a moment.',
  6: '',
  default: 'Malware suspected image.'
};

export const PACKER_BRAND_GTIN = '70000000000009';

export const GDSN_DISABLED_CLAIMS = [
  'Contains HFCS Claim',
  'Fat Nutrient Content Claim',
  'Gluten Claim Status',
  'MSG Status Claim',
  'Protein Claim',
  'Sodium Nutrient Content Claim',
  'Sugar Nutrient Content Claim',
  'Organic Claim Status'
];

export const SYSCO_DISABLED_CLAIMS = ['Organic Claim Status'];

export const GDSN_DISABLED_GENERAL_ATTRIBUTES = ['Child Nutrition Eligibility'];

export const SERVING_DATA_KEYS = {
  DESCRIPTION: 'servingSizeDescription',
  QUANTITY: 'servingSizeQuantity',
  UOFM: 'servingSizeUofm'
};

export const HEADER_INDEX = 0;

export const CHILD_NUTRITION_ELIGIBILITY = 'Child Nutrition Eligibility';
export const NOT_APPLICABLE = 'Not Applicable';

export const K12_FOOD_FIELDS = [
  'sleBeansPeasVegetableQuantity',
  'sleDarkGreenVegetableQuantity',
  'sleNSLPbreadQuantity',
  'sleNSLPfruitQuantity',
  'sleNSLPmeatQuantity',
  'sleNotes',
  'sleRedOrangeVegetableQuantity',
  'sleStarchyVegetableQuantity'
];

export const PORTION_SIZE = 'slePortionSize';

export const NUTRITION_TAB_CN_VALIDATION_TEXT = 'Portion Size and at least one of the other fields required';

export const OSD_TAB_CN_VALIDATION_TEXT = 'Please update K12 information in nutrition tab';

export const ALLERGEN_FREE_FROM_CHECK_BOX_TEXT =
  'Product is inherently free from the FDA`S big 9 allergens; by checking this box, product will be displayed to customers as "Free From" the allergens listed.';

export const SUSTAINABILITY_MASS_CHECK_BOX = 'No sustainability claims applicable';

export const ATTRIBUTE_TYPES_SYNCHED_WITH_NUTRITIONS = ['Diet Type', 'Allergen', 'Claim'];

export const ITEM_DOES_NOT_BELONG_TO_SUPPLIER = [
  'No longer produced by Supplier',
  'Acquired by another company',
  `Product attributes aren't recognizable`,
  'Never My Item'
];

export const PFAS_CLAIM_MAPPING_NAMES = ['PFAS Intentionally Added', 'PFAS Not Intentionally Added'];
export const PFAS_MAPPING_NAMES = [
  'Contains intentionally added PFAS',
  'Does not contain intentionally added PFAS that provides a functional or technical effect in the product'
];

export const PFAS_CLAIM = 'PFAS Claim';
export const PFAS_PRODUCT_LEVEL = 'Product Level';

export const NUTRITION_SYNCHED_TAXONOMY_ATTRIBUTE_NAMES_LIST = [
  'Kosher Diet Type',
  'Halal Diet Type',
  'Vegan Diet Type',
  'Vegetarian Diet Type',
  'Crustacean Shellfish Allergen',
  'Egg Allergen',
  'Fish Allergen',
  'Milk Allergen',
  'Peanut Allergen',
  'Sesame Allergen',
  'Soybean Allergen',
  'Tree Nuts Allergen',
  'Wheat Allergen',
  'Organic Claim',
  'Organic Claim Status',
  'Contains HFCS Claim',
  'Fat Nutrient Content Claim',
  'Gluten Claim Status',
  'MSG Status Claim',
  'Protein Claim',
  'Sodium Nutrient Content Claim',
  'Sugar Nutrient Content Claim'
];

export const ATTRIBUTE_FILTER_OPTIONS = [
  'Diet Types',
  'Allergens',
  'Sustainability Claims',
  'Nutritional Claims',
  'Product Attributes',
  'Nutritional Certifications',
  'Sustainability Certifications'
];

export const MAIN_ATTRIBUTE_FILTER_OPTIONS = [
  'Diet Types',
  'Allergens',
  'Claims',
  'Product Attributes',
  'Certifications'
];

export const REGULATORY_COMPLIANCE_FILTERING_OPTIONS = ['PFAS'];

export const PARTIAL_HIERARCHY_MESSAGE =
  'You may select a partial hierarchy (at least a Business Center) if you want to work on items across multiple groups. However, you will only see the Diet Types, Allergens, and some Nutritional Claim attributes which are common to all groups. To work on all Attribute Group specific attributes, please select a complete hierarchy (BC/IG/AG).';

export const PFAS_MASS_EDIT_BC_IG_AG_SELECTION_TOOLTIP = 'Select at least the BC';
export const MASS_UPDATE_TYPES = {
  IMAGE: 'IMAGE'
};

export const REGULATORY_TITLES = {
  PFAS: 'PFAS'
};

export const MASS_UPDATE_STYLE_TYPES = {
  primary: {
    description: 'OUT OF PACKAGING OR RAW/UNCOOKED',
    tooltip: ''
  }
};

export const CHILD_NUTRITION_RELATED_FIELDS = {
  slePortionSize: null,
  sleNSLPmeatQuantity: null,
  sleNSLPbreadQuantity: null,
  sleNotes: '',
  sleNSLPfruitQuantity: null,
  sleRedOrangeVegetableQuantity: null,
  sleDarkGreenVegetableQuantity: null,
  sleStarchyVegetableQuantity: null,
  sleBeansPeasVegetableQuantity: null,
  sleOtherQuantity: null,
  sleProductId: ''
};

export const PFAS_MASS_NOT_APPLICABLE_VALUE = 'Does not contain PFAS (not applicable to this product)';

export const NO_LONGER_PRODUCED_BY_SUPPLIER = [
  'Discontinued, depleted',
  'Discontinued, replacement offered',
  'Discontinued, significant attribute changes',
  'Duplicate'
];

export const IMAGE_FIELDS_NAMES = {
  primary: 'OUT OF PACKAGING OR RAW/UNCOOKED',
  inPacking: 'IN PACKAGING/INNERPACK',
  openCase: 'OPEN CASE',
  case: 'CASE',
  lifeStyle: 'LIFESTYLE'
};

export const CN_PFS_EMPTY_FIELDS = {
  slePortionSize: null,
  sleNSLPmeatQuantity: null,
  sleNSLPbreadQuantity: null,
  sleNotes: '',
  sleNSLPfruitQuantity: null,
  sleRedOrangeVegetableQuantity: null,
  sleDarkGreenVegetableQuantity: null,
  sleStarchyVegetableQuantity: null,
  sleBeansPeasVegetableQuantity: null,
  sleOtherQuantity: null,
  sleProductId: ''
};

export const TAXONOMY_AVERAGE_LATENCY = 11.83;
export const IMAGES_AVERAGE_LATENCY = 6.03;
export const COMMENTS_AVERAGE_LATENCY = 0.74;

export const MASS_IMAGE_UPLOAD_ACCEPTED_FORMATS = ['image/png', 'image/jpg', 'image/jpeg'];

export const MASS_IMAGE_FIELD_KEYS = ['primary', 'inPacking', 'openCase', 'case', 'lifeStyle'];
export const CN_AND_PFM_KEYS = ['isChildNutrition', 'sleHasProductFormulationStmt'];

export const uploadRequestAvgLatencyFor100kb = 1.52;

export const MASS_IMAGE_UPLOAD_DISABLED_MESSAGE =
  'Uploads are disabled for this field since there is a rejected or pending delete request for this image. Go to item view for more actions';

export const IMAGE_CHANGE_TYPE_DESCRIPTIONS = {
  DELETE: 'IMAGE DELETION',
  STYLE_CHANGE: 'STYLE CHANGE'
};

export const IMAGE_ORDER = ['otmmMasterRenditionId', 'otmmPreviewRenditionId', 'otmmThumbnailRenditionId'];

export const MASS_IMAGE_UPLOAD_INITIAL_STEP_PROGRESS = 0;
export const MASS_IMAGE_UPLOAD_FIRST_STEP_PROGRESS = 5;
export const MASS_IMAGE_UPLOAD_SECOND_STEP_PROGRESS = 10;
export const MASS_IMAGE_UPLOAD_THIRD_STEP_PROGRESS = 50;
export const MASS_IMAGE_UPLOAD_FOURTH_STEP_PROGRESS = 90;
export const MASS_IMAGE_UPLOAD_LAST_STEP_PROGRESS = 100;

export const OSD_VS_GS1_ATTRIBUTE_NAMES_MAPPING = [
  {
    id: '10000030',
    osd: 'Halal Diet Type',
    gs1: 'dietTypeCode(HALAL)'
  },
  {
    id: '10000031',
    osd: 'Kosher Diet Type',
    gs1: 'dietTypeCode(KOSHER)'
  },
  {
    id: '10000040',
    osd: 'Vegan Diet Type',
    gs1: 'dietTypeCode(VEGAN)'
  },
  {
    id: '10000041',
    osd: 'Vegetarian Diet Type',
    gs1: 'dietTypeCode(VEGETARIAN)'
  },
  {
    id: '10000025',
    osd: 'Crustacean Shellfish Allergen',
    gs1: 'allergenTypeCode(AC) & levelOfContainmentCode'
  },
  {
    id: '10000026',
    osd: 'Egg Allergen',
    gs1: 'allergenTypeCode(A) & levelOfContainmentCode'
  },
  {
    id: '10000028',
    osd: 'Fish Allergen',
    gs1: 'allergenTypeCode(AF) & levelOfContainmentCode'
  },
  {
    id: '10000032',
    osd: 'Milk Allergen',
    gs1: 'allergenTypeCode(AM) & levelOfContainmentCode'
  },
  {
    id: '10000035',
    osd: 'Peanut Allergen',
    gs1: 'allergenTypeCode(P) & levelOfContainmentCode'
  },
  {
    id: '10000037',
    osd: 'Soybean Allergen',
    gs1: '(allergenTypeCode(AY) & levelOfContainmentCode'
  },
  {
    id: '10000039',
    osd: 'Tree Nuts Allergen',
    gs1: '(allergenTypeCode(AN) & levelOfContainmentCode'
  },
  {
    id: '10000042',
    osd: 'Wheat Allergen',
    gs1: 'allergenTypeCode(UW) & levelOfContainmentCode'
  },
  {
    id: '10000024',
    osd: 'Contains HFCS Claim',
    gs1: 'nutritionalClaimNutrientElementCode(HIGH_FRUCTOSE_CORN_SYRUP)& nutritionalClaimTypeCode'
  },
  {
    id: '10000027',
    osd: 'Fat Nutrient Content Claim',
    gs1: 'nutritionalClaimNutrientElementCode(FAT) & nutritionalClaimTypeCode'
  },
  {
    id: '10000029',
    osd: 'Gluten Claim Status',
    gs1: 'nutritionalClaimNutrientElementCode(GLUTEN) & nutritiona/ClaimTypeCode(FREE_FROM)'
  },
  {
    id: '10000033',
    osd: 'MSG Status Claim',
    gs1: 'nutritionalClaimNutrientElementCode(MSG) & nutritionalClaimTypeCode'
  },
  {
    id: '10000034',
    osd: 'Organic Claim',
    gs1: 'OrganicTradeltemCode'
  },
  {
    id: '10000034',
    osd: 'Organic Claim Status',
    gs1: 'OrganicTradeltemCode'
  },
  {
    id: '',
    osd: 'Protein Claim',
    gs1: 'nutritionalClaimNutrientElementCode(PROTEIN) & nutritionalClaimTypeCode'
  },
  {
    id: '10000036',
    osd: 'Sodium Nutrient Content Claim',
    gs1: 'nutritionalClaimNutrientElementCode(SODIUM SALT) & nutritionalClaimTypeCode'
  },
  {
    id: '',
    osd: 'Soy Claim',
    gs1: 'nutritionalClaimNutrientElementCode(SOY) & nutritionalClaimTypeCode'
  },
  {
    id: '10000038',
    osd: 'Sugar Nutrient Content Claim',
    gs1: 'nutritionalClaimNutrientElementCode(SUGAR) & nutritionalClaimTypeCode'
  }
];

export const BUSINESS_CENTERS = [
  {
    id: 10,
    name: 'BAKERY'
  },
  {
    id: 11,
    name: 'BEEF'
  },
  {
    id: 12,
    name: 'BEVERAGE'
  },
  {
    id: 13,
    name: 'CANNED'
  },
  {
    id: 14,
    name: 'CHEMICAL'
  },
  {
    id: 15,
    name: 'COMMODITIES'
  },
  {
    id: 16,
    name: 'CONVENIENCE'
  },
  {
    id: 17,
    name: 'DAIRY'
  },
  {
    id: 18,
    name: 'DISPOSABLES'
  },
  {
    id: 19,
    name: 'FROZEN FRUITS & VEGETABLES'
  },
  {
    id: 20,
    name: 'FROZEN POTATOES'
  },
  {
    id: 21,
    name: 'GROCERY'
  },
  {
    id: 22,
    name: 'PORK'
  },
  {
    id: 23,
    name: 'POULTRY'
  },
  {
    id: 24,
    name: 'PRODUCE'
  },
  {
    id: 25,
    name: 'SEAFOOD'
  },
  {
    id: 26,
    name: 'SUPPLIES & EQUIPMENT'
  },
  {
    id: 27,
    name: 'ADMINISTRATIVE'
  }
];

export const NUTRITION_TAB_TOOLTIPS = {
  serving_size:
    'Serving size description is a customer-friendly description of the serving size (amount of food upon which the nutrient values are calculated).  Data for both fields can be found on your nutrition facts panel.',
  nutrient_values:
    'Enter your nutrient values based on your serving size. Units of measure are reflective of a nutrition facts panel. To add a value for an unlisted nutrient, select the “add nutrients” button.',
  k12:
    'Please select/complete the appropriate fields for your product.  The school nutrition note refers to the equivalency summary statement from your CN/PFS document.',
  ingredient_statement: 'Please copy and paste the text from your ingredient statement into this field.'
};

export const NONFOOD_BC_IDS = [18, 14, 26, 27];

export const FNB_KEYS = [
  'descriptor',
  'packaging',
  'size',
  'yield',
  'quality',
  'prep',
  'storage',
  'handling',
  'additional',
  'marketing',
  'culinary'
];
const generateTooltopValue = gs1Code => `Changes to this column must be done in ${gs1Code} in GS1`;

const getOtherAvailableGdsnTooltipValues = gs1Code =>
  `Changes to this field may be made in GS1 from the GDSN code list nutritionalClaimNutrientElementCode(${gs1Code}) & nutritionalClaimTypeCode. If Supplier is not a GS1 Supplier, changes to this may be made in the Sysco Supplier Suite.`;

export const GDSN_MAPPED_ATTRIBUTION_FIELDS = [
  'Halal Diet Type',
  'Kosher Diet Type',
  'Vegan Diet Type',
  'Vegetarian Diet Type'
];

const GDSN_ENABLED_NOT_SAME_VALUE_FIELDS_DIET_TYPES_TOOLTIP =
  'Supplier can only select the "Not" value on this attribute. For the affirmative value, please publish the correct value from GDSN code list DietTypeCode.';

const generateEnabledNotSameFieldAllergenTooltipValue = gs1Code =>
  `Supplier can only select the "Not Intentionally Included" or "Undeclared" value on this attribute. For the "Contains", "May Contains" or "Free From" value, please publish the correct value from GDSN code list allergenTypeCode(${gs1Code}) & levelOfContainmentCode in GS1.`;

export const ENUT_WINNING_SOURCE_VALUE = 'eNutrition';

export const DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS = {
  gdsn: {
    disabled_for_same_values: [
      'Halal Diet Type',
      'Kosher Diet Type',
      'Vegan Diet Type',
      'Vegetarian Diet Type',
      'Crustacean Shellfish Allergen',
      'Egg Allergen',
      'Fish Allergen',
      'Milk Allergen',
      'Peanut Allergen',
      'Soybean Allergen',
      'Tree Nuts Allergen',
      'Wheat Allergen',
      'Sesame Allergen',
      'Child Nutrition Eligibility'
    ],
    enabled_same_fields: {
      'Halal Diet Type': {
        tooltip: GDSN_ENABLED_NOT_SAME_VALUE_FIELDS_DIET_TYPES_TOOLTIP,
        enabledOptions: ['Not Halal']
      },
      'Kosher Diet Type': {
        tooltip: GDSN_ENABLED_NOT_SAME_VALUE_FIELDS_DIET_TYPES_TOOLTIP,
        enabledOptions: ['Not Kosher']
      },
      'Vegan Diet Type': {
        tooltip: GDSN_ENABLED_NOT_SAME_VALUE_FIELDS_DIET_TYPES_TOOLTIP,
        enabledOptions: ['Not Vegan']
      },
      'Vegetarian Diet Type': {
        tooltip: GDSN_ENABLED_NOT_SAME_VALUE_FIELDS_DIET_TYPES_TOOLTIP,
        enabledOptions: ['Not Vegetarian']
      },
      'Crustacean Shellfish Allergen': {
        tooltip: generateEnabledNotSameFieldAllergenTooltipValue('AC'),
        enabledOptions: ['Crustacean Shellfish Not Intentionally Included In Product', 'Undeclared']
      },
      'Egg Allergen': {
        tooltip: generateEnabledNotSameFieldAllergenTooltipValue('AE'),
        enabledOptions: ['Egg Not Intentionally Included In Product', 'Undeclared']
      },
      'Fish Allergen': {
        tooltip: generateEnabledNotSameFieldAllergenTooltipValue('AF'),
        enabledOptions: ['Fish Not Intentionally Included In Product', 'Undeclared']
      },
      'Milk Allergen': {
        tooltip: generateEnabledNotSameFieldAllergenTooltipValue('AM'),
        enabledOptions: ['Milk Not Intentionally Included In Product', 'Undeclared']
      },
      'Peanut Allergen': {
        tooltip: generateEnabledNotSameFieldAllergenTooltipValue('AP'),
        enabledOptions: ['Peanuts Not Intentionally Included In Product', 'Undeclared']
      },
      'Soybean Allergen': {
        tooltip: generateEnabledNotSameFieldAllergenTooltipValue('AY'),
        enabledOptions: ['Soybeans Not Intentionally Included In Product', 'Undeclared']
      },
      'Tree Nuts Allergen': {
        tooltip: generateEnabledNotSameFieldAllergenTooltipValue('AN'),
        enabledOptions: ['Tree Nuts Not Intentionally Included In Product', 'Undeclared']
      },
      'Wheat Allergen': {
        tooltip: generateEnabledNotSameFieldAllergenTooltipValue('AUW'),
        enabledOptions: ['Wheat Not Intentionally Included In Product', 'Undeclared']
      },
      'Sesame Allergen': {
        tooltip: generateEnabledNotSameFieldAllergenTooltipValue('AS'),
        enabledOptions: ['Sesame Not Intentionally Included', 'Undeclared']
      }
    },
    eNutrition: {
      'Halal Diet Type': { tooltip: generateTooltopValue('dietTypeCode(HALAL)') },
      'Kosher Diet Type': { tooltip: generateTooltopValue('dietTypeCode(KOSHER)') },
      'Vegan Diet Type': { tooltip: generateTooltopValue('dietTypeCode(VEGAN)') },
      'Vegetarian Diet Type': { tooltip: generateTooltopValue('dietTypeCode(VEGETARIAN)') },
      'Crustacean Shellfish Allergen': {
        tooltip: generateTooltopValue('allergenTypeCode(AC) & levelOfContainmentCode')
      },
      'Egg Allergen': { tooltip: generateTooltopValue('allergenTypeCode(AE) & levelOfContainmentCode') },
      'Fish Allergen': { tooltip: generateTooltopValue('allergenTypeCode(AF) & levelOfContainmentCode') },
      'Milk Allergen': { tooltip: generateTooltopValue('allergenTypeCode(AM) & levelOfContainmentCode') },
      'Peanut Allergen': { tooltip: generateTooltopValue('allergenTypeCode(AP) & levelOfContainmentCode') },
      'Soybean Allergen': { tooltip: generateTooltopValue('allergenTypeCode(AY) & levelOfContainmentCode') },
      'Tree Nuts Allergen': { tooltip: generateTooltopValue('allergenTypeCode(AN) & levelOfContainmentCode') },
      'Wheat Allergen': { tooltip: generateTooltopValue('allergenTypeCode(UW) & levelOfContainmentCode') },
      'Sesame Allergen': { tooltip: generateTooltopValue('allergenTypeCode(AS) & levelOfContainmentCode') },
      'Child Nutrition Eligibility': {
        tooltip: generateTooltopValue('isChildNutrition & sleHasProductFormulationStmt')
      }
    },
    other_available_tooltips: {
      'Contains HFCS Claim': { tooltip: getOtherAvailableGdsnTooltipValues('HIGH_FRUCTOSE_CORN_SYRUP') },
      'Fat Nutrient Content Claim': { tooltip: getOtherAvailableGdsnTooltipValues('FAT') },
      'Gluten Claim Status': { tooltip: getOtherAvailableGdsnTooltipValues('GLUTEN') },
      'MSG Status Claim': { tooltip: getOtherAvailableGdsnTooltipValues('MSG') },
      'Organic Claim Status': { tooltip: getOtherAvailableGdsnTooltipValues('OrganicTradeItemCode') },
      'Sodium Nutrient Content Claim': { tooltip: getOtherAvailableGdsnTooltipValues('SODIUM SALT') },
      'Sugar Nutrient Content Claim': { tooltip: getOtherAvailableGdsnTooltipValues('SUGAR') },
      'Child Nutrition Eligibility': {
        tooltip:
          'Changes to this field may be made in GS1 from the GDSN code list isChildNutrition & sleHasProductFormulationStmt. If Supplier is not a GS1 Supplier, changes to this may be made in the Sysco Supplier Suite.'
      }
    }
  }
};

export const SUSTAINABILITY_MASS_CHECK_BOX_TITLE = 'No sustainability attributes applicable';

export const INT_IDENTIFIERS = ['in', 'inch', '"', `”`];

export const NUM_TRAILING_CHARS = [`"`, `”`];

export const NUM_LEADING_CHARS = [`-`];

export const NOT_REQUIRED_NUTRIENTS_VARIED_SHORT_NAMES = ['ADDSGR', 'K', 'VITD'];

export const FNB_EXCLUDED_WORDS = [
  {
    message: 'Please add value added content for: ',
    values: ['not relevant', 'n/a', 'does not apply', 'not applicable']
  },
  {
    message: 'Please reach out to your local operating company or business center to discontinue this item',
    values: ['disco', 'discontinue', 'discontinued']
  },
  {
    message:
      'If this is a proprietary item or the item does not belong to you please mark it as such under item status in the core data tab',
    values: ['proprietary', 'item is not mine', 'does not belong to us', 'not mine', 'not my item', 'not our item']
  }
];

export const ENUT_EXCLUDED_BUSINESS_CENTERS = ['14', '18', '26', '27'];

export const PREF_IMG_REJECT_EXP_DAYS = 7;

export const PFAS_DISABLED_TOOLTIP_CONTENT = 'This attribute is not applicable for this item category.';

export const PFAS_SINGLE_SELECTIONS = [
  'Does not contain PFAS (not applicable to this product)',
  'Contains intentionally added PFAS'
];

export const IFOODS_LINK = 'https://supplier-trace.ifoodds.com';

export const PACKAGING_MULTIVALUE_FIELD_IDS = ['packaging_feature', 'packaging_sustainability_claim'];

export const PACKAGING_MANDATORY_FIELD_IDS = [
  'packaging_product_hierarchy_level',
  'packaging_type',
  'packaging_weight',
  'packaging_weight_uom',
  'packaging_material_type'
];

export const PACKAGING_PERCENTAGE_FIELD_IDS = [
  'packaging_post_consumer_recyclability',
  'packaging_post_industrial_recyclability'
];

export const EMPTY_PACKAGING_POST_DATA = {
  syy_itm_a_pkg_level: '',
  syy_itm_a_pkg_type: '',
  syy_itm_a_pkg_isreturnable: '',
  syy_itm_a_pkg_sustainabilityclaim: [],
  syy_itm_a_pkg_weight: '',
  syy_itm_a_pkg_weightuom: '',
  syy_itm_a_pkg_feature: [],
  syy_itm_a_pkg_postconsumerrecyclabletype: '',
  syy_itm_a_pkg_postconsumerrecyclability: '',
  syy_itm_a_pkg_materialtype: '',
  syy_itm_a_pkg_pstindustryrecyclabletype: '',
  syy_itm_a_pkg_pstindustryrecyclability: '',
  syy_itm_a_pkg_componentdescription: ''
};

export const PACKAGING_GDSN_FIELD_IDS = [
  'packaging_product_hierarchy_level',
  'packaging_type',
  'packaging_is_returnable',
  'packaging_sustainability_claim',
  'packaging_weight',
  'packaging_weight_uom',
  'packaging_level',
  'packaging_material_type',
  'packaging_feature',
  'packaging_component_description',
  'packaging_post_consumer_recyclable_type',
  'packaging_post_consumer_recyclability'
];
