import React from 'react';
import { Button } from 'antd';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import openNotification from 'components/Notification';
import PfasAttributeFilter from './PfasAttributeFilter';
import * as actionCreators from '../../actions';
import { checkIfFilteredValuesExists } from '../../util/Util';

export default function PfasAttributeSelector({ onFilterChange, hightlight }) {
  const dispatch = useDispatch();
  const { applyMassPfasFilteredValues, clearPfasAppliedValues } = bindActionCreators(actionCreators, dispatch);

  const selectedRows = useSelector(state => _.get(state.massUpdate.pfas, 'selectedRows', []));
  const appliedValues = useSelector(state => _.get(state.massUpdate.pfas, 'appliedValues', {}));
  const isLoading = useSelector(state => _.get(state.massUpdate.pfas, 'isLoading', false));
  const isSaving = useSelector(state => _.get(state.massUpdate.pfas, 'isSaving', false));

  const hasFilteredValues = checkIfFilteredValuesExists(appliedValues);
  const count = selectedRows.length;
  const countMessage = `Changes will be applied to ${count} ${count === 1 ? 'item' : 'items'}.`;
  const disabledStatus = hasFilteredValues || isLoading || isSaving;

  const onClickApply = () => {
    if (selectedRows.length > 0) {
      applyMassPfasFilteredValues();
    } else {
      openNotification({
        className: 'error',
        message: 'NO ITEMS SELECTED',
        description: 'Please select the items before applying the changes'
      });
    }
  };

  const onClickClear = () => {
    clearPfasAppliedValues();
  };

  return (
    <div className="mass-attr-selector-wrapper">
      <div className="mass-attr-selector-bar">
        <div className="selector-message">
          {`Any changes made here will be applied to all selected items on the current page. ${countMessage}`}
        </div>
        <div className={`mass-attr-selector with-pfas ${hightlight ? 'attr-selector-highlight' : ''}`}>
          <PfasAttributeFilter onFilterChange={onFilterChange} />
        </div>
      </div>
      <div>
        <Button className="attr-selector-action-btns submit-btn" disabled={disabledStatus} onClick={onClickApply}>
          Apply
        </Button>
        <Button className="attr-selector-action-btns clear-btn" disabled={disabledStatus} onClick={onClickClear}>
          Clear
        </Button>
      </div>
    </div>
  );
}
