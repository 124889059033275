import React, { useState } from 'react';
import { Select, Input, Modal, Button } from 'antd';
import { UNIVERSAL_FIELDS, UNIVERSAL_REJECT_STATUS_DROPDOWNS } from '../../util/ThirdpartyUniversalConstants';
import { DEFAULT_OPTION } from '../../util/Constants';

const { Option } = Select;
const { TextArea } = Input;

const [REJECTED_STATUS, FIX_STATUS] = UNIVERSAL_REJECT_STATUS_DROPDOWNS;

export default function PendingThirdpartyUniversal({
  updatedComment,
  id,
  status,
  handleAttributeChange,
  onRejectAllModalOpen,
  onConfirmRejectAll,
  onCancelRejectAll,
  isAllRejectModalOpen
}) {
  const [selectStatuses, setSelectStatuses] = useState({});
  const getRejectedStatus = key => {
    let status = DEFAULT_OPTION;
    if (updatedComment[key].isRejected) status = REJECTED_STATUS;
    if (
      updatedComment[key].isFixed &&
      selectStatuses &&
      selectStatuses[key] !== DEFAULT_OPTION &&
      !updatedComment[key].isRejected
    )
      status = FIX_STATUS;
    else if (selectStatuses && selectStatuses[key] === DEFAULT_OPTION && !updatedComment[key].isRejected)
      status = DEFAULT_OPTION;
    return status;
  };

  const onChangeRejectStatus = (value, key) => {
    setSelectStatuses({
      ...selectStatuses,
      [key]: value
    });
    let rejectStatus = false;
    let fixedStatus = false;
    if (value === REJECTED_STATUS) rejectStatus = true;
    if (value === FIX_STATUS || value === DEFAULT_OPTION) fixedStatus = true;
    const updatedData = {
      ...updatedComment,
      [key]: { ...updatedComment[key], isRejected: rejectStatus, isFixed: fixedStatus, isRejectedFixed: false }
    };
    handleAttributeChange({ id, comment: JSON.stringify(updatedData) });
  };

  const onChangeDescription = (value, key) => {
    const updatedData = { ...updatedComment, [key]: { ...updatedComment[key], feedback: value } };
    handleAttributeChange({ id, comment: JSON.stringify(updatedData) });
  };

  const getCommentTextAreaStylesForRejections = key => updatedComment[key].isRejected && !updatedComment[key].feedback;

  const getStatusDisabledForSelect = (key, value) => {
    let disableStatus = false;
    if (value === REJECTED_STATUS && updatedComment[key].isRejected && !updatedComment[key].isFixed)
      disableStatus = true;
    if (
      value === FIX_STATUS &&
      !updatedComment[key].isRejected &&
      updatedComment[key].isFixed &&
      selectStatuses &&
      selectStatuses[key] !== DEFAULT_OPTION
    )
      disableStatus = true;
    if (value === DEFAULT_OPTION && selectStatuses && selectStatuses[key] === DEFAULT_OPTION) disableStatus = true;
    return disableStatus;
  };

  return (
    <div className="core-data-table-container">
      <table className="core-data-table">
        <tbody className="core-data-tbody">
          <tr>
            <th>Attribute</th>
            <th>Changed Value</th>
            <th style={{ width: '200px' }}>
              Reject Status
              <span
                className={`tiny-click ${status === 30 ? 'tiny-span-disabled' : undefined}`}
                onClick={() => onRejectAllModalOpen()}
              >
                Reject All
              </span>
              <Modal
                visible={isAllRejectModalOpen}
                title="Please confirm..."
                onOk={() => onConfirmRejectAll({ id })}
                onCancel={() => onCancelRejectAll()}
                footer={[
                  <Button key="back" onClick={() => onCancelRejectAll()}>
                    Cancel
                  </Button>,
                  <Button key="submit" type="danger" onClick={() => onConfirmRejectAll({ id })}>
                    Reject All
                  </Button>
                ]}
              >
                Do you want to reject all the attributes of this item?
              </Modal>
            </th>
            <th style={{ width: '275px' }}>Description (Optional)</th>
          </tr>
          {Object.keys(updatedComment).map((key, index) => {
            let element = null;
            element = (
              <tr key={index}>
                <td>{UNIVERSAL_FIELDS[key].caption}</td>
                <td>{updatedComment[key].value}</td>
                <td>
                  <Select
                    disabled={status === 30}
                    value={getRejectedStatus(key)}
                    dropdownMatchSelectWidth={false}
                    style={{ width: '100%' }}
                    onChange={value => onChangeRejectStatus(value, key)}
                    className="image-feedback-dropdown"
                  >
                    <Option key={0} value="Select Status">
                      Select Status
                    </Option>
                    {UNIVERSAL_REJECT_STATUS_DROPDOWNS.map(value => {
                      return (
                        <Option key={value} value={value} disabled={getStatusDisabledForSelect(key, value)}>
                          {value}
                        </Option>
                      );
                    })}
                  </Select>
                </td>
                <td>
                  <TextArea
                    className={`${getCommentTextAreaStylesForRejections(key) ? 'text-box-rejected' : undefined}`}
                    value={updatedComment[key].feedback}
                    rows={4}
                    disabled={status === 30 || !updatedComment[key].isRejected}
                    onChange={e => onChangeDescription(e.target.value, key)}
                  />
                </td>
              </tr>
            );
            return element;
          })}
        </tbody>
      </table>
    </div>
  );
}
