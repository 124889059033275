import React from 'react';

const ImageGuideNonExpanded = () => {
  return (
    <div className="non-expanded-image-guidline">
      <p>
        Please ensure upload images follow guidelines according to the Industry standard that is distributed by&nbsp;
        <a
          rel="noopener noreferrer"
          href="https://www.gs1.org/standards/gs1-product-image-specification-standard/current-standard#2-Marketing-image-types+2-6-Image-types"
          target="_blank"
        >
          GS1
        </a>
        .
      </p>
      <p>Image resolution and size must include the listed:</p>
      <ul>
        <li>High resolution images: 300 ppi</li>
        <li>Minimum size: 900x900 - 2400x2400 pixels</li>
        <li>Maximum size: 8MB</li>
      </ul>
      <p>File types must be restricted to the following:</p>
      <ul>
        <li>JPG</li>
        <li>PNG</li>
      </ul>
      <p>Acceptable color mode:</p>
      <ul>
        <li>RGB</li>
      </ul>
    </div>
  );
};

export default ImageGuideNonExpanded;
