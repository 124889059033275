import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import ProtectedRoute from 'components/ProtectedRoute';

import { BrowserRouter, Switch } from 'react-router-dom';
import ItemManagementPage from 'pages/Item';
import ItemDashboardPage from 'pages/ItemDashboard';
import InternalItemManagementPage from 'pages/InternalItems';
import ItemVisibilityView from 'pages/ItemVisibilityView';

import AppLoader from 'components/AppLoader';

import * as userActions from 'actions';
import AppLogo from './styles/images/app-logos/item-mgmt.svg';
import styles from './styles/CostingApp.scss';

const setAppLogo = () => {
  if (document.getElementById('app-logo')) {
    document.getElementById('app-logo').src = '/ui/item-management' + AppLogo;
  }
};

const setAppHeaderToAppMode = () => {
  if (document.getElementById('app-bar')) {
    document.getElementById('app-bar').className = 'app-bar app-mode';
  }
};

function MainApp({ actions, userId, userFetching, userPermissions }) {
  console.log('Inner width:', window.innerWidth, 'x Inner height:', window.innerHeight);
  setAppLogo();
  setAppHeaderToAppMode();

  useEffect(() => {
    styles.use();
    return () => {
      styles.unuse();
    };
  }, []);

  useEffect(() => {
    actions.loadUserDetails(userId);
  }, [actions, userId]);

  return !userFetching ? (
    <div className="wrapper items-wrapper">
      <BrowserRouter>
        <Switch>
          <ProtectedRoute
            exact
            path="/suite/item-management"
            component={ItemManagementPage}
            type="SupplierRoute"
            userPermissions={userPermissions}
          />
          <ProtectedRoute
            exact
            path="/suite/item-management/dashboard"
            component={ItemDashboardPage}
            type="AssociateRoute"
            userPermissions={userPermissions}
          />
          <ProtectedRoute
            exact
            path="/suite/item-management/feedback"
            component={ItemDashboardPage}
            type="AssociateRoute"
            userPermissions={userPermissions}
          />
          <ProtectedRoute
            exact
            path="/suite/item-management/internal"
            component={InternalItemManagementPage}
            type="AssociateRoute"
            userPermissions={userPermissions}
          />
          <ProtectedRoute
            exact
            path="/suite/item-management/itemvisibility"
            component={ItemVisibilityView}
            type="AssociateRoute"
            userPermissions={userPermissions}
          />
        </Switch>
      </BrowserRouter>
    </div>
  ) : (
    <AppLoader show />
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    userPermissions: state.user.data.permissions,
    userFetching: state.user.fetching || state.user.data.email == null
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(userActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainApp);
