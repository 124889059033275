import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import AppLoader from 'components/AppLoader';
import * as batchesActions from 'actions';
import CommentList from 'components/CommentList';
import ItemView from 'components/ItemView';
import { isSbsUser, isCoreDataManager, isHidingProprietaryComments } from './../util/Util';

function ItemDashboardPage({
  state,
  actions,
  commentsFetching,
  comments,
  search,
  fType,
  fStatus,
  page,
  recordCount,
  loading,
  itemsFetching,
  selectedItem,
  fieldComment,
  pendingComments,
  tableFilters,
  brandGroupFilters,
  orderBy,
  tableSearchData,
  filterType,
  dateRangeFilter,
  permissions,
  roles
}) {
  const isSbs = isSbsUser(roles);
  const isCoreManager = isCoreDataManager(permissions);
  const isHidingProprietaryFeedbacksPermission = isHidingProprietaryComments(permissions);

  useEffect(() => {
    actions.loadDashComments({
      page: 1,
      search: '',
      fType: null,
      fStatus: null,
      isSbsUser: isSbs,
      isCoreDataManager: isCoreManager,
      isLoadingFirstTime: true,
      isHidingProprietaryFeedbacksPermission
    });
  }, [actions, isCoreManager, isSbs]);

  if (commentsFetching) {
    return <AppLoader show />;
  }

  return (
    <React.Fragment>
      {itemsFetching && <AppLoader show />}
      {selectedItem && selectedItem.supc && (
        <ItemView
          suvc={selectedItem.suvc}
          item={selectedItem}
          actions={actions}
          fieldComment={fieldComment}
          pendingComments={pendingComments}
        />
      )}
      <CommentList
        comments={comments}
        actions={actions}
        page={page}
        fType={fType}
        fStatus={fStatus}
        search={search}
        recordCount={recordCount}
        loading={loading}
        tableFilters={tableFilters}
        brandGroupFilters={brandGroupFilters}
        orderBy={orderBy}
        tableSearchData={tableSearchData}
        filterType={filterType}
        dateRangeFilter={dateRangeFilter}
        isSbsUser={isSbs}
        isCoreDataManager={isCoreManager}
        isHidingProprietaryFeedbacksPermission={isHidingProprietaryFeedbacksPermission}
      />
    </React.Fragment>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.data,
    comments: state.comments.data,
    loading: state.comments.loading,
    commentsFetching: state.comments.fetching || state.comments.data == null || state.comments.fetchingFnbComments,
    page: state.comments.page,
    search: state.comments.search,
    fType: state.comments.fType,
    fStatus: state.comments.fStatus,
    recordCount: state.comments.recordCount,
    itemsFetching: state.selectedItem.fetching,
    selectedItem: state.selectedItem,
    fieldComment: state.fieldComment,
    pendingComments: state.pendingComments,
    tableFilters: state.vendor.tableFilters,
    brandGroupFilters: state.vendor.brandGroupFilters,
    orderBy: state.vendor.orderBy,
    tableSearchData: state.vendor.tableSearchData,
    filterType: state.vendor.filterType,
    dateRangeFilter: state.vendor.dateRangeFilter,
    permissions: state.user.data.permissions,
    roles: state.user.data.roles
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(batchesActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ItemDashboardPage));

ItemDashboardPage.propTypes = {};
