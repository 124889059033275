import React, { forwardRef, useEffect, useCallback } from 'react';
import { Table, Input, Select, Button } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import * as _ from 'lodash';

import OverallItemStatusFeedbackHistory from './OverallItemsStatusFeedbackHistory';

import {
  getOverallStatusFromText,
  getCoreDataCommentDisabledFields,
  isCommentAssignedToMe,
  getLastElementOfArray
} from '../util/Util';
import { OVERALL_REJECT_STATUSES } from '../util/Constants';

const { TextArea } = Input;
const { Option } = Select;

const CommentExpandedOverall = ({ commentData, actions, userId }, ref) => {
  const { id, assignee, comment, originalValue, status, supc } = commentData;

  const {
    changes: { commentText, statusTo },
    historyData,
    isFetching
  } = useSelector(state =>
    _.get(state.comments.overallItemFeedbackHistory.singleComments, parseInt(id), { changes: {}, historyData: [] })
  );

  const { existingValue, newValue } = getLastElementOfArray({
    arrayOfObjects: historyData,
    originalValue,
    newValue: comment
  });
  const dispatch = useDispatch();

  const handleFetch = useCallback(commentId => {
    dispatch(actions.fetchOverallHistoryForComment({ commentId }));
  });

  useEffect(() => {
    handleFetch(parseInt(id));
  }, [id]);

  const getOptionsDisabled = useCallback(key => [0, 20].includes(key), []);

  const handleStatusChange = useCallback(
    statusText => {
      const { key } = getOverallStatusFromText(statusText, 'value');
      actions.updateOverallHistoryComment({ statusTo: key, commentId: id, commentText: null });
    },
    [actions.updateOverallHistoryComment, id]
  );

  const handleCommentTextChange = useCallback(
    comment => {
      actions.updateOverallHistoryComment({ commentText: comment, commentId: id });
    },
    [actions.updateOverallHistoryComment, id]
  );

  const onClickAssignToMe = useCallback(() => {
    actions.selfAssignComment({
      commentId: id,
      userId
    });
  }, [actions.selfAssignComment, id, userId]);

  const getCommentStatus = useCallback((newStatusTo, currentStatus) => {
    let selectedValue = currentStatus;
    if ([0, 10, 20, 30].includes(newStatusTo)) selectedValue = newStatusTo;
    const { value } = getOverallStatusFromText(selectedValue, 'key');
    return value;
  }, []);

  const getCommentFieldRequiredState = useCallback(
    (comment, newStatusTo, currentStatus) => {
      if (getCommentStatus(newStatusTo, currentStatus) === 'REJECT' && (!comment || comment === ''))
        return 'text-box-rejected';
      return '';
    },
    [getCommentStatus]
  );

  const getDescription = record => {
    const { commentText, statusTo, statusFrom } = record;
    let previousStatusTo = null;
    let previousStatusFrom = null;
    let previousCommentText = null;
    if (historyData && historyData.length) {
      const { statusTo: toStatus, statusFrom: fromStatus, commentText: text } = historyData[historyData.length - 1];
      previousCommentText = text;
      previousStatusFrom = fromStatus;
      previousStatusTo = toStatus;
    }
    if (commentText !== null) return commentText;
    else if (previousCommentText) return previousCommentText;
    return null;
  };

  const columns = [
    {
      title: () => (
        <div className="flags-parts">
          <Button className="assign-to-me-btn" onClick={onClickAssignToMe}>
            Assign to Me
          </Button>
          {assignee && <span className="assignee-label">{`Assigned to: ${assignee}`}</span>}
          <OverallItemStatusFeedbackHistory supc={supc} historyData={historyData} type={'ITEM_HISTORY'} />
        </div>
      ),
      dataIndex: 'comment',
      key: 'comment',
      render: (text, record) => {
        if (isFetching) return <div className="empty-reviews">Loading...</div>;
        return (
          <div className="row-expand" style={{ display: 'flex' }}>
            <div id="core-expanded-table-wrapper" className="image-expand-info-table">
              <div className="core-data-table-container">
                <table className="core-data-table">
                  <tbody className="core-data-tbody">
                    <tr key={10}>
                      <th>Existing Value</th>
                      <th>New Value</th>
                      <th style={{ width: '200px' }}>Status</th>
                      <th style={{ width: '275px' }}>Description</th>
                    </tr>
                    <tr key={20}>
                      <td>{record.existingValue}</td>
                      <td>{record.newValue}</td>
                      <td>
                        <Select
                          key={`status-${id}${Math.random()}`}
                          disabled={status === 30 || !isCommentAssignedToMe(userId, assignee)}
                          value={getCommentStatus(record.statusTo, record.status)}
                          dropdownMatchSelectWidth={false}
                          style={{ width: '100%' }}
                          onSelect={handleStatusChange}
                          className="image-feedback-dropdown"
                        >
                          {OVERALL_REJECT_STATUSES.map(({ key, value }) => (
                            <Option key={key} value={value} disabled={getOptionsDisabled(key)}>
                              {value}
                            </Option>
                          ))}
                        </Select>
                      </td>
                      <td style={{ textAlign: 'right' }}>
                        <TextArea
                          className={getCommentFieldRequiredState(record.commentText, record.statusTo, record.status)}
                          key="comment"
                          disabled={!isCommentAssignedToMe(userId, assignee) || status == 30}
                          value={getDescription(record)}
                          rows={4}
                          onChange={e => {
                            handleCommentTextChange(e.target.value);
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        );
      }
    }
  ];

  return (
    <div className="row-expand" style={{ display: 'flex' }}>
      <div id="core-expanded-table-wrapper" className="image-expand-info-table">
        <Table
          rowKey="rowKey"
          dataSource={[
            {
              rowKey: id,
              comment,
              rejectStatus: '',
              commentDescription: '',
              historyData,
              originalValue,
              existingValue,
              newValue,
              commentText,
              statusTo,
              status,
              isFetching
            }
          ]}
          columns={columns}
          pagination={false}
          ref={ref}
        />
      </div>
    </div>
  );
};

export default React.memo(forwardRef(CommentExpandedOverall));
