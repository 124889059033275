import { bindActionCreators } from 'redux';
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import { formatDate } from '../util/Util';
import * as actionCreators from '../actions';

export default function ItemDatesBar() {
  const createdDate = useSelector(state => _.get(state, 'selectedItem.master.syy_itm_a_createdtime.value.value', {}));
  const updatedDate = useSelector(state =>
    _.get(state, 'selectedItem.master.syy_itm_a_lastchangedtime.value.value', {})
  );
  const supc = useSelector(state => _.get(state, 'selectedItem.supc', null));
  const fetching = useSelector(state => _.get(state, 'selectedItem.itemMasterData.fetching', false));
  const lastObligDate = useSelector(state => _.get(state, 'selectedItem.itemMasterData.lastObligDate', null));
  const lastPoDate = useSelector(state => _.get(state, 'selectedItem.itemMasterData.lastPoDate', null));

  const dispatch = useDispatch();
  const { getItemMasterData } = bindActionCreators(actionCreators, dispatch);

  useEffect(() => {
    if (supc && !fetching) {
      getItemMasterData({ supc, fields: ['last_oblig_date', 'last_po_date'] });
    }
  }, [supc]);

  return (
    <div className="item-dates-bar">
      {createdDate && <div className="date-field">Create Date: {formatDate(createdDate)}</div>}
      {updatedDate && <div className="date-field">Last Update Date: {formatDate(updatedDate)}</div>}
      {lastObligDate && <div className="date-field">Last Sale Date: {formatDate(lastObligDate)}</div>}
      {lastPoDate && <div className="date-field">Last PO Date: {formatDate(lastPoDate)}</div>}
    </div>
  );
}
