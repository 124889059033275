import React from 'react';
import { nutritionHeaderKeys, nutritionPropertyKeys, UOM_TYPES_MAPPING } from '../../enums';
import { getIsFeedbackChangedClassName, getChangedTitle, getUofmForCholAsAlreadyInGram } from '../../util/Util';

const nutritionHeaderFields = ['servingDetails', 'nutritionAnalysisDetail'];
const servingDetailFields = ['servingSizeDescription', 'servingSizeQuantity', 'servingSizeUofm'];

const stylesForChangesOrRemovals = (isUpdated, isRemoved, quantityContained) => {
  // if (isRemoved || (isUpdated && quantityContained === null)) return 'table-removed-row';
  if (isRemoved) return 'table-removed-row';
  return '';
};

const getNutritionDetailsForInnerElemens = (objectKey, headerData, changedFields) => {
  return (
    <tbody key={objectKey} className="nutritions-tbody">
      <tr className="body-sub-title-header">
        <th className="sub-title-text">Nutrition Details</th>
      </tr>
      <tr className="table-header-nutrition">
        <th className="nutrition-property-name">Nutrition Name</th>
        <th className="nutrition-property-name">Quantity Contained</th>
        <th className="nutrition-property-name">Quantity Contained Uofm</th>
      </tr>
      {headerData[objectKey].map(
        (
          {
            quantityContained,
            quantityContainedUofm,
            nutritionName,
            isUpdated,
            shortName,
            isRequiredNutrition,
            hasRemoved
          },
          index
        ) => {
          let innerElement = null;
          const feedbackChangedClass = getIsFeedbackChangedClassName(changedFields, shortName, hasRemoved);
          if ((!isRequiredNutrition && (quantityContained || hasRemoved)) || isRequiredNutrition) {
            innerElement = (
              <tr
                className={`${stylesForChangesOrRemovals(
                  isUpdated,
                  hasRemoved,
                  quantityContained
                )} ${feedbackChangedClass}`}
                key={index}
                title={getChangedTitle(feedbackChangedClass)}
              >
                <th>{nutritionName}</th>
                <th className="white">{quantityContained}</th>
                <th className="white">
                  {UOM_TYPES_MAPPING[getUofmForCholAsAlreadyInGram(shortName, quantityContainedUofm)]}
                </th>
              </tr>
            );
          }
          return innerElement;
        }
      )}
    </tbody>
  );
};

const assignOuterAndInnerElements = (objectKey, headerData, element, changedFields) => {
  let outerElement = element;
  if (objectKey !== 'nutritionAnalysisDetail') {
    outerElement = (
      <tbody key={objectKey} className="nutritions-tbody">
        <tr className="body-sub-title-header">
          <th className="sub-title-text">Serving Details</th>
        </tr>
        {servingDetailFields.map((key, index) => {
          return (
            <tr key={key} className={`${getIsFeedbackChangedClassName(changedFields, key)}`}>
              <th>{nutritionHeaderKeys[key]}</th>
              <th className="white">{headerData[key]}</th>
            </tr>
          );
        })}
      </tbody>
    );
  } else {
    outerElement = getNutritionDetailsForInnerElemens(objectKey, headerData, changedFields);
  }
  return outerElement;
};

const NutritionAnalysisHeader = ({ comment, identifier }) => {
  const [nutritionHeaderData] = comment[identifier];
  if (nutritionHeaderData) {
    return (
      <div className="nutrition-container" key={identifier}>
        <div className="nutrition-title">{nutritionPropertyKeys[identifier]}</div>
        <table className="nutrition-data-table">
          {nutritionHeaderFields.map((objectKey, indexOfKey) => {
            return assignOuterAndInnerElements(objectKey, nutritionHeaderData, null, comment.changedFields);
          })}
        </table>
      </div>
    );
  }
};

export default NutritionAnalysisHeader;
