import React from 'react';
import TraitRowData from './TraitRowData';

const getTypeAndCode = (type, allergenCode, dietTypeCode) =>
  (type === 'Allergens' && allergenCode) || (type === 'Diet Types' && dietTypeCode);

const TraitData = ({ mainKey, title, codeTitle, data }) => {
  return (
    <div className="nutrition-container">
      <div className="nutrition-title">{title}</div>
      <div key={mainKey}>
        <table className="nutrition-data-table">
          <tbody className="nutritions-tbody">
            <tr className="table-header-nutrition">
              <th className="nutrition-property-name">Property</th>
              <th className="nutrition-property-name">{`${codeTitle} Code`}</th>
              {codeTitle === 'Allergen' && <th className="nutrition-property-name">Level Of Containment</th>}
            </tr>
            {data.map((eachTrait, traitIndex) => {
              const {
                name,
                allergenCode,
                dietTypeCode,
                levelOfContainment,
                isExisting,
                value,
                previousValue
              } = eachTrait;
              let element = null;
              if (getTypeAndCode(title, allergenCode, dietTypeCode)) {
                element = (
                  <TraitRowData
                    field={name}
                    value={allergenCode || dietTypeCode}
                    level={levelOfContainment || 'N/A'}
                    index={traitIndex}
                    key={traitIndex}
                    removedStatus={value === null || levelOfContainment === null}
                    isExisting={isExisting}
                    previousValue={previousValue}
                    codeTitle={codeTitle}
                  />
                );
              }
              return element;
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TraitData;
