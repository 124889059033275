import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Checkbox } from 'antd';
import PfasSelectCheckBox from './PfasSelectCheckBox';
import TooltipInfo from './TooltipInfo';
import { checkIfPfasAttributeIsDisabled } from '../../../util/Util';

const headerTooltip =
  'PFAS (Per- and Polyfluoroalkyl Substances) are a large class of man-made chemicals with water- and grease-resistant properties. Select as many values as are appropriate. If this question is not applicable to your product or packaging/packaging components, please select ‘Does not contain PFAS’.  If PFAS has been intentionally added to your product or packaging/packaging components, please select ‘Contains intentionally added PFAS’. If your product or packaging/packaging components do not contain intentionally added PFAS, please select one or more of the remaining options to give us additional details. Sysco is committed to the highest level of accuracy in reporting sustainability attributes. Answering in the affirmative for any sustainability attribute for any item should only be done when accurate and supportable.';

const PFas = ({
  actions,
  item: {
    supc,
    pfas: { attributes, changes, isMassNotApplicable, isLoadingOrSaving },
    isLoadingPfas,
    basicData: { brandCode },
    readOnly,
    taxonomy
  }
}) => {
  const [isDisabled, setIsDisabled] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    let disabledStatus = false;
    if (brandCode === 'PACKER' || readOnly || isLoadingOrSaving || isLoadingPfas) {
      disabledStatus = true;
    }
    setIsDisabled(disabledStatus);
  }, [brandCode, readOnly, isLoadingOrSaving, isLoadingPfas]);

  const handleSelectionChange = useCallback(
    isChecked => {
      dispatch(actions.pfasMassNotApplicable({ isChecked }));
    },
    [actions]
  );

  return (
    <div className="attr-section">
      <div className="attr-section-small" style={{ display: 'flex' }}>
        <div className="attr-section-title">PFAS Contact Level </div>
        <div className="attr-label" style={{ marginTop: '5px' }}>
          <TooltipInfo tooltip={headerTooltip} className="pfas-custom-tooltip" placement="right" />
        </div>
      </div>
      <div className="attr-section-subtitle subtitle-and-tooltip-container">You can review the value and update</div>
      {}
      {/* </div> */}

      <div className="attr-set">
        <Checkbox
          className="mass-free-from-checkbox"
          disabled={isDisabled}
          checked={isMassNotApplicable}
          onChange={value => handleSelectionChange(value.target.checked)}
        >
          No PFAS attributes applicable
        </Checkbox>
        {attributes.map(eachAttribute => {
          const { disabledTooltip } = checkIfPfasAttributeIsDisabled(taxonomy, { name: eachAttribute.name });
          return (
            <PfasSelectCheckBox
              key={eachAttribute.attrId}
              attribute={eachAttribute}
              actions={actions}
              isDisabled={isDisabled}
              brandCode={brandCode}
              changes={changes}
              taxonomy={taxonomy}
            />
          );
        })}
      </div>
    </div>
  );
};

export default PFas;
