import _ from 'lodash';

import { imageCategories, mainCategoriesList } from '../util/Data';
import { isValidImageName } from './Util';

export const getPreferredImages = images => {
  let preferredImages = [];
  let preferredAssetIds = [];

  mainCategoriesList.forEach(mainCategory => {
    const styles = imageCategories[mainCategory].styles;
    let image = null;

    if (images) {
      image = _.find(images, img => {
        return img.imageStyle && styles.includes(img.imageStyle.toLowerCase()) && img.isPreferred;
      });
    }

    preferredImages[mainCategory] = { ...imageCategories[mainCategory], image: image || null };

    if (image) preferredAssetIds.push(image.assetId);
  });

  return { preferredImages, preferredAssetIds };
};

export const getAdditionalAndUnclassifiedImages = images => {
  const { preferredAssetIds } = getPreferredImages(images);

  let additionalImages = [];
  let unclassifiedImages = [];
  let existingStyles = [];

  if (images) {
    _.forEach(images, img => {
      // Identifying the existing image styles
      if (img.imageStyle) {
        const formattedImgStyle = img.imageStyle.toLowerCase().trim();
        existingStyles.push(formattedImgStyle);

        _.forEach(mainCategoriesList, mainCategory => {
          const styles = imageCategories[mainCategory].styles;
          if (_.includes(styles, formattedImgStyle)) existingStyles = [...existingStyles, ...styles];
        });
      }

      // Identifying additional and unclassified images
      const isValidName = isValidImageName(img.name);

      if (!_.includes(preferredAssetIds, img.assetId) && img.imageStyle && isValidName) {
        additionalImages.push(img);
      } else if (!_.includes(preferredAssetIds, img.assetId) && (!img.imageStyle || !isValidName)) {
        unclassifiedImages.push(img);
      }
    });

    existingStyles = _.uniq(existingStyles);
  }

  return { additionalImages, unclassifiedImages, existingStyles };
};

// Checks if the image is the first the first image of the image bucket
export const isFirstImage = (style, existingStyles, pendingComments) => {
  _.forEach(pendingComments.comments, cmt => {
    if (cmt.type === 'IMAGE' && cmt?.imgComment?.changeType === 'CREATE') {
      const formattedImgStyle = _.toLower(cmt.imgComment.style);

      _.forEach(mainCategoriesList, mainCategory => {
        const styles = imageCategories[mainCategory].styles;
        if (_.includes(styles, formattedImgStyle)) existingStyles = [...existingStyles, ...styles];
      });
    }
  });

  return !_.includes(existingStyles, _.toLower(style));
};

// Disable delete and pen icon if an image has a pending/rejected style change or deletion
export const getDisabledOptions = (assetId, pendingImageComments) => {
  let disableDelete = false;
  let disableEdit = false;

  if (assetId) {
    pendingImageComments.forEach(obj => {
      if (assetId === obj.assetId) {
        if (obj.changeType === 'STYLE_CHANGE') {
          disableEdit = true;
        } else if (obj.changeType === 'DELETE') {
          disableDelete = true;
          disableEdit = true;
        }
      }
    });
  }

  return { disableDelete, disableEdit };
};

export const isTempData = data => {
  if (data && _.includes(JSON.stringify(data), 'TEMP_PLACEHOLDER')) {
    return true;
  }
};
