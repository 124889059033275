import React, { useState } from 'react';
import { Button, Modal, Table, Icon } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Papa from 'papaparse';
import _ from 'lodash';

import * as actionCreators from './../actions';
import { CORE_DATA_ATTRIBUTE_STATUSES } from './../util/Constants';
import { CORE_DATA_FIELDS } from '../util/CoreDataConstants';
import openNotification from 'components/Notification';
import { fileDownload, formatDate } from '../util/Util';

export default function CoreAttributeHistory({ supc, attribute = '', type, range, caption, buttonClasses }) {
  const windowHeight = window.innerHeight;
  let buttonTitle = type === 'ITEM_HISTORY' ? 'Item History' : 'Attribute History';
  const className = type === 'ITEM_HISTORY' ? 'core-attr-item-history' : 'core-attr-history';
  if (caption) {
    buttonTitle = caption;
  }

  const dispatch = useDispatch();
  const { fetchCoreAttributesHistory } = bindActionCreators(actionCreators, dispatch);

  const fetching = useSelector(state => _.get(state.comments.history, 'fetching', false));
  const history = useSelector(state => _.get(state.comments.history, 'coreData', []));

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    if (showModal) {
      setShowModal(false);
    } else {
      fetchCoreAttributesHistory({ supc, attribute, range });
      setShowModal(true);
    }
  };

  const exportHistory = () => {
    const exportData = _.map(_.reverse(history), record => {
      const attribute = _.get(CORE_DATA_FIELDS[record.attribute], 'caption', record.attribute);
      const list = _.get(CORE_DATA_FIELDS[record.attribute], 'list', []);
      const target1 = _.find(list, obj => record.changedFrom === obj.value);
      const changedFrom = target1 ? target1.label : record.changedFrom;
      const target2 = _.find(list, obj => record.changedTo === obj.value);
      const changedTo = target2 ? target2.label : record.changedTo;
      const target3 = _.find(CORE_DATA_ATTRIBUTE_STATUSES, obj => record.statusFrom === obj.value);
      const statusFrom = target3 ? target3.title : '-';
      const target4 = _.find(CORE_DATA_ATTRIBUTE_STATUSES, obj => record.statusTo === obj.value);
      const statusTo = target4 ? target4.title : '-';

      return {
        ...record,
        suvc: `"${record.suvc}"`,
        attribute,
        changedFrom,
        changedTo,
        statusFrom,
        statusTo
      };
    });

    try {
      fileDownload('history', Papa.unparse(exportData));
    } catch (error) {
      openNotification({
        className: 'error',
        message: 'ERROR',
        description: 'An error occoured while exporting history'
      });
    }
  };

  const renderModal = () => {
    return (
      <Modal
        className="core-attr-history-modal"
        visible={showModal}
        maskClosable={false}
        closable={true}
        width={'95%'}
        bodyStyle={{ height: windowHeight - 200 }}
        onCancel={toggleModal}
        footer={
          <div style={{ width: '20%', display: 'flex', alignItems: 'flex-end' }}>
            <Button key="export" disabled={_.isEmpty(history)} onClick={exportHistory}>
              Export
            </Button>
          </div>
        }
      >
        {renderTable()}
      </Modal>
    );
  };

  const renderValueColumns = (text, record) => {
    const list = _.get(CORE_DATA_FIELDS[record.attribute], 'list', null);
    const target = _.find(list, obj => text === obj.value);
    const label = target ? target.label : text ? text : '-';

    return <div>{`${label}`}</div>;
  };

  const renderTable = () => {
    const columns = [
      {
        title: 'SUPC',
        dataIndex: 'supc',
        key: 'supc',
        width: 90
      },
      {
        title: 'Date',
        dataIndex: 'changedAt',
        key: 'changedAt',
        width: 180,
        render: text => <div>{formatDate(text, 'MM/DD/YYYY hh:mm A')}</div>
      },
      {
        title: 'User',
        dataIndex: 'changedBy',
        key: 'changedBy',
        width: 200
      },
      {
        title: 'Attribute',
        dataIndex: 'attribute',
        key: 'attribute',
        width: 300,
        render: text => {
          const label = _.get(CORE_DATA_FIELDS[text], 'caption', '');
          return <div>{label}</div>;
        }
      },
      {
        title: 'Changed From',
        dataIndex: 'changedFrom',
        key: 'changedFrom',
        render: renderValueColumns
      },
      {
        title: 'Changed To',
        dataIndex: 'changedTo',
        key: 'changedTo',
        render: renderValueColumns
      },
      {
        title: 'Status From',
        dataIndex: 'statusFrom',
        key: 'statusFrom',
        width: 120,
        render: text => {
          const status = _.find(CORE_DATA_ATTRIBUTE_STATUSES, obj => obj.value === text);
          return <div>{status ? status.title : '-'}</div>;
        }
      },
      {
        title: 'Status To',
        dataIndex: 'statusTo',
        key: 'statusTo',
        width: 120,
        render: text => {
          const status = _.find(CORE_DATA_ATTRIBUTE_STATUSES, obj => obj.value === text);
          return <div>{status ? status.title : '-'}</div>;
        }
      }
    ];

    if (fetching) {
      return <Icon className="core-attr-history-loader" type="loading" />;
    } else {
      return (
        <Table
          id="core-attr-history-table"
          rowKey={'changedAt'}
          columns={columns}
          dataSource={_.reverse(history)}
          pagination={history.length > 5000}
          scroll={{ y: windowHeight - 290 }}
        />
      );
    }
  };

  return (
    <React.Fragment>
      {renderModal()}
      <div className={buttonClasses ? buttonClasses : `core-data-history-btn ${className}`} onClick={toggleModal}>
        {buttonTitle}
      </div>
    </React.Fragment>
  );
}
