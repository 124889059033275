import { Table, Select, Input, Radio } from 'antd';
import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import * as actionCreators from '../actions';
import { getOtmmBaseUrl } from '../util/Util';
import { isTempData } from '../util/ImageUtils';

const { Option } = Select;
const { TextArea } = Input;

const ImagePreferenceComment = forwardRef(({ comment }, ref) => {
  const dispatch = useDispatch();
  const {
    getPreferredImageCommentDetails,
    updateAssociatePreferredImage,
    updatePreferredImageReviewStatus
  } = bindActionCreators(actionCreators, dispatch);

  const preferredImageComments = useSelector(state => _.get(state, 'comments.preferredImages', []));
  const [rejectReason, setRejectReason] = useState('');

  const otmmAssetId = comment.imageComment.otmmAssetId;
  const data = _.find(preferredImageComments, cmt => cmt.commentId === comment.id);
  const additionalImages = _.get(data, 'additionalImages', []);
  const isRejected = _.get(data, 'isReject', false);
  const disabled = _.includes([10, 30], comment.status) || data?.loading;

  useEffect(() => {
    getPreferredImageCommentDetails({
      commentId: comment.id,
      supc: comment.supc,
      assetId: otmmAssetId,
      styleBucket: comment.imageComment.meta.styleBucket
    });
  }, []);

  // If you put this line of code after the below conditional loader, it will throw an error
  useImperativeHandle(ref, () => ({ getRejectReason: () => rejectReason }));

  if (_.isEmpty(data) || data.fetching) {
    return <div className="empty-reviews">Loading...</div>;
  } else if (isTempData(data)) {
    return (
      <div className="empty-reviews">
        Waiting for some data to be ready. It may take a couple of minutes. Please refresh the page in a moment.
      </div>
    );
  }

  const handleInputChange = e => {
    setRejectReason(e.target.value);
  };

  const onSelectDropdownOption = val => {
    updatePreferredImageReviewStatus({ commentId: comment.id, isReject: val === 'REJECT' });
  };

  const updatePreferred = (assetId, currentPreferred) => {
    const val = currentPreferred ? null : assetId;
    updateAssociatePreferredImage({ commentId: comment.id, associatePreferred: val });
  };

  const getRenditionUrl = (type, assetId) => {
    const target = _.find(additionalImages, imgs => imgs.assetId === assetId);
    const url = _.get(target, `[renditions][${type}][id]`, 'oops');
    return `${getOtmmBaseUrl()}/otmmstream/productimage/${url}`;
  };

  const renderPreferredImageColumn = () => {
    const masterUrl = getRenditionUrl('master', otmmAssetId);
    const previewUrl = getRenditionUrl('preview', otmmAssetId);
    return <img className="preferred-image" src={previewUrl} onClick={() => window.open(masterUrl)} />; // TODO: get rendition url
  };

  const renderOtherImagesColumn = () => {
    const images = _.filter(additionalImages, img => img.assetId !== otmmAssetId);

    if (_.isEmpty(images)) {
      return <div className="other-images-container">No Additional Images</div>;
    }

    return (
      <div className="other-images-container">
        {_.map(images, img => {
          const checked = img.assetId === data.associatePreferred || (!data.associatePreferred && img.isPreferred);
          const tooltip = img.isPreferred ? 'Current Preferred Image' : null;
          const masterUrl = getRenditionUrl('master', img.assetId);
          const previewUrl = getRenditionUrl('preview', img.assetId);

          return (
            <div key={img.assetId} title={tooltip} className="additional-image-wrapper">
              <img className="additional-image" src={previewUrl} onClick={() => window.open(masterUrl)} />
              <Radio
                checked={checked}
                disabled={!isRejected || disabled}
                onClick={() => updatePreferred(img.assetId, img.isPreferred)}
              >
                Mark as Preferred
              </Radio>
            </div>
          );
        })}
      </div>
    );
  };

  const renderStatusColumn = () => {
    return (
      <Select
        className="image-feedback-dropdown"
        defaultValue={comment.status === 10 ? 'REJECT' : null}
        disabled={disabled}
        onSelect={onSelectDropdownOption}
      >
        <Option key={0} value={null}>
          Select
        </Option>
        <Option key={1} value={'REJECT'}>
          REJECT
        </Option>
      </Select>
    );
  };

  const renderReasonColumn = () => {
    const reason = _.get(comment, 'imageComment.review.comment', null);
    return <TextArea defaultValue={reason} rows={4} disabled={!isRejected || disabled} onChange={handleInputChange} />;
  };

  const columns = [
    {
      title: 'Image Style',
      dataIndex: 'imageComment.style',
      key: 'style',
      width: 250,
      render: style => <div>{_.toUpper(style)}</div>
    },
    {
      title: 'New Preferred Image',
      dataIndex: 'imageComment.url',
      key: 'preferred',
      width: 250,
      render: () => renderPreferredImageColumn()
    },
    {
      title: 'Additional Images',
      dataIndex: 'imageComment.url',
      key: 'other',
      width: 800,
      render: () => renderOtherImagesColumn()
    },
    {
      title: 'Status',
      width: 150,
      render: () => renderStatusColumn()
    },
    {
      title: 'Reject Reason',
      render: () => renderReasonColumn()
    }
  ];

  return (
    <div id="pref-img-expand">
      <Table id="pref-img-table" dataSource={[comment]} columns={columns} pagination={false} rowKey={'id'} />
    </div>
  );
});

export default ImagePreferenceComment;
