import React from 'react';
import { Table, Icon } from 'antd';
import _ from 'lodash';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import SearchBox from './SearchBox';
import * as actionCreators from '../../actions';
import { getHierarchyFilters, checkIfPfasAttributeIsDisabled } from '../../util/Util';
import PfasCheckboxGroup from './PfasCheckboxGroup';
import TooltipInfo from '../RegulatoryCompliance/PFas/TooltipInfo';

export default function PfasMassAttributeTable({ suvc, hasUnsubmittedData }) {
  const dispatch = useDispatch();
  const { updatePfasMassEditCheckboxes, fetchPfasItemList } = bindActionCreators(actionCreators, dispatch);

  const list = useSelector(state => _.get(state.massUpdate.pfas, 'list', []));
  const changes = useSelector(state => _.get(state.massUpdate.pfas, 'changes', {}));
  const selectedRows = useSelector(state => _.get(state.massUpdate.pfas, 'selectedRows', []));
  const orderBy = useSelector(state => _.get(state.massUpdate.pfas, 'filters.orderBy', null));
  const bc = useSelector(state => _.get(state.massUpdate.pfas, 'filters.bc', null));
  const ig = useSelector(state => _.get(state.massUpdate.pfas, 'filters.ig', null));
  const ag = useSelector(state => _.get(state.massUpdate.pfas, 'filters.ag', null));
  const tableSearchData = useSelector(state => _.get(state.massUpdate.pfas, 'filters.tableSearchData', []));
  const brands = useSelector(state => _.get(state.vendor.details, 'brands', []));
  const tableFilters = useSelector(state => _.get(state.massUpdate.pfas, 'filters.tableFilters', []));
  const pfasAttributes = useSelector(state => _.get(state.massUpdate.pfas, 'filters.attributes.PFAS', []));
  const bcObject = useSelector(state => {
    const completeTaxonomy = _.get(state.massUpdate.pfas, 'completeTaxonomy', []);
    return completeTaxonomy.find(item => item.name === bc);
  });

  const windowHeight = window.innerHeight;
  const yScroll = windowHeight - 390;
  const supcSearchTerm = _.find(tableSearchData, obj => obj.param === 'supc');
  const materialDescriptionSearchTerm = _.find(tableSearchData, obj => obj.param === 'materialDescription');
  const manufactProdCodeSearchTerm = _.find(tableSearchData, obj => obj.param === 'manufactProdCode');
  const gtinSearchTerm = _.find(tableSearchData, obj => obj.param === 'gtin');
  const targetBrandName = _.find(tableFilters, obj => obj.param === 'brandName');
  const filteredBrands = _.get(targetBrandName, 'val', []);

  const getSortOrder = columnName => {
    const field = _.get(orderBy, 'param', null);
    let order = _.get(orderBy, 'val', false);

    if (order === 'asc') {
      order = 'ascend';
    } else if (order === 'desc') {
      order = 'descend';
    }

    if (field === columnName) {
      return order;
    }

    return false;
  };

  const getFormattedBrands = () => {
    brands.sort();
    const formatted = [];

    _.forEach(brands, elem => {
      formatted.push({ text: elem, value: elem });
    });
    return formatted;
  };

  const handleFilterChanges = (pagination, filters, sorter) => {
    if (!hasUnsubmittedData()) {
      const field = _.get(sorter, 'field', null);
      let order = _.get(sorter, 'order', false);
      const tFilters = [];

      if (order === 'ascend') {
        order = 'asc';
      } else if (order === 'descend') {
        order = 'desc';
      }

      _.forIn(filters, (value, key) => {
        if (!_.isEmpty(value)) {
          tFilters.push({ param: key, val: value });
        }
      });

      const newOrder = field ? { param: field, val: order } : null;
      const graphFilter = getHierarchyFilters({ bc, ig, ag });

      fetchPfasItemList({ suvc, orderBy: newOrder, graphFilter, tableSearchData, tableFilters: tFilters });
    }
  };

  const clearAllFilters = () => {
    const graphFilter = getHierarchyFilters({ bc, ig, ag });
    fetchPfasItemList({
      suvc,
      orderBy: null,
      graphFilter,
      tableSearchData: [],
      tableFilters: []
    });
  };

  const renderTitle = ({ name }) => {
    const { disabledTooltip } = checkIfPfasAttributeIsDisabled({ bc: { id: bcObject?.id?.toString() } }, { name });
    return (
      <div className="attr-mass-edit-table-header">
        <div className="attr-mass-edit-table-header-txt" title={name}>
          {name}
        </div>
        {disabledTooltip && <TooltipInfo className="tooltip-span" tooltip={disabledTooltip} />}
      </div>
    );
  };

  const rowSelection = {
    onChange: (keys, records) => {
      updatePfasMassEditCheckboxes(keys);
    },
    selectedRowKeys: selectedRows
  };

  const columns = [
    {
      title: 'SUPC',
      dataIndex: 'supc',
      key: 'supc',
      width: 100,
      fixed: true,
      sorter: true,
      sortOrder: getSortOrder('supc'),
      filterDropdown: () => <SearchBox suvc={suvc} columnName={'supc'} hasUnsubmittedData={hasUnsubmittedData} />,
      filterIcon: <Icon type="search" style={{ color: !_.isEmpty(supcSearchTerm) ? '#108ee9' : '#aaa' }} />
    },
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      key: 'gtin',
      width: 140,
      fixed: true,
      sorter: true,
      sortOrder: getSortOrder('gtin'),
      filterDropdown: () => <SearchBox suvc={suvc} columnName={'gtin'} hasUnsubmittedData={hasUnsubmittedData} />,
      filterIcon: <Icon type="search" style={{ color: !_.isEmpty(gtinSearchTerm) ? '#108ee9' : '#aaa' }} />
    },
    {
      title: 'MPC',
      dataIndex: 'manufactProdCode',
      key: 'manufactProdCode',
      width: 140,
      fixed: true,
      sorter: true,
      sortOrder: getSortOrder('manufactProdCode'),
      filterDropdown: () => (
        <SearchBox suvc={suvc} columnName={'manufactProdCode'} hasUnsubmittedData={hasUnsubmittedData} />
      ),
      filterIcon: <Icon type="search" style={{ color: !_.isEmpty(manufactProdCodeSearchTerm) ? '#108ee9' : '#aaa' }} />
    },
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      key: 'brandName',
      width: 150,
      fixed: true,
      sorter: true,
      sortOrder: getSortOrder('brandName'),
      filters: getFormattedBrands(),
      filteredValue: filteredBrands
    },
    {
      title: 'Material Description',
      className: 'mat-desc-col',
      dataIndex: 'materialDescription',
      key: 'materialDescription',
      render: (text, record) => (
        <div title={text} className="mat-desc-col-text">
          {text}
        </div>
      ),
      width: 230,
      fixed: true,
      sorter: true,
      sortOrder: getSortOrder('materialDescription'),
      filterDropdown: () => (
        <SearchBox suvc={suvc} columnName={'materialDescription'} hasUnsubmittedData={hasUnsubmittedData} />
      ),
      filterIcon: (
        <Icon
          type="search"
          style={{
            color: !_.isEmpty(materialDescriptionSearchTerm) ? '#108ee9' : '#aaa'
          }}
        />
      )
    }
  ];

  _.forEach(pfasAttributes, obj => {
    columns.push({
      title: renderTitle(obj),
      key: obj.id,
      width: 560,
      render: (text, record) => <PfasCheckboxGroup record={record} obj={obj} changes={changes[record.supc] || {}} />
    });
  });

  const rowClassName = (record, index) => {
    // Add a custom class to every even row
    // return index % 2 === 0 ? 'even-row' : 'odd-row';
    return { style: { marginBottom: '100px' } };
  };

  return (
    <div className="attr-mass-edit-table-wrapper">
      <div className="clear-all-filters" onClick={clearAllFilters}>
        Clear Table Filters
      </div>
      <Table
        id="attr-mass-edit-table"
        rowKey={'supc'}
        // bordered
        columns={columns}
        dataSource={list}
        rowClassName={rowClassName}
        pagination={false}
        scroll={{ x: 400, y: yScroll }}
        rowSelection={rowSelection}
        onChange={handleFilterChanges}
      />
    </div>
  );
}
