import { PACKER_BRAND_GTIN } from './Constants';

const gtinValidateLengthAndNumbers = ({ value }) => value && /^\d{14}$/.test(value);
const numbersAndThreeDecimalsOnlyValidate = ({ value }) => value && /^\d+(\.\d{1,3})?$/.test(value);
const numbersAndDecimalsOnlyWithNegativeValuesValidate = ({ value }) => value === '' || /^-?\d*(\.\d+)?$/.test(value);
const getMpcValidate = ({ value }) => value && /^[a-zA-Z0-9 #_.-]{1,14}$/.test(value);
const numbersOnlyWithoutSpecificNumbers = ({ value }) => {
  return value && /^(?!1$|999$)\d+$/.test(value) && value > 1 && value < 999;
};
const checkLessThanOrEqual = ({ value, secondValue }) => {
  try {
    return Number(value) <= Number(secondValue);
  } catch (e) {}
};
const checkLessThan = ({ value, secondValue }) => {
  try {
    return secondValue === undefined || secondValue === '' || Number(value) < Number(secondValue);
  } catch (e) {}
};

const checkIfNumberIsBetween = ({ value }) => {
  try {
    return Number(value) > 0.001 && Number(value) < 9999999999.999;
  } catch (e) {}
};

const checkGtinForPackerBrands = ({ value, brandCode }) => {
  if (brandCode === 'PACKER') {
    if (value === PACKER_BRAND_GTIN) return true;
    return false;
  }
  return true;
};

const checkForBetweenValueTemperature = ({ value }) => {
  try {
    return Number(value) >= -999.99 && Number(value) <= 999.999;
  } catch (e) {}
};

const checkForBetweenValuePack = ({ value }) => {
  try {
    return Number(value) >= 1 && Number(value) <= 9999;
  } catch (e) {}
};

const checkLengthForSize = ({ value }) => {
  try {
    return value.length < 7;
  } catch (e) {}
};

const checkPercentageExceededOrNot = (value, previousValue) =>
  Math.abs(((Number(value) - Number(previousValue)) / Number(previousValue)) * 100) > 20;

const checkHasExceededPercentage = ({ value, previousValue, commentValue }) => {
  const commentValueExceededPercentage = checkPercentageExceededOrNot(commentValue, previousValue);
  if (
    previousValue !== '' &&
    ((commentValueExceededPercentage && commentValue === '') ||
      (!commentValueExceededPercentage && commentValue !== ''))
  ) {
    if (checkPercentageExceededOrNot(value, previousValue)) return true;
  }
};

const numbersAndTwoDecimalsOnlyWithNegatives = ({ value }) => value === '' || /^-?\d+(\.\d{1,2})?$/.test(value);

const numbericOnlyWithoutDecimals = ({ value }) => value && /^\d+?$/.test(value);

const getFirstNumericValuesFromText = value => {
  if (value.includes(' ')) {
    return value.split(' ')[0].trim();
  } else if (value.match(/[a-zA-Z]/)) {
    return value.substring(0, value.indexOf(value.match(/[a-zA-Z]/).pop())).trim();
  } else if (convertToNumeric(value.trim()) > -1) {
    return value.trim();
  }
  return null;
};

const checkSizeAndSizeUomValidity = ({ value, secondValue }) => {
  try {
    return Number(getFirstNumericValuesFromText(value)) > 0 && secondValue;
  } catch (e) {}
};

const checkIfNotNull = ({ value }) => {
  return value;
};

const checkNotZeroOrEmpty = ({ value }) => {
  try {
    return Number(value) > 0;
  } catch (e) {}
};

const checkIfNotBlankOrEmpty = ({ value }) => {
  return value !== '' && value !== ' ';
};

const checkCannotBeEmpty = ({ value }) => {
  return value !== '';
};

const validateGtin = ({ value }) => {
  const firstThirteenDigits = value.substring(0, 13); // get the first thirteen digits
  const lastDigit = Number(value.charAt(value.length - 1)); // get the last digit
  const lengthOfFirstThirteenDigits = firstThirteenDigits.length; // get the length of the thirteendigits
  let iCount = 0;
  let iOdd = 0;
  let iEven = 0;
  let bOdd = true;

  if (lengthOfFirstThirteenDigits > 0) {
    do {
      const sNum = firstThirteenDigits.substring(iCount, iCount + 1);
      const iNum = Number(sNum);
      if (bOdd) {
        iOdd += iNum;
        bOdd = false;
      } else {
        iEven += iNum;
        bOdd = true;
      }
      iCount++;
    } while (iCount < lengthOfFirstThirteenDigits);

    iOdd *= 3; // multiply odd total by 3
    const iTotal = iOdd + iEven; // calculate total
    const iLastDigit = iTotal % 10; // get last digit of total
    let iCheckDigit;
    // substract last digit from 10
    if (iLastDigit === 0) iCheckDigit = 0;
    else iCheckDigit = 10 - iLastDigit;
    return iCheckDigit === lastDigit;
  }
};

const checkBothFieldsAreEqual = ({ value, againstFieldValue }) => {
  if (value && againstFieldValue && Number(value) > -1 && Number(againstFieldValue) > -1) {
    return Number(value) === Number(againstFieldValue);
  }
  return false;
};

const checkForCurrentAndPreviousValues = ({ value, commentValue }) => {
  return value !== commentValue && !commentValue && value !== '';
};

const convertToNumeric = value => {
  try {
    const removedLetters = value.replace(/[^0-9.]/g, '');
    if (removedLetters[removedLetters.length - 1] === '.') throw new Error();
    if (removedLetters.includes('.')) return parseFloat(removedLetters);
    return Number(removedLetters);
  } catch (error) {
    return null;
  }
};

const alphaNumericOnlyWithoutDecimals = ({ value }) => {
  if (value) {
    if (value === '') return true;
    const isRegexSatisfied = /^[a-zA-Z0-9. #]*$/.test(value);
    if (isRegexSatisfied) {
      const firstHalfOfText = getFirstNumericValuesFromText(value);
      if (firstHalfOfText !== null) {
        const convertedNumber = convertToNumeric(firstHalfOfText.trim());
        if (convertedNumber !== null) {
          return /^\d*(\.\d{1,3})*$/.test(convertedNumber);
        }
      }
    }
  }
  return false;
};

export const TARE_WEIGHT_FIELDS = ['syy_itm_a_netweight', 'syy_itm_a_grossweight'];

export const CORE_DATA_FIELDS = {
  syy_itm_a_materialunabbreviateddesc: {
    list: [],
    isDisabled: true,
    caption: 'Material Unabbreviated Description'
  },
  syy_itm_a_pack: {
    validations: [
      { condition: numbericOnlyWithoutDecimals, validateError: 'should be numeric only, no decimals' },
      { condition: checkForBetweenValuePack, validateError: 'should be >= 1 and <= 9999' },
      { condition: checkNotZeroOrEmpty, validateError: 'cannot be zero/empty' }
    ],
    popUps: [
      {
        condition: checkForCurrentAndPreviousValues,
        titleText: 'Pack has been changed. Please confirm your validation on size, net and weight'
      }
    ],
    caption: 'Pack',
    isRequired: true
  },
  syy_itm_a_size: {
    validations: [
      { condition: alphaNumericOnlyWithoutDecimals, validateError: 'should be alphanumeric' },
      {
        condition: checkSizeAndSizeUomValidity,
        validateError: 'should be >= 0',
        synchedField: 'syy_itm_a_sizeuom',
        isCheckOneSideOnly: true
      },
      { condition: checkCannotBeEmpty, validateError: 'cannot be empty' },
      { condition: checkLengthForSize, validateError: 'maximum 6 characters' }
    ],
    popUps: [
      {
        condition: checkForCurrentAndPreviousValues,
        titleText: 'Size has been changed. Please confirm your validation on pack, net and weight'
      }
    ],
    caption: 'Size',
    isRequired: true
  },
  syy_itm_a_sizeuom: {
    list: [
      { value: 'BAG', label: 'BAG' },
      { value: 'BAR', label: 'BAR' },
      { value: 'BT', label: 'BOTTLE' },
      { value: 'BX', label: 'BOX' },
      { value: 'CAN', label: 'CANISTER' },
      { value: 'CAR', label: 'CARTON' },
      { value: 'CS', label: 'CASE' },
      { value: 'CT', label: 'COUNT' },
      { value: 'CRT', label: 'CRATE' },
      { value: 'FT3', label: 'CUBIC FT' },
      { value: '3"', label: 'CUBIC INCH' },
      { value: 'YD3', label: 'CUBIC YARD' },
      { value: 'DZ', label: 'DOZEN' },
      { value: 'EA', label: 'EACH' },
      { value: 'FO"', label: 'FLUID OUNCE US' },
      { value: 'FT"', label: 'FOOT' },
      { value: 'G', label: 'GRAM' },
      { value: 'HR', label: 'HOURS' },
      { value: '"', label: 'INCH' },
      { value: 'KG', label: 'KILOGRAM' },
      { value: 'L', label: 'LITER' },
      { value: 'M', label: 'METER' },
      { value: 'MG', label: 'MILLIGRAM' },
      { value: 'ML', label: 'MILLILITER' },
      { value: 'MM', label: 'MILLIMETER' },
      { value: 'OZ', label: 'OUNCE' },
      { value: 'PAC', label: 'PACK' },
      { value: 'PA', label: 'PACKAGE' },
      { value: 'PAA', label: 'PAIR' },
      { value: 'PAL', label: 'PALLET' },
      { value: 'PCE', label: 'PIECE' },
      { value: 'PT', label: 'PINT, US LIQUID' },
      { value: 'QT', label: 'QUART, US LIQUID' },
      { value: 'ROL', label: 'ROLE' },
      { value: 'RO', label: 'ROLL' },
      { value: '2"', label: 'SQUARE INCH' },
      { value: 'GAL', label: 'US GALLON' },
      { value: 'LB', label: 'US POUND' },
      { value: 'V', label: 'VOLT' },
      { value: 'W', label: 'WATT' },
      { value: 'YD', label: 'YARDS' }
    ],
    validations: [
      { condition: checkIfNotNull, validateError: 'should not be null', checkAgainstField: 'syy_itm_a_size' }
    ],
    caption: 'Size UOM'
  },
  syy_itm_a_catchweight: {
    list: [
      { value: 'Yes', label: 'Yes' },
      { value: 'No', label: 'No' }
    ],
    validations: [{ condition: checkIfNotBlankOrEmpty, validateError: 'cannot be blank/empty' }],
    caption: 'Catch Weight',
    // isRequired: true
    isDisabled: true
  },
  syy_itm_a_containerreqmts: { isDisabled: true, caption: 'Container Requirements' },
  syy_itm_a_brandname_calc: { isDisabled: true, caption: 'Brand Name' },
  syy_itm_a_manufactprodcode: {
    gdsnDisabled: true,
    validations: [{ condition: getMpcValidate, validateError: 'maximum 14 characters, accept only a-zA-Z0-9 #_.-' }],
    caption: 'Manufacturer Product Code'
  },
  syy_itm_a_gtin: {
    validations: [
      { condition: gtinValidateLengthAndNumbers, validateError: 'should be 14 digits only' },
      { condition: checkGtinForPackerBrands, validateError: `should be ${PACKER_BRAND_GTIN} for packer brands` },
      { condition: validateGtin, validateError: 'invalid GTIN' }
    ],
    caption: 'GTIN'
  },
  syy_itm_a_length: {
    gdsnDisabled: true,
    validations: [
      { condition: numbersAndThreeDecimalsOnlyValidate, validateError: 'should be numeric, maximum 3 decimal points' },
      { condition: checkNotZeroOrEmpty, validateError: 'cannot be zero/empty' }
    ],
    popUps: [
      {
        condition: checkHasExceededPercentage,
        titleText: 'The change you have entered has exceeded 20%',
        isOneTimeCheck: true
      },
      {
        condition: checkForCurrentAndPreviousValues,
        titleText: 'Length has been changed. Please confirm your review for UOM'
      }
    ],
    caption: 'Length',
    isRequired: true
  },
  syy_itm_a_width: {
    gdsnDisabled: true,
    validations: [
      { condition: numbersAndThreeDecimalsOnlyValidate, validateError: 'should be numeric, maximum 3 decimal points' },
      { condition: checkNotZeroOrEmpty, validateError: 'cannot be zero/empty' }
    ],
    popUps: [
      {
        condition: checkHasExceededPercentage,
        titleText: 'The change you have entered has exceeded 20%',
        isOneTimeCheck: true
      },
      {
        condition: checkForCurrentAndPreviousValues,
        titleText: 'Width has been changed. Please confirm your review for UOM'
      }
    ],
    caption: 'Width',
    isRequired: true
  },
  syy_itm_a_height: {
    gdsnDisabled: true,
    validations: [
      { condition: numbersAndThreeDecimalsOnlyValidate, validateError: 'should be numeric, maximum 3 decimal points' },
      { condition: checkNotZeroOrEmpty, validateError: 'cannot be zero/empty' }
    ],
    popUps: [
      {
        condition: checkHasExceededPercentage,
        titleText: 'The change you have entered has exceeded 20%',
        isOneTimeCheck: true
      },
      {
        condition: checkForCurrentAndPreviousValues,
        titleText: 'Height has been changed. Please confirm your review for UOM'
      }
    ],
    caption: 'Height',
    isRequired: true
  },
  syy_itm_a_lwhuom: {
    list: [
      { value: 'PT', label: 'Pint, US liquid' },
      { value: 'QT', label: 'Quart, US liquid' },
      { value: 'C', label: '100' },
      { value: 'M1', label: '1000' },
      { value: 'AS', label: 'Assortment' },
      { value: 'BAG', label: 'Bag' },
      { value: 'BK', label: 'Book' },
      { value: 'BOT', label: 'Bottle' },
      { value: 'BD', label: 'Bundle' },
      { value: 'KAN', label: 'Canister' },
      { value: 'CRD', label: 'Card' },
      { value: 'KAR', label: 'Carton' },
      { value: 'CS', label: 'Case' },
      { value: 'CTL', label: 'Centiliter' },
      { value: 'CM', label: 'Centimeter' },
      { value: 'CLP', label: 'Clip Strip' },
      { value: 'CRT', label: 'Crate' },
      { value: 'CSL', label: 'CS/LB' },
      { value: 'CAW', label: 'Cube Adjusted Weight' },
      { value: 'CCM', label: 'Cubic centimeter' },
      { value: 'CDM', label: 'Cubic decimeter' },
      { value: 'FT3', label: 'Cubic foot' },
      { value: 'IN3', label: 'Cubic inch' },
      { value: 'M3', label: 'Cubic Meter' },
      { value: 'MMQ', label: 'Cubic Millimeter' },
      { value: 'YD3', label: 'Cubic yard' },
      { value: 'CUR', label: 'Currency' },
      { value: 'DL', label: 'Deal' },
      { value: 'DM', label: 'Decimeter' },
      { value: 'DS', label: 'Display' },
      { value: 'DZ', label: 'Dozen' },
      { value: 'DR', label: 'Drum' },
      { value: 'EA', label: 'Each' },
      { value: 'FA', label: 'Fahrenheit' },
      { value: 'FL', label: 'Filler' },
      { value: 'FOZ', label: 'Fluid Ounce US' },
      { value: 'FT', label: 'Foot' },
      { value: 'G', label: 'Gram' },
      { value: 'GRO', label: 'Gross' },
      { value: 'HL', label: 'Hectoliter' },
      { value: 'IN', label: 'Inch' },
      { value: 'KG', label: 'Kilogram' },
      { value: 'KM', label: 'Kilometer' },
      { value: 'KT', label: 'Kilotonne' },
      { value: 'KIT', label: 'Kit' },
      { value: 'L', label: 'Liter' },
      { value: 'LT', label: 'Lot' },
      { value: 'MC', label: 'Master Case' },
      { value: 'M', label: 'Meter' },
      { value: '4G', label: 'Microliter' },
      { value: 'MIM', label: 'Micrometer' },
      { value: 'MI', label: 'Mile' },
      { value: 'MG', label: 'Milligram' },
      { value: 'ML', label: 'Milliliter' },
      { value: 'MM', label: 'Millimeter' },
      { value: 'OZ', label: 'Ounce' },
      { value: 'PAK', label: 'Pack' },
      { value: 'PD', label: 'Pad' },
      { value: 'PAA', label: 'Pair' },
      { value: 'PAL', label: 'Pallet' },
      { value: 'PP', label: 'Polypack' },
      { value: 'LBI', label: 'Pound' },
      { value: 'QR', label: 'Quire' },
      { value: 'RM', label: 'Ream' },
      { value: 'SET', label: 'Set' },
      { value: 'SH', label: 'Sheet' },
      { value: 'SP', label: 'Spool' },
      { value: 'FT2', label: 'Square Foot' },
      { value: 'IN2', label: 'Square Inch' },
      { value: 'M2', label: 'Square Meter' },
      { value: 'YD2', label: 'Square Yard' },
      { value: 'TO', label: 'Tonne' },
      { value: 'TL', label: 'Truckload' },
      { value: 'TU', label: 'Tub' },
      { value: 'TB', label: 'Tube' },
      { value: 'GLL', label: 'US gallon' },
      { value: 'LB', label: 'US Pound' },
      { value: 'TON', label: 'US ton' },
      { value: 'VAL', label: 'Value-only material' },
      { value: 'YD', label: 'Yard' }
    ],
    caption: 'L/W/H UOM',
    defaultValue: 'IN'
    // isDisabled: true
  },
  syy_itm_a_calc_baseunit: { isDisabled: true, caption: 'Base Unit' },
  syy_itm_a_netweight: {
    gdsnDisabled: true,
    validations: [
      { condition: numbersAndThreeDecimalsOnlyValidate, validateError: 'should be numeric, maximum 3 decimal points' },
      { condition: checkIfNumberIsBetween, validateError: 'should be between 0.001 - 9999999999.99' },
      {
        condition: checkLessThanOrEqual,
        validateError: 'should be less than or equal gross weight',
        checkAgainstField: 'syy_itm_a_grossweight'
      },
      { condition: checkIfNotBlankOrEmpty, validateError: 'cannot be blank/empty' }
    ],
    popUps: [
      {
        condition: checkBothFieldsAreEqual,
        titleText: 'Net weight has been changed. Please confirm, 0 tare weight',
        checkAgainstField: 'syy_itm_a_grossweight'
      }
    ],
    caption: 'Net Weight',
    isRequired: true
  },
  syy_itm_a_grossweight: {
    gdsnDisabled: true,
    validations: [
      {
        condition: numbersAndThreeDecimalsOnlyValidate,
        validateError: 'should be numeric, maximum 3 decimal points',
        synchedField: 'syy_itm_a_netweight'
      },
      { condition: checkIfNumberIsBetween, validateError: 'should be between 0.001 - 9999999999.99' },
      { condition: checkIfNotBlankOrEmpty, validateError: 'cannot be blank/empty' }
    ],
    popUps: [
      {
        condition: checkBothFieldsAreEqual,
        titleText: 'Gross weight has been changed. Please confirm, 0 tare weight',
        checkAgainstField: 'syy_itm_a_netweight'
      }
    ],
    caption: 'Gross Weight',
    isRequired: true
  },
  syy_itm_a_volume: {
    gdsnDisabled: true,
    isDisabled: true,
    caption: 'Volume'
  },
  syy_itm_a_vendorstoragecode: {
    list: [
      { value: 'C', label: 'COOLER' },
      { value: 'D', label: 'DRY' },
      { value: 'F', label: 'FROZEN' }
    ],
    validations: [{ condition: checkIfNotBlankOrEmpty, validateError: 'cannot be blank/empty' }],
    popUps: [
      {
        condition: checkForCurrentAndPreviousValues,
        titleText:
          'Supplier storage temperature code has been changed. Please confirm your review for supplier storage temperature min & max'
      }
    ],
    caption: 'Supplier Storage Code',
    isRequired: true
  },
  syy_itm_a_vendorstoragetemperaturemin: {
    gdsnDisabled: true,
    validations: [
      { condition: numbersAndDecimalsOnlyWithNegativeValuesValidate, validateError: 'should be numeric only' },
      { condition: checkForBetweenValueTemperature, validateError: 'should be >= -999.99 and <= 999.999' },
      { condition: numbersAndTwoDecimalsOnlyWithNegatives, validateError: 'maximum 2 decimal points' },
      {
        condition: checkLessThan,
        validateError: 'should be less than vendor storage temperature max',
        checkAgainstField: 'syy_itm_a_vendorstoragetemperaturemax'
      }
    ],
    popUps: [
      {
        condition: checkForCurrentAndPreviousValues,
        titleText:
          'Supplier storage temperature min has been changed. Please confirm your review for supplier storage temperature max & code'
      }
    ],
    caption: 'Supplier Storage Temperature Min'
  },
  syy_itm_a_vendorstoragetemperaturemax: {
    gdsnDisabled: true,
    validations: [
      {
        condition: numbersAndDecimalsOnlyWithNegativeValuesValidate,
        validateError: 'should be numeric only',
        synchedField: 'syy_itm_a_vendorstoragetemperaturemin'
      },
      { condition: checkForBetweenValueTemperature, validateError: 'should be >= -999.99 and <= 999.999' },
      { condition: numbersAndTwoDecimalsOnlyWithNegatives, validateError: 'maximum 2 decimal points' }
    ],
    popUps: [
      {
        condition: checkForCurrentAndPreviousValues,
        titleText:
          'Supplier storage temperature max has been changed. Please confirm your review for supplier storage temperature min & code'
      }
    ],
    caption: 'Supplier Storage Temperature Max'
  },
  syy_itm_a_vendorstoragetemperatureuom: {
    gdsnDisabled: true,
    list: [
      { value: 'CEL', label: 'CELSIUS' },
      { value: 'FAH', label: 'FAHRENHEIT' }
    ],
    caption: 'Supplier Storage Temperature UOM',
    defaultValue: 'FAH'
    // isDisabled: true
  },
  syy_itm_a_vendorti: {
    gdsnDisabled: true,
    validations: [
      {
        condition: numbersOnlyWithoutSpecificNumbers,
        validateError: 'should be integers between 1- 999'
      }
    ],
    caption: 'Supplier Ti'
  },
  syy_itm_a_vendorhi: {
    gdsnDisabled: true,
    validations: [
      {
        condition: numbersOnlyWithoutSpecificNumbers,
        validateError: 'should be integers between 1- 999'
      }
    ],
    caption: 'Supplier Hi'
  },
  syy_itm_a_totalshelflife: {
    gdsnDisabled: true,
    validations: [
      { condition: numbericOnlyWithoutDecimals, validateError: 'should be numeric only, no decimals' },
      { condition: checkForBetweenValuePack, validateError: 'should be >= 1 and <= 9999' }
    ],
    caption: 'Total Shelf Life'
  },
  syy_itm_a_hazardous: { isDisabled: true, caption: 'Hazardous' },
  product_activity_type_code: {
    list: [
      { value: 'Assembly', label: 'Assembly' },
      { value: 'Birth', label: 'Birth' },
      { value: 'Bottling', label: 'Bottling' },
      { value: 'Catch zone', label: 'Catch zone' },
      // { value: 'Cutter', label: 'Cutter' },
      { value: 'Farming', label: 'Farming' },
      { value: 'Last processing', label: 'Last processing' },
      { value: 'Rearing', label: 'Rearing' },
      { value: 'Slaughter', label: 'Slaughter' },
      { value: 'Importation', label: 'Importation' },
      { value: 'Milking', label: 'Milking' }
      // { value: 'ASSEMBLY', label: 'Assembly' },
      // { value: 'BIRTH', label: 'Birth' },
      // { value: 'BOTTLING', label: 'Bottling' },
      // { value: 'CATCH_ZONE', label: 'Catch zone' },
      // { value: 'CUTTER', label: 'Cutter' },
      // { value: 'FARMING', label: 'Farming' },
      // { value: 'LAST_PROCESSING', label: 'Last processing' },
      // { value: 'REARING', label: 'Rearing' },
      // { value: 'SLAUGHTER', label: 'Slaughter' },
      // { value: 'IMPORTATION', label: 'Importation' },
      // { value: 'MILKING', label: 'Milking' }
    ],
    caption: 'Product Activity Type',
    isRequired: true,
    tooltip: 'productactivitytypecode'
  },
  product_activity_region_description: {
    caption: 'Product Activity Region Description',
    tooltip: 'productactivityregiondesc'
  },
  country_of_activity_code: {
    list: [],
    caption: 'Country of Activity Code',
    tooltip: 'countryofactivitycode'
  },
  country_of_activity_subdivision_code: {
    list: [],
    caption: 'Country of Activity Subdivision Code',
    tooltip: 'countryofactivitysubcode'
  }
};

export const CORE_DATA_PENDING_TOOLTIP = 'Pending change to be reviewed.';

export const CORE_DATA_REJECT_STATUS_DROPDOWNS = ['REJECT', 'FIXED', 'APPROVE', 'REVIEW', '---'];

export const CORE_DATA_ATTRIBUTE_TYPES = {
  CORE: [
    'syy_itm_a_materialunabbreviateddesc',
    'syy_itm_a_pack',
    'syy_itm_a_size',
    'syy_itm_a_sizeuom',
    'syy_itm_a_catchweight',
    'syy_itm_a_containerreqmts',
    'syy_itm_a_brandname_calc',
    'syy_itm_a_manufactprodcode',
    'syy_itm_a_gtin'
  ],
  MEASUREMENT: [
    'syy_itm_a_length',
    'syy_itm_a_width',
    'syy_itm_a_height',
    'syy_itm_a_lwhuom',
    'syy_itm_a_calc_baseunit',
    'syy_itm_a_netweight',
    'syy_itm_a_grossweight',
    'syy_itm_a_volume',
    'syy_itm_a_vendorstoragecode',
    'syy_itm_a_vendorstoragetemperaturemin',
    'syy_itm_a_vendorstoragetemperaturemax',
    'syy_itm_a_vendorstoragetemperatureuom',
    'syy_itm_a_vendorti',
    'syy_itm_a_vendorhi',
    'syy_itm_a_totalshelflife',
    'syy_itm_a_hazardous'
  ]
};

export const CORE_DATA_REQUIRED_FIELDS = [
  'syy_itm_a_catchweight',
  'syy_itm_a_grossweight',
  'syy_itm_a_netweight',
  'syy_itm_a_height',
  'syy_itm_a_length',
  'syy_itm_a_width',
  'syy_itm_a_pack',
  'syy_itm_a_size',
  'syy_itm_a_vendorstoragecode'
];

export const VOLUME_KEY = 'syy_itm_a_volume';

export const VOLUME_CALCULATED_FIELDS = {
  length: 'syy_itm_a_length',
  width: 'syy_itm_a_width',
  height: 'syy_itm_a_height',
  uom: 'syy_itm_a_lwhuom'
};

export const MAXIMUM_TIME_OUT_FIELDS = {
  syy_itm_a_length: 'syy_itm_a_length',
  syy_itm_a_width: 'syy_itm_a_width',
  syy_itm_a_height: 'syy_itm_a_height',
  syy_itm_a_grossweight: 'syy_itm_a_grossweight',
  syy_itm_a_netweight: 'syy_itm_a_netweight',
  syy_itm_a_vendorstoragetemperaturemin: 'syy_itm_a_vendorstoragetemperaturemin',
  syy_itm_a_vendorstoragetemperaturemax: 'syy_itm_a_vendorstoragetemperaturemax'
};

export const CORE_LIST_FIELDS = [
  // 'syy_itm_a_sizeuom',
  // 'syy_itm_a_lwhuom',
  // 'syy_itm_a_vendorstoragecode',
  // 'syy_itm_a_vendorstoragetemperatureuom'
];

export const ACTIVITY_TYPE_KEY = 'product_activity_type_code';
export const REGION_DESC_KEY = 'product_activity_region_description';
export const ACTIVITY_CODE_KEY = 'country_of_activity_code';
export const ACTIVITY_SUBCODE_KEY = 'country_of_activity_subdivision_code';

export const emptyCooCountryData = {
  country_of_activity_code: {
    key: ACTIVITY_CODE_KEY,
    caption: 'Country of Activity Code',
    value: '',
    existingValue: '',
    errMsg: ''
  },
  country_of_activity_subdivision_code: {
    key: ACTIVITY_SUBCODE_KEY,
    caption: 'Country of Activity Subdivision Code',
    value: '',
    existingValue: '',
    errMsg: ''
  }
};

export const emptyCooData = {
  product_activity_type_code: {
    key: ACTIVITY_TYPE_KEY,
    caption: 'Product Activity Type',
    value: '',
    existingValue: '',
    errMsg: ''
  },
  product_activity_region_description: {
    key: REGION_DESC_KEY,
    caption: 'Product Activity Region Description',
    value: '',
    existingValue: '',
    errMsg: ''
  },
  country: [{ ...emptyCooCountryData }]
};
