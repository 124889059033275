import { combineReducers } from 'redux';
import initialState from 'store/initialState';
import * as _ from 'lodash';

import {
  USER,
  ITEMS,
  ITEMMASTER,
  ITEMTAXO,
  ITEMUNLOAD,
  ITEMIMAGE,
  ITEMFNB,
  FEEDBACK,
  ATTRCHANGE,
  TAXOSAVE,
  TAXOQUITESAVE,
  MSTRCOMMENT,
  SENDCOMMENTS,
  COMMENTS,
  UPDATECOMMENT,
  FIELDCOMMENT,
  SENDCOMMENT,
  ITEMCOMMENTS,
  ITEMSCOUNT,
  SCORES,
  CHARTS,
  SUMMARY,
  FILTERFORGRAPH,
  IMGLOADING,
  IMGCOMMENT,
  IMGRESERVATION,
  SUPCBASIC,
  VENDORS,
  SUBSUVC,
  SUPPLIERS,
  FNBCOMMENTS,
  EDITATTRIBUTE,
  UPDATEFNBCOMMENT,
  RESETTAB,
  TABCHANGED,
  REJECTEDSUPCS,
  OVERALLSUMMARY,
  NUTRITIONS,
  UPDATENUTRITIONS,
  AUTOSAVE,
  UPDATEIMAGECOMMENT,
  UPDATEIMAGESTYLE,
  UPDATEFEEDBACKSORTFILTERS,
  COMPLEXGRAPHRENDERING,
  FEEEXCLUSIONSBYSUPC,
  FEEEXCLUSIONSCOUNT,
  UPDATENUTRITIONDATA,
  NUTRITIONQUESTIONS,
  NUTRITIONAPPLICABLESTATUS,
  UPDATENUTRITIONAPPLICABLESTATUS,
  PENDINGNUTRITIONS,
  CANCELNUTRITIONUPDATE,
  ONNUTRITIONREJECTFIELDSCHANGED,
  BLOCKTAXONMYNUTRITIONUPDATE,
  SETSYNCEDNUTRITIONS,
  FNBMASSEDITINITIALDATA,
  FNBMASSEDIT,
  TOGGLEFNBMASSEDITMODAL,
  GDSNFNBCOMMENTS,
  GDSNFNB,
  COREDATA,
  COREDATACHANGED,
  COREDATACOMMENT,
  COREDATACOMMENTCHANGED,
  REJECTALLCOREDATAATTRIBUTES,
  SENDCOREDATACOMMENT,
  GETITEMDETAILS,
  RESETCURRENTCOREDATACHANGES,
  PGMITEMS,
  UPDATEVISIBILITY,
  CHANGEVISIBILITY,
  FORCEDVISIBLESUPCS,
  NUTRITIONUPDATE,
  UPDATENUTRITIONFIELDERRORS,
  UPDATETAXONOMYCHANGESWHOLE,
  UPDATENUTRITIONTAXONOMYSYNCHEDCHANGES,
  SELFASSIGNCOMMENT,
  COREATTRIBUTEHISTORY,
  TOGGLECOREMODAL,
  UPDATEATTRIBCOMMENT,
  HANDLEAALREADYASSIGNEDCOREDATAISSUE,
  MDMITEMMASTER,
  UNSAVEDPREFERREDIMGS,
  SUBMITPREFERREDIMAGES,
  PREFERREDIMAGECOMMENT,
  ASSOCIATEPREFERREDIMAGE,
  PREFERREDIMAGEREVIEWSTATUS,
  APPROVEPREFERREDIMAGE,
  REJECTPREFERREDIMAGE,
  FETCHOVERALLITEMFEEDBACKHISTORY,
  UPDATEOVERALLFEEDBACK,
  UPDATEALLOVERALLHISTORY,
  SUBMITOVERALLFEDBACK,
  FETCHITEMCOMMENTSOVERALLHISTORY,
  FETCHITEMVIEWEXCLUSIONS,
  COODATACHANGED,
  COOACTIVITYADD,
  COOACTIVITYDEL,
  COOCOUNTRYADD,
  COOCOUNTRYDEL,
  COODATAPUSHED,
  ITEMCOO,
  ITEMSSUMMARY,
  HANDLECNREMOVALSILENCE,
  PFAS,
  PFASSELECTIONCHANGED,
  PFASCLEARSELECTIONS,
  PFASMASSNOTAPPLICABLE,
  PFASUPDATE,
  FSMA,
  FSMACHANGES,
  REGULATORYSUBTABCHANGE,
  SAVEFSMACHANGES,
  PACKAGE,
  PACKAGECHANGES,
  SAVEPACKAGECHANGES,
  PACKAGINGSUBTABCHANGE,
  ADDPACKAGINGLEVEL,
  RESETPACKAGING,
  PACKAGECERTIFICATIONCHANGES,
  DELETEPACKAGINGLEVEL
} from 'actions/actionTypes';
import { createReducer } from 'reduxHelpers';
import feedback from '../helpers/feedback';
import { fnbCommentAttributes } from '../util/Data';
import {
  checkArrayAndSpreadOrDefault,
  getFieldDisabledStatusByTwoParams,
  checkStatusWithTwoAndConditions,
  isValidImageName,
  getChangedFieldsAfterSilenceSubmitionNutritions,
  checkIfPfasAttributeIsDisabled,
  getPfasClaimValue,
  getDefaultValuesForPfas,
  getDefaultValueForPfasClaim,
  checkForPackagingChanges
} from '../util/Util';
import massUpdate from './MassUpdateReducer';
import {
  ITEM_DOES_NOT_BELONG_TO_SUPPLIER,
  NO_LONGER_PRODUCED_BY_SUPPLIER,
  CN_PFS_EMPTY_FIELDS,
  PFAS_MASS_NOT_APPLICABLE_VALUE,
  PACKAGING_MULTIVALUE_FIELD_IDS
} from '../util/Constants';
import { ACTIVITY_TYPE_KEY, emptyCooCountryData, emptyCooData, REGION_DESC_KEY } from 'util/CoreDataConstants';

const user = createReducer(USER, initialState.user);

const mergeImageComments = (existingCommentsState, imageComments) => {
  const existingComments = _.cloneDeep(existingCommentsState);
  imageComments.forEach(imageComment => {
    const existingComment = _.find(existingComments, comment => {
      return comment.id === imageComment.commentId;
    });
    if (existingComment) {
      let commentMeta = {};
      try {
        commentMeta = JSON.parse(imageComment.meta);
      } catch (err) {}
      existingComment.imageComment = { ...imageComment, meta: commentMeta };
      existingComment.virusStatus = existingComment.status === 30 ? 6 : 0;
    }
  });
  return existingComments;
};

const mergeFileReservation = (existingCommentsState, fileReservations) => {
  const existingComments = _.cloneDeep(existingCommentsState);
  if (fileReservations && fileReservations.length > 0) {
    fileReservations.forEach(fileReservation => {
      const existingComment = _.find(existingComments, comment => {
        return (
          comment.imageComment &&
          comment.imageComment.reservationId &&
          comment.imageComment.reservationId === fileReservation.id
        );
      });
      if (existingComment) {
        existingComment.virusStatus = fileReservation.status;
      }
    });
  }
  return existingComments;
};

const getTabResetValues = tabId => {
  let resultObject = {};
  if (tabId === 1) {
    resultObject = {
      ...resultObject,
      feedback: null
    };
  }
  if (tabId === 4) {
    resultObject = {
      ...resultObject,
      taxonomyChanges: []
    };
  }
  return resultObject;
};

const setRejectAllToCoreAttributes = comment => {
  let updatedComment = {};
  for (const [key, data] of Object.entries(comment)) {
    updatedComment = {
      ...updatedComment,
      [key]: { ...data, isRejected: true, isFixed: false }
    };
  }
  return JSON.stringify(updatedComment);
};

const findSpecificIndex = (data, id) => {
  return _.findIndex(data, { id });
};

const spliceAndManipulateStateData = (index, data, stateData) => {
  const existingCommentData = [...stateData];
  existingCommentData.splice(index, 1, {
    ...stateData[index],
    ...data
  });
  return existingCommentData;
};

const generateOverallChangesBasedOnPreviousHistoricalData = historicalDataArray => {
  if (!(historicalDataArray && historicalDataArray.length)) return {};
  const { changedTo, statusTo, supc, suvc, commentId } = historicalDataArray[historicalDataArray.length - 1];
  return {
    changedFrom: changedTo,
    changedTo,
    supc,
    suvc,
    commentId,
    statusFrom: statusTo,
    commentText: null,
    statusTo: null
  };
};

const initiateOverallFeedbackChangesForEachComments = (state, comments) => {
  const { comments: { overallItemFeedbackHistory: existingOverall } = { overallItemFeedbackHistory: {} } } = state;
  let overallItemFeedbackHistory = {
    ...existingOverall
  };

  const getExistingChangesIfAvailable = id => {
    if (
      existingOverall &&
      existingOverall.singleComments &&
      existingOverall.singleComments[parseInt(id)] &&
      existingOverall.singleComments[parseInt(id)].changes
    ) {
      return {
        ...overallItemFeedbackHistory.singleComments[parseInt(id)]
      };
    }
    return {
      changes: { commentText: null, statusTo: null, commentId: null },
      historyData: [],
      isFetching: false
    };
  };
  _.forEach(comments, ({ id, type }) => {
    if (type === 'OVERALL') {
      overallItemFeedbackHistory = {
        ...overallItemFeedbackHistory,
        singleComments: {
          ...overallItemFeedbackHistory.singleComments,
          [parseInt(id)]: {
            ...getExistingChangesIfAvailable(id)
          }
        }
      };
    }
  });
};

const updateCommentStatus = (commentId, newStatus, dataSet) => {
  if (!(dataSet && dataSet.length && commentId)) {
    return [];
  }
  let updatedDataSet = [...dataSet];
  const index = _.findIndex(updatedDataSet, { id: commentId });
  if (index < 0) {
    return updatedDataSet;
  }
  _.assign(updatedDataSet[index], { ...updatedDataSet[index], status: newStatus });
  return updatedDataSet;
};

const comments = (state = initialState.comments, action) => {
  switch (action.type) {
    case COMMENTS.MERGE:
      return {
        ...state,
        comments: action.payload,
        overallItemFeedbackHistory: { ...initiateOverallFeedbackChangesForEachComments(state, action.payload) },
        recordCount: action.recordCount
      };
    case IMGCOMMENT.SUCCESS:
      return { ...state, data: mergeImageComments(state.data, action.payload) };
    case IMGRESERVATION.SUCCESS:
      return { ...state, data: mergeFileReservation(state.data, action.payload) };
    case COMMENTS.REQUEST:
      return {
        ...state,
        fetching: true,
        search: action.search,
        fType: action.fType,
        fStatus: action.fStatus,
        page: action.page || 1
      };
    case COMMENTS.SUCCESS:
      return { ...state, fetching: false, data: [...mergeCommentsWithInfo(state.comments, action.payload)] };
    case COMMENTS.FAILURE:
      return { ...state, fetching: false, data: [], error: action.error };
    case UPDATECOMMENT.REQUEST:
      return { ...state, loading: action.commentId };
    case UPDATEATTRIBCOMMENT.REQUEST:
      return { ...state, loading: action.commentId };
    case UPDATECOMMENT.SUCCESS:
      return {
        ...state,
        loading: false,
        data: [...updateStatus(state.data, action.payload)],
        comments: [...updateStatus(state.comments, action.payload)]
      };
    case UPDATECOMMENT.FAILURE:
      return { ...state, loading: false, error: action.error };
    case FNBCOMMENTS.REQUEST:
      return { ...state, fetchingFnbComments: true };
    case FNBCOMMENTS.SUCCESS:
      return { ...state, fnbComments: action.payload, fetchingFnbComments: false };
    case FNBCOMMENTS.FAILURE:
      return { ...state, fetchingFnbComments: false };
    case EDITATTRIBUTE.SUCCESS:
      const clone = [...state.fnbComments];
      const target = _.find(clone, item => {
        if (item.pending) {
          return action.payload.supc === item.pending.supc;
        }
      });
      if (target) {
        target.pending = action.payload.pending;
        return { ...state, fnbComments: clone };
      }
      return { ...state };
    case UPDATEFNBCOMMENT.REQUEST:
      return { ...state, loading: action.commentId };
    case UPDATEFNBCOMMENT.SUCCESS:
      const fnbClone = updateFnBReviews(state.fnbComments, action.payload.commentId, action.payload.updatedData);
      const commentsClone = updateStatus(state.data, { id: action.payload.commentId, status: action.payload.status });
      return { ...state, loading: false, fnbComments: fnbClone, data: commentsClone };
    case UPDATEFNBCOMMENT.FAILURE:
      return { ...state, loading: false };
    case UPDATEIMAGECOMMENT.REQUEST:
      return { ...state, loading: action.commentId };
    case UPDATEIMAGECOMMENT.SUCCESS:
      const dataClone = updateImageReviews(
        state.data,
        action.payload.commentId,
        action.payload.updatedData,
        action.payload.status
      );
      return { ...state, loading: false, data: dataClone };
    case UPDATEIMAGECOMMENT.FAILURE:
      return { ...state, loading: false };
    case UPDATEIMAGESTYLE.SUCCESS: {
      const {
        comment: { commentId, style }
      } = action.payload;
      let index = findSpecificIndex(state.data, commentId);
      const existingData = [...state.data];
      existingData.splice(index, 1, {
        ...state.data[index],
        imageComment: {
          ...existingData[index].imageComment,
          style,
          previous: { ...existingData[index].imageComment.previous, style }
        }
      });
      return { ...state, data: existingData };
    }
    case UPDATENUTRITIONDATA.REQUEST:
      return { ...state, loading: action.commentId };
    case UPDATENUTRITIONDATA.SUCCESS:
      return { ...state, loading: false, data: updateStatus(state.data, action.payload) };
    case UPDATENUTRITIONDATA.FAILURE:
      return { ...state, loading: false };
    case ONNUTRITIONREJECTFIELDSCHANGED.REQUEST:
      const { comment, id, status } = action;
      let index = findSpecificIndex(state.data, id);
      const existingData = [...state.data];
      existingData.splice(index, 1, {
        ...state.data[index],
        comment,
        rejectStatus: status
      });
      return { ...state, data: existingData };
    case GDSNFNBCOMMENTS.REQUEST:
      const fnbComments1 = updateGdsnFnb({
        supc: action.supc,
        fnbComments: state.fnbComments,
        isFetchingGdsnFnb: true
      });
      return { ...state, fnbComments: fnbComments1 };
    case GDSNFNBCOMMENTS.SUCCESS:
      const fnbComments2 = updateGdsnFnb({
        ...action.payload,
        fnbComments: state.fnbComments,
        isFetchingGdsnFnb: false
      });
      return { ...state, fnbComments: fnbComments2 };
    case GDSNFNBCOMMENTS.FAILURE:
      const fnbComments3 = updateGdsnFnb({
        supc: action.payload.supc,
        fnbComments: state.fnbComments,
        isFetchingGdsnFnb: false
      });
      return { ...state, fnbComments: fnbComments3 };
    case COREDATACOMMENTCHANGED.REQUEST:
      let updatedData = spliceAndManipulateStateData(
        findSpecificIndex(state.data, action.id),
        {
          comment: action.comment
        },
        state.data
      );
      return { ...state, data: updatedData };
    case HANDLEAALREADYASSIGNEDCOREDATAISSUE.REQUEST:
      return {
        ...state,
        data: spliceAndManipulateStateData(
          findSpecificIndex(state.data, action.id),
          {
            assignee: action.assignee
          },
          state.data
        )
      };
    case REJECTALLCOREDATAATTRIBUTES.REQUEST:
      return { ...state, isAllCoreAttributesInCommentRejecting: true };
    case REJECTALLCOREDATAATTRIBUTES.SUCCESS:
      let dataIndex = findSpecificIndex(state.data, action.id);
      let updatedExistingData = spliceAndManipulateStateData(
        dataIndex,
        {
          comment: setRejectAllToCoreAttributes(JSON.parse(state.data[dataIndex].comment))
        },
        state.data
      );
      return { ...state, data: updatedExistingData, isAllCoreAttributesInCommentRejecting: false };
    case REJECTALLCOREDATAATTRIBUTES.FAILURE:
      return { ...state, isAllCoreAttributesInCommentRejecting: false };
    case SENDCOREDATACOMMENT.REQUEST:
      return { ...state, coreModal: { ...state.coreModal, isSaving: true } };
    case SENDCOREDATACOMMENT.SUCCESS:
      let updateExistingCommentdData = spliceAndManipulateStateData(
        findSpecificIndex(state.data, action.payload.id),
        {
          comment: action.payload.comment,
          status: action.payload.status
        },
        state.data
      );
      return { ...state, data: updateExistingCommentdData, coreModal: { isSaving: false, isVisible: false, data: {} } };
    case SENDCOREDATACOMMENT.FAILURE:
      return { ...state, coreModal: { ...state.coreModal, isSaving: false } };
    case GETITEMDETAILS.SUCCESS:
      let updatedCommentData = spliceAndManipulateStateData(
        findSpecificIndex(state.data, action.payload.commentId),
        {
          catManFlag: action.payload.catmanCorporate
        },
        state.data
      );
      return { ...state, data: updatedCommentData };
    case SELFASSIGNCOMMENT.REQUEST:
      return {
        ...state,
        data: updateCommentAssignee([...state.data], action.commentId, null, true)
      };
    case SELFASSIGNCOMMENT.SUCCESS:
      return {
        ...state,
        data: updateCommentAssignee([...state.data], action.payload.commentId, action.payload.userId, false)
      };
    case SELFASSIGNCOMMENT.FAILURE:
      return {
        ...state,
        data: updateCommentAssignee([...state.data], action.payload.commentId, null, false)
      };
    case COREATTRIBUTEHISTORY.REQUEST:
      return {
        ...state,
        history: { ...state.history, fetching: true, coreData: [] }
      };
    case COREATTRIBUTEHISTORY.SUCCESS:
      return {
        ...state,
        history: { ...state.history, fetching: false, coreData: action.payload }
      };
    case COREATTRIBUTEHISTORY.FAILURE:
      return {
        ...state,
        history: { ...state.history, fetching: false }
      };
    case TOGGLECOREMODAL.REQUEST:
      return {
        ...state,
        coreModal: { ...state.coreModal, ...action.payload }
      };
    case PREFERREDIMAGECOMMENT.REQUEST:
    case PREFERREDIMAGECOMMENT.SUCCESS:
    case PREFERREDIMAGECOMMENT.FAILURE:
    case ASSOCIATEPREFERREDIMAGE.REQUEST:
    case PREFERREDIMAGEREVIEWSTATUS.REQUEST:
    case APPROVEPREFERREDIMAGE.REQUEST:
    case APPROVEPREFERREDIMAGE.FAILURE:
    case REJECTPREFERREDIMAGE.REQUEST:
    case REJECTPREFERREDIMAGE.FAILURE:
      return {
        ...state,
        preferredImages: updatePreferredImageComments([...state.preferredImages], action.payload)
      };
    case APPROVEPREFERREDIMAGE.SUCCESS:
      return {
        ...state,
        preferredImages: updatePreferredImageComments([...state.preferredImages], action.payload),
        data: updatePreferredImageCommentStatus(action.payload.commentId, state.data, 30),
        comments: updatePreferredImageCommentStatus(action.payload.commentId, state.comments, 30)
      };
    case REJECTPREFERREDIMAGE.SUCCESS:
      return {
        ...state,
        preferredImages: updatePreferredImageComments([...state.preferredImages], action.payload),
        data: updatePreferredImageCommentStatus(action.payload.commentId, state.data, 10),
        comments: updatePreferredImageCommentStatus(action.payload.commentId, state.comments, 10)
      };
    case FETCHOVERALLITEMFEEDBACKHISTORY.SUCCESS:
      const { fetchedCommentId } = action;
      return {
        ...state,
        overallItemFeedbackHistory: {
          ...state.overallItemFeedbackHistory,
          singleComments: {
            ...state.overallItemFeedbackHistory.singleComments,
            [fetchedCommentId]: {
              isFetching: false,
              historyData: [...action.payload],
              changes: { ...generateOverallChangesBasedOnPreviousHistoricalData(action.payload) }
            }
          }
        }
      };
    case FETCHOVERALLITEMFEEDBACKHISTORY.FAILURE:
      return {
        ...state,
        overallItemFeedbackHistory: {
          ...state.overallItemFeedbackHistory,
          singleComments: {
            ...state.overallItemFeedbackHistory.singleComments,
            [action.failedCommentId]: {
              isFetching: false,
              historyData: [],
              changes: {}
            }
          }
        }
      };
    case FETCHOVERALLITEMFEEDBACKHISTORY.REQUEST:
      if (!action.range) {
        return {
          ...state,
          overallItemFeedbackHistory: {
            ...state.overallItemFeedbackHistory,
            singleComments: {
              ...state.overallItemFeedbackHistory.singleComments,
              [action.commentId]: {
                isFetching: true,
                history: [],
                changes: {}
              }
            },
            allHistory: []
          }
        };
      } else {
        return state;
      }
    case UPDATEALLOVERALLHISTORY.REQUEST:
      return {
        ...state,
        overallItemFeedbackHistory: {
          ...state.overallItemFeedbackHistory,
          isOverallHistoryLoading: true
        }
      };
    case UPDATEOVERALLFEEDBACK.REQUEST:
      const { type, commentId, ...rest } = action;
      return {
        ...state,
        overallItemFeedbackHistory: {
          ...state.overallItemFeedbackHistory,
          singleComments: {
            ...state.overallItemFeedbackHistory.singleComments,
            [commentId]: {
              ...state.overallItemFeedbackHistory.singleComments[commentId],
              changes: { ...state.overallItemFeedbackHistory.singleComments[commentId].changes, ...rest }
            }
          }
        }
      };
    case UPDATEALLOVERALLHISTORY.SUCCESS:
      return {
        ...state,
        overallItemFeedbackHistory: {
          ...state.overallItemFeedbackHistory,
          allHistory: [...action.payload],
          isOverallHistoryLoading: false
        }
      };
    case UPDATEALLOVERALLHISTORY.FAILURE:
      return {
        ...state,
        overallItemFeedbackHistory: {
          ...state.overallItemFeedbackHistory,
          allHistory: [],
          isOverallHistoryLoading: false
        }
      };
    case SUBMITOVERALLFEDBACK.SUCCESS:
      const {
        payload: { newStatus, ...restItems }
      } = action;
      return {
        ...state,
        comments: [...updateCommentStatus(action.payload.commentId, newStatus, state.comments)],
        data: [...updateCommentStatus(action.payload.commentId, newStatus, state.data)],
        overallItemFeedbackHistory: {
          ...state.overallItemFeedbackHistory,
          singleComments: {
            ...state.overallItemFeedbackHistory.singleComments,
            [action.payload.commentId]: {
              ...state.overallItemFeedbackHistory.singleComments[action.payload.commentId],
              historyData: [
                ...state.overallItemFeedbackHistory.singleComments[action.payload.commentId].historyData,
                { ...restItems }
              ],
              changes: {}
            }
          }
        }
      };
    default:
      return state;
  }
};

const updatePreferredImageComments = (existingData, newData) => {
  const { commentId, supc, assetId, styleBucket } = newData;
  const updatedData = [...existingData];
  const index = _.findIndex(updatedData, obj => obj.commentId === commentId);

  if (index === -1) {
    const data = {
      commentId,
      supc,
      styleBucket,
      prefferedImage: assetId,
      associatePreferred: null,
      fetching: true,
      loading: false,
      additionalImages: [],
      isReject: false
    };

    updatedData.push(data);
  } else {
    const data = {
      ...updatedData[index],
      ...newData,
      fetching: false
    };

    updatedData[index] = data;
  }

  return updatedData;
};

const updatePreferredImageCommentStatus = (commentId, existingData, status) => {
  const clone = [...existingData];
  const index = _.findIndex(clone, { id: commentId });
  clone[index] = { ...clone[index], status };
  return clone;
};

const updateCommentAssignee = (data, commentId, assignee, loading) => {
  let index = _.findIndex(data, obj => obj.id === commentId);

  if (index !== -1) {
    let comment = { ...data[index] };

    if (assignee) {
      comment.assignee = assignee;
    }

    comment.assigningComment = loading;
    data[index] = comment;
  }

  return data;
};

const updateGdsnFnb = ({ supc, gdsnFnb = {}, fnbComments, isFetchingGdsnFnb }) => {
  let fnbCommentsClone = [...fnbComments];
  let index = _.findIndex(fnbCommentsClone, obj => supc === obj.pending?.supc);

  if (index !== -1) {
    let fnbClone = { ...fnbCommentsClone[index] };
    fnbClone.isFetchingGdsnFnb = isFetchingGdsnFnb;
    fnbClone.gdsnFnb = gdsnFnb;
    fnbCommentsClone[index] = fnbClone;
  }

  return fnbCommentsClone;
};

const getOverallItemScore = scores => {
  let total = 0;
  scores.forEach(scoreObj => {
    total += scoreObj.score;
  });

  return {
    cardType: 'overall',
    title: 'OVERALL SCORE',
    score: Math.round((total / scores.length) * 10) / 10,
    explain: 'This is the average of attribution, images, and features & benefits scores.'
  };
};

const mergeScores = (scores, payload) => {
  const mergedScores = [];
  scores.forEach(scoreElement => {
    const payloadScore = _.find(payload, payloadElement => {
      return payloadElement.cardType === scoreElement.cardType;
    });
    if (payloadScore) {
      mergedScores.push({ ...scoreElement, score: payloadScore.score });
    }
  });

  mergedScores.unshift(getOverallItemScore(mergedScores));

  return mergedScores;
};

const getTaxonomyAndBrands = payload => {
  const payloadScore = _.find(payload, payloadElement => {
    return payloadElement.cardType === 'attribution';
  });
  if (payloadScore) {
    return { taxonomy: { ...payloadScore.taxonomy }, brands: [...payloadScore.brands] };
  }
  return {};
};

const scorecards = (state = initialState.scorecards, action) => {
  switch (action.type) {
    case SCORES.SUCCESS:
      return {
        ...state,
        scores: mergeScores(initialState.scorecards.scores, action.payload),
        scoresLoading: false,
        ...getTaxonomyAndBrands(action.payload),
        activeChart: initialState.scorecards.activeChart,
        summaryItems: initialState.scorecards.summaryItems
      };
    case SCORES.FAILURE: {
      const {
        scorecards: { scores, activeChart, summaryItems, filterForGraphs, brands, taxonomy }
      } = initialState;
      return {
        ...state,
        activeChart,
        summaryItems,
        filterForGraphs,
        brands,
        taxonomy,
        scores
      };
    }
    case CHARTS.REQUEST:
      return { ...state, activeChart: action.chartType };
    case SUMMARY.SUCCESS:
      return { ...state, summaryItems: action.payload };
    case FILTERFORGRAPH.REQUEST:
      return { ...state, filterForGraphs: action.filter };
    case SUPPLIERS.REQUEST: {
      const {
        scorecards: { filterForGraphs }
      } = initialState;
      return { ...state, filterForGraphs, overallSummary: {} };
    }
    case OVERALLSUMMARY.REQUEST:
      return { ...state, fetchingOverallSummary: true };
    case OVERALLSUMMARY.SUCCESS:
      return { ...state, overallSummary: action.payload.overallSummary, fetchingOverallSummary: false };
    case OVERALLSUMMARY.FAILURE:
      return { ...state, fetchingOverallSummary: false };
    case ITEMSSUMMARY.REQUEST:
      return { ...state, summaryItems: [] };
    case ITEMSSUMMARY.SUCCESS:
      return { ...state, summaryItems: action.payload };
    default:
      return { ...state };
  }
};

const vendor = (state = initialState.vendor, action) => {
  switch (action.type) {
    case USER.SUCCESS:
      let details = {};
      try {
        if (localStorage.getItem('vendor')) {
          details = JSON.parse(localStorage.getItem('vendor'));
        }
      } catch (error) {}
      return { ...state, suvc: action.payload.suvc, details };
    case ITEMS.REQUEST: {
      return { ...state, fetching: true, selectedSuvc: action.suvc };
    }
    case ITEMS.SUCCESS: {
      const list = _.filter(action.payload.items, item => item !== null);
      const {
        fnbRejectFilterOn,
        imageRejectFilterOn,
        feeFilterOn,
        isAutoSavedFnbFilterOn,
        tableFilters,
        tableSearchData,
        brandGroupFilters,
        freeSearch,
        orderBy,
        nutritionRejectFilterOn,
        coreDataRejectFilterOn
      } = action.payload;
      return {
        ...state,
        fetching: false,
        list,
        fnbRejectFilterOn,
        imageRejectFilterOn,
        feeFilterOn,
        isAutoSavedFnbFilterOn,
        tableFilters,
        tableSearchData,
        brandGroupFilters,
        freeSearch,
        orderBy,
        nutritionRejectFilterOn,
        coreDataRejectFilterOn
      };
    }
    case ITEMS.FAILURE: {
      return { ...state, fetching: false };
    }
    case VENDORS.REQUEST:
      return { ...state, fetching: true };
    case VENDORS.SUCCESS:
      return { ...state, fetching: false, all: action.payload };
    case SUBSUVC.SUCCESS:
      return { ...state, details: action.payload };
    case REJECTEDSUPCS.REQUEST:
      return { ...state, fetchingRejectedSupcs: true };
    case REJECTEDSUPCS.SUCCESS:
      const { rejectedFnbs, rejectedImages, rejectedNutritions, rejectedCoreData, rejectedOverall } = action.payload;
      return {
        ...state,
        fetchingRejectedSupcs: false,
        rejectedFnbs,
        rejectedImages,
        rejectedNutritions,
        rejectedCoreData,
        rejectedOverall
      };
    case REJECTEDSUPCS.FAILURE:
      return { ...state, fetchingRejectedSupcs: false };
    case AUTOSAVE.REQUEST:
      return { ...state, autoSavedFnb: action.autoSavedData };
    case UPDATEFEEDBACKSORTFILTERS.REQUEST: {
      const { brandGroupFilters, orderBy, tableFilters, tableSearchData, fStatus, dateRangeFilter } = action;
      return {
        ...state,
        brandGroupFilters,
        orderBy,
        tableFilters,
        tableSearchData,
        filterType: fStatus,
        dateRangeFilter
      };
    }
    case COMPLEXGRAPHRENDERING.REQUEST: {
      const { payload } = action;
      return {
        ...state,
        isFiltersComplex: payload
      };
    }
    case FEEEXCLUSIONSBYSUPC.REQUEST:
      return {
        ...state,
        list: updateFeeExclusions(state.list, action.supc, { isFetchingFeeExclusionDetails: true })
      };
    case FEEEXCLUSIONSBYSUPC.SUCCESS:
      return {
        ...state,
        list: updateFeeExclusions(state.list, action.payload.supc, {
          ...action.payload,
          isFetchingFeeExclusionDetails: false
        })
      };
    case FEEEXCLUSIONSBYSUPC.FAILURE:
      return {
        ...state,
        list: updateFeeExclusions(state.list, action.payload.supc, { isFetchingFeeExclusionDetails: false })
      };
    case FEEEXCLUSIONSCOUNT.REQUEST:
      return { ...state, fetchingFeeExclusionsCount: true };
    case FEEEXCLUSIONSCOUNT.SUCCESS:
      return { ...state, feeExclusionsSupcs: action.payload.feeExclusionsSupcs, fetchingFeeExclusionsCount: false };
    case FEEEXCLUSIONSCOUNT.FAILURE:
      return { ...state, fetchingFeeExclusionsCount: false };
    case TOGGLEFNBMASSEDITMODAL.REQUEST:
      return { ...state, showFnbMassUpdateModal: action.isVisible };
    case FNBMASSEDITINITIALDATA.REQUEST:
      return { ...state, fnbMassEditItems: [], fetchingFnbMassEditData: true };
    case FNBMASSEDITINITIALDATA.SUCCESS:
      let fnbMassEditItems = getMergedFnbMassUpdateData(action.payload, state.list);
      return { ...state, fnbMassEditItems, fetchingFnbMassEditData: false };
    case FNBMASSEDITINITIALDATA.FAILURE:
      return { ...state, fetchingFnbMassEditData: false };
    case FNBMASSEDIT.REQUEST:
      return { ...state, savingFnbMassUpdate: true };
    case FNBMASSEDIT.SUCCESS:
      return {
        ...state,
        savingFnbMassUpdate: false,
        showFnbMassUpdateModal: action.payload.showFnbMassUpdateModal,
        fnbMassEditItems: _.filter(state.fnbMassEditItems, item => _.includes(action.payload.failed, item.supc))
      };
    case FNBMASSEDIT.FAILURE:
      return { ...state, savingFnbMassUpdate: false };
    default:
      return { ...state };
  }
};

const getMergedFnbMassUpdateData = (payload, items) => {
  const { supcs, fnbs, taxonomies, masterData } = payload;
  let data = [];

  _.forEach(supcs, supc => {
    let item = _.find(items, item => item.supc === supc);
    let fnb = _.find(fnbs, obj => obj.supc === supc);
    let taxonomy = _.find(taxonomies, obj => obj.supc === supc);
    let master = _.find(masterData, elem => elem.supc === supc);
    let masterFnb = extractItemsnFnb(master);

    if (item && item.supc && taxonomy) {
      let record = {
        supc: item.supc,
        basicDetails: {
          suvc: item.suvc,
          stepId: item.stepId,
          materialDescription: item.materialDescription,
          brandName: item.brandName,
          manufactProdCode: item.manufactProdCode,
          gtin: item.gtin
        },
        taxonomy,
        existing: masterFnb,
        hasPending: fnb ? !_.isEmpty(fnb.pending) : false
      };

      record.fnb = {};
      _.forEach(fnbCommentAttributes, attr => {
        let value = '';

        if (fnb && !_.isEmpty(fnb.pending)) {
          value = _.get(fnb, `pending[${attr}]`, '');
        } else {
          value = _.get(masterFnb, `${attr}`, '');
        }

        record['fnb'][attr] = value;
      });

      record.initialFnb = { ...record.fnb };

      data.push(record);
    }
  });
  return data;
};

const updateFeeExclusions = (list, supc, updated) => {
  let clone = [...list];
  let index = _.findIndex(clone, obj => supc === obj.supc);

  if (index !== -1) {
    let target = { ...clone[index], ...updated };
    clone[index] = target;
  }

  return clone;
};

const updateFnBReviews = (fnbComments, commentId, updatedData) => {
  const fnbClone = [...fnbComments];
  const index = _.findIndex(fnbClone, item => {
    if (item.pending) {
      return commentId === item.pending.comment_id;
    }
  });

  if (index !== -1) {
    const reviews = [..._.get(fnbClone[index], 'review', [])];

    updatedData.forEach(item => {
      let review = _.find(reviews, review => review.attribute === item.attribute);
      if (review) {
        review.status = item.status;
        review.comment = item.comment;
      } else {
        review = {
          attribute: item.attribute,
          status: item.status,
          comment: item.comment
        };

        reviews.push(review);
      }
    });

    fnbClone[index].review = reviews;
  }

  return fnbClone;
};

const updateImageReviews = (comments, commentId, updatedData, status) => {
  const commentsClone = [...comments];
  const index = _.findIndex(commentsClone, { id: commentId });
  const imageComment = { ...commentsClone[index].imageComment, review: updatedData };
  commentsClone[index] = { ...commentsClone[index], imageComment, status };
  return commentsClone;
};

const updateStatus = (comments, Info) => {
  const newComment = [...comments];
  const elementIndex = _.findIndex(newComment, { id: Info.id });
  newComment[elementIndex] = { ...newComment[elementIndex], status: Info.status, comment: Info.comment };
  return newComment;
};

const mergeCommentsWithInfo = (comments, Info) => {
  const data = comments.map(function(comment) {
    let info = _.find(Info, ['supc', comment.supc]);
    if (!info) {
      info = {
        trueVendorName: '',
        materialDescription: ''
      };
    }

    return {
      supc: comment.supc,
      supplierName: info.trueVendorName,
      materialDescription: info.materialDescription,
      field: comment.field,
      originalValue: comment.value,
      comment: comment.comment,
      status: comment.status,
      id: comment.id,
      stepId: comment.stepId,
      createdAt: comment.createdAt,
      createdBy: comment.createdBy,
      type: comment.type,
      suvc: info.suvc,
      itemDescription: comment.itemDescription,
      brand: comment.brand,
      gdsn: comment.gdsn,
      gtin: comment.gtin,
      assignee: comment.assignee
    };
  });

  return data;
};

const setTaxonomyChanges = (existingchanges, newChange) => {
  existingchanges = existingchanges.filter(attrObj => {
    return attrObj.attrId !== newChange.attrId;
  });
  existingchanges.push(newChange);
  return existingchanges;
};

const getExistingChangesForFeedbacks = (existing, newChange, existingChanges) => {
  let changes = existingChanges;
  if (!existing || newChange.name === 'Item Status') {
    changes = [];
  } else {
    let updatedChanges = existing.changers;
    if (newChange.type === 'Select') {
      const index = existing.changers.findIndex(eachChange => eachChange.name === newChange.name);
      if (index > -1) updatedChanges = updatedChanges.splice(0, index);
    }
    changes = updatedChanges.filter(attrObj => {
      return attrObj.name !== newChange.name;
    });
  }
  return changes;
};

const handleInputValidationError = (validationObj, existingChanges, change, isValidate, isValidationRes) => {
  let validate = isValidate;
  let validationRes = isValidationRes;
  if (validationObj[0].validation.includes('[Corresponding]')) {
    const value = existingChanges.filter(attrObj => {
      return attrObj.name === validationObj[0].validation.split('[Corresponding]|')[1];
    });
    if (change.selected === '' || (value.length > 0 && value[0].selected && value[0].selected !== '')) {
      validationRes = true;
    } else {
      validate = false;
      validationRes = false;
    }
  }
  return { validate, validationRes };
};

const getValidationChangesOnInput = (validationObj, change, isValidate, isValidationRes) => {
  let validate = isValidate;
  let validationRes = isValidationRes;
  if (getFieldDisabledStatusByTwoParams(change.selected === '', validationObj[0].validation.test(change.selected))) {
    validationRes = true;
  } else {
    validate = false;
    validationRes = false;
  }
  return { validate, validationRes };
};

const checkWhetherFieldsAreNotIncluded = (newChange, dataCheckArray, status) => {
  const { name, selected } = newChange;
  if (name === status && !dataCheckArray.includes(selected)) return true;
};

const checkWhetherFieldsAreIncluded = (newChange, dataCheckArray, status) => {
  const { name, selected } = newChange;
  if (name === status && dataCheckArray.includes(selected)) return true;
};

const replaceSpacesInText = text => text.replaceAll(' ', '_').replaceAll(`'`, '');

const getValidateStatusForNoLongerProducedBySupplier = selected => {
  return ['Discontinued, depleted', 'Discontinued, significant attribute changes'].includes(selected);
};

const getUpdatedChanges = (newChange, existing, existingChanges, validate) => {
  if (checkStatusWithTwoAndConditions(newChange.name === 'Item Status', newChange.selected === 'Discontinued Item')) {
    return {
      ...existing,
      mainStatus: 'discontinuedItem',
      validate: false,
      changers: existingChanges,
      secondStatus: null,
      thirdStatus: null
    };
  }
  if (
    checkStatusWithTwoAndConditions(
      newChange.name === 'Item Status',
      newChange.selected === 'Item does not belong to supplier'
    )
  ) {
    return { ...existing, mainStatus: 'notBelongsToSupplier', validate: false, changers: existingChanges };
  }
  if (checkStatusWithTwoAndConditions(newChange.name === 'Item Status', !newChange.selected)) {
    return null;
  }
  if (checkStatusWithTwoAndConditions(newChange.name === 'Item Status', newChange.selected === 'Proprietary Item')) {
    return {
      ...existing,
      mainStatus: null,
      secondStatus: null,
      thirdStatus: null,
      validate: true,
      changers: existingChanges
    };
  }
  if (checkStatusWithTwoAndConditions(newChange.name === 'Discontinued Item', newChange.selected === 'Duplicate')) {
    return { ...existing, secondStatus: 'Duplicate', validate: false, changers: existingChanges };
  }
  if (
    checkStatusWithTwoAndConditions(
      newChange.name === 'Discontinued Item',
      newChange.selected === 'Discontinued, replacement offered'
    )
  ) {
    return {
      ...existing,
      secondStatus: 'Discontinued,_replacement_offered',
      validate: false,
      changers: existingChanges
    };
  }
  if (
    checkWhetherFieldsAreNotIncluded(newChange, ['Discontinued, replacement offered', 'Duplicate'], 'Discontinued Item')
  ) {
    return { ...existing, secondStatus: null, validate, changers: existingChanges };
  }
  if (
    checkStatusWithTwoAndConditions(
      newChange.name === 'Item does not belong to Supplier',
      ITEM_DOES_NOT_BELONG_TO_SUPPLIER.includes(newChange.selected)
    )
  ) {
    return {
      ...existing,
      secondStatus: replaceSpacesInText(newChange.selected),
      validate: false,
      changers: existingChanges,
      thirdStatus: null
    };
  }
  if (
    checkStatusWithTwoAndConditions(
      newChange.name === 'No longer produced by Supplier',
      NO_LONGER_PRODUCED_BY_SUPPLIER.includes(newChange.selected)
    )
  ) {
    return {
      ...existing,
      thirdStatus: replaceSpacesInText(newChange.selected),
      validate: getValidateStatusForNoLongerProducedBySupplier(newChange.selected),
      changers: existingChanges
    };
  }
  if (
    checkWhetherFieldsAreIncluded(newChange, ['Discontinued Item', 'Item does not belong to supplier'], 'Item Status')
  ) {
    return {
      ...existing,
      mainStatus: null,
      secondStatus: null,
      thirdStatus: null,
      validate: true,
      changers: existingChanges
    };
  }

  return { ...existing, validate, changers: existingChanges };
};

const setFeedbackChanges = (existing, newChange) => {
  let existingchanges;
  let validate = true;
  existingchanges = getExistingChangesForFeedbacks(existing, newChange, existingchanges);
  existingchanges.push(newChange);

  existingchanges = existingchanges.map(change => {
    if (change.type === 'Select') {
      const validationRes = change.selected != null;
      if (!validationRes) {
        validate = false;
      }
      return { ...change, validation: validationRes };
    }
    if (change.type === 'Input') {
      const validationObj = _.get(feedback, change.parent, []).filter(obj => {
        return obj.name === change.name;
      });
      let validationRes;
      try {
        const { validate: isValidate, validationRes: isValidationRes } = getValidationChangesOnInput(
          validationObj,
          change,
          validate,
          validationRes
        );
        validate = isValidate;
        validationRes = isValidationRes;
      } catch (error) {
        const { validate: isValidate, validationRes: isValidationRes } = handleInputValidationError(
          validationObj,
          existingchanges,
          change,
          validate,
          validationRes
        );
        validate = isValidate;
        validationRes = isValidationRes;
      }
      return { ...change, validation: validationRes };
    }
    return false;
  });

  return getUpdatedChanges(newChange, existing, existingchanges, validate);
};

const setMasterComment = (existingComments, newComment) => {
  existingComments = existingComments.filter(commentObj => {
    return commentObj.field !== newComment.field;
  });
  if (newComment.comment !== '') {
    existingComments.push(newComment);
  }
  return existingComments;
};

const defaultSelectedItem = {
  fetching: false,
  basicData: null,
  supc: null,
  master: null,
  feedback: null,
  fnb: null,
  images: null,
  taxonomy: null,
  pending: false,
  taxonomyChanges: [],
  taxonomyComment: '',
  defaultTab: '1',
  masterComments: [],
  suvc: null,
  imagesLoaded: false,
  activeTab: 1,
  updateTaxonomyNutritions: 0,
  coreData: { comment: {} },
  isCoreDataSubmitable: false,
  isCoreDataSubmitting: false,
  originalCoreDataValues: { comment: {} },
  isNutritionDataHasBeenFetched: false,
  nutritionErrors: {},
  pendingNutrition: { changedFields: [] },
  pendingNutritions: [],
  nutritions: {},
  hasNutritionSynchedTaxonomyAttributeChanges: false,
  previousHasNutritionSynchedTaxonomyAttributeChanges: false,
  feeExclusions: [],
  cooDatas: [],
  originalCooDatas: [],
  isCooDataSubmitable: false,
  isCooDataSubmitting: false,
  fetchingCooData: false,
  pfas: {
    supc: null,
    attributes: [],
    changes: {},
    changesBackup: {},
    isMassNotApplicable: false,
    isLoadingOrSaving: false
  }
};

const spreadEachCommentProp = (action, field, comment, userInput) => {
  let input = userInput;
  let userComment = comment;
  if (action.existingComment && action.existingComment.comment) {
    let splited = action.existingComment.comment.split(' -> ');
    if (splited.length > 1) {
      splited = action.existingComment.comment.split(' ] \n[ Description -> ');
      if (splited.length === 1) {
        input = action.existingComment.comment.replace(`[ ${field} -> `, '');
        input = input.replace(' ] ', '');
        userComment = { ...action.existingComment, comment: '' };
      } else {
        input = splited[0].replace(`[ ${field} -> `, '');
        userComment = { ...action.existingComment, comment: splited[1].replace(' ] ', '') };
      }
    } else {
      userComment = action.existingComment;
    }
  } else {
    userComment = action.existingComment;
  }
  return { input, userComment };
};

const commentsProp = (state, action) => {
  let comment = null;
  let userInput = null;
  switch (action.field) {
    case 'fnb':
      return {
        ...state,
        show: true,
        item: action.item,
        type: action.fieldType,
        field: action.field,
        currentValue: action.currentValue,
        fieldCaption: action.fieldCaption,
        hint: action.hint,
        existingComment: action.existingComment,
        existingStyles: [],
        pending: action.pending,
        imageCategoryName: null,
        imageCategory: null,
        execution: 1
      };
    case 'syy_itm_a_gtin':
      let { input, userComment } = spreadEachCommentProp(action, 'GTIN', null, null);
      comment = userComment;
      userInput = input;

      return {
        ...state,
        show: true,
        item: action.item,
        type: action.fieldType,
        field: action.field,
        userInput: {
          type: 'Text',
          descriptor: 'Please provide a correct GTIN number on the below field',
          placeholder: 'GTIN',
          validation: { regex: /^\d{14}$/, msg: 'Please provide a valid GTIN' }
        },
        currentValue: action.currentValue,
        fieldCaption: action.fieldCaption,
        hint: action.hint,
        existingUserInput: userInput,
        existingComment: comment,
        existingStyles: checkArrayAndSpreadOrDefault(action.existingStyles, []),
        imageCategoryName: getFieldDisabledStatusByTwoParams(action.imageCategoryName, null),
        imageCategory: getFieldDisabledStatusByTwoParams(action.imageCategory, null),
        image: getFieldDisabledStatusByTwoParams(action.image, null),
        execution: 1
      };
    case 'syy_itm_a_manufactprodcode':
      let { input: updatedInput, userComment: updatedUserComment } = spreadEachCommentProp(action, 'GTIN', null, null);
      comment = updatedUserComment;
      userInput = updatedInput;

      return {
        ...state,
        show: true,
        item: action.item,
        type: action.fieldType,
        field: action.field,
        userInput: {
          type: 'Text',
          descriptor: 'Please provide a correct MPC number on the below field',
          placeholder: 'MPC',
          validation: { regex: /^[a-zA-Z0-9_.-]*$/, msg: 'Please provide a valid MPC' }
        },
        currentValue: action.currentValue,
        fieldCaption: action.fieldCaption,
        hint: action.hint,
        existingUserInput: userInput,
        existingComment: comment,
        existingStyles: checkArrayAndSpreadOrDefault(action.existingStyles, []),
        imageCategoryName: getFieldDisabledStatusByTwoParams(action.imageCategoryName, null),
        imageCategory: getFieldDisabledStatusByTwoParams(action.imageCategory, null),
        image: getFieldDisabledStatusByTwoParams(action.image, null),
        execution: 1
      };

    case 'syy_itm_a_materialunabbreviateddesc':
      comment = null;
      userInput = null;
      if (checkStatusWithTwoAndConditions(action.existingComment, action.existingComment.comment)) {
        let splited = action.existingComment.comment.split(' -> ');
        if (splited.length > 1) {
          splited = action.existingComment.comment.split(' ] \n[ Description -> ');
          if (splited.length === 1) {
            userInput = action.existingComment.comment.replace('[ Material Unabbreviated Description -> ', '');
            userInput = userInput.replace(' ] ', '');
          } else {
            userInput = splited[0].replace('[ Material Unabbreviated Description -> ', '');
            comment = { ...action.existingComment, comment: splited[1].replace(' ] ', '') };
          }
        } else {
          comment = action.existingComment;
        }
      } else {
        comment = action.existingComment;
      }

      return {
        ...state,
        show: true,
        item: action.item,
        type: action.fieldType,
        field: action.field,
        userInput: {
          type: 'Select',
          descriptor: 'Please provide feedback on the below field',
          placeholder: 'Material Unabbreviated Description',
          picklist: [
            { id: 'ITS000001', value: 'Inaccurate Abbreviation Expansion' },
            { id: 'ITS000002', value: 'Missing Information' },
            { id: 'ITS000003', value: 'Misspelling' }
          ],
          validation: { msg: 'Please provide a valid Material Unabbreviated Description' }
        },
        currentValue: action.currentValue,
        fieldCaption: action.fieldCaption,
        hint: action.hint,
        existingUserInput: userInput,
        existingComment: comment,
        existingStyles: checkArrayAndSpreadOrDefault(action.existingStyles, []),
        imageCategoryName: getFieldDisabledStatusByTwoParams(action.imageCategoryName, null),
        imageCategory: getFieldDisabledStatusByTwoParams(action.imageCategory, null),
        image: getFieldDisabledStatusByTwoParams(action.image, null),
        execution: 1
      };
    default:
      return {
        ...state,
        show: true,
        item: action.item,
        type: action.fieldType,
        field: action.field,
        userInput: null,
        currentValue: action.currentValue,
        fieldCaption: action.fieldCaption,
        hint: action.hint,
        existingComment: action.existingComment,
        existingStyles: checkArrayAndSpreadOrDefault(action.existingStyles, []),
        imageCategoryName: getFieldDisabledStatusByTwoParams(action.imageCategoryName, null),
        imageCategory: getFieldDisabledStatusByTwoParams(action.imageCategory, null),
        image: getFieldDisabledStatusByTwoParams(action.image, null),
        execution: 1,
        previousCommentId: action.previousCommentId
      };
  }
};

const fieldComment = (state = initialState.fieldComment, action) => {
  switch (action.type) {
    case FIELDCOMMENT.REQUEST:
      return commentsProp(state, action);
    case FIELDCOMMENT.SUCCESS:
      return { ...state, ...initialState.fieldComment };
    case ITEMMASTER.REQUEST:
      return { ...state, ...initialState.fieldComment };
    case ITEMFNB.COMMENTS_SUCCESS:
      return { ...state, ...initialState.fieldComment };
    case SENDCOMMENT.SUCCESS:
      return { ...state, ...initialState.fieldComment };
    case SENDCOMMENT.FAILURE:
      return { ...state, ...initialState.fieldComment };
    case ITEMIMAGE.COMMENTS_SUCCESS:
      return { ...state, ...initialState.fieldComment };
    case IMGLOADING.REQUEST:
      return { ...state, loading: true };
    case IMGLOADING.SUCCESS:
      return { ...state, loading: false };
    case ITEMUNLOAD.REQUEST:
      return { ...state, ...initialState.fieldComment };
    default:
      return { ...state };
  }
};

const filterActiveComments = commentsList => {
  return commentsList.filter(comment => comment.status < 30);
};

const mergeActiveComments = (commentsList, newComments) => {
  const newCommentIds = [];
  newComments.forEach(comment => {
    newCommentIds.push(comment.id);
  });
  const noDuplicateList = commentsList.filter(comment => newCommentIds.indexOf(comment.id) === -1);
  return [...noDuplicateList, ...newComments];
};

const getFieldValue = (key, item) => {
  if (item[key] && item[key].value && item[key].value.value) {
    return item[key].value.value;
  }
};

const extractItemsnFnb = item => {
  return {
    descriptor: getFieldValue('syy_itm_a_productdescriptor', item),
    packaging: getFieldValue('syy_itm_a_packaginginformation', item),
    size: getFieldValue('syy_itm_a_sizeandshapeofproduct', item),
    yield: getFieldValue('syy_itm_a_yieldorservingsize', item),
    quality: getFieldValue('syy_itm_a_qualityandformat', item),
    prep: getFieldValue('syy_itm_a_prepandcookinginstructions', item),
    storage: getFieldValue('syy_itm_a_storageandusage', item),
    handling: getFieldValue('syy_itm_a_handlinginstructions', item),
    additional: getFieldValue('syy_itm_a_additionalproductinfo', item),
    marketing: getFieldValue('syy_itm_a_marketingstatements', item),
    culinary: getFieldValue('syy_itm_a_culinaryapplications', item)
  };
};

const pendingComments = (state = initialState.pendingComments, action) => {
  switch (action.type) {
    case ITEMCOMMENTS.SUCCESS:
      return { ...state, comments: filterActiveComments(action.payload) };
    case SENDCOMMENT.SUCCESS:
      return { ...state, comments: mergeActiveComments(state.comments, action.payload) };
    case ITEMUNLOAD.REQUEST:
      return { ...state, comments: [] };
    case ITEMMASTER.REQUEST:
      return { ...state, comments: [], pendingOverallFeedbackHistory: [] };
    case FETCHITEMCOMMENTSOVERALLHISTORY.SUCCESS:
      return { ...state, pendingOverallFeedbackHistory: [...state.pendingOverallFeedbackHistory, ...action.payload] };
    case FETCHITEMCOMMENTSOVERALLHISTORY.FAILURE:
      return { ...state, pendingOverallFeedbackHistory: [] };
    default:
      return { ...state };
  }
};

const page = (state = initialState.page, action) => {
  switch (action.type) {
    case ITEMS.REQUEST:
      return {
        ...state,
        search: action.search,
        page: action.page,
        subSuvc: action.subSuvc,
        graphfilter: action.graphfilter
      };
    case ITEMSCOUNT.SUCCESS:
      return { ...state, recordCount: action.payload };
    case SUPPLIERS.REQUEST:
      return { ...state, subSuvc: null };
    default:
      return { ...state };
  }
};

const selectedItem = (state = initialState.selectedItem, action) => {
  switch (action.type) {
    case ITEMMASTER.REQUEST:
      return {
        ...state,
        ...defaultSelectedItem,
        fetching: true,
        basicData: action.item,
        supc: action.supc,
        suvc: action.suvc,
        fnb: {
          fnbFetching: true,
          tooltip: state.fnb?.tooltip || '',
          tooltipFetching: state.fnb?.tooltipFetching || false,
          cannotEditComment: true
        }
      };
    case ITEMMASTER.FAILURE:
      return { ...state, ...defaultSelectedItem };
    case SUPCBASIC.REQUEST:
      return { ...state, readOnly: true, fetching: true };
    case SENDCOMMENTS.REQUEST:
      return { ...state, fetching: true };
    case SENDCOMMENTS.SUCCESS:
      return { ...state, fetching: false, masterComments: [] };
    case SENDCOMMENTS.FAILURE:
      return { ...state, fetching: false, masterComments: [] };
    case TAXOSAVE.REQUEST:
      return { ...state, fetching: true };
    case ITEMMASTER.SUCCESS:
      return {
        ...state,
        fetching: false,
        fnb: { ...state.fnb, fnb: extractItemsnFnb(action.payload.values), fnbFetching: false },
        master: action.payload.values
      };
    case ITEMTAXO.REQUEST:
      return { ...state, taxonomy: {}, pending: true };
    case FEEDBACK.REQUEST:
      return { ...state, fetching: true };
    case ITEMIMAGE.REQUEST:
      return { ...state, images: [], pending: true };
    case ITEMFNB.REQUEST:
      return { ...state, fnb: { ...state.fnb, pending: '' }, pending: true };
    case ITEMFNB.TOOLTIP_REQUEST:
      return { ...state, fnb: { ...state.fnb, tooltip: '', tooltipFetching: true }, pending: true };
    case ITEMFNB.COMMENTS_REQUEST:
      return { ...state, fnb: { ...state.fnb, comments: '' }, pending: true };
    case ATTRCHANGE.REQUEST:
      return { ...state, taxonomyChanges: setTaxonomyChanges(state.taxonomyChanges, action.attrChange) };
    case FEEDBACK.ADD_FEEDBACK_REQUEST:
      return { ...state, feedback: setFeedbackChanges(state.feedback, action.attrChange) };
    case ITEMTAXO.SUCCESS:
      return { ...state, taxonomy: action.payload, pending: false };
    case ITEMTAXO.FAILURE:
      return { ...state, taxonomy: {}, pending: false };
    case ITEMIMAGE.SUCCESS:
      return {
        ...state,
        images: action.payload,
        pending: false,
        imagesLoaded: true,
        imageTab: {
          ...state.imageTab,
          incorrectNaming: getImagesWithIncorrectNames(action.payload)
        }
      };
    case ITEMIMAGE.FAILURE:
      return { ...state, images: [], pending: false };
    case ITEMFNB.SUCCESS:
      return {
        ...state,
        fnb: { ...state.fnb, pending: action.payload.pending, review: action.payload.review },
        pending: false
      };
    case ITEMFNB.COMMENTS_SUCCESS:
      return { ...state, fnb: { ...state.fnb, pending: action.payload }, pending: false };
    case ITEMFNB.COMMENTS_FAILURE:
      return { ...state, fnb: { ...state.fnb, comments: '' }, pending: false };
    case ITEMFNB.TOOLTIP_SUCCESS:
      return { ...state, fnb: { ...state.fnb, tooltip: action.payload, tooltipFetching: false }, pending: false };
    case ITEMFNB.TOOLTIP_FAILURE:
      return { ...state, fnb: { ...state.fnb, tooltip: '', tooltipFetching: false }, pending: false };
    case ITEMFNB.FAILURE:
      return { ...state, pending: false };
    case MSTRCOMMENT.REQUEST:
      return { ...state, masterComments: setMasterComment(state.masterComments, action.comment) };
    case FEEDBACK.SUCCESS:
      return {
        ...state,
        fetching: false,
        pending: false,
        defaultTab: '1',
        feedback: null
      };
    case FEEDBACK.FAILURE:
      return { ...state, fetching: false, pending: false, defaultTab: '1' };
    case TAXOSAVE.SUCCESS:
      return {
        ...state,
        taxonomy: action.payload,
        fetching: false,
        pending: false,
        defaultTab: '4',
        taxonomyChanges: []
      };
    case TAXOQUITESAVE.SUCCESS:
      return {
        ...state,
        taxonomy: action.payload,
        fetching: false,
        pending: false
      };
    case TAXOSAVE.FAILURE:
      return { ...state, fetching: false, pending: false, defaultTab: '4' };
    case ITEMUNLOAD.REQUEST:
      return {
        ...state,
        ...defaultSelectedItem,
        fnb: { tooltip: state.fnb?.tooltip || '' },
        imageTab: initialState.selectedItem.imageTab,
        packagingTab: initialState.selectedItem.packagingTab
      };
    case RESETTAB.REQUEST: {
      return { ...state, ...getTabResetValues(action.tab), hasNutritionSynchedTaxonomyAttributeChanges: false };
    }
    case TABCHANGED.REQUEST:
      return { ...state, activeTab: action.tab };
    case ITEMCOMMENTS.SUCCESS:
      return {
        ...state,
        fnb: { ...state.fnb, cannotEditComment: false },
        imageTab: {
          ...state.imageTab,
          pendingPreferredImgs: updatePendingPreferredImgs(action.payload)
        }
      };
    case NUTRITIONS.REQUEST:
      return { ...state, nutritions: {}, fetchingNutritions: true };
    case NUTRITIONS.SUCCESS:
      let nutritions = { ...action.payload.nutritions };
      return {
        ...state,
        nutritions: nutritions,
        fetchingNutritions: false,
        updateTaxonomyNutritions: 1,
        isNutritionDataHasBeenFetched: true,
        pendingNutrition: { ...action.payload.formattedNutritions, ...state.pendingNutrition }
      };
    case HANDLECNREMOVALSILENCE.REQUEST: {
      return {
        ...state,
        nutritions: {
          ...state.nutritions,
          child_or_product_formulation: { ...CN_PFS_EMPTY_FIELDS }
        },
        pendingNutrition: {
          ...state.pendingNutrition,
          changedFields: [...getChangedFieldsAfterSilenceSubmitionNutritions(state.pendingNutrition)],
          ...CN_PFS_EMPTY_FIELDS
        }
      };
    }
    case BLOCKTAXONMYNUTRITIONUPDATE.REQUEST:
      return { ...state, updateTaxonomyNutritions: 2 };
    case NUTRITIONS.FAILURE:
      return { ...state, fetchingNutritions: false, isNutritionDataHasBeenFetched: false };
    case UPDATENUTRITIONS.REQUEST:
      return { ...state, updatingNutritions: true };
    case UPDATENUTRITIONS.SUCCESS:
      return { ...state, updatingNutritions: false };
    case UPDATENUTRITIONS.FAILURE:
      return { ...state, updatingNutritions: false };
    case NUTRITIONQUESTIONS.REQUEST:
      return { ...state, sendingNutritionQuestions: true };
    case NUTRITIONQUESTIONS.SUCCESS:
      return { ...state, sendingNutritionQuestions: false };
    case NUTRITIONQUESTIONS.FAILURE:
      return { ...state, sendingNutritionQuestions: false };
    case NUTRITIONAPPLICABLESTATUS.REQUEST:
      return {
        ...state,
        fetchingNutritionsApplicableStatus: true,
        nutritionsApplicableStatusError: false
      };
    case NUTRITIONAPPLICABLESTATUS.SUCCESS:
      return {
        ...state,
        fetchingNutritionsApplicableStatus: false,
        isNutritionDetailsNotApplicable: action.payload,
        nutritionsApplicableStatusError: false
      };
    case NUTRITIONAPPLICABLESTATUS.FAILURE:
      return {
        ...state,
        fetchingNutritionsApplicableStatus: false,
        nutritionsApplicableStatusError: true
      };
    case UPDATENUTRITIONAPPLICABLESTATUS.REQUEST:
      return { ...state, updatingNutritionsApplicableStatus: true };
    case UPDATENUTRITIONAPPLICABLESTATUS.SUCCESS:
      return { ...state, updatingNutritionsApplicableStatus: false, isNutritionDetailsNotApplicable: action.payload };
    case UPDATENUTRITIONAPPLICABLESTATUS.FAILURE:
      return { ...state, updatingNutritionsApplicableStatus: false };
    case PENDINGNUTRITIONS.REQUEST:
      return { ...state, fetchingPentingNutritions: true };
    case PENDINGNUTRITIONS.SUCCESS:
      return {
        ...state,
        fetchingPentingNutritions: false,
        pendingNutrition: { ...state.pendingNutrition, ...action.payload.pendingNutrition },
        pendingNutritions: [...action.payload.pendingNutritions]
      };
    case PENDINGNUTRITIONS.FAILURE:
      return { ...state, fetchingPentingNutritions: false };
    case CANCELNUTRITIONUPDATE.REQUEST:
      return { ...state, cancelingNutritionUpdate: true };
    case CANCELNUTRITIONUPDATE.SUCCESS:
      let rejectedNutritions = _.filter(state.rejectedNutritions, obj => obj.id !== action.payload.commentId);
      return { ...state, cancelingNutritionUpdate: false, rejectedNutritions };
    case CANCELNUTRITIONUPDATE.FAILURE:
      return { ...state, cancelingNutritionUpdate: false };
    case SETSYNCEDNUTRITIONS.REQUEST:
      const { traits, claims, organicTradeItemCode } = action;
      return { ...state, syncedNutritions: { ...state.syncedNutritions, traits, claims, organicTradeItemCode } };
    case GDSNFNB.REQUEST:
      return { ...state, fnb: { ...state.fnb, isFetchingGdsnFnb: true, gdsnFnb: {}, isGdsnFetchedOnce: true } };
    case GDSNFNB.SUCCESS:
      return {
        ...state,
        fnb: { ...state.fnb, isFetchingGdsnFnb: false, gdsnFnb: action.payload, isGdsnFetchedOnce: true }
      };
    case GDSNFNB.FAILURE:
      return { ...state, fnb: { ...state.fnb, isFetchingGdsnFnb: false }, isGdsnFetchedOnce: false };
    case COREDATA.REQUEST:
      return { ...state, coreData: { ...action.payload }, originalCoreDataValues: { ...action.payload } };
    case COREDATACHANGED.REQUEST:
      const { field, value, errorMessage, existingValue, isChangedByValidation } = action;
      const { isChangedByValidation: changedCheckValue, ...restCommentAttributeData } =
        state.coreData.comment[field] || {};
      let updatingData = { ...restCommentAttributeData, value, existingValue };
      if (isChangedByValidation) updatingData = { ...updatingData, isChangedByValidation };
      if (errorMessage) {
        updatingData = { ...updatingData, errorMessage };
      } else if (state.coreData.comment[field] && state.coreData.comment[field].isRejected && !isChangedByValidation) {
        const { errorMessage, ...rest } = updatingData;
        updatingData = { ...rest, isFixed: true, isRejected: false, isRejectedFixed: true };
      } else {
        const { errorMessage, ...rest } = updatingData;
        updatingData = { ...rest };
      }
      return {
        ...state,
        coreData: {
          ...state.coreData,
          comment: {
            ...state.coreData.comment,
            [field]: {
              ...updatingData
            }
          }
        },
        isCoreDataSubmitable: true
      };
    case COREDATACOMMENT.REQUEST:
      return { ...state, isCoreDataSubmitting: true };
    case COREDATACOMMENT.SUCCESS:
      return {
        ...state,
        coreData: { ...action.payload },
        originalCoreDataValues: { ...action.payload },
        isCoreDataSubmitting: false,
        isCoreDataSubmitable: false
      };
    case COREDATACOMMENT.FAILURE:
      return { ...state, isCoreDataSubmitting: false };
    case RESETCURRENTCOREDATACHANGES.REQUEST:
      return {
        ...state,
        coreData: { ...state.originalCoreDataValues },
        isCoreDataSubmitable: false,
        cooDatas: JSON.parse(JSON.stringify(state.originalCooDatas)),
        isCooDataSubmitable: false,
        isCooDataSubmitting: false
      };
    case NUTRITIONUPDATE.REQUEST:
      const { type, ...rest } = action;
      return {
        ...state,
        pendingNutrition: {
          ...state.pendingNutrition,
          ...rest
        }
      };
    case UPDATENUTRITIONFIELDERRORS.REQUEST:
      const { updatedErrors } = action;
      return {
        ...state,
        nutritionErrors: { ...updatedErrors }
      };
    case UPDATETAXONOMYCHANGESWHOLE.REQUEST:
      const { updatedTaxonomyData } = action;
      return {
        ...state,
        taxonomyChanges: [...updatedTaxonomyData]
      };
    case UPDATENUTRITIONTAXONOMYSYNCHEDCHANGES.REQUEST:
      const { type: actionType, ...restData } = action;
      return {
        ...state,
        ...restData
      };
    case MDMITEMMASTER.REQUEST: {
      return {
        ...state,
        itemMasterData: { fetching: true }
      };
    }
    case MDMITEMMASTER.SUCCESS: {
      return {
        ...state,
        itemMasterData: { ...action.payload, fetching: false }
      };
    }
    case MDMITEMMASTER.FAILURE: {
      return {
        ...state,
        itemMasterData: { fetching: false }
      };
    }
    case UNSAVEDPREFERREDIMGS.REQUEST: {
      return {
        ...state,
        imageTab: {
          ...state.imageTab,
          unsavedPreferredImgs: action.payload.preferred
        }
      };
    }
    case SUBMITPREFERREDIMAGES.REQUEST: {
      return {
        ...state,
        imageTab: {
          ...state.imageTab,
          submittingPreferredImgs: true
        }
      };
    }
    case SUBMITPREFERREDIMAGES.SUCCESS: {
      return {
        ...state,
        imageTab: {
          ...state.imageTab,
          submittingPreferredImgs: false,
          unsavedPreferredImgs: {},
          pendingPreferredImgs: action.payload
        }
      };
    }
    case SUBMITPREFERREDIMAGES.FAILURE: {
      return {
        ...state,
        imageTab: {
          ...state.imageTab,
          submittingPreferredImgs: false
        }
      };
    }
    case FETCHITEMVIEWEXCLUSIONS.FAILURE: {
      return {
        ...state,
        feeExclusions: []
      };
    }
    case FETCHITEMVIEWEXCLUSIONS.SUCCESS: {
      return {
        ...state,
        feeExclusions: action.payload
      };
    }
    case COODATACHANGED.REQUEST:
      const { key, cooValue, activityIndex, countryIndex, errMsg } = action;
      const newCooDatas = [...state.cooDatas];
      if (key === ACTIVITY_TYPE_KEY || key === REGION_DESC_KEY) {
        let oldData = newCooDatas[activityIndex][key];
        newCooDatas[activityIndex][key] = {
          ...oldData,
          value: cooValue,
          errMsg
        };
      } else {
        let oldData = newCooDatas[activityIndex].country[countryIndex][key];
        newCooDatas[activityIndex].country[countryIndex][key] = {
          ...oldData,
          value: cooValue,
          errMsg
        };
      }
      return {
        ...state,
        cooDatas: newCooDatas,
        isCooDataSubmitable: true
      };
    case COOACTIVITYADD.REQUEST:
      let activityAdded = [...state.cooDatas];
      activityAdded.push(JSON.parse(JSON.stringify(emptyCooData)));
      return {
        ...state,
        cooDatas: activityAdded,
        isCooDataSubmitable: true
      };
    case COOACTIVITYDEL.REQUEST:
      let activityDel = [...state.cooDatas];
      activityDel.splice(action.activityIndex, 1);
      return {
        ...state,
        cooDatas: activityDel,
        isCooDataSubmitable: true
      };
    case COOCOUNTRYADD.REQUEST:
      let countryAdded = [...state.cooDatas];
      countryAdded[action.activityIndex].country.push({ ...emptyCooCountryData });
      return {
        ...state,
        cooDatas: countryAdded,
        isCooDataSubmitable: true
      };
    case COOCOUNTRYDEL.REQUEST:
      let countryDel = [...state.cooDatas];
      countryDel[action.activityIndex].country.splice(action.countryIndex, 1);
      return {
        ...state,
        cooDatas: countryDel,
        isCooDataSubmitable: true
      };
    case COODATAPUSHED.REQUEST:
      return { ...state, isCooDataSubmitting: true };
    case COODATAPUSHED.SUCCESS:
      return {
        ...state,
        cooDatas: JSON.parse(JSON.stringify(action.payload)),
        originalCooDatas: JSON.parse(JSON.stringify(action.payload)),
        isCooDataSubmitable: false,
        isCooDataSubmitting: false
      };
    case COODATAPUSHED.FAILURE:
      return { ...state, isCooDataSubmitting: false };
    case ITEMCOO.REQUEST:
      return {
        ...state,
        fetchingCooData: true,
        cooDatas: [],
        originalCooDatas: [],
        isCooDataSubmitable: false,
        isCooDataSubmitting: false
      };
    case ITEMCOO.SUCCESS:
      const cooData = formatCooData(action.payload.cooData, action.payload.syscoBrand, action.payload.gdsn);
      return {
        ...state,
        fetchingCooData: false,
        cooDatas: JSON.parse(JSON.stringify(cooData)),
        originalCooDatas: JSON.parse(JSON.stringify(cooData))
      };
    case ITEMCOO.FAILURE:
      return {
        ...state,
        fetchingCooData: false,
        cooDatas: [],
        originalCooDatas: [],
        isCooDataSubmitable: false,
        isCooDataSubmitting: false
      };
    case PFAS.REQUEST:
      return { ...state, isLoadingPfas: true };
    case PFAS.SUCCESS:
      const pfasClaimSelectedValue = getPfasClaimValue(state.taxonomy.attributes, 'pfas');
      const latestChanges = { ...getDefaultValuesForPfas(action.payload.attributes, pfasClaimSelectedValue) };
      return {
        ...state,
        isLoadingPfas: false,
        pfas: { ...state.pfas, ...action.payload, changes: { ...latestChanges } },
        taxonomy: {
          ...state.taxonomy,
          attributes: [
            ...getDefaultValueForPfasClaim(state.taxonomy.attributes, action.payload.attributes, latestChanges)
          ]
        }
      };
    case PFAS.FAILURE:
      return { ...state, isLoadingPfas: false };
    case PFASSELECTIONCHANGED.REQUEST:
      const { attrId, value: selectedPfasValue } = action;
      return {
        ...state,
        pfas: {
          ...state.pfas,
          changes: { ...getPfasChangeStructure(state.pfas.changes, attrId, selectedPfasValue) },
          isMassNotApplicable: false
        }
      };
    case PFASCLEARSELECTIONS.REQUEST:
      return {
        ...state,
        pfas: {
          ...state.pfas,
          ...action.payload,
          changes: { ...getDefaultValuesForPfas(state.pfas.attributes) },
          changesBackup: {},
          isMassNotApplicable: false
        }
      };

    case PFASMASSNOTAPPLICABLE.REQUEST:
      const { isChecked } = action;
      let changes = { ...getDefaultValuesForPfas(state.pfas.attributes) };
      if (isChecked) changes = { ...getPfasMassNotApplicableChanges(state) };
      return {
        ...state,
        pfas: { ...state.pfas, changesBackup: { ...state.pfas.change }, changes, isMassNotApplicable: isChecked }
      };
    case PFASUPDATE.REQUEST:
      return { ...state, pfas: { ...state.pfas, isLoadingOrSaving: true } };
    case PFASUPDATE.SUCCESS:
      const updatedChanges = getDefaultValuesForPfas(action.payload.attributes);
      return {
        ...state,
        pfas: {
          ...state.pfas,
          ...action.payload,
          changes: { ...updatedChanges },
          changesBackup: {},
          isLoadingOrSaving: false
        },
        taxonomy: {
          ...state.taxonomy,
          attributes: [...getDefaultValueForPfasClaim(state.taxonomy.attributes, state.pfas.attributes, updatedChanges)]
        }
      };
    case PFASUPDATE.FAILURE:
      return { ...state, pfas: { ...state.pfas, isLoadingOrSaving: false } };
    case FSMA.REQUEST:
      return { ...state, fsma: { ...state.fsma, attributes: [], isLoadingFsma: true } };
    case FSMA.SUCCESS:
      return { ...state, fsma: { ...state.fsma, attributes: action.payload, isLoadingFsma: false } };
    case FSMA.FAILURE:
      return { ...state, fsma: { ...state.fsma, isLoadingFsma: false } };
    case FSMACHANGES.REQUEST:
      return { ...state, fsma: { ...state.fsma, changes: action.payload } };
    case REGULATORYSUBTABCHANGE.REQUEST:
      return { ...state, regulatorySubTab: action.payload };
    case SAVEFSMACHANGES.REQUEST:
      return { ...state, fsma: { ...state.fsma, isSavingFsma: true } };
    case SAVEFSMACHANGES.SUCCESS:
      return { ...state, fsma: { ...state.fsma, attributes: action.payload, changes: [], isSavingFsma: false } };
    case SAVEFSMACHANGES.FAILURE:
      return { ...state, fsma: { ...state.fsma, isSavingFsma: false } };
    case PACKAGINGSUBTABCHANGE.REQUEST:
      return { ...state, packagingTab: { ...state.packagingTab, selectedTab: action.payload } };
    case PACKAGE.REQUEST:
      return { ...state, packagingTab: { ...state.packagingTab, isLoading: true } };
    case PACKAGE.SUCCESS:
      return { ...state, packagingTab: setFetchedPackagingData(_.cloneDeep(state.packagingTab), action.payload) };
    case PACKAGE.FAILURE:
      return { ...state, packagingTab: { ...state.packagingTab, isLoading: false } };
    case ADDPACKAGINGLEVEL.REQUEST:
      return {
        ...state,
        packagingTab: {
          ...state.packagingTab,
          currentPackaging: addNewPackagingLevel(state.packagingTab.currentPackaging)
        }
      };
    case PACKAGECHANGES.REQUEST:
      const { currentPackaging, hasPackagingChanges } = changePackagingValues(
        state.packagingTab.originalPackaging,
        state.packagingTab.currentPackaging,
        action.payload
      );
      return {
        ...state,
        packagingTab: {
          ...state.packagingTab,
          currentPackaging,
          hasPackagingChanges
        }
      };
    case SAVEPACKAGECHANGES.REQUEST:
      return { ...state, packagingTab: { ...state.packagingTab, isSavingPackaging: true } };
    case SAVEPACKAGECHANGES.SUCCESS:
      return {
        ...state,
        packagingTab: { ...updatePackagingData(action.payload, state.packagingTab), isSavingPackaging: false }
      };
    case SAVEPACKAGECHANGES.FAILURE:
      return { ...state, packagingTab: { ...state.packagingTab, isSavingPackaging: false } };
    case RESETPACKAGING.REQUEST:
      return { ...state, packagingTab: resetPackagingData(action.payload, state.packagingTab) };
    case PACKAGECERTIFICATIONCHANGES.REQUEST:
      const { currentCertifications, hasCertificationChanges } = changePackagingCertificationValues(
        state.packagingTab.originalCertifications,
        state.packagingTab.currentCertifications,
        action.payload
      );
      return {
        ...state,
        packagingTab: {
          ...state.packagingTab,
          currentCertifications,
          hasCertificationChanges
        }
      };
    case DELETEPACKAGINGLEVEL.REQUEST:
      return {
        ...state,
        packagingTab: removePackagingLevel(state.packagingTab, action.payload)
      };
    default:
      return { ...state };
  }
};

const resetPackagingData = (type, packagingTab) => {
  let clone;

  if (type === 'PACKAGING') {
    let packagingTabKey = packagingTab.packagingTabKey + 1;
    clone = {
      ...packagingTab,
      currentPackaging: _.cloneDeep(packagingTab.originalPackaging),
      hasPackagingChanges: false,
      packagingTabKey,
      deletedSections: []
    };
  } else {
    let certificationTabKey = packagingTab.certificationTabKey + 1;
    clone = {
      ...packagingTab,
      currentCertifications: _.cloneDeep(packagingTab.originalCertifications),
      hasCertificationChanges: false,
      certificationTabKey
    };
  }

  return clone;
};

const updatePackagingData = (type, packagingTab) => {
  let clone;

  if (type === 'PACKAGING') {
    clone = {
      ...packagingTab,
      originalPackaging: _.cloneDeep(packagingTab.currentPackaging),
      hasPackagingChanges: false
    };
  } else {
    clone = {
      ...packagingTab,
      originalCertifications: _.cloneDeep(packagingTab.currentCertifications),
      hasCertificationChanges: false
    };
  }

  return clone;
};

const setFetchedPackagingData = (packagingTab, fetchedData) => {
  const originalPackaging = {
    sections: fetchedData.packaging,
    packagingProductHierarchyLevel: fetchedData.packagingProductHierarchyLevel
  };
  const originalCertifications = fetchedData.certifications;

  packagingTab.currentPackaging = _.cloneDeep(originalPackaging);
  packagingTab.currentCertifications = _.cloneDeep(originalCertifications);
  packagingTab.originalPackaging = originalPackaging;
  packagingTab.originalCertifications = originalCertifications;
  packagingTab.isLoading = false;

  return packagingTab;
};

const addNewPackagingLevel = currentPackaging => {
  const empty = _.map(currentPackaging.sections[0], section => {
    return {
      ...section,
      decidedValue: null
    };
  });

  const sectionsClone = [...currentPackaging.sections];
  sectionsClone.push(empty);

  return { ...currentPackaging, sections: sectionsClone };
};

const removePackagingLevel = (packagingTab, payload) => {
  const { index, deleted } = payload;
  let tabClone = _.cloneDeep(packagingTab);

  if (!_.isEmpty(deleted)) {
    const diff = _.difference(deleted, tabClone.deletedSections);
    tabClone.deletedSections = [...tabClone.deletedSections, ...diff];
  }

  // Remove section from currentPackaging sections
  const updatedSections = _.filter(tabClone.currentPackaging.sections, (value, i) => i !== index);
  tabClone.packagingTabKey = tabClone.packagingTabKey + 1; // Rerenders tab
  tabClone.currentPackaging.sections = updatedSections;

  const { hasChanges } = checkForPackagingChanges(tabClone.originalPackaging, tabClone);
  tabClone.hasPackagingChanges = hasChanges;

  return tabClone;
};

const changePackagingValues = (originalPackaging, currentPackaging, changes) => {
  const { sectionIndex, stepId, value } = changes;

  const clone = _.cloneDeep(currentPackaging);

  if (stepId === 'packaging_product_hierarchy_level') {
    const decidedValue = _.find(clone.packagingProductHierarchyLevel.picklist, pick => pick.valueId === value);
    clone.packagingProductHierarchyLevel = { ...clone.packagingProductHierarchyLevel, decidedValue };
  } else {
    const section = _.get(clone, `sections[${sectionIndex}]`, null);

    if (section) {
      const attribute = _.find(section, { stepId });

      if (attribute) {
        if (_.isEmpty(attribute.picklist)) {
          attribute.decidedValue = value;
        } else if (_.includes(PACKAGING_MULTIVALUE_FIELD_IDS, stepId)) {
          const decidedValue = _.map(value, val => _.find(attribute.picklist, pick => pick.valueId === val));
          attribute.decidedValue = decidedValue;
        } else {
          const decidedValue = _.find(attribute.picklist, pick => pick.valueId === value);
          attribute.decidedValue = decidedValue;
        }
      }
    }
  }

  const { hasChanges } = checkForPackagingChanges(originalPackaging, clone);
  return { currentPackaging: clone, hasPackagingChanges: hasChanges };
};

const changePackagingCertificationValues = (originalCertifications, currentCertifications, changes) => {
  const { stepId, value } = changes;

  const clone = _.cloneDeep(currentCertifications);

  const attribute = _.find(clone, { stepId });

  if (attribute) {
    const decidedValue = _.find(attribute.picklist, pick => pick.valueId === value);
    attribute.decidedValue = decidedValue;
    clone[stepId] = attribute;
  }

  const { hasChanges } = checkForPackagingChanges(originalCertifications, clone);
  return { currentCertifications: clone, hasCertificationChanges: hasChanges };
};

const getImagesWithIncorrectNames = images => {
  let incorrect = [];

  _.forEach(images, img => {
    const isValid = isValidImageName(img.name);
    if (!isValid) incorrect.push(img.assetId);
  });

  return incorrect;
};

const getPfasMassNotApplicableChanges = ({ pfas: { attributes }, taxonomy }) => {
  let changes = {};
  attributes.forEach(({ attrId, picklist, name }) => {
    const { disabledTooltip } = checkIfPfasAttributeIsDisabled(taxonomy, { name });
    if (!disabledTooltip) {
      const [{ id }] = picklist.filter(({ value }) => value === PFAS_MASS_NOT_APPLICABLE_VALUE);
      changes = {
        ...changes,
        [attrId]: [id]
      };
    }
  });
  return changes;
};

const getPfasChangeStructure = (changes, attributeId, value) => {
  if (!changes[attributeId]) return { ...changes, [attributeId]: [...value] };
  return { ...changes, [attributeId]: [...value] };
};

const defaultCooData = [
  {
    product_activity_type_code: {
      key: 'product_activity_type_code',
      caption: 'Product Activity Type',
      value: '',
      existingValue: '',
      errMsg: ''
    },
    product_activity_region_description: {
      key: 'product_activity_region_description',
      caption: 'Product Activity Region Description',
      value: '',
      existingValue: '',
      errMsg: ''
    },
    country: [
      {
        country_of_activity_code: {
          key: 'country_of_activity_code',
          caption: 'Country of Activity Code',
          value: '',
          existingValue: '',
          errMsg: ''
        },
        country_of_activity_subdivision_code: {
          key: 'country_of_activity_subdivision_code',
          caption: 'Country of Activity Subdivision Code',
          value: '',
          existingValue: '',
          errMsg: ''
        }
      }
    ]
  }
];

const formatCooData = (countryOfOrigin, syscoBrand, gdsn) => {
  let result = [];
  countryOfOrigin.forEach(item => {
    const activityType = item.product_activity_type_code || '';
    let regionDesc = '';
    if (item.product_activity_region_description && item.product_activity_region_description.length) {
      regionDesc = item.product_activity_region_description.join(',');
    }
    let activityCode = '';
    if (item.country_of_activity_code) activityCode = item.country_of_activity_code.value_id || '';
    if (!activityCode && (syscoBrand === 'Y' || gdsn === 'Y'))
      activityCode = item.country_of_origin_code.value_id || '';
    if (activityType || gdsn == 'Y' || syscoBrand == 'Y') {
      let country = [];
      let coaSubCode = [];
      if (item.country_of_activity_subdivision_code && item.country_of_activity_subdivision_code.length)
        coaSubCode = item.country_of_activity_subdivision_code;
      if (
        coaSubCode.length === 0 &&
        syscoBrand === 'Y' &&
        item.country_of_origin_subdivision_code &&
        item.country_of_origin_subdivision_code.length
      )
        coaSubCode = item.country_of_origin_subdivision_code;
      if (coaSubCode.length) {
        coaSubCode.forEach(subdivisionItem => {
          let activitySubCode = subdivisionItem.value_id;
          country.push({
            country_of_activity_code: {
              key: 'country_of_activity_code',
              caption: 'Country of Activity Code',
              value: activityCode,
              existingValue: activityCode,
              errMsg: ''
            },
            country_of_activity_subdivision_code: {
              key: 'country_of_activity_subdivision_code',
              caption: 'Country of Activity Subdivision Code',
              value: activitySubCode,
              existingValue: activitySubCode,
              errMsg: ''
            }
          });
        });
      } else {
        country.push({
          country_of_activity_code: {
            key: 'country_of_activity_code',
            caption: 'Country of Activity Code',
            value: activityCode,
            existingValue: activityCode,
            errMsg: ''
          },
          country_of_activity_subdivision_code: {
            key: 'country_of_activity_subdivision_code',
            caption: 'Country of Activity Subdivision Code',
            value: '',
            existingValue: '',
            errMsg: ''
          }
        });
      }

      const sectionData = {
        product_activity_type_code: {
          key: 'product_activity_type_code',
          caption: 'Product Activity Type',
          value: activityType,
          existingValue: activityType,
          errMsg: ''
        },
        product_activity_region_description: {
          key: 'product_activity_region_description',
          caption: 'Product Activity Region Description',
          value: regionDesc,
          existingValue: regionDesc,
          errMsg: ''
        },
        country
      };

      if (item.source) {
        sectionData.source = item.source;
      }
      if (item.supplier) {
        sectionData.supplier = item.supplier;
      }
      if (item.country_of_origin_code) {
        sectionData.country_of_origin_code = item.country_of_origin_code.value || '';
      }
      if (item.country_of_origin_subdivision_code && item.country_of_origin_subdivision_code.length) {
        sectionData.country_of_origin_subdivision_code = [];
        item.country_of_origin_subdivision_code.forEach(subdivisionItem => {
          sectionData.country_of_origin_subdivision_code.push(subdivisionItem.value);
        });
      }

      result.push(sectionData);
    }
  });
  if (result.length === 0) {
    result = defaultCooData;
  }

  return result;
};

const updatePGMVisibilityStatus = (pgmItems, state) => {
  const getPGMFiltered = f => {
    return _.get(pgmItems, 'data', [])
      .filter(f)
      .map(({ supc }) => supc);
  };
  let addedPGM = getPGMFiltered(({ active }) => active === 1);
  let removedPGM = getPGMFiltered(({ active }) => active !== 1);
  console.log('addedPGM', addedPGM);
  console.log('removedPGM', removedPGM);

  let itemList = _.cloneDeep(_.get(state, 'list', []));

  itemList.forEach(item => {
    if (addedPGM.includes(item.supc)) {
      item.sdpVisible = 1;
    }
  });

  return {
    forcedVisibleSupcs: _.filter(_.uniq([...state.forcedVisibleSupcs, ...addedPGM]), i => !removedPGM.includes(i)),
    list: itemList
  };
};

const pgmItems = (state = initialState.pgmItems, action) => {
  switch (action.type) {
    case PGMITEMS.REQUEST:
      return { ...state, fetchingList: true };
    case PGMITEMS.SUCCESS:
      return {
        ...state,
        list: action.payload.list,
        page: action.payload.page,
        search: action.payload.search,
        recordCount: action.payload.recordCount,
        fetchingList: false,
        visibilityChanges: [],
        forcedVisibilityBannerOn: action.payload.forcedVisibilityBannerOn
      };
    case PGMITEMS.FAILURE:
      return { ...state, fetchingList: false };
    case CHANGEVISIBILITY.REQUEST:
      const visibilityChanges = changeVisibility(state.visibilityChanges, action.payload);
      return { ...state, visibilityChanges };
    case UPDATEVISIBILITY.REQUEST:
      return { ...state, updatingVisibility: true, ...updatePGMVisibilityStatus(action, state) };
    case UPDATEVISIBILITY.SUCCESS:
      const list = updatePgmItemsList(state.list, action.payload);
      return { ...state, list, updatingVisibility: false, visibilityChanges: [] };
    case UPDATEVISIBILITY.FAILURE:
      return { ...state, updatingVisibility: false };
    case FORCEDVISIBLESUPCS.REQUEST:
      return { ...state };
    case FORCEDVISIBLESUPCS.SUCCESS:
      return { ...state, forcedVisibleSupcs: action.payload };
    case FORCEDVISIBLESUPCS.FAILURE:
      return { ...state, updatingVisibility: false };
    default:
      return { ...state };
  }
};

const updatePgmItemsList = (list, updates) => {
  let clone = [...list];

  _.forEach(updates, obj => {
    const index = _.findIndex(clone, item => obj.supc === item.supc);
    if (index !== -1) {
      let item = { ...clone[index], isForcedVisible: obj.active };
      clone[index] = item;
    }
  });

  return clone;
};

const changeVisibility = (current, { supc, active }) => {
  let clone = [...current];
  const index = _.findIndex(clone, obj => supc === obj.supc);

  if (index === -1) {
    clone.push({ supc, active });
  } else {
    clone = _.filter(clone, obj => supc !== obj.supc);
  }

  return clone;
};

const updatePendingPreferredImgs = comments => {
  const preferred = {};

  _.forEach(comments, comment => {
    if (comment.type === 'IMAGE' && comment.status === 0 && comment?.imgComment?.changeType === 'PREFERRED_IMAGE') {
      const meta = JSON.parse(comment.imgComment.meta);
      const styleBucket = meta.styleBucket;
      preferred[styleBucket] = comment.imgComment.otmmAssetId;
    }
  });

  return preferred;
};

export default combineReducers({
  massUpdate,
  user,
  vendor,
  selectedItem,
  comments,
  fieldComment,
  pendingComments,
  page,
  scorecards,
  pgmItems
});
