import React, { useState } from 'react';
import { Button, Input } from 'antd';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import * as actionCreators from '../../actions';
import { getHierarchyFilters } from '../../util/Util';

export default function ColumnSearchBox({ suvc, columnName, hasUnsubmittedData }) {
  const dispatch = useDispatch();
  const { getAttributeMassEditList } = bindActionCreators(actionCreators, dispatch);

  const tableSearchData = useSelector(state => _.get(state.massUpdate.attribute, 'filters.tableSearchData', []));
  const tableFilters = useSelector(state => _.get(state.massUpdate.attribute, 'filters.tableFilters', []));
  const orderBy = useSelector(state => _.get(state.massUpdate.attribute, 'filters.orderBy', null));
  const bc = useSelector(state => _.get(state.massUpdate.attribute, 'filters.bc', null));
  const ig = useSelector(state => _.get(state.massUpdate.attribute, 'filters.ig', null));
  const ag = useSelector(state => _.get(state.massUpdate.attribute, 'filters.ag', null));

  const [tableSearch, setTableSearch] = useState(tableSearchData);

  const targetObject = _.find(tableSearch, obj => columnName === obj.param);
  const value = _.get(targetObject, 'val[0]', '');

  const onTableSearchInputChange = newValue => {
    let clone = [...tableSearch];
    let target = _.find(clone, obj => columnName === obj.param);

    if (!_.isEmpty(newValue)) {
      if (target) {
        target.val = [newValue];

        if (['materialDescription', 'supc', 'manufactProdCode', 'gtin'].includes(columnName)) {
          target.operator = 'like';
        }
      } else {
        if (['materialDescription', 'supc', 'manufactProdCode', 'gtin'].includes(columnName)) {
          clone.push({ param: columnName, val: [newValue], operator: 'like' });
        } else {
          clone.push({ param: columnName, val: [newValue] });
        }
      }
    } else {
      clone = _.filter(clone, obj => columnName !== obj.param);
    }

    setTableSearch(clone);
  };

  const onTableSearch = () => {
    if (!hasUnsubmittedData()) {
      const graphFilter = getHierarchyFilters({ bc, ig, ag });
      getAttributeMassEditList({ suvc, orderBy, graphFilter, tableSearchData: tableSearch, tableFilters });
    }
  };

  const onClearTableSearch = () => {
    if (!hasUnsubmittedData()) {
      const clone = [...tableSearchData];
      const result = _.filter(clone, obj => columnName !== obj.param);
      const graphFilter = getHierarchyFilters({ bc, ig, ag });

      getAttributeMassEditList({ suvc, orderBy, graphFilter, tableSearchData: result, tableFilters });
    }
  };

  return (
    <div className="table-search-dropdown">
      <Input
        className="table-search-input"
        value={value}
        onChange={e => onTableSearchInputChange(e.target.value)}
        onPressEnter={onTableSearch}
      />
      <div className="table-search-btn-wrapper">
        <Button className="table-search-btn" type="primary" onClick={onTableSearch}>
          Search
        </Button>
        <Button className="table-search-btn-reset" onClick={onClearTableSearch}>
          Reset
        </Button>
      </div>
    </div>
  );
}
