import _ from 'lodash';
import { Select, Tooltip } from 'antd';
import React, { useState } from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import * as actionCreators from '../../../actions/index';
import { getEmptyFsmaAttributes } from '../../../util/Util';
import { IFOODS_LINK } from '../../../util/Constants';

const { Option } = Select;
const FSMA_DESCRIPTION = (
  <div className="description">
    Food traceability refers to the process of tracking a food product and its ingredients throughout every stage of the
    supply chain, both upstream and downstream. This involves recording and linking details from production, processing,
    and distribution.
    <br />
    <br />
    FSMA 204 addresses the need for prompt and efficient food tracking and tracing. The rule mandates that entities
    involved in manufacturing, processing, packing, or holding foods listed for traceability must maintain records
    containing Key Data Elements (KDEs) related to Critical Tracking Events (CTEs). They are also required to provide
    this information to the FDA within 24 hours using an electronically sortable spreadsheet.
    <br />
    <br />
    <strong>
      FSMA 204 specifically targets certain foods such as produce, cheese, shell eggs, nut butter, deli salads, and
      seafood, but does not cover all food items.
    </strong>
    <br />
    <br />
    For items identified as Food Traceability enrollment to iFoodDS is mandatory for additional record-keeping and
    compliance with FDA's regulations.{' '}
    <a href={IFOODS_LINK} target="_blank" rel="noopener noreferrer">
      [Link to Enrollment]
    </a>
  </div>
);

export default function FsmaTab({ readOnly }) {
  const dispatch = useDispatch();
  const { setFsmaChanges } = bindActionCreators(actionCreators, dispatch);

  const attributes = useSelector(state => _.get(state, 'selectedItem.fsma.attributes', []));
  const changedValues = useSelector(state => _.get(state, 'selectedItem.fsma.changes', []));
  const isSavingFsma = useSelector(state => _.get(state, 'selectedItem.fsma.isSavingFsma', false));

  const onChange = (key, value) => {
    const clone = [...changedValues];
    const index = _.findIndex(clone, obj => obj.attrId === key);

    if (index !== -1) {
      clone[index] = { attrId: key, selected: value };
    } else {
      clone.push({ attrId: key, selected: value });
    }

    setFsmaChanges(clone);
  };

  const renderTooltip = tooltip => {
    if (!_.isEmpty(tooltip)) {
      return (
        <Tooltip
          overlayClassName={'fsma-tooltip-icon'}
          style={{ width: '100px' }}
          title={
            <div style={{ paddingLeft: '15px', paddingRight: '15px' }} className="gdsn-info">
              {tooltip}
            </div>
          }
        >
          {'i'}
        </Tooltip>
      );
    }
  };

  const renderInputFields = () => {
    const emptyList = getEmptyFsmaAttributes(attributes);
    const disabled = readOnly || isSavingFsma;

    return (
      <div className="input-fields-wrapper">
        {_.map(attributes, obj => {
          const defaultValue = _.get(obj, 'selected[0]', null);
          const target = _.find(changedValues, { attrId: obj.attrId });
          const value = _.get(target, 'selected', defaultValue);
          const isEmpty = _.includes(emptyList, obj.name);

          return (
            <div key={obj.attrId} className="input-field-box">
              <div className="input-field-label">
                {obj.name}
                {renderTooltip(obj.tooltip)}
              </div>
              <Select
                key={obj.attrId}
                className={`input-field ${isEmpty && 'empty-field'}`}
                value={value}
                onChange={val => onChange(obj.attrId, val)}
                disabled={disabled}
              >
                {_.map(obj.picklist, opt => (
                  <Option value={opt.id} key={opt.id}>
                    {opt.value}
                  </Option>
                ))}
              </Select>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="fsma-sub-tab">
      {FSMA_DESCRIPTION}
      {renderInputFields()}
    </div>
  );
}
