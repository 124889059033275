import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import TaxonomyAttributeTab from './TaxonomyAttributeTab';
import {
  ALLERGEN_FREE_FROM_CHECK_BOX_TEXT,
  SUSTAINABILITY_MASS_CHECK_BOX_TITLE,
  SUSTAINABILITY_MASS_CHECK_BOX
} from '../util/Constants';
import {
  isAllergenAttribute,
  isClaimAttribute,
  isDietTypeAttribute,
  getGdsnOrSyscoBrandFlag,
  isCertificationAttribute,
  splitAttributes,
  checkForClaimsSubType
} from '../util/Util';
import MassUpdateCheckBox from './ItemProductAttribution/MassUpdateCheckBox';
import RejectFeedback from './ItemNutritionTab/RejectFeedback';
import SustainabilityMassUpdateCheckBox from './ItemProductAttribution/SustainabilityMassUpdateCheckBox';
import { Tabs, Tooltip } from 'antd';
import _ from 'lodash';

const PRODUCT_ATTRIBUTE_DISABLED_MESSAGES = {
  31: {
    allergens: (
      <p>
        Updates may not be made in this portal to disabled fields.{' '}
        <b>This product is a GDSN-published, national brand item</b>, please update your product data with your data
        pool partner and be sure to publish your changes to Sysco (GLN 0074865000000).
      </p>
    ),
    diet_types: (
      <p>
        Updates may not be made in this portal to disabled fields.{' '}
        <b>This product is a GDSN-published, national brand item</b>, please update your product data with your data
        pool partner and be sure to publish your changes to Sysco (GLN 0074865000000).
      </p>
    ),
    claims: (
      <p>
        Updates may not be made in this portal to disabled fields.{' '}
        <b>This product is a GDSN-published, national brand item</b>, please update this attribute on the
        NutritionalClaimNutrientElementCode and NutritionalClaimTypeCode GS1 attributes with your data pool partner and
        be sure to publish your changes to Sysco (GLN 0074865000000).
      </p>
    )
  },
  23: {
    allergens: (
      <p>
        Updates may not be made in this portal to disabled fields.{' '}
        <b>This product is a Sysco Brand private label item</b>, please update your product data in the Sysco Stibo QA
        specification database. For access questions, contact your Program Quality Manager or email
        fnc_ssdatabase_000@corp.sysco.com.
      </p>
    ),
    diet_types: (
      <p>
        Updates may not be made in this portal to disabled fields.{' '}
        <b>This product is a Sysco Brand private label item</b>, please update your product data in the Sysco Stibo QA
        specification database. For access questions, contact your Program Quality Manager or email
        fnc_ssdatabase_000@corp.sysco.com.
      </p>
    ),
    claims: (
      <p>
        Updates may not be made in this portal to disabled fields.{' '}
        <b>This product is a Sysco Brand private label item</b>, please update your product data in the Sysco Stibo QA
        specification database. For access questions, contact your Program Quality Manager or email
        fnc_ssdatabase_000@corp.sysco.com.
      </p>
    )
  }
};

const TITLE_MAPPER = {
  diettype: 'Diet Types',
  allergen: 'Allergens',
  claim: 'Claims',
  product: 'Product Attributes',
  certification: 'Certifications'
};

class TaxonomyInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      commentButtonShow: false,
      commentDropdownShow: false,
      incompleteAttributes: {}
    };

    for (let i = 1; i < 5; i++) {
      this[`taxonomyAttributeTabRef${i}`] = React.createRef();
    }
    this.enableCommentButton = this.enableCommentButton.bind(this);
    this.enableCommentDropdown = this.enableCommentDropdown.bind(this);
  }

  componentDidMount() {
    this.findIncompleteAttributes();
  }

  componentDidUpdate(prevProps) {
    if (!_.isEqual(prevProps.item?.taxonomy?.attributes, this.props.item?.taxonomy?.attributes)) {
      this.findIncompleteAttributes();
    }
  }

  findIncompleteAttributes = () => {
    const attributes = _.get(this.props, 'item.taxonomy.attributes', []);
    const incompleteAttributes = _.filter(attributes, attr => !attr.selected);

    const {
      dietTypes,
      allergens,
      sustainabilityClaims,
      nutritionalClaims,
      otherAttributes,
      sustainabilityCertifications,
      nutritionalCertifications
    } = splitAttributes(incompleteAttributes);

    this.setState({
      incompleteAttributes: {
        diettype: dietTypes,
        allergen: allergens,
        claim: [...sustainabilityClaims, ...nutritionalClaims],
        product: otherAttributes,
        certification: [...sustainabilityCertifications, ...nutritionalCertifications]
      }
    });
  };

  handleCancel = () => {
    for (let i = 1; i < 5; i++) {
      this[`taxonomyAttributeTabRef${i}`]?.current?.handleCancel() || console.log();
    }
  };

  enableCommentButton() {
    this.setState({
      commentButtonShow: true
    });
  }

  enableCommentDropdown() {
    this.setState({
      commentDropdownShow: true
    });
  }

  getSubSectionDescription = type => {
    let tooltipValue;
    const { item: { nutritions: { nutritionSourceOid } } = { nutritions: {} } } = this.props;
    if (nutritionSourceOid === 23) tooltipValue = PRODUCT_ATTRIBUTE_DISABLED_MESSAGES[23][type];
    else if (nutritionSourceOid === 31) tooltipValue = PRODUCT_ATTRIBUTE_DISABLED_MESSAGES[31][type];
    return tooltipValue;
  };

  massAllergenFreeFromUpdateAvailability = () => {
    const { item: { nutritions: { nutritionSourceOid } } = { nutritions: {} }, basicData = {} } = this.props;
    if (getGdsnOrSyscoBrandFlag(nutritionSourceOid, basicData) === 'gdsn') return true;
  };

  renderTabTitles = type => {
    const title = TITLE_MAPPER[type];
    const attrs = _.get(this.state, `incompleteAttributes[${type}]`, []);
    const count = attrs.length;

    const content = (
      <div>
        <div>Please select values for the below unfilled attributes</div>
        <ul className="tooltip-content">
          {_.map(attrs, attr => (
            <li>{attr.name}</li>
          ))}
        </ul>
      </div>
    );

    return (
      <div className="subtab-title">
        <div>{title}</div>
        <Tooltip overlayClassName="incomplete-attr-tool-tip" title={content} placement="bottom">
          {count > 0 && <div className="incomplete-count">{count}</div>}
        </Tooltip>
      </div>
    );
  };

  render() {
    const { item, actions, pendingComments, nutritionErrors, pendingNutrition } = this.props;
    const { TabPane } = Tabs;
    const isGeneralAttribute = ({ type }) => type === 'product';

    if (!(item.taxonomy && item.taxonomy.supc) || item.fetchingNutritions) {
      // comment item.fetchingNutritions field in above condition when disabling enut application
      return <div className="tab-loading-msg">Loading...</div>;
    }
    if (item.taxonomy.attributes && item.taxonomy.attributes.length === 0) {
      return <div className="tab-loading-msg">Product attributes loading error!</div>;
    }

    let tabs = [
      {
        key: 1,
        title: this.renderTabTitles('product'),
        content: (
          <div className="attr-section">
            <div className="attr-section-title">Product Attributes</div>
            <div className="attr-section-subtitle">
              Special product attributes. You can review the values and update.
            </div>
            <TaxonomyAttributeTab
              item={item}
              actions={actions}
              filter={isGeneralAttribute}
              attrType="General"
              pendingComments={pendingComments}
              ref={this.taxonomyAttributeTabRef1}
              isAvailableForSyscoOrGdsnDisabled
              avoidForOtherValidations
              nutritionErrors={nutritionErrors}
            />
          </div>
        )
      },
      {
        key: 2,
        title: this.renderTabTitles('diettype'),
        content: (
          <div className="attr-section">
            <div className="attr-section-title">Diet Types</div>{' '}
            <div className="attr-section-subtitle subtitle-and-tooltip-container">
              Special diet type attributes. You can review the values and update.
            </div>
            <RejectFeedback field="diet_type" nutritionData={pendingNutrition} />
            <TaxonomyAttributeTab
              item={item}
              actions={actions}
              filter={isDietTypeAttribute}
              attrType="Diet Type"
              pendingComments={pendingComments}
              ref={this.taxonomyAttributeTabRef2}
              isAvailableForSyscoOrGdsnDisabled
            />
          </div>
        )
      },
      {
        key: 3,
        title: this.renderTabTitles('allergen'),
        content: (
          <div className="attr-section">
            <div className="attr-section-title">Allergens</div>
            <div className="attr-section-subtitle">
              <MassUpdateCheckBox
                description={ALLERGEN_FREE_FROM_CHECK_BOX_TEXT}
                item={item}
                filter={isAllergenAttribute}
                isDisabled={this.massAllergenFreeFromUpdateAvailability() || item.readOnly}
                textToFilter="Free From"
                actions={actions}
              />
            </div>
            <RejectFeedback field="allergen" nutritionData={pendingNutrition} />
            <TaxonomyAttributeTab
              item={item}
              actions={actions}
              filter={isAllergenAttribute}
              attrType="Allergen"
              pendingComments={pendingComments}
              ref={this.taxonomyAttributeTabRef3}
              isAvailableForSyscoOrGdsnDisabled
            />
          </div>
        )
      },
      {
        key: 4,
        title: this.renderTabTitles('claim'),
        content: (
          <div>
            <div className="attr-section">
              <div className="attr-section-title">Sustainability Claims</div>
              <div className="attr-section-subtitle subtitle-and-tooltip-container">
                Sustainability claims. You can review the values and update.
              </div>
              <div className="attr-section-subtitle">
                <SustainabilityMassUpdateCheckBox
                  description={SUSTAINABILITY_MASS_CHECK_BOX}
                  item={item}
                  filter={checkForClaimsSubType}
                  isDisabled={item.readOnly}
                  textToFilter="Not"
                  actions={actions}
                  attrType="Claim"
                />
              </div>
              <RejectFeedback field="nutritionalClaimsDetails" nutritionData={pendingNutrition} />
              <RejectFeedback field="organicTradeItemCodes" nutritionData={pendingNutrition} />
              <TaxonomyAttributeTab
                item={item}
                actions={actions}
                filter={isClaimAttribute}
                attrType="Claim"
                subType="sustainability"
                nonCaption="Sustainability Claim"
                pendingComments={pendingComments}
                ref={this.taxonomyAttributeTabRef4}
                isAvailableForSyscoOrGdsnDisabled
                avoidForOtherValidations
              />
            </div>
            <div className="attr-section">
              <div className="attr-section-title">Nutritional Claims</div>
              <div className="attr-section-subtitle subtitle-and-tooltip-container">
                Nutritional claims. You can review the values and update.
              </div>
              <RejectFeedback field="nutritionalClaimsDetails" nutritionData={pendingNutrition} />
              <RejectFeedback field="organicTradeItemCodes" nutritionData={pendingNutrition} />
              <TaxonomyAttributeTab
                item={item}
                actions={actions}
                filter={isClaimAttribute}
                attrType="Claim"
                subType="nutritional"
                nonCaption="Nutritional Claim"
                pendingComments={pendingComments}
                ref={this.taxonomyAttributeTabRef4}
                isAvailableForSyscoOrGdsnDisabled
                avoidForOtherValidations
              />
            </div>
          </div>
        )
      },
      {
        key: 5,
        title: this.renderTabTitles('certification'),
        content: (
          <div>
            <div className="attr-section">
              <div className="attr-section-title">Sustainability Certifications</div>
              <div className="attr-section-subtitle">
                <SustainabilityMassUpdateCheckBox
                  description={SUSTAINABILITY_MASS_CHECK_BOX_TITLE}
                  item={item}
                  filter={isCertificationAttribute}
                  isDisabled={item.readOnly}
                  textToFilter="Not"
                  actions={actions}
                />
              </div>
              <TaxonomyAttributeTab
                item={item}
                actions={actions}
                filter={isCertificationAttribute}
                attrType="Sustainability"
                subType="sustainability"
                nonCaption="Sustainability Certification"
                pendingComments={pendingComments}
                ref={this.taxonomyAttributeTabRef1}
                isAvailableForSyscoOrGdsnDisabled
                avoidForOtherValidations
                nutritionErrors={nutritionErrors}
              />
            </div>
            <div className="attr-section">
              <div className="attr-section-title">Nutritional Certifications</div>
              <div className="attr-section-subtitle subtitle-and-tooltip-container">
                Nutritional certifications. You can review the values and update.
              </div>
              <TaxonomyAttributeTab
                item={item}
                actions={actions}
                filter={isCertificationAttribute}
                attrType="Sustainability"
                subType="nutritional"
                nonCaption="Nutritional Certification"
                pendingComments={pendingComments}
                ref={this.taxonomyAttributeTabRef1}
                isAvailableForSyscoOrGdsnDisabled
                avoidForOtherValidations
                nutritionErrors={nutritionErrors}
              />
            </div>
          </div>
        )
      }
    ];

    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => (
          <div className="attr-panel taxonomy taxonomy-sub-tabs" style={{ height: `${window.innerHeight - 177}px` }}>
            <Tabs tabPosition="top" animated={false}>
              {tabs.map(({ title, key, content }) => (
                <TabPane tab={<span className="inner-tab-taxonomy">{title}</span>} key={key}>
                  {content}
                </TabPane>
              ))}
            </Tabs>
            <div className="attr-wrapper" style={{ height: '50px' }}></div>
          </div>
        )}
      />
    );
  }
}

export default TaxonomyInfo;
