import React from 'react';
import _ from 'lodash';
import { Select } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import TooltipInfo from './PackageTooltipInfo';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../actions/index';

const { Option } = Select;
const basePath = 'selectedItem.packagingTab';

export default function CertificationsTab({ readOnly }) {
  const dispatch = useDispatch();
  const { setPackageCertificationChanges } = bindActionCreators(actionCreators, dispatch);
  const certifications = useSelector(state => _.get(state, `${basePath}.currentCertifications`, []));
  const isSavingPackaging = useSelector(state => _.get(state, `${basePath}.isSavingPackaging`, false));

  const onChange = (stepId, value, sectionIndex) => {
    setPackageCertificationChanges({ stepId, value, sectionIndex });
  };

  const renderInputFields = () => {
    const disabled = readOnly || isSavingPackaging;

    return (
      <div className="attributes-set">
        {_.map(certifications, obj => {
          const defaultValue = _.get(obj, 'decidedValue.valueId', null);
          const isEmpty = _.isEmpty(defaultValue);

          return (
            <div key={obj.stepId} className="attribute-wrapper">
              <div className="attribute-label-wrapper" title={obj.name}>
                <div className="attribute-label" title={obj.name}>
                  {obj.name}
                </div>
                <TooltipInfo stepId={obj.stepId} />
              </div>
              <Select
                className={`packaging-attribute ${isEmpty && 'empty-field'}`}
                defaultValue={defaultValue}
                onChange={val => onChange(obj.stepId, val)}
                disabled={disabled}
              >
                {_.map(obj.picklist, opt => {
                  // Below change is to address an ad-hoc request during UAT
                  const prefix = obj.stepId === 'packaging_how_2_recycle' && opt.valueId !== 'NHL';

                  return (
                    <Option value={opt.valueId} key={opt.valueId}>
                      {`${prefix ? 'How2Recycle Labeled' : ''} ${opt.valueName}`}
                    </Option>
                  );
                })}
              </Select>
            </div>
          );
        })}
      </div>
    );
  };

  if (!_.isEmpty(certifications)) {
    return (
      <div className="packaging-sub-tab">
        <div className="main-title">Packaging Certifications & Standards</div>
        {renderInputFields()}
      </div>
    );
  } else {
    return <div className="packaging-sub-tab">Loading...</div>;
  }
}
