import React from 'react';

import NutritionAnalysisHeader from './NutritionAnalysisHeader';
import NutritionRowData from './NutritionRowData';
import ChildNutritionData from './ChildNutritionData';
// import ManufacturerData from './ManufacturerData';
import TraitData from './Traits/TraitsData';
import ClaimsData from './ClaimsData';
import { organicTradeItemCodes } from '../../enums';

const getOrganicTradeItemArrayDisplayValue = (data, key) => {
  if (data && data[key] && data[key].length) return organicTradeItemCodes[data[key][0]];
  return 'N/A';
};

export default function PendingNutritions({ updatedComment }) {
  const { fieldKey, fieldIndex } = updatedComment;
  let element = null;
  switch (fieldKey) {
    case 'nutritionAnalysisHeader': {
      element = <NutritionAnalysisHeader comment={updatedComment} identifier={fieldKey} key={`header-${fieldKey}`} />;
      break;
    }
    case 'organicTradeItemCodes': {
      element = (
        <NutritionRowData
          fieldKey={fieldKey}
          value={getOrganicTradeItemArrayDisplayValue(updatedComment, fieldKey)}
          key={`organic-${fieldKey}`}
          changedFields={updatedComment.changedFields}
        />
      );
      break;
    }
    case 'slePortionSize': {
      element = <ChildNutritionData comment={updatedComment} mainKey={fieldKey} key={`child-product-${fieldKey}`} />;
      break;
    }
    case 'manufacturer': {
      // element = <ManufacturerData comment={updatedComment} mainKey={fieldKey} key={`manu-${fieldKey}`} />;
      element = null;
      break;
    }
    case 'allergen': {
      const allergens = updatedComment.traits.filter(({ allergenCode }) => allergenCode);
      element = (
        <TraitData
          title="Allergens"
          codeTitle="Allergen"
          data={allergens}
          comment={updatedComment}
          mainKey="traits"
          key={`allergen-${fieldKey}`}
        />
      );
      break;
    }
    case 'diet_type': {
      const dietTypes = updatedComment.traits.filter(({ dietTypeCode }) => dietTypeCode);
      element = (
        <TraitData
          title="Diet Types"
          codeTitle="Diet Type"
          data={dietTypes}
          comment={updatedComment}
          mainKey="traits"
          key={`diet-${fieldKey}`}
        />
      );
      break;
    }
    case 'nutritionalClaimsDetails': {
      element = <ClaimsData comment={updatedComment} mainKey={fieldKey} key={`claims-${fieldKey}`} />;
      break;
    }
    default: {
      element = (
        <NutritionRowData
          fieldKey={fieldKey}
          value={updatedComment[fieldKey]}
          key={`other-${fieldKey}-${fieldIndex}`}
          changedFields={updatedComment.changedFields}
        />
      );
    }
  }
  return element;
}
