import _ from 'lodash';

import initialState from 'store/initialState';
import {
  getSpecificAttributes,
  getGdsnOrSyscoBrandFlag,
  getDisabledOptionsDetailsForGdsnAttributes
} from '../util/Util';
import {
  ATTRIBUTE_FILTER_OPTIONS,
  DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS,
  PFAS_PRODUCT_LEVEL,
  PFAS_CLAIM,
  PFAS_MAPPING_NAMES,
  PFAS_CLAIM_MAPPING_NAMES
} from '../util/Constants';

import {
  MASSUPDATETYPECHANGED,
  RETRIEVEMASSIMAGEUPLOADDATA,
  CLOSEMASSIMAGEUPLOADOVERLAY,
  CHANGEMASSIMAGELOADINGPERCENTAGE,
  MASSIMAGEUPLOADITEMDATALOADEDDONE,
  UPDATEMASSIMAGEUPLOADFIELDS,
  REVERTMASSIMAGEUPLOADSUPCFIELD,
  MASSIMAGEUPLOADSUBMIT,
  MASSIMAGEUPLOADEACHRESPONSE,
  MASSIMAGEUPLOADCOMMENTSRESPONSE,
  COMPLETEMASSIMAGEUPLOADJOB,
  VALIDATEMASSUPLOADIMAGES,
  VALIDATECOMPLETEDMASSUPLOADIMAGES,
  CLEAREACHITEMCHANGESINMASSIMAGES,
  TOGGLEATTRIBUTEMASSEDITMODAL,
  ALLBUSINESSCENTERS,
  ATTRIBUTEMASSEDITFILTERS,
  ATTRIBUTEMASSEDITLIST,
  ATTRIBUTESLIST,
  SELECTATTRIBUTESELECTORVALUE,
  UPDATEATTRIBUTEFILTERS,
  CLEARATTRIBUTESELECTOR,
  APPLYATTRIBUTESELECTIONS,
  ATTRMASSEDITCHECKBOX,
  MASSUPDATEATTRIBUTES,
  ATTRIBUTEMASSUNDO,
  UPDATEINDIVIDUALATTRIBUTE,
  MASSATTRIBUTIONUPDATEALLITEMLIST,
  CLEARMASSIMAGESELECTEDVALUESONVALIDATIONFAILURE,
  MASSATTRIBUTEITEMTAXONOMYSYNCHEDFIELDCHANGED,
  MASSATTRIBUTIONTAXONOMYFETCHEDAFTEREACHTAXONOMYWITHNUTRITIONALCOMMENT,
  TOGGLEPFASMASSEDIT,
  PFASMASSEDITFILTERS,
  ALLBUSINESSCENTERSPFAS,
  PFASMASSEDITLIST,
  PFASMASSEDITCHECKBOX,
  PFASMASSEDITITEMATTRIBUTESELECTION,
  REGULATORYCOMPLIANCESELECTIONCHANGED,
  REGULATORYCOMPLIANCEAPPLYVALUE,
  CLEARMASSPFASAPPLIEDVALUES,
  APPLYPFASMASSFILTEREDVALUES,
  UNDOMASSPFASCHANGES,
  SUBMITMASSPFASCHANGES
} from 'actions/actionTypes';
import { getFormat, getStyleCode, applyMassPfasFilterChanges } from '../util/Util';
import osdNutrition from '../util/osdNutrition';

const updateItemIndexFields = (itemList, supc, updatingData) => {
  const itemIndex = itemList.findIndex(item => item.supc === supc);
  if (itemIndex > -1) {
    const updatedItem = { ...itemList[itemIndex], ...updatingData };
    return [...itemList.slice(0, itemIndex), updatedItem, ...itemList.slice(itemIndex + 1)];
  }
  return itemList;
};

const updateListValuesWithFetchedCommentsData = (itemList, updatingDataSet) => {
  let newUpdatedItemList = [...itemList];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < updatingDataSet.length; i++) {
    const { supc, ...rest } = updatingDataSet[i];
    newUpdatedItemList = [...updateItemIndexFields(newUpdatedItemList, supc, rest)];
  }
  return newUpdatedItemList;
};

const undoItemListNutritionalSynchedStatusField = itemLit => {
  return itemLit.map(({ hasNutritionSynchedTaxonomyAttributeChanges, ...rest }) => {
    return {
      ...rest,
      hasNutritionSynchedTaxonomyAttributeChanges: false
    };
  });
};

const massUpdate = (state = initialState.massUpdate, action) => {
  switch (action.type) {
    case MASSUPDATETYPECHANGED.REQUEST: {
      const { massUpdateType } = action;
      return {
        ...state,
        updateType: massUpdateType
      };
    }
    case RETRIEVEMASSIMAGEUPLOADDATA.REQUEST: {
      const { supcs } = action;
      return {
        ...state,
        image: setImageSupcs(supcs),
        isLoadingContent: true,
        loadingPercentage: 0
      };
    }
    case CLOSEMASSIMAGEUPLOADOVERLAY.REQUEST: {
      return {
        ...state,
        image: {}
      };
    }
    case CHANGEMASSIMAGELOADINGPERCENTAGE.REQUEST: {
      const {
        payload: { percentage, supc, ...rest }
      } = action;
      return {
        ...state,
        loadingPercentage: percentage,
        image: setImageData(state.image, supc, rest)
      };
    }
    case MASSIMAGEUPLOADITEMDATALOADEDDONE.REQUEST: {
      return {
        ...state,
        isLoadingContent: false,
        loadingPercentage: 0
      };
    }
    case UPDATEMASSIMAGEUPLOADFIELDS.REQUEST: {
      const { supc, fieldType, type, ...rest } = action;
      return {
        ...state,
        image: {
          ...state.image,
          [supc]: {
            ...state.image[supc],
            changes: {
              ...state.image[supc].changes,
              [fieldType]: {
                ...state.image[supc].changes[fieldType],
                ...rest,
                formattedImageName: generateFormattedImageName({
                  ...state.image[supc].changes[fieldType],
                  ...rest
                })
              }
            }
          }
        }
      };
    }
    case MASSIMAGEUPLOADEACHRESPONSE.REQUEST: {
      return {
        ...state
      };
    }
    case REVERTMASSIMAGEUPLOADSUPCFIELD.REQUEST: {
      const { supc, fieldType } = action;
      return {
        ...state,
        image: {
          ...state.image,
          [supc]: {
            ...state.image[supc],
            changes: {
              ...state.image[supc].changes,
              [fieldType]: {}
            }
          }
        }
      };
    }
    case MASSIMAGEUPLOADSUBMIT.REQUEST: {
      return {
        ...state,
        isUploading: true
      };
    }
    case COMPLETEMASSIMAGEUPLOADJOB.REQUEST: {
      return {
        ...state,
        isUploading: false
      };
    }
    case MASSIMAGEUPLOADCOMMENTSRESPONSE.REQUEST: {
      const { supc, type, ...rest } = action;
      return {
        ...state,
        image: {
          ...state.image,
          [supc]: {
            ...state.image[supc],
            changes: {
              ...initialChangesObject
            },
            ...rest
          }
        }
      };
    }
    case VALIDATECOMPLETEDMASSUPLOADIMAGES.REQUEST: {
      const { supc, isValidating } = action;
      return {
        ...state,
        image: {
          ...state.image,
          [supc]: {
            ...state.image[supc],
            isValidating
          }
        }
      };
    }
    case CLEAREACHITEMCHANGESINMASSIMAGES.REQUEST: {
      const { supc } = action;
      return {
        ...state,
        image: {
          ...state.image,
          [supc]: {
            ...state.image[supc],
            changes: {
              ...initialChangesObject
            }
          }
        }
      };
    }
    case VALIDATEMASSUPLOADIMAGES.REQUEST: {
      const { supc, isValidating } = action;
      return {
        ...state,
        image: {
          ...state.image,
          [supc]: {
            ...state.image[supc],
            isValidating
          }
        }
      };
    }
    case TOGGLEATTRIBUTEMASSEDITMODAL.REQUEST: {
      return {
        ...state,
        attribute: {
          ...initialState.massUpdate.attribute,
          showModal: action.payload.showModal,
          taxonomy: _.get(action.payload, 'taxonomy', {}),
          filters: {
            ...initialState.massUpdate.attribute.filters,
            bc: _.get(action.payload, 'bc', null),
            ig: _.get(action.payload, 'ig', null),
            ag: _.get(action.payload, 'ag', null)
          }
        }
      };
    }
    case TOGGLEPFASMASSEDIT.REQUEST: {
      const {
        payload: { showModal, bc = null, ig = null, ag = null, taxonomy = {} }
      } = action;
      return {
        ...state,
        pfas: {
          ...initialState.massUpdate.pfas,
          showModal,
          taxonomy,
          filters: {
            ...initialState.massUpdate.pfas.filters,
            bc,
            ig,
            ag
          }
        }
      };
    }
    case ALLBUSINESSCENTERS.REQUEST: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          fetchingTaxonomy: true,
          completeTaxonomy: []
        }
      };
    }
    case ALLBUSINESSCENTERS.SUCCESS: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          fetchingTaxonomy: false,
          completeTaxonomy: action.payload
        }
      };
    }
    case ALLBUSINESSCENTERS.FAILURE: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          fetchingTaxonomy: false
        }
      };
    }
    case ATTRIBUTEMASSEDITFILTERS.REQUEST: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          filters: { ...state.attribute.filters, ...action.payload }
        }
      };
    }
    case ATTRIBUTEMASSEDITLIST.REQUEST: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          fetchingList: true,
          list: [],
          selectedRows: []
        }
      };
    }
    case MASSATTRIBUTIONUPDATEALLITEMLIST.REQUEST: {
      return {
        ...state,
        list: [...action.updatedItemList]
      };
    }
    case ATTRIBUTEMASSEDITLIST.SUCCESS: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          fetchingList: false,
          list: action.payload.list,
          pagination: {
            ...state.attribute.pagination,
            page: action.payload.page,
            recordsCount: action.payload.recordsCount
          },
          filters: {
            ...state.attribute.filters,
            orderBy: action.payload.orderBy,
            tableSearchData: action.payload.tableSearchData,
            tableFilters: action.payload.tableFilters
          }
        }
      };
    }
    case CLEARMASSIMAGESELECTEDVALUESONVALIDATIONFAILURE.REQUEST: {
      const { supc, imageField } = action;
      const { imageAngle, imageFacing, imageStyle, imageType, ...rest } = state.image[supc].changes[imageField];
      return {
        ...state,
        image: {
          ...state.image,
          [supc]: {
            ...state.image[supc],
            changes: {
              ...state.image[supc].changes,
              [imageField]: {
                ...rest
              }
            }
          }
        }
      };
    }
    case ATTRIBUTEMASSEDITLIST.FAILURE: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          fetchingList: false
        }
      };
    }
    case ATTRIBUTESLIST.REQUEST: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          fetchingAttributes: true,
          attributes: []
        }
      };
    }
    case ATTRIBUTESLIST.SUCCESS: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          fetchingAttributes: false,
          attributes: getAttributesList(action.payload)
        }
      };
    }
    case ATTRIBUTESLIST.FAILURE: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          fetchingAttributes: false
        }
      };
    }
    case SELECTATTRIBUTESELECTORVALUE.REQUEST: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          selectedValues: setAttributeSelectorValue({
            ...action.payload,
            selectedValues: state.attribute.selectedValues
          })
        }
      };
    }
    case UPDATEATTRIBUTEFILTERS.REQUEST: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          filters: { ...state.attribute.filters, attributeFilters: [...action.payload.filters] }
        }
      };
    }
    case CLEARATTRIBUTESELECTOR.REQUEST: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          selectedValues: []
        }
      };
    }
    case APPLYATTRIBUTESELECTIONS.REQUEST: {
      const { selectedRows, list, selectedValues, appliedValues, attributes } = state.attribute;
      return {
        ...state,
        attribute: {
          ...state.attribute,
          appliedValues: getAppliedAttributeValues(appliedValues, selectedRows, list, selectedValues, attributes)
        }
      };
    }
    case ATTRMASSEDITCHECKBOX.REQUEST: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          selectedRows: action.payload
        }
      };
    }
    case MASSUPDATEATTRIBUTES.REQUEST: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          saving: true
        }
      };
    }
    case MASSUPDATEATTRIBUTES.SUCCESS: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          saving: false,
          appliedValues: removeSuccessFromAppliedAttributesList(state.attribute.appliedValues, action.payload.success),
          list: updateMassEditItemsList(action.payload.success, state.attribute.list, state.attribute.appliedValues)
        }
      };
    }
    case MASSUPDATEATTRIBUTES.FAILURE: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          saving: false
        }
      };
    }
    case MASSATTRIBUTEITEMTAXONOMYSYNCHEDFIELDCHANGED.REQUEST: {
      const { type, supc, ...rest } = action;
      return {
        ...state,
        attribute: {
          ...state.attribute,
          list: updateItemIndexFields(state.attribute.list, supc, rest)
        }
      };
    }
    case ATTRIBUTEMASSUNDO.REQUEST: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          appliedValues: undoAppliedAttributes(state.attribute.selectedRows, state.attribute.appliedValues),
          list: [...undoItemListNutritionalSynchedStatusField(state.attribute.list)]
        }
      };
    }
    case UPDATEINDIVIDUALATTRIBUTE.REQUEST: {
      const { supc, attributeId, value } = action.payload;
      return {
        ...state,
        attribute: {
          ...state.attribute,
          appliedValues: updateAppliedAttributes(state.attribute.appliedValues, supc, attributeId, value)
        }
      };
    }
    case MASSATTRIBUTIONTAXONOMYFETCHEDAFTEREACHTAXONOMYWITHNUTRITIONALCOMMENT.SUCCESS: {
      return {
        ...state,
        attribute: {
          ...state.attribute,
          list: [...updateListValuesWithFetchedCommentsData(state.attribute.list, action.payload)]
        }
      };
    }
    case PFASMASSEDITFILTERS.REQUEST: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          filters: { ...state.pfas.filters, ...action.payload }
        }
      };
    }
    case ALLBUSINESSCENTERSPFAS.REQUEST: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          fetchingTaxonomy: true,
          completeTaxonomy: []
        }
      };
    }
    case ALLBUSINESSCENTERSPFAS.SUCCESS: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          fetchingTaxonomy: false,
          completeTaxonomy: action.payload
        }
      };
    }
    case ALLBUSINESSCENTERSPFAS.FAILURE: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          fetchingTaxonomy: false
        }
      };
    }
    case PFASMASSEDITLIST.REQUEST: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          isLoading: true,
          list: [],
          selectedRows: [],
          pfasAttributes: []
        }
      };
    }
    case PFASMASSEDITLIST.SUCCESS: {
      const {
        payload: { list, page, recordsCount, orderBy, tableSearchData, tableFilters }
      } = action;
      return {
        ...state,
        pfas: {
          ...state.pfas,
          list: [...list],
          pagination: {
            ...state.pfas.pagination,
            page,
            recordsCount
          },
          filters: {
            ...state.pfas.filters,
            orderBy,
            tableSearchData,
            tableFilters,
            attributes: {
              ...state.pfas.filters.attributes,
              PFAS: [...getPfasAttributesAndPicklists(list)]
            }
          },
          isLoading: false,
          changes: { ...generateDefaultPfasChanges(list) }
        }
      };
    }
    case PFASMASSEDITLIST.FAILURE: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          isLoading: false,
          pfasAttributes: [],
          filters: {
            ...state.pfas.filters,
            attributes: {
              ...state.pfas.filters.attributes,
              PFAS: []
            }
          }
        }
      };
    }
    case PFASMASSEDITCHECKBOX.REQUEST: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          selectedRows: action.payload
        }
      };
    }
    case PFASMASSEDITITEMATTRIBUTESELECTION.REQUEST: {
      const { attrId, supc, value } = action;
      return {
        ...state,
        pfas: {
          ...state.pfas,
          changes: { ...getPfasChangeStructure(state.pfas.changes, attrId, value, supc) }
        }
      };
    }
    case REGULATORYCOMPLIANCESELECTIONCHANGED.REQUEST: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          filters: { ...state.pfas.filters, attributeFilters: [...action.filters] }
        }
      };
    }
    case REGULATORYCOMPLIANCEAPPLYVALUE.REQUEST: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          appliedValues: {
            ...state.pfas.appliedValues,
            [action.attrId]: [...action.data]
          }
        }
      };
    }
    case CLEARMASSPFASAPPLIEDVALUES.REQUEST: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          appliedValues: {}
        }
      };
    }
    case APPLYPFASMASSFILTEREDVALUES.REQUEST: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          changes: {
            ...applyMassPfasFilterChanges(state.pfas)
          }
        }
      };
    }
    case UNDOMASSPFASCHANGES.REQUEST: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          changes: {
            ...generateDefaultPfasChanges(state.pfas.list)
          }
        }
      };
    }
    case SUBMITMASSPFASCHANGES.REQUEST: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          isSaving: true
        }
      };
    }
    case SUBMITMASSPFASCHANGES.FAILURE: {
      return {
        ...state,
        pfas: {
          ...state.pfas,
          isSaving: false
        }
      };
    }
    case SUBMITMASSPFASCHANGES.SUCCESS: {
      const {
        payload: { itemList }
      } = action;
      return {
        ...state,
        pfas: {
          ...state.pfas,
          isSaving: false,
          list: [...itemList],
          changes: { ...generateDefaultPfasChanges(itemList) }
        }
      };
    }
    default: {
      return { ...state };
    }
  }
};

const getPfasClaimSelectedValue = taxonomyAttributes => {
  const { selected: pfasSelected, picklist } = _.find(
    taxonomyAttributes,
    { name: PFAS_CLAIM },
    { selected: null, picklist: [] }
  ) ?? { selected: null, picklist: [] };
  let pfasClaimSelectedValue = null;
  if (pfasSelected) {
    const { value: pfasClaimSelectedTextValue } = _.find(picklist, { id: pfasSelected }, { value: null });
    pfasClaimSelectedValue = pfasClaimSelectedTextValue;
  }
  return pfasClaimSelectedValue;
};

const setPfasClaimValueIfEmptySelections = (pfasClaimSelectedTextValue, selected, name, picklist) => {
  if (!selected.length && pfasClaimSelectedTextValue && name === PFAS_PRODUCT_LEVEL) {
    let picklistValueToCheck = null;
    // PFAS_CLAIM_MAPPING_NAMES
    if (pfasClaimSelectedTextValue.includes(PFAS_CLAIM_MAPPING_NAMES[0])) picklistValueToCheck = PFAS_MAPPING_NAMES[0];
    else if (pfasClaimSelectedTextValue.includes(PFAS_CLAIM_MAPPING_NAMES[1]))
      picklistValueToCheck = PFAS_MAPPING_NAMES[1];
    try {
      const { id } = _.find(picklist, { value: picklistValueToCheck });
      return [id];
    } catch (error) {
      return [];
    }
  }
  return selected;
};

const generateDefaultPfasChanges = itemList => {
  let changes = {};
  _.forEach(itemList, ({ supc, pfasAttributes, taxonomyAttributes }) => {
    changes = {
      ...changes,
      [supc]: {}
    };
    const pfasClaimSelectedTextValue = taxonomyAttributes.length ? getPfasClaimSelectedValue(taxonomyAttributes) : null;
    _.forEach(pfasAttributes, ({ selected, attrId, name, picklist }) => {
      let updatedSelectedValue = setPfasClaimValueIfEmptySelections(
        pfasClaimSelectedTextValue,
        selected,
        name,
        picklist
      );
      changes = {
        ...changes,
        [supc]: {
          ...changes[supc],
          [attrId]: [...updatedSelectedValue]
        }
      };
    });
  });
  return changes;
};

const getPfasChangeStructure = (changes, attributeId, value, supc) => {
  if (changes[supc]) {
    return {
      ...changes,
      [supc]: {
        ...changes[supc],
        [attributeId]: [...value]
      }
    };
  }
  if (!changes[attributeId]) return { ...changes, [attributeId]: [...value] };
  return { ...changes, [supc]: { [attributeId]: [...value] } };
};

const getPfasAttributesAndPicklists = list => {
  if (list && list.length) {
    const [{ pfasAttributes: attributes }] = list;
    let allAttributes = [];
    _.forEach(attributes, ({ attrId, name, picklist, tooltip }) => {
      allAttributes.push({
        attrId,
        name,
        picklist,
        tooltip
      });
    });
    return allAttributes;
  }
  return [];
};

const setImageSupcs = supcs => {
  if (supcs && supcs.length) {
    let massImageUploadObject = {};
    supcs.forEach(supc => {
      massImageUploadObject = { ...massImageUploadObject, [supc]: {} };
    });
    return massImageUploadObject;
  }
  return {};
};

const initialChangesObject = {
  primary: {},
  inPacking: {},
  openCase: {},
  case: {},
  lifeStyle: {}
};

const setImageData = (image, supc, data) => {
  return {
    ...image,
    [supc]: {
      ...image[supc],
      ...data,
      changes: { ...initialChangesObject }
    }
  };
};

const generateFormattedImageName = dataObject => {
  const { imageStyle, imageType, imageFacing, imageAngle, selectedImage, gtin } = dataObject;
  let formattedName = '';
  if (imageFacing && gtin && selectedImage && selectedImage.value && selectedImage.value.type) {
    const format = getFormat(selectedImage.value.type);
    if (imageFacing.indexOf('L') !== -1) {
      formattedName = `${gtin}_${imageFacing}.${format}`;
    } else if (imageStyle && imageType && imageAngle) {
      formattedName = `${gtin}_${imageType}${imageFacing}${imageAngle}${getStyleCode(imageStyle)}.${format}`;
    }
  }
  return formattedName;
};

const removeCnAttributeFromDisabledItems = (changedAttributes, isCnDisabledItem, cnAttributeId) => {
  if (!isCnDisabledItem) return changedAttributes;
  return changedAttributes.filter(({ id }) => id !== cnAttributeId);
};

const checkAndUpdateDisabledFields = (changedAttributes, disabledKey, taxonomyAttributes, nutritions) => {
  let finalChangedAttributes = [];
  _.forEach(changedAttributes, ({ id, value }) => {
    const { name, winningSource, picklist } = _.defaultTo(_.find(taxonomyAttributes, { attrId: Number(id) }), {});
    const isFieldDisabledForSameValue = DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledKey]?.[
      'disabled_for_same_values'
    ].includes(name);

    const disabledFields = DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledKey]?.[winningSource]?.[name] ?? {};
    const isFieldDisabled = Object.keys(disabledFields).length;
    const isFieldHavingSameValuesInEnutAndAttribution = osdNutrition.getNutritionRelatedOsdValue(
      name,
      nutritions,
      taxonomyAttributes
    );

    const areEnutAndAttributionValuesAreSameForGdsnItem =
      isFieldDisabledForSameValue && isFieldHavingSameValuesInEnutAndAttribution;

    // check if the attribute is not disabled for gdsn items
    if (!(isFieldDisabled || areEnutAndAttributionValuesAreSameForGdsnItem)) {
      const enabledSameFieldDetails = getDisabledOptionsDetailsForGdsnAttributes(
        isFieldDisabledForSameValue,
        disabledKey,
        name
      );
      const isPositiveOptionsDisabled = Object.keys(enabledSameFieldDetails).length;
      const { value: selectedPickListValue } = _.defaultTo(_.find(picklist, { id: Number(value) }), {});
      // check if positive values are disabled or field values are same as nutrition value
      if (
        (isPositiveOptionsDisabled &&
          enabledSameFieldDetails.enabledOptions &&
          enabledSameFieldDetails.enabledOptions.includes(selectedPickListValue)) ||
        (!isPositiveOptionsDisabled && !isFieldDisabled && !areEnutAndAttributionValuesAreSameForGdsnItem)
      ) {
        finalChangedAttributes = [...finalChangedAttributes, { id, value }];
      }
    }
  });
  return finalChangedAttributes;
};

const getAppliedAttributeValues = (existingValues, selectedRows, list, newValues, attributes) => {
  const results = [...existingValues];

  const cnAttributionData = _.find(attributes, { name: 'Child Nutrition Eligibility' });

  _.forEach(
    list,
    ({
      supc,
      pendingNutrition,
      pendingNutritions,
      nutritions = {},
      taxonomy: { attributes: taxonomyAttributes } = { attributes: [] },
      ...restItemFields
    }) => {
      const disabledMainKey = getGdsnOrSyscoBrandFlag(nutritions.nutritionSourceOid, restItemFields);

      const changedAttributes = checkAndUpdateDisabledFields(
        newValues,
        disabledMainKey,
        taxonomyAttributes,
        nutritions
      );
      if (changedAttributes.length && (_.isEmpty(selectedRows) || _.includes(selectedRows, supc))) {
        const index = _.findIndex(results, obj => obj.supc === supc);

        if (index !== -1) {
          results[index] = { supc, attributes: changedAttributes };
        } else {
          results.push({ supc, attributes: changedAttributes });
        }
      }
    }
  );

  return results;
};

const undoAppliedAttributes = (selectedRows, appliedValues) => {
  if (_.isEmpty(selectedRows)) return [];

  return _.filter(appliedValues, obj => !_.includes(selectedRows, obj.supc));
};

const removeSuccessFromAppliedAttributesList = (appliedValues, successList) => {
  return _.filter(appliedValues, obj => !_.includes(successList, obj.supc));
};

const updateAppliedAttributes = (existing, supc, attributeId, value) => {
  const clone = [...existing];
  const itemIndex = _.findIndex(clone, obj => obj.supc === supc);

  if (itemIndex !== -1) {
    const attributesClone = [...clone[itemIndex].attributes];
    const attributeIndex = _.findIndex(attributesClone, obj => obj.id === attributeId);

    if (attributeIndex !== -1) {
      attributesClone[attributeIndex] = { id: attributeId, value };
    } else {
      attributesClone.push({ id: attributeId, value });
    }

    clone[itemIndex] = { ...clone[itemIndex], attributes: [...attributesClone] };
  } else {
    clone.push({
      supc,
      attributes: [{ id: attributeId, value }]
    });
  }

  return clone;
};

const getAttributesList = ({ attributes, isPartial }) => {
  if (isPartial) {
    const specifics = _.filter(
      ATTRIBUTE_FILTER_OPTIONS,
      elem => elem !== 'Product Attributes' && !_.includes(elem, 'Certifications')
    );
    return getSpecificAttributes(attributes, specifics);
  } else {
    return getSpecificAttributes(attributes, ATTRIBUTE_FILTER_OPTIONS);
  }
};

const setAttributeSelectorValue = ({ id, value, selectedValues }) => {
  const clone = [...selectedValues];
  const index = _.findIndex(clone, obj => obj.id === id);

  if (index !== -1) {
    if (value === null) {
      clone.splice(index, 1);
    } else {
      clone[index] = { id, value };
    }
  } else {
    clone.push({ id, value });
  }

  return clone;
};

const updateMassEditItemsList = (successSupcs, currentList, updatedData) => {
  let clone = [...currentList];

  _.forEach(successSupcs, supc => {
    const target = _.find(updatedData, obj => obj.supc === supc);
    const updatedAttributes = _.get(target, 'attributes', []);
    const formatted = _.map(updatedAttributes, obj => {
      return { attrId: obj.id, value: obj.value };
    });

    const itemIndex = _.findIndex(clone, item => item.supc === supc);

    if (itemIndex !== -1) {
      let attributesClone = [...clone[itemIndex].attributes];

      _.forEach(formatted, obj => {
        const attributeIndex = _.findIndex(attributesClone, attr => attr.attrId === obj.attrId);

        if (attributeIndex !== -1) {
          attributesClone[attributeIndex] = { ...attributesClone[attributeIndex], value: obj.value };
        } else {
          attributesClone.push(obj);
        }
      });

      const itemClone = { ...clone[itemIndex], attributes: attributesClone };
      clone[itemIndex] = itemClone;
    }
  });

  return clone;
};

export default massUpdate;
