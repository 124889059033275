import _ from 'lodash';
import React from 'react';
import moment from 'moment';

const dataSet = require('./Data');
const {
  IMAGE_REGEX_ALL,
  IMAGE_REGEX_FACE,
  FnB_TYPE,
  GDSN_VALUE,
  SUBMIT_FOR_REVIEW_BUTTON_TITLE,
  SEND_FEEDBACK_BUTTON_TITLE,
  COMMENT_IMAGE_VIRUS_STATUS,
  SERVING_UOM_OPTIONS,
  UOM_TYPES,
  K12_FOOD_FIELDS,
  PORTION_SIZE,
  CHILD_NUTRITION_ELIGIBILITY,
  NOT_APPLICABLE,
  uploadRequestAvgLatencyFor100kb,
  IMAGE_CHANGE_TYPE_DESCRIPTIONS,
  REJECTED_REASON_CODES,
  IMAGE_ORDER,
  NONFOOD_BC_IDS,
  ATTRIBUTE_TYPES_SYNCHED_WITH_NUTRITIONS,
  DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS,
  NOT_REQUIRED_NUTRIENTS_VARIED_SHORT_NAMES,
  OVERALL_REJECT_STATUSES,
  NUTRITION_SYNCHED_TAXONOMY_ATTRIBUTE_NAMES_LIST,
  CN_PFS_EMPTY_FIELDS,
  ENUT_EXCLUDED_BUSINESS_CENTERS,
  PFAS_DISABLED_TOOLTIP_CONTENT,
  PFAS_CLAIM_MAPPING_NAMES,
  PFAS_MAPPING_NAMES
} = require('./Constants');

const osdNutrition = require('../util/osdNutrition');

const { NUTRITION_REJECT_OPTIONS } = require('../enums');

const {
  CORE_DATA_REJECT_STATUS_DROPDOWNS,
  CORE_DATA_FIELDS,
  CORE_LIST_FIELDS,
  ACTIVITY_TYPE_KEY,
  REGION_DESC_KEY,
  ACTIVITY_CODE_KEY,
  ACTIVITY_SUBCODE_KEY
} = require('./CoreDataConstants');

const { COUNTRY_CODE, COUNTRY_SUBDIVISION } = require('./CountryCode');

const [REJECTED_STATUS, FIX_STATUS, APPROVE_STATUS, REVIEW_STATUS, BLANK_STATUS] = CORE_DATA_REJECT_STATUS_DROPDOWNS;

const getPendingStatus = status => {
  if (status == 0) return 'PENDING';
  return '-';
};

export const getOverallFeedbackStatusValue = (status, changedBy) => {
  const foundValue = _.find(OVERALL_REJECT_STATUSES, obj => obj.key == status);
  if (foundValue) {
    const { value } = foundValue;
    if (changedBy === 'AUTOMATIC - (ITEM Moved)' && status === 30) return 'COMPLETE';
    return value || getPendingStatus(status);
  }
};

export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getFormat = mime => {
  return mime
    .replace(/image\//i, '')
    .replace(/jpeg/i, 'jpg')
    .toLowerCase();
};

export const isCoreFeedbackInputEmpty = (data, field, newField) => {
  const existing = _.get(data, `${field}`, []);
  const newValue = _.get(data, `${newField}`, '');

  if (_.isEmpty(existing)) {
    if (data.hasOwnProperty(newField)) {
      return _.isEmpty(newValue);
    }
    return true;
  }
  if (data.hasOwnProperty(newField)) {
    return _.isEmpty(newValue);
  }
  return false;
};

export const getOtmmBaseUrl = () => {
  let url = process.env.REACT_APP_BFF.replace(/\/itemmanagement$/, '').replace(/9005/, '8093');
  if (process.env.REACT_APP_BFF.match(/localhost/i)) {
    url = 'https://bff-dev.sss.mdm-supplier-np.us-east-1.aws.sysco.net';
  }
  return url;
};

export const getFileNamePart = type => {
  return type.replace(/:(.*)/g, '').trim();
};

export const getStyleCode = style => {
  const { styleCodes } = dataSet;
  if (!style) {
    return '';
  }
  const styleCode = styleCodes[style.toLowerCase().trim()];
  if (styleCode) {
    return styleCode.toUpperCase();
  }
  return '';
};

export const isNonFood = item => {
  if (item && item.taxonomy && item.taxonomy.bc) {
    return ['18', '14', '26', '27'].includes(item.taxonomy.bc.id);
  }
  return false;
};

export const setValuesToLocalStore = (key, value) => {
  const dateAfterThreeDays = new Date();
  dateAfterThreeDays.setDate(dateAfterThreeDays.getDate() + 3);
  value.expiry = dateAfterThreeDays;
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const getRelevantOverallHistoryChangeForComment = (id, history, isGettingAll) => {
  try {
    let statusTo = null;
    let commentText = null;
    const {
      changes: { statusTo: newStatusTo, statusFrom, commentText: newCommentText, ...rest },
      historyData
    } = _.find(history, (value, key) => key == id);
    statusTo = !newStatusTo && [10, 20].includes(statusFrom) ? statusFrom : newStatusTo;
    commentText = newCommentText;
    if (isGettingAll) return { ...rest, statusTo, statusFrom, commentText };
    return { statusTo, commentText };
  } catch (error) {}
  return { statusTo: null, commentText: null };
};

export const getOverallFeedbackButtonCaption = (comment, overallHistory) => {
  const { statusTo, commentText } = getRelevantOverallHistoryChangeForComment(comment.id, overallHistory);
  if (statusTo === 10) return 'SEND FEEDBACK';
  return 'APPROVE';
};

export const getOverallFeedbackButtonTitle = (permissions, roles, comment, overallHistory) => {
  const { statusTo, commentText } = getRelevantOverallHistoryChangeForComment(comment.id, overallHistory);
  if (permissions && !isCoreDataManager(permissions)) return `You don't have enough permissions to perform this action`;
  if (statusTo === 10) return 'Send feedback to the supplier';
  if (statusTo === 30) return 'Approve item status feedback';
  return 'Process item status';
};

export const getOverallFeedbackButtonColor = (id, overallHistory, greenColor, yellowColor) => {
  const { statusTo, commentText } = getRelevantOverallHistoryChangeForComment(id, overallHistory);
  if (statusTo === 10) return yellowColor;
  return greenColor;
};

export const getOverallFeedbackButtonDisableStatus = (id, overallHistory, greenColor, yellowColor) => {
  const { statusTo, commentText } = getRelevantOverallHistoryChangeForComment(id, overallHistory);
  if (statusTo === 10) return yellowColor;
  return greenColor;
};

export const getValuesFromLocalStore = key => {
  return JSON.parse(window.localStorage.getItem(key));
};

export const getSupc = (searchValue, selectedSuvc) => {
  return parseInt(searchValue) > 0 && selectedSuvc < 2 && searchValue.length === 7 ? searchValue : 0;
};

const regex = /^(• |^\*. |^\*|•. |^•)/s;

const regexOnlyForBullets = /^(• |^\•. |^•)/gm;

export const filterCollectFnBComments = (fnbComment, isByValue = null) => {
  let formatedfnb = '';
  for (const [key] of Object.entries(dataSet.featuresAndBenifitsCommentKeys)) {
    if (fnbComment[key] && getValueBasedOnTheCondition(isByValue, fnbComment[key].value, true)) {
      if (key !== 'descriptor') {
        if (formatedfnb !== '') formatedfnb += '\n';
        formatedfnb += '\u2022 ';
      }
      formatedfnb += (isByValue ? fnbComment[key].value.replace(regexOnlyForBullets, '') : fnbComment[key]).replace(
        regexOnlyForBullets,
        ''
      );
    }
  }
  return formatedfnb;
};

// TODO: add bullet if new line
export const restructureFnBCollectStatement = (event, field, fnbComment, expression = regex) => {
  let fnbCommentNew = {};

  if (fnbComment[field]) {
    fnbCommentNew = {
      ...fnbComment,
      [field]: {
        ...fnbComment[field],
        value: event.target.value.replace(expression, '')
      }
    };
  }
  return fnbCommentNew;
};

export const replaceFirstBullets = (value, expression = regex) => {
  return value ? value.replace(expression, '') : '';
};

export const getRejectStatus = reviewDataArray => {
  const rejectedParameter = [];
  if (reviewDataArray && reviewDataArray.length > 0) {
    const { featuresAndBenifitsCommentKeys } = dataSet;
    reviewDataArray.forEach(({ status, attribute }) => {
      if (
        ['REJECT', 'DENY'].includes(status) &&
        featuresAndBenifitsCommentKeys[attribute] &&
        featuresAndBenifitsCommentKeys[attribute].name
      ) {
        rejectedParameter.push(featuresAndBenifitsCommentKeys[attribute].name);
      }
    });
  }
  return rejectedParameter;
};

export const tableStyles = text => {
  return {
    props: {
      style: { whiteSpace: 'pre' }
    },
    children: text
  };
};

export const stripFromFilter = (strip, graphFilter) => {
  const existingGraphFilter = JSON.parse(graphFilter);
  const strippedFilters = _.filter(existingGraphFilter, filterObj => {
    return strip.indexOf(filterObj.param) === -1;
  });
  return strippedFilters;
};

const filterGraphValue = (jsonGraphFilter, filter) => {
  if (checkStatusWithTwoAndConditions(jsonGraphFilter, jsonGraphFilter.length > 0)) filter = jsonGraphFilter[0];
  return filter;
};

const setTableFilterWithBrandsIfAvailable = (filtersWithBrands, filter, tableFilter) => {
  if (filtersWithBrands && filtersWithBrands.length > 0) {
    tableFilter = { ...filtersWithBrands[0] };
    if (checkObjectAndKeyExists(filter, 'param'))
      tableFilter = { ...tableFilter, val: [...new Set([...tableFilter.val, filter.val])] };
  }
  return tableFilter;
};

export const filterSummaryItems = (summaryItems, filterForGraphs, graphFilters = '[]', tableFilters = []) => {
  tableFilters = getValuesFromTwoFields(tableFilters, []);
  let filter = {};
  let tableFilter = {};

  graphFilters = JSON.parse(graphFilters || '[]');

  const filteredTablefilterWithBrands = tableFilters.filter(({ param }) => param === 'brandName');
  tableFilter = setTableFilterWithBrandsIfAvailable(filteredTablefilterWithBrands, graphFilters, tableFilter);
  const filtered = [];
  summaryItems.forEach(item => {
    if (checkStatusWithTwoAndConditions(filterForGraphs.sysco === '1', item.prm.sy !== '1')) {
      return;
    }
    if (checkStatusWithTwoAndConditions(filterForGraphs.bc !== null, item.prm.bc !== filterForGraphs.bc)) {
      return;
    }
    if (checkStatusWithTwoAndConditions(filterForGraphs.ig !== null, item.prm.ig !== filterForGraphs.ig)) {
      return;
    }
    if (checkStatusWithTwoAndConditions(filterForGraphs.ag !== null, item.prm.ag !== filterForGraphs.ag)) {
      return;
    }
    if (checkObjectAndKeyExists(tableFilter, 'val') && !tableFilter.val.includes(item.prm.br)) {
      return;
    }
    if (checkStatusWithTwoAndConditions(filterForGraphs.brand !== null, item.prm.br !== filterForGraphs.brand)) {
      return;
    }

    for (const gFil of graphFilters) {
      const { param, val } = gFil;

      if (param === 'ag') {
        const ag = item.prm.ag;
        if (val !== ag) {
          return;
        }
      }

      if (param === 'brand') {
        const br = item.prm.br;
        if (val !== br) {
          return;
        }
      }

      if (param === 'comp_attr') {
        const cmpt = item.dim.attr.cmpt;
        if ((val == 0 && cmpt) || (val == 1 && !cmpt)) {
          return;
        }
      }

      if (_.includes(['pa', 'dt', 'al', 'cl', 'ct'], param)) {
        const itemVal = item.dim.attr.at[param];
        if ((val === 'complete' && itemVal !== 100) || (val === 'incomplete' && itemVal === 100)) {
          return;
        }
      }
    }

    filtered.push(item);
  });
  return filtered;
};

export const addBrandNameToTableFilter = (tableFilters, brandFromGraphFilter, key) => {
  let isBrandNameAlreadyExists = false;
  const allFilters = tableFilters.map(eachFilter => {
    let updatedFilter = { ...eachFilter };
    if (eachFilter.param === key) {
      isBrandNameAlreadyExists = true;
      updatedFilter = { ...updatedFilter, val: [...new Set([...updatedFilter.val, brandFromGraphFilter])] };
    }
    return updatedFilter;
  });
  return [allFilters, isBrandNameAlreadyExists];
};

export const isValidImageName = imageName => IMAGE_REGEX_FACE.test(imageName) || IMAGE_REGEX_ALL.test(imageName);

export const removeFromLocalStorage = key => window.localStorage.removeItem(key);

export const isEligibleForGdsnFnb = fieldComment => {
  return (
    fieldComment.item &&
    fieldComment.item.basicData &&
    fieldComment.item.basicData.gdsn === GDSN_VALUE &&
    fieldComment.type === FnB_TYPE
  );
};

export const checkObjectPropertyExists = (obj, property) => obj.hasOwnProperty(property);

export const getFnbFieldName = field => dataSet.featuresAndBenifitsCommentKeys[field].name;

export const getButtonTitle = fieldType => {
  let title = SEND_FEEDBACK_BUTTON_TITLE;
  if (['FnB', 'IMAGE'].includes(fieldType)) title = SUBMIT_FOR_REVIEW_BUTTON_TITLE;
  return title;
};

export const getFieldCommentWithoutCulinaryAndMarketing = fieldComment => {
  const { culinary, marketing, ...rest } = fieldComment;
  return rest;
};

const checkConditionsForCoreDataTags = (isRejected, isFixed, isRejectedFixed) => {
  if (isRejected || (isFixed && isRejectedFixed)) return true;
  return false;
};

export const getCoreDataTagsStyles = ({ isRejected, isFixed, isRejectedFixed } = {}) => {
  let styles = {
    color: '#074623',
    backgroundColor: '#d3ecc7',
    borderColor: '#6ecc40'
  };
  if (checkConditionsForCoreDataTags(isRejected, isFixed, isRejectedFixed)) {
    styles = {
      color: '#f5222d',
      backgroundColor: '#fff7e6',
      borderColor: '#ffa39e'
    };
  }
  return styles;
};

export const getCoreDataTagText = ({ isRejected, isRejectedFixed } = {}) => {
  let text = 'FIXED';
  if (isRejected || isRejectedFixed) text = 'REJECT';
  return text;
};

export const getCoreDataTagTooltip = ({ isRejected } = {}) => {
  let tooltip = 'Attribute has been fixed, waiting to be reviewed by associate';
  if (isRejected)
    tooltip = 'Attribute has been rejected, please update the attribute value to a correct state and submit';
  return tooltip;
};

export const parseJson = jsonString => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return null;
  }
};

export const stringifyJson = jsonObject => {
  try {
    return JSON.stringify(jsonObject);
  } catch (error) {
    return null;
  }
};

export const sortArray = array => {
  try {
    return [...array].sort();
  } catch (error) {
    return array;
  }
};

export const sortObjectByKeys = data => {
  try {
    let unordered = { ...data };
    return Object.keys(unordered)
      .sort()
      .reduce((obj, key) => {
        obj[key] = unordered[key];
        return obj;
      }, {});
  } catch (error) {
    return data;
  }
};

export const appendToArrayWithSpread = (baseArray, appendingArray) => {
  try {
    return [...baseArray, ...appendingArray];
  } catch (error) {
    return baseArray;
  }
};

export const checkArrayLengthIsZero = dataArray => dataArray.length === 0;

export const getFilterGraphParameters = (param, val, removingArray) => {
  if (val !== null) {
    return { add: [{ param, val }], remove: removingArray };
  }
  return { add: null, remove: removingArray };
};

export const getPendingCoreDataSelectedValue = (updatedComment, key, selectStatuses) => {
  let status = BLANK_STATUS;
  if (updatedComment[key].isRejected) status = REJECTED_STATUS;
  if (
    updatedComment[key].isFixed &&
    selectStatuses &&
    selectStatuses[key] !== BLANK_STATUS &&
    !updatedComment[key].isRejected
  )
    status = FIX_STATUS;
  else if (selectStatuses && selectStatuses[key] === BLANK_STATUS && !updatedComment[key].isRejected)
    status = BLANK_STATUS;

  if (updatedComment[key]?.isInternalReview) status = REVIEW_STATUS;
  if (updatedComment[key]?.isApproved) status = APPROVE_STATUS;
  return status;
};

export const getCommentTextAreaStylesForRejections = (updatedComment, key, style) => {
  const data = updatedComment[key];

  if (data.isRejected && isCoreFeedbackInputEmpty(data, 'feedback', 'newFeedback')) {
    return style;
  }
  if (data.isInternalReview && isCoreFeedbackInputEmpty(data, 'internalReview', 'newInternalReview')) {
    return style;
  }
  return undefined;
};

export const getCoreDataCommentDisabledFields = (fieldValue, value, updatedComment = null, key = null) => {
  if (fieldValue === value && !updatedComment && !key) return true;
  if (updatedComment && key && !updatedComment[key].isRejected && fieldValue === value) return true;
  return false;
};

export const getStatusDisabledForSelect = (key, value, updatedComment, selectStatuses) => {
  let disableStatus = false;
  if (value === REJECTED_STATUS && updatedComment[key].isRejected && !updatedComment[key].isFixed) disableStatus = true;
  if (
    value === FIX_STATUS &&
    !updatedComment[key].isRejected &&
    updatedComment[key].isFixed &&
    selectStatuses &&
    selectStatuses[key] !== BLANK_STATUS
  )
    disableStatus = true;
  if (value === BLANK_STATUS && selectStatuses && selectStatuses[key] === BLANK_STATUS) disableStatus = true;
  if (value === FIX_STATUS) disableStatus = true;
  return disableStatus;
};

export const getClassNameFromConditions = (condition, className) => {
  return condition ? className : '';
};

export const getFeedbackSelectedValue = (e, feedback) => (e == null || e === feedback ? null : e.toString());

export const getFieldValidationForFeedbackAttributes = (key, item) => {
  let value = '';
  if (item && item.feedback && item.feedback.changers) {
    value = item.feedback.changers.filter(attrObj => {
      return attrObj.name === key;
    });
    if (value.length > 0) {
      return value[0].validation;
    }
    if (['Item does not belong to Supplier', 'Discontinued Item'].includes(key)) {
      return false;
    }
    return true;
  }
  return true;
};

export const getValueBasedOnTheCondition = (condition, trueValue, falseValue, secondCondition = true) => {
  return condition && secondCondition ? trueValue : falseValue;
};

export const getFieldCommentValueIdOrDefault = (data, defaultValue) => {
  return data ? data.id : defaultValue;
};

export const getFieldDisabledStatus = (condition, secondCondition, thirdCondition) =>
  condition || secondCondition || thirdCondition;

export const getFieldDisabledStatusByTwoParams = (condition, secondCondition) => condition || secondCondition;

export const getConditionStatus = condition => !!condition;

export const getValuesFromTwoFields = (firstValue, secondValue) => firstValue || secondValue;

export const checkStatusWithTwoAndConditions = (condition, secondCondition) => condition && secondCondition;

export const checkStatusWithTwoAndConditionsWithLengthCheck = condition => condition && condition.length > 1;

export const checkStatusWithThreeAndConditions = (condition, secondCondition, thirdCondition) =>
  condition && secondCondition && thirdCondition;

export const checkStatusWithFourOrConditions = (
  condition,
  secondCondition,
  thirdCondition,
  fourthCondition,
  optionalCondition = true
) => condition || optionalCondition || secondCondition || thirdCondition || fourthCondition;

export const checkStatusWithThreeOrConditions = (condition, secondCondition, thirdCondition) =>
  condition || secondCondition || thirdCondition;

export const getFieldKeyOrAssignDefaultValue = (data, key, defaultValue) => {
  return data ? data[key] : defaultValue;
};

export const checkIsNotEmptyAndAppendUri = (data, key, uri) => {
  return !_.isEmpty(data) ? `${uri}&${key}=${encodeURIComponent(JSON.stringify(data))}` : uri;
};

export const checkIsNotNullAndAppendUri = (data, key, uri) => {
  return data !== null ? `${uri}&${key}=${encodeURIComponent(JSON.stringify(data))}` : uri;
};

export const getSpreadedArrayOrDefault = (dataArray, defaultValue = []) => {
  return dataArray ? [...dataArray] : defaultValue;
};

export const getImageVirusScanStatus = status => {
  switch (status) {
    case 0:
      return COMMENT_IMAGE_VIRUS_STATUS[0];
    case 1:
      return COMMENT_IMAGE_VIRUS_STATUS[1];
    case 6:
      return COMMENT_IMAGE_VIRUS_STATUS[6];
    default:
      return COMMENT_IMAGE_VIRUS_STATUS.default;
  }
};

export const checkValueAndAppendUrl = (value, key, uri) => {
  return value ? `${uri}&${key}=${encodeURIComponent(value)}` : uri;
};

export const checkValueAndAppendUrlWithReplacements = (value, key, uri) => {
  return value ? `${uri}&${key}=${encodeURIComponent(value.replace(/%/, '###'))}` : uri;
};

export const assignFilterValues = (allTableFilter, graphFilter) => {
  const allFilters = [...allTableFilter];
  _.forEach(JSON.parse(graphFilter), elem => {
    if (elem.param === 'sysco' && elem.val === '1') {
      allFilters.push({ param: 'syscoBrand', val: ['Y'] });
    } else if (elem.param === 'sysco' && elem.val === '0') {
      allFilters.push({ param: 'national', val: ['national'] });
    } else if (elem.param === 'brand') {
      allFilters.push({ param: 'brandName', val: [elem.val] });
    }
  });
  return allFilters;
};

export const checkAllTableFiltersWithTableFilter = (allTableFilters, tableFilters) => {
  let allTableFiltersAfter = allTableFilters;
  let allFiltersAfter = tableFilters;
  if (tableFilters) {
    allTableFiltersAfter = [...tableFilters];
  } else {
    allFiltersAfter = null;
  }
  return { allTableFiltersAfterCheck: allTableFiltersAfter, tableFiltersAfterCheck: allFiltersAfter };
};

export const checkAllTableFiltersWithTableSearch = (allTableFilters, tableSearchData) => {
  let allTableAfterCheck = allTableFilters;
  let allSearchFiltersAfter = tableSearchData;
  if (tableSearchData && !_.isEmpty(tableSearchData)) {
    allTableAfterCheck = [...allTableFilters, ...tableSearchData];
  } else {
    allSearchFiltersAfter = null;
  }
  return { allTableAfterCheck, allSearchFiltersAfter };
};

export const assignDefaultPageNo = page => {
  if (!page) page = 1;
  return page;
};

export const updateMetaContent = (currentMeta, comment) => {
  if (comment && comment.meta) {
    return { ...currentMeta, ...comment.meta };
  }
  return currentMeta;
};

export const checkArrayAndLength = dataArray => dataArray && dataArray.length > 0;

export const handleImageOnClick = (getCaption, getAwsImageUrl) => {
  if (getCaption().length === 0) window.open(getAwsImageUrl());
};

export const getUppdateCaseValueOrNull = value => value.toUpperCase() || null;

export const removeRejectedAttribyteFnbFeedbacks = (status, type, checkingConstant) => {
  if (status === 10 && type === checkingConstant) return 90;
  return status;
};

const updateTraitsInDataSet = (traits, jsonComment) => {
  let updatedJsonComment = { ...jsonComment };
  if (traits && traits.length > 0) {
    const updatedTraits = traits.map(({ name, isExisting, osdAttributeName, previousValue, ...rest }) => {
      return { ...rest };
    });
    updatedJsonComment = { ...jsonComment, traits: updatedTraits };
  }
  return updatedJsonComment;
};

// uncomment this when claims update part is done by CBORD
const updateClaimsInDataSet = (nutritionalClaimsDetails, jsonComment) => {
  let updatedJsonComment = { ...jsonComment };
  if (nutritionalClaimsDetails && nutritionalClaimsDetails.length > 0) {
    const allClaims = nutritionalClaimsDetails.map(({ nutritionalClaimElementCode, nutritionalClaimTypeCode }) => {
      return { nutritionalClaimElementCode, nutritionalClaimTypeCode };
    });
    updatedJsonComment = { ...jsonComment, nutritionalClaimsDetails: [...allClaims] };
  }
  return updatedJsonComment;
};

const updateFeedbackStatus = (jsonComment, status) => {
  let updatedStatus = status;
  if (jsonComment.feedback && jsonComment.feedback.status) {
    if (jsonComment.feedback.status === NUTRITION_REJECT_OPTIONS[0]) updatedStatus = 10;
    else if (jsonComment.feedback.status === NUTRITION_REJECT_OPTIONS[2]) updatedStatus = 90;
  }
  return updatedStatus;
};

const setManufacturerDetails = (row, manufacturer, jsonComment, oid) => {
  if (!oid) {
    let manufacturerObject = { ...manufacturer };
    if (manufacturer) {
      const { name } = manufacturer;
      manufacturerObject = { name };
    }
    return {
      ...jsonComment,
      manufacturer: { ...manufacturerObject },
      vendorItemLink: [{ vendorItemIdentifier: row.supc, vendorItemOwnerOid: 110 }]
    };
  }
  return jsonComment;
};

export const getChangedFieldsAfterSilenceSubmitionNutritions = pendingNutrition => {
  if (!(pendingNutrition && pendingNutrition.changedFields)) {
    return [];
  }
  return pendingNutrition.changedFields.filter(field => !Object.keys(CN_PFS_EMPTY_FIELDS).includes(field));
};

const deleteFeedbackFromNutrition = jsonComment => {
  if (jsonComment.feedback) delete jsonComment.feedback;
  return jsonComment;
};

const handleChildOrProductFormulations = (jsonComment, child_or_product_formulation) => {
  let updatedJsonComment = { ...jsonComment };
  if (child_or_product_formulation) {
    Object.keys(child_or_product_formulation).forEach((key, index) => {
      if (key) {
        updatedJsonComment = { ...updatedJsonComment, [key]: child_or_product_formulation[key] };
      }
    });
    delete updatedJsonComment.child_or_product_formulation;
  }
  return updatedJsonComment;
};

const setOid = (oid, updatedData) => {
  if (oid) return { ...updatedData, oid };
  return { ...updatedData };
};

const setOrganicTradeItemCode = (data, organicTradeItemCodes) => {
  return { ...data, organicTradeItemCodes: organicTradeItemCodes || [] };
};

export const getUofmForCholAsAlreadyInGram = (shortName, quantityContainedUofm) => {
  if (shortName === 'CHOL') return 'MGM';
  return quantityContainedUofm;
};

export const handleNutritionFeedbackSubmit = row => {
  let status = 30;
  const jsonComment = JSON.parse(row.comment);
  const {
    nutritionAnalysisHeader,
    traits,
    nutritionalClaimsDetails,
    oid: itemOid,
    manufacturer,
    organicTradeItemCodes,
    organicTradeItemCode,
    changedFields,
    isReadOnly,
    taxonomyChanges,
    status: commentStatus,
    ...rest
  } = jsonComment;
  let updatedData = setOrganicTradeItemCode(rest, organicTradeItemCodes);
  if (
    nutritionAnalysisHeader &&
    nutritionAnalysisHeader.length > 0 &&
    nutritionAnalysisHeader[0].nutritionAnalysisDetail
  ) {
    const analysisDetailsUpdated = [];
    [...nutritionAnalysisHeader[0].nutritionAnalysisDetail].forEach(
      ({
        quantityContained,
        quantityContainedUofm,
        dailyValueIntakePercent,
        oid,
        nutrientOid,
        isUpdated,
        shortName,
        nutritionName
      }) => {
        if (
          !checkStatusWithTwoAndConditions(isUpdated, !(quantityContained >= 0)) &&
          getFieldDisabledStatusByTwoParams(oid, nutrientOid) &&
          checkValidationForThreeNutrientsInRequiredFields(nutritionName, quantityContained) &&
          (checkStatusWithThreeAndConditions(
            quantityContained > -1,
            quantityContained !== '',
            quantityContained !== null
          ) ||
            dailyValueIntakePercent)
        ) {
          let nutrientObject = {
            quantityContained,
            dailyValueIntakePercent,
            quantityContainedUofm: getUofmForCholAsAlreadyInGram(shortName, quantityContainedUofm)
          };
          if (oid) nutrientObject = { ...nutrientObject, oid };
          if (nutrientOid)
            nutrientObject = {
              ...nutrientObject,
              nutrientOid
            };
          if (itemOid) {
            nutrientObject = {
              ...nutrientObject,
              shortName
            };
          }
          analysisDetailsUpdated.push(nutrientObject);
        }
      }
    );
    const updatedNutritionAnalysisHeader = Object.assign([], nutritionAnalysisHeader, {
      0: { ...nutritionAnalysisHeader[0], nutritionAnalysisDetail: [...analysisDetailsUpdated] }
    });
    updatedData = { ...updatedData, nutritionAnalysisHeader: [...updatedNutritionAnalysisHeader] };
  }
  updatedData = { ...updateTraitsInDataSet(traits, updatedData) };
  updatedData = { ...updateClaimsInDataSet(nutritionalClaimsDetails, updatedData) }; // uncomment this when claims update part is done by CBORD
  status = updateFeedbackStatus(jsonComment, status);
  updatedData = { ...setManufacturerDetails(row, manufacturer, updatedData, itemOid) };
  updatedData = { ...deleteFeedbackFromNutrition(updatedData) };
  updatedData = { ...setOid(itemOid, updatedData) };

  return { updatedData, status, taxonomyChanges: taxonomyChanges || null };
};

export const getSearchValue = (priorityText, nonPriorityText) => {
  if (priorityText) return priorityText.val;
  if (nonPriorityText) return nonPriorityText.val;
  return '';
};

export const getIsFeedbackRejected = (rejectedComments, comment, currentTitle) => {
  if (_.includes(rejectedComments, comment.id)) return 'Send feedback to the supplier.';
  return currentTitle;
};

export const getImagesAndAtrbyteImageButtonTitleInFeedback = (
  imageDisable,
  comment,
  rejectedComment,
  buttonDisableFunction
) => {
  let buttonTitle = 'This will create a job in OTMM.';
  if (imageDisable) buttonTitle = 'Internal employee access temporary disabled.';
  if (buttonDisableFunction(comment)) buttonTitle = 'Checking malware detection status. Please wait...';
  buttonTitle = getIsFeedbackRejected(rejectedComment, comment, buttonTitle);
  return buttonTitle;
};

export const getButtonCaptionOnRejectedFeedbacks = (rejectedComments, comment, currentCaption) => {
  if (_.includes(rejectedComments, comment.id)) return 'SEND FEEDBACK';
  return currentCaption;
};

export const getNutritionSubmitButtonCaption = comment => {
  let caption = 'APPROVE';
  if (comment && comment.comment) {
    const { feedback: { status } = { status: null } } = JSON.parse(comment.comment);
    if (comment.status === 10 || status === 'REJECT') caption = 'SEND FEEDBACK';
    if (status === 'FIXED') caption = 'APPROVE';
  }
  return caption;
};

export const checkObjectAndKeyExists = (obj, key) => obj && obj[key];

const handleCoreCommentTypeButtonDisableStatus = (comment, permissions, disableStatus) => {
  let status = disableStatus;
  if (permissions && !isCoreDataManager(permissions)) status = true;
  if (checkObjectAndKeyExists(comment, 'comment')) {
    const jsonedComment = JSON.parse(comment.comment);
    for (const key of Object.keys(jsonedComment)) {
      if (
        checkStatusWithTwoAndConditions(
          jsonedComment[key].isRejected,
          isCoreFeedbackInputEmpty(jsonedComment[key], 'feedback', 'newFeedback')
        )
      ) {
        status = true;
        break;
      } else if (
        checkStatusWithTwoAndConditions(
          jsonedComment[key].isInternalReview,
          isCoreFeedbackInputEmpty(jsonedComment[key], 'internalReview', 'newInternalReview')
        )
      ) {
        status = true;
        break;
      }
    }
  }
  return status;
};

const checkForAllEmptyComments = comment => {
  try {
    if ((comment.status === 10 && !comment.rejectStatus) || comment.rejectStatus !== 'REJECT') return false;
    let status = true;
    const { feedback } = JSON.parse(comment.comment);
    if (feedback && feedback.comments) {
      const commentKeys = Object.keys(feedback.comments);
      if (commentKeys && commentKeys.length) {
        for (let i = 0; i < commentKeys.length; i++) {
          if (feedback.comments[commentKeys[i]] && feedback.comments[commentKeys[i]] !== '') {
            status = false;
            break;
          }
        }
      }
    }
    return status;
  } catch (error) {
    return true;
  }
};

export const checkNutritionRoleNotExists = roles => !roles.includes(802);

const handleOverallCommentTypeButtonDisableStatus = (comment, permissions, overallFeedbackHistoryChanges) => {
  let status = false;
  if (permissions && !isCoreDataManager(permissions)) status = true;
  const { statusTo, commentText } = getRelevantOverallHistoryChangeForComment(
    comment.id,
    overallFeedbackHistoryChanges
  );
  if (statusTo === 10 && (!commentText || commentText === '')) status = true;
  if (!statusTo) return true;
  if (statusTo === 30 && comment.status != 30) return false;
  return status;
};

export const getButtonDisableStatusOnFeedback = (
  comment,
  imageDisable,
  permissions,
  roles,
  overallFeedbackHistoryChanges
) => {
  let disableStatus = false;
  if (checkStatusWithThreeAndConditions(comment, ['IMAGE', 'ATRBYTIMG'].includes(comment.type), imageDisable))
    disableStatus = true;
  if (
    checkStatusWithThreeAndConditions(
      comment,
      ['IMAGE', 'ATRBYTIMG'].includes(comment.type),
      comment.field === 'Image Feedback'
    )
  )
    disableStatus = false;
  if (
    comment &&
    ['IMAGE', 'ATRBYTIMG'].includes(comment.type) &&
    (!comment.imageComment ||
      checkStatusWithTwoAndConditions(
        comment.imageComment,
        ['DELETE_REPLACE', 'CREATE'].includes(comment.imageComment.changeType)
      )) &&
    (!comment.virusStatus || checkStatusWithTwoAndConditions(comment.virusStatus, comment.virusStatus !== 6))
  )
    disableStatus = true;
  if (comment && comment.type === 'CORE') {
    disableStatus = handleCoreCommentTypeButtonDisableStatus(comment, permissions, disableStatus);
  }
  if (comment && comment.type === 'NUTRITION') {
    disableStatus = checkForAllEmptyComments(comment) || checkNutritionRoleNotExists(roles);
  }
  if (comment && comment.type === 'OVERALL') {
    disableStatus = handleOverallCommentTypeButtonDisableStatus(comment, permissions, overallFeedbackHistoryChanges);
  }
  return disableStatus;
};

export const getButtonColorOnFeedback = (id, type, status, comment, state, feedbackChangesHistory) => {
  let buttonColor = 'green-action-btn';
  if (checkStatusWithTwoAndConditions(['FnB', 'ATRBYTFNB'].includes(type), _.includes(state.rejectedFnBComments, id)))
    buttonColor = 'yellow-action-btn';
  else if (
    checkStatusWithTwoAndConditions(['IMAGE', 'ATRBYTIMG'].includes(type), _.includes(state.rejectedImageComments, id))
  )
    buttonColor = 'yellow-action-btn';
  else if (type === 'NUTRITION') {
    const { feedback: { status: feedbackStatus } = { status: null } } = JSON.parse(comment);
    if (status === 10 || feedbackStatus === 'REJECT') buttonColor = 'yellow-action-btn';
    if (feedbackStatus === 'FIXED') buttonColor = 'green-action-btn';
  } else if (type === 'CORE') {
    buttonColor = 'yellow-action-btn';
  } else if (type === 'OVERALL') {
    buttonColor = getOverallFeedbackButtonColor(id, feedbackChangesHistory, 'green-action-btn', 'yellow-action-btn');
  }
  return buttonColor;
};

export const getBrandColumnSymbolAndTitle = item => {
  let symbol = '';
  let symbolTitle = '';

  if (item.brandCode === 'PACKER') {
    symbol = 'P';
    symbolTitle = 'Packer Brand';
  } else if (item.syscoBrand === 'Y') {
    symbol = 'S';
    symbolTitle = 'Sysco Brand';
  }
  return { symbol, symbolTitle };
};

export const checkArrayLength = dataArray => {
  try {
    return dataArray.length;
  } catch (error) {
    return false;
  }
};

export const checkArrayAndSpreadOrDefault = (dataArray, defaultValue) => {
  return dataArray ? [...dataArray] : defaultValue;
};

export const updateArrayOnItemStatus = (item, updateArray, obj) => {
  const updatedData = [...updateArray];
  if (item.status !== null) {
    updatedData.push(obj);
  }
  return updatedData;
};

export const setUpdateTargetValueAndStatus = (value, DEFAULT_STATUS, targetObject) => {
  const target = { ...targetObject };
  if (value === DEFAULT_STATUS) {
    target.status = null;
    target.comment = null;
  } else {
    target.status = value;
  }
  return target;
};

export const checkValueAndReturnElement = (value, element) => {
  if (value) return element;
};

export const updateArrayValuesIfNotExisting = (dataArray, attribute) => {
  const updatedArray = [...dataArray];
  if (!updatedArray.includes(attribute)) updatedArray.push(attribute);
  return updatedArray;
};

export const pushObjectToColumnArrayByCheckingCondition = (columnsArray, gdsnFnb, record) => {
  const columns = [...columnsArray];
  if (!_.isEmpty(gdsnFnb)) {
    columns.push(record);
  }
  return columns;
};

export const updateValueAndGetBasedOnCondition = (condition, attribute, valueToSet) => {
  if (condition) return valueToSet;
  return attribute;
};

export const updateSavedFnbArray = (dataArray, existingValue) => {
  const savedArray = [...dataArray];
  if (existingValue) {
    savedArray.push(existingValue);
  }
  return savedArray;
};

export const getFnbMassUpdateClosableState = (condition, secondCondition, thirdCondition) => {
  return !(condition || secondCondition || thirdCondition);
};

export const checkNonFoodStatusForFieldComment = (fieldComment, nonFoodItemBusinessCenterNames, isNonFood) => {
  let nonFood = isNonFood;
  if (fieldComment.item && fieldComment.item.taxonomy && fieldComment.item.taxonomy.bc) {
    nonFood = nonFoodItemBusinessCenterNames.indexOf(fieldComment.item.taxonomy.bc.name) !== -1;
  }
  return nonFood;
};

const spliceExistingArray = (index, dataArray, splicingData) => {
  const updatedData = [...dataArray];
  updatedData.splice(index, 1, splicingData);
  return updatedData;
};

const pushToExistingArray = (dataArray, dataObject) => {
  const updatedData = [...dataArray];
  updatedData.push(dataObject);
  return updatedData;
};

export const checkIfTraitsAreChanges = traits => {
  let hasChanges = false;
  _.forEach(traits, ({ levelOfContainment, dietTypeCode, nutritionalClaimTypeCode, previousValue, isExisting }) => {
    if (
      (isExisting &&
        ((levelOfContainment && levelOfContainment.toLowerCase() !== previousValue.toLowerCase()) ||
          (dietTypeCode && dietTypeCode.toLowerCase() !== previousValue.toLowerCase()) ||
          (nutritionalClaimTypeCode &&
            nutritionalClaimTypeCode.toLowerCase() !== nutritionalClaimTypeCode.toLowerCase()))) ||
      !isExisting
    ) {
      hasChanges = true;
    }
  });
  return hasChanges;
};

export const updateNutritionTraits = (existingTraits, index, existingObject, eachAllergen) => {
  let traits = [...existingTraits];
  if (index > -1) {
    traits = [
      ...spliceExistingArray(index, traits, {
        ...traits[index],
        levelOfContainment: eachAllergen.eNutValue,
        traitName: eachAllergen.traitName,
        isExisting: eachAllergen.eNutValue === existingObject.levelOfContainment,
        previousValue: existingObject.levelOfContainment,
        osdAttributeName: eachAllergen.osdAttribute
      })
    ];
  } else {
    traits = [
      ...pushToExistingArray(traits, {
        levelOfContainment: eachAllergen.eNutValue,
        allergenCode: eachAllergen.allergenCode,
        name: eachAllergen.eNutName,
        traitName: eachAllergen.traitName,
        isExisting: eachAllergen.eNutValue === existingObject.levelOfContainment,
        previousValue: existingObject.levelOfContainment,
        osdAttributeName: eachAllergen.osdAttribute
      })
    ];
  }
  return traits;
};

export const updateNutritionTraitsDietTypes = (existingTraits, index, eachDietType, existingObject) => {
  let traits = [...existingTraits];
  if (index > -1) {
    traits = [
      ...spliceExistingArray(index, traits, {
        ...traits[index],
        dietTypeCode: eachDietType.dietTypeCode,
        name: eachDietType.osdPick,
        isExisting: eachDietType.eNutValue === existingObject.dietTypeCode,
        osdAttributeName: eachDietType.osdAttribute
      })
    ];
  } else {
    traits = [
      ...pushToExistingArray(traits, {
        dietTypeCode: eachDietType.dietTypeCode,
        name: eachDietType.osdPick,
        traitName: eachDietType.traitName,
        isExisting: eachDietType.eNutValue === existingObject.dietTypeCode,
        previousValue: existingObject.traitName,
        osdAttributeName: eachDietType.osdAttribute
      })
    ];
  }
  return traits;
};

export const getTaxonomyPickedTraits = (taxonomyPick, eachAttribute, existingTraits, dataObject) => {
  let traits = [...existingTraits];
  if (taxonomyPick && eachAttribute.selected && taxonomyPick.id === eachAttribute.selected) {
    traits = [...pushToExistingArray(traits, dataObject)];
  }
  return traits;
};

export const updateNutritionClaims = (existingClaims, index, existingData, eachClaim) => {
  const claims = [...existingClaims];
  if (index > -1) {
    claims.splice(index, 1, {
      ...claims[index],
      nutritionalClaimElementCode: existingData.nutritionalClaimElementCode,
      nutritionalClaimTypeCode: existingData.nutritionalClaimTypeCode,
      name: eachClaim.osdAttribute,
      isExisting: true,
      osdAttributeName: eachClaim.osdAttribute
    });
  } else {
    claims.push({
      nutritionalClaimElementCode: eachClaim.nutritionalClaimNutrientElementCode,
      nutritionalClaimTypeCode: eachClaim.eNutValue,
      name: eachClaim.osdAttribute,
      isExisting: true,
      previousValue: existingData.nutritionalClaimTypeCode,
      osdAttributeName: eachClaim.osdAttribute
    });
  }
  return claims;
};

export const getTaxonomyPickedClaimsAndOrganicStatus = (
  existingClaims,
  taxonomyPick,
  eachAttribute,
  eachClaim,
  existingData,
  organicCode
) => {
  let organicTradeItemCode = organicCode;
  const claims = [...existingClaims];
  if (taxonomyPick && eachAttribute.selected && taxonomyPick.id === eachAttribute.selected) {
    if (!eachClaim.isForTradeItemCodes) {
      claims.push({
        nutritionalClaimElementCode: eachClaim.nutritionalClaimNutrientElementCode,
        nutritionalClaimTypeCode: eachClaim.eNutValue,
        name: eachClaim.osdAttribute,
        isExisting: false,
        osdAttributeName: eachClaim.osdAttribute
      });
    } else {
      organicTradeItemCode = eachClaim.eNutValue;
    }
  }
  return { updatedClaims: claims, updatedOrganicTradeItemCode: organicTradeItemCode };
};

const checkAttributeType = (name, type, checkingType, checkingName, isByType) => {
  type = `${type}`.replace(/\-(.*)$/g, '');
  if (isByType) return type === checkingType;
  return name && name.indexOf(checkingName) > -1;
};

export const isDietTypeAttribute = ({ type, name }, isByType = false) =>
  checkAttributeType(name, type, 'diettype', 'Diet Type', isByType);

export const isAllergenAttribute = ({ type, name }, isByType = false) =>
  checkAttributeType(name, type, 'allergen', 'Allergen', isByType);

export const isClaimAttribute = ({ type, name }, isByType = false) =>
  checkAttributeType(name, type, 'claim', 'Claim', isByType);

export const checkForClaimsSubType = ele => isClaimAttribute(ele, true) && `${ele.type}`.includes('sustainability');

export const isCertificationAttribute = ({ type, name }, isByType = false) =>
  checkAttributeType(name, type, 'certification', 'certification', isByType);

export const isSustainabilityCertifications = type => {
  return type === 'certification-sustainability';
};

export const isNutritionalCertifications = type => {
  return type === 'certification-nutritional';
};

export const isSustainabilityClaim = type => {
  return type === 'claim-sustainability';
};

export const isNutritionalClaim = type => {
  return type === 'claim-nutritional';
};

export const getAttributeType = type => {
  if (isClaimAttribute({ type }, true)) return 'Claim';
  if (isAllergenAttribute({ type }, true)) return 'Allergen';
  if (isDietTypeAttribute({ type }, true)) return 'Diet Type';
  return 'General';
};

export const getRelevantPickListValuesForAttr = (taxonomyAttributes, attrId, selectedValue) => {
  const result = _.find(taxonomyAttributes, obj => obj.attrId === parseInt(attrId));
  if (result && result.picklist && result.picklist.length) {
    return _.find(result.picklist, obj => obj.id === parseInt(selectedValue)).value;
  }
  return null;
};

const getFormattedNutritionAnalysisHeader = nutritionalData => {
  let finalNutritions = [];

  const {
    nutrition_facts: { optionalNutritions, requiredNutritions } = { optionalNutritions: [], requiredNutritions: [] },
    optionalNutritionsPickList = []
  } = nutritionalData;
  const nutritionAnalysisHeaderOid = _.get(nutritionalData, 'nutrition_facts.oid', null);

  // Serving size data
  const servingSizeDescription = _.get(nutritionalData, 'nutrition_facts.servingSizeInfo.servingSizeDescription', null);
  const servingSizeQuantity = _.get(nutritionalData, 'nutrition_facts.servingSizeInfo.servingSizeQuantity', null);
  const servingSizeUofm =
    _.get(nutritionalData, 'nutrition_facts.servingSizeInfo.servingSizeUofm', null) || SERVING_UOM_OPTIONS[0].code;

  _.forEach(requiredNutritions, refObj => {
    const originalNutrition = _.find(requiredNutritions, obj => refObj.shortName === obj.shortName);
    const quantityContained = _.get(originalNutrition, 'quantityContained', null);

    if (originalNutrition) {
      const nutrition = {
        ...originalNutrition,
        quantityContained,
        isRequiredNutrition: true
      };
      finalNutritions = [...finalNutritions, nutrition];
    }
  });

  _.forEach(optionalNutritionsPickList, refObj => {
    const originalNutrition = _.find(optionalNutritions, obj => refObj.shortName === obj.shortName);
    const quantityContained = _.get(originalNutrition, 'quantityContained', null);

    finalNutritions = [
      ...finalNutritions,
      {
        ...originalNutrition,
        quantityContained: Number(quantityContained) > -1 ? quantityContained : null,
        nutritionName: refObj.nutritionName,
        quantityContainedUofm: refObj.quantityContainedUofm,
        isRequiredNutrition: false,
        hasSelected: quantityContained !== null,
        nutrientOid: refObj.nutrientOid,
        shortName: refObj.shortName
      }
    ];
  });

  let finalNutritionAnalysisHeaderDetails = {
    nutritionAnalysisDetail: finalNutritions,
    servingSizeDescription,
    servingSizeQuantity,
    servingSizeUofm
  };

  if (nutritionAnalysisHeaderOid)
    finalNutritionAnalysisHeaderDetails = { ...finalNutritionAnalysisHeaderDetails, oid: nutritionAnalysisHeaderOid };

  return finalNutritionAnalysisHeaderDetails;
};

export const initializeNutritionData = data => {
  let oid = _.get(data, 'oid', null);
  const organicTradeItemCode = _.get(data, 'organicTradeItemCodes[0]', null);
  const ingredientStatement = _.get(data, 'ingredient_statement', '');
  const isReadOnly = _.get(data, 'isReadOnly', true);

  const isChildNutrition = _.get(data, 'isChildNutrition', false);
  const sleHasProductFormulationStmt = _.get(data, 'sleHasProductFormulationStmt', false);
  const childOrProdFormStmt = _.get(data, 'child_or_product_formulation', {});
  let nutritionSourceOid = _.get(data, 'nutritionSourceOid', null);

  if (nutritionSourceOid === 1) {
    nutritionSourceOid = null;
    oid = null;
  }

  let nutritionData = {
    isReadOnly,
    nutritionSourceOid,
    ingredientStatement,
    isChildNutrition,
    sleHasProductFormulationStmt,
    organicTradeItemCode,
    ...childOrProdFormStmt,
    nutritionAnalysisHeader: [
      {
        ...getFormattedNutritionAnalysisHeader(data)
      }
    ]
  };

  if (oid) nutritionData = { ...nutritionData, oid };
  return nutritionData;
};

export const getDecimalLengthOfNumber = value => {
  const stringNumber = String(value);
  let valueLength = 0;
  if (stringNumber.includes('.')) valueLength = stringNumber.split('.')[1].length;
  return valueLength;
};

export const isValidNutritionHeaderField = value => {
  let status = true;
  if (_.isNull(value) || _.isUndefined(value)) status = false;
  else if (!_.isNumber(value) && value.trim() === '') status = false;
  else if (value === '') status = false;
  return status;
};

export const getReadableUom = code => {
  try {
    return _.find(UOM_TYPES, obj => obj.code === code).des;
  } catch (e) {
    return code;
  }
};

const isK12FieldValid = (nutrition, nutritionErrors, field) =>
  field !== 'sleNotes' && nutrition[field] && nutrition[field] !== '' && !nutritionErrors[field];

const checkPortionSizeFilled = (nutrition, nutritionErrors) =>
  isK12FieldValid(nutrition, nutritionErrors, PORTION_SIZE);

export const checkPortionSizeValidation = nutrition =>
  nutrition && nutrition[PORTION_SIZE] && nutrition[PORTION_SIZE].length > 16;

export const checkOtherFoodItemFieldsFilled = (nutrition, nutritionErrors, numberOfFieldsToCheck) => {
  let validNumberOfFields = 0;
  let status = false;
  for (const field of K12_FOOD_FIELDS) {
    if (isK12FieldValid(nutrition, nutritionErrors, field)) {
      validNumberOfFields += 1;
      if (validNumberOfFields === numberOfFieldsToCheck) {
        status = true;
        break;
      }
    }
  }
  return status;
};

const checkPortionSizeAndOneOtherFieldsFilled = (nutrition, nutritionErrors) => {
  if (
    checkPortionSizeFilled(nutrition, nutritionErrors) &&
    checkOtherFoodItemFieldsFilled(nutrition, nutritionErrors, 1)
  )
    return false;
  return true;
};

const getFilteredData = (dataArray, key, checkAgainstValue) => {
  const childNutritions = dataArray.filter(({ [key]: value }) => value == checkAgainstValue);
  if (childNutritions && childNutritions.length) {
    return childNutritions[0];
  }
  return null;
};

export const isGdsnOrSysco = (gdsn, sysco) => gdsn === 'Y' || sysco === 'Y';

export const getTaxonomyDisabledStatusOnChildNutritionValue = (item, isForNutritionTab) => {
  let status = false;
  const {
    taxonomyChanges,
    taxonomy: { attributes },
    pendingNutrition,
    basicData: { gdsn, syscoBrand },
    nutritionErrors,
    hasNutritionSynchedTaxonomyAttributeChanges,
    pendingNutritions,
    nutritions: { nutritionSourceOid }
  } = item;
  if (!isGdsnOrSysco(gdsn, syscoBrand) && (isForNutritionTab || pendingNutritions.length || nutritionSourceOid)) {
    const childNutritionObject = getFilteredData(attributes, 'name', CHILD_NUTRITION_ELIGIBILITY);
    if (childNutritionObject) {
      const { picklist, attrId } = childNutritionObject;
      const changedChildNutritions = getFilteredData(taxonomyChanges, 'attrId', attrId);
      if (changedChildNutritions) {
        const { selected } = changedChildNutritions;
        if (selected) {
          const selectedPicklist = getFilteredData(picklist, 'id', selected);
          if (selectedPicklist && selectedPicklist.value !== NOT_APPLICABLE) {
            status = checkPortionSizeAndOneOtherFieldsFilled(pendingNutrition, nutritionErrors);
          }
        }
      } else if (pendingNutrition && pendingNutrition.changedFields && hasNutritionSynchedTaxonomyAttributeChanges) {
        const { changedFields } = pendingNutrition;
        if (['isChildNutrition', 'sleHasProductFormulationStmt'].every(value => changedFields.includes(value))) {
          status = checkPortionSizeAndOneOtherFieldsFilled(pendingNutrition, nutritionErrors);
        }
      }
    }
  }
  return status;
};

export const checkIsForNewNutritionFeedback = (pendingNutritions, pendingNutrition) => {
  return pendingNutritions && pendingNutritions.length < 1 && !(pendingNutrition && pendingNutrition.oid);
};

export const checkForFieldPendingNutrition = (field, pendingNutritions, servingFields) => {
  if (pendingNutritions && pendingNutritions.length) {
    const [{ comment }] = pendingNutritions;
    const { changedFields: nutritionChangedFields } = JSON.parse(comment);
    if (!servingFields && nutritionChangedFields && nutritionChangedFields.includes(field)) return 'nt-pending-field';
    // if (servingFields && nutritionChangedFields && nutritionChangedFields.includes(field)) {
    //   const { servingSeperatedField, servingSizeDescription, } = servingFields;
    //   const [servingSizeDescriptionQuantity, servingSizeDescriptionValue] = seperateServingDescription(
    //     servingSizeDescription
    //   );
    //   if (servingSeperatedField === 'QUANTITY' && !servingSizeDescriptionQuantity) return 'nt-pending-field';
    //   if (servingSeperatedField === 'DESCRIPTION' && !servingSizeDescriptionValue) return 'nt-pending-field';
    // }
  }
  return '';
};

export const getIsFeedbackChangedClassName = (changedFields = [], field, isFieldRemoved = false) => {
  return changedFields.includes(field) && !isFieldRemoved ? 'nt-feedback-changed-fields-tr' : '';
};

export const getChangedTitle = className => (className !== '' ? 'Value has been changed' : null);

export const getNutritionFeedbackSubmitButtonTitle = (comment, roles) => {
  if (checkNutritionRoleNotExists(roles)) return `You don't have enough permissions to perform this action`;
  if (comment.rejectStatus === 'REJECT') {
    return 'Send feedback to the supplier.';
  }
  return 'This will auto-update the NUTRITION data in Nutrition.';
};

export const validateForThreeDecimalsNutritionsQuantity = (value, previousValue) => {
  if (/^(\d+)?(?:\.\d{1,3})?$/.test(value)) return value;
  return previousValue;
};

// disable proprietary option for sysco brand items
export const getIfProprietarySelectionIsDisabledForSyscoBrandItems = ({ syscoBrand }, id) =>
  id === 'ITS000002' && syscoBrand === 'Y';

export const willPassCurrentDate = (dateString, daysToAdd) => {
  const targetDate = moment(dateString).add(daysToAdd, 'days');
  const currentDate = moment();

  return targetDate.isAfter(currentDate);
};

export const filterCommentsByType = (comments, commentType) => comments.filter(({ type }) => type == commentType);

export const getRejectedFeedbackOrPending = (
  pendingOverallFeedbackHistory,
  pendingComments,
  { itemStatus, statusOnly }
) => {
  // get rejected overall comment
  const overallComment = _.find(pendingComments.comments, ({ type, status }) => type === 'OVERALL' && status === 10);
  if (statusOnly) return overallComment;
  if (pendingOverallFeedbackHistory && pendingOverallFeedbackHistory.length) {
    if (overallComment) {
      const { commentText } = pendingOverallFeedbackHistory[pendingOverallFeedbackHistory.length - 1]; // get last history object in overall feedback history array
      if (willPassCurrentDate(overallComment.updatedAt, 30)) {
        return (
          <div className="pending-feedback-section">
            <div className="comment-row reject">
              <div className="type">Feedback Rejected : </div>
              <div className="comment">{itemStatus ? `${itemStatus} | ${commentText}` : commentText}</div>
            </div>
          </div>
        );
      }
    } else if (itemStatus) {
      return (
        <div className="pending-feedback-section">
          <div className="comment-row">
            <div className="type">Pending Feedback : </div>
            <div className="comment">{itemStatus}</div>
          </div>
        </div>
      );
    }
  }
};

const getLastValidUntilExclusion = exclusions => {
  if (!exclusions || !exclusions.length) return [];
  const activeExclusions = exclusions.filter(({ isActive }) => isActive === 1);
  if (activeExclusions && !activeExclusions.length) return [];
  const latestObject = activeExclusions.reduce((latest, current) => {
    const latestDate = new Date(latest.validUntil);
    const currentDate = new Date(current.validUntil);
    return latestDate >= currentDate || moment(latestDate).isSame(currentDate, 'day') ? latest : current;
  });
  return [{ ...latestObject }];
};

export const prepareOverallFeeExclusionDescription = exclusions => {
  const updatedData = getLastValidUntilExclusion(exclusions).map(({ description, ...rest }) => {
    const lowerCaseDescription = description.toLowerCase();
    if (lowerCaseDescription.includes('proprietary item')) {
      return {
        description:
          'Item is not Proprietary according to Sysco’s guidelines. For more questions please reach out to your merchandizer.',
        ...rest
      };
    } else if (lowerCaseDescription.includes('item does not belong to supplier')) {
      return {
        description:
          'Item deemed to belong to this account. For more questions, please reach out to your merchandizer.',
        ...rest
      };
    } else {
      return {
        description,
        ...rest
      };
    }
  });
  return updatedData;
};

const fetSchStatusOnlytatus = (comment, currentStatus) => {
  let status = currentStatus;
  comment.split(' \n').forEach(cmnt => {
    let keyValue = cmnt
      .replace('[ ', '')
      .replace(' ]', '')
      .split(' -> ');
    if (keyValue[0] === 'Item Status') {
      status = keyValue[1];
    }
  });
  return status;
};

export const fetchItemStatus = ({ comments }) => {
  let itemStatus;
  comments &&
    comments.forEach(comment => {
      if (comment.field === 'PRODUCT_STATUS') {
        itemStatus = fetSchStatusOnlytatus(comment.comment, itemStatus);
      }
    });
  return itemStatus;
};

export const fetchItemStatusFromOverallHistory = history => {
  let itemStatuses = [];
  history.forEach(eachHistory => {
    if (eachHistory && eachHistory != undefined)
      itemStatuses = [...itemStatuses, fetSchStatusOnlytatus(eachHistory.changedTo, '')];
  });
  return itemStatuses;
};

export const getLastFeedbackForHistory = pendingOverallFeedbackHistory => {
  if (pendingOverallFeedbackHistory && pendingOverallFeedbackHistory.length)
    return pendingOverallFeedbackHistory[pendingOverallFeedbackHistory.length - 1];
  return null;
};

const checkForDisabledPicklistContainsInCommentHistoryAndNotSelectedOption = (
  itemStatus,
  overallStatusValues,
  value
) => {
  return (
    overallStatusValues.some(str => str.toLowerCase().trim() == value.toLowerCase().trim()) &&
    itemStatus != value.toLowerCase().trim() &&
    [...new Set([...overallStatusValues])].length > 1
  );
};

const checkOptionDisabledStatus = (
  selectedOverallFeedback,
  overallStatusValues,
  itemStatus,
  value,
  excludeSixtyDaysCheck = false
) => {
  if (willPassCurrentDate(selectedOverallFeedback.updatedAt, 60) && !excludeSixtyDaysCheck) {
    // check if rejected picklist value is to be disabled
    if (itemStatus && itemStatus.toLowerCase().trim() === value.toLowerCase().trim()) {
      return true;
    } else if (
      checkForDisabledPicklistContainsInCommentHistoryAndNotSelectedOption(itemStatus, overallStatusValues, value)
    ) {
      // disable picklist values that is in previous history and not equals to current rejected value
      return true;
    }
  }
  if (checkForDisabledPicklistContainsInCommentHistoryAndNotSelectedOption(itemStatus, overallStatusValues, value)) {
    return true;
  }
};

export const getOverallSelectionIsDisabledForSixtyDays = (pendingComments, overallHistory, feeExclusions, value) => {
  const selectedOverallFeedback = getRejectedFeedbackOrPending(overallHistory, pendingComments, {
    itemStatus: null,
    statusOnly: true
  });
  let overallSubmittedValues = [];
  let itemStatus = fetchItemStatus(pendingComments);
  if (overallHistory && overallHistory.length) {
    overallSubmittedValues = overallHistory.map(({ changedFrom }) =>
      changedFrom ? changedFrom.toLowerCase().trim() : changedFrom
    );
  }
  // get current suvc changed exclusions
  let suvcChangeExclusions =
    feeExclusions && feeExclusions.filter(({ reasonCode, isActive }) => reasonCode === 'M7' && isActive === 1);

  const overallStatusValues = fetchItemStatusFromOverallHistory(overallHistory);
  if (selectedOverallFeedback) {
    suvcChangeExclusions = _.reverse(_.sortBy(suvcChangeExclusions, obj => new Date(obj.createdAt)));
    const [{ createdAt } = { createdAt: null }] = suvcChangeExclusions;
    if (
      suvcChangeExclusions &&
      suvcChangeExclusions.length &&
      createdAt &&
      moment(selectedOverallFeedback.updatedAt).isBefore(moment(createdAt))
    ) {
      return checkOptionDisabledStatus(selectedOverallFeedback, overallStatusValues, itemStatus, value, true);
    } else {
      return checkOptionDisabledStatus(selectedOverallFeedback, overallStatusValues, itemStatus, value);
    }
  }
};
export const validateForServingDescriptionQuantity = (value, previousValue) => {
  const [decimals, text] = separateDecimalsAndLetters(value);
  if (decimals) return decimals;
  return value;
};

export const validateServingDescriptionQuantity = value => {
  return (
    /^(\d+|(\.\d{1,3}|\d+(\.\d{1,3})?))(\s?-\s?\d+\/\d+|\s\d+(\s?-\s?)?\d+(\s\-\s)?\d+\/\d+)?$/.test(value) ||
    /^\d+( \d+\/\d+)?$/.test(value) ||
    /^.*\s$/.test(value) || // check if value is having a space at the end
    /^\d+\/\d+$/.test(value) // check if value is a fraction 3/4, 10/13
  );
};

export const checkIfStartsWithNumberic = value => {
  if (value) {
    const regexWithDashAndSpacesFraction = /^([\d\s-\/]+)\s+(.*)$/; // '1 - 3/4 cup of spoon'
    const regexWithDashAndSpacesFractionMatch = value.match(regexWithDashAndSpacesFraction);
    if (regexWithDashAndSpacesFractionMatch) {
      const newFormatMatch = value.match(/^(\d+)\s{2,}/);
      // when the format is 1 1/2 cup of team
      if (newFormatMatch) {
        return [`${newFormatMatch[1]} `, value.substring(`${newFormatMatch[1]} `.length).trim() || ''];
      }
      const regexForOnlyNumericPartWithFraction = /^([\d\s-\/]+)(?:\s+(.*))?$/;
      const regexForOnlyNumericPartWithFractionMatch = value.match(regexForOnlyNumericPartWithFraction);
      if (regexForOnlyNumericPartWithFractionMatch) {
        return [regexForOnlyNumericPartWithFractionMatch[1], regexForOnlyNumericPartWithFractionMatch[2] || ''];
      }
      return [regexWithDashAndSpacesFractionMatch[1].trim(), (regexWithDashAndSpacesFractionMatch[2] || '').trim()];
    }

    const regexSpaceWithFraction = /^(\d+\s\d+\/\d+)\s(.*)$/; // '1 3/4 cup of spoon'
    const regexSpaceWithFractionMatch = value.match(regexSpaceWithFraction);
    if (regexSpaceWithFractionMatch) {
      return [regexSpaceWithFractionMatch[1].trim(), (regexSpaceWithFractionMatch[2] || '').trim()];
    }
    const regexWithDashFraction = /^([\d-]+\/?\d*)\s(.*)$/; // '1-3/4 cup of spoon'
    const regexWithDashFractionMatch = value.match(regexWithDashFraction);
    if (regexWithDashFractionMatch) {
      return [regexWithDashFractionMatch[1].trim(), (regexWithDashFractionMatch[2] || '').trim()];
    }

    if (value.match(/\.\s/) || value.match(/\.\s*$/)) {
      const hasSpaceOrEmpty = regex.test(value) || /\.\s/.test(value);
      if (hasSpaceOrEmpty) {
        const regex = /^([^ ]+)/;
        if (value) {
          const match = value.match(regex);
          if (match) {
            const subtractedText = value.substring(match[0].trim().length).trim();
            return [match[0].trim(), subtractedText];
          }
        }
      }
    }

    // check if string is type of '10.'
    const matchWithOnlyEndingWithDot = value.match(/^(\d+(\.\d*)?)(\.?)([^-]*)$/);
    if (matchWithOnlyEndingWithDot) {
      return [
        `${matchWithOnlyEndingWithDot[1]}`,
        `${matchWithOnlyEndingWithDot[4] === undefined ? '' : matchWithOnlyEndingWithDot[4].trim()}`
      ];
    }
    // Check if the string starts with numeric values
    const numericMatch = value.match(/^(\d+(\.\d+)?)(\s*[^0-9.].*)?/);
    if (numericMatch) {
      return [numericMatch[0].trim(), (numericMatch[2] === undefined ? '' : numericMatch[2]).trim()];
    }

    if (hasDecimal(value) || value.match(/^\.$/)) {
      const [decimals, letters] = separateDecimalsAndLetters(value);
      return [decimals, letters ? letters.trim() : ''];
    }
    if (!numericMatch) return [null, value];
  }
  return [null, ''];
};

const separateDecimalsAndLetters = text => {
  const decimalRegex = /^\.\d+/;
  const decimalMatch = text.match(decimalRegex);
  if (!decimalMatch) return [text, ''];

  const decimals = decimalMatch ? decimalMatch[0] : '';
  const letters = decimalMatch ? text.slice(decimals.length) : '';

  return [decimals, letters];
};

export const hasDecimal = text => /\b\d*\.?\d+\b/.test(text);

export const seperateServingDescription = description => {
  if (description == null || (!description && description.trim === '')) return [null, null];
  return checkIfStartsWithNumberic(description);
};

export const isDataSourcedFromQaSpecOrGdsn = nutritionSourceOid => [23, 31].includes(nutritionSourceOid);

export const checkForProductAttributeTabSaveButtonActionChangeNeed = item => {
  const {
    pendingNutrition: { isReadOnly, nutritionSourceOid },
    pendingNutritions,
    hasNutritionSynchedTaxonomyAttributeChanges,
    isNutritionDetailsNotApplicable
  } = item;
  if (
    (pendingNutritions.length > 0 ||
      // !isDataSourcedFromQaSpecOrGdsn(nutritionSourceOid) ||
      // !isReadOnly ||
      (nutritionSourceOid && nutritionSourceOid === 21)) &&
    hasNutritionSynchedTaxonomyAttributeChanges &&
    !isNutritionDetailsNotApplicable // synching part will happen if nutrition is applicable
  ) {
    return true;
  }
  return false;
};

// Checks if the comment is assigned to the current user
export const isCommentAssignedToMe = (currentUser, assignee) => {
  return assignee && assignee === currentUser;
};

const downloadFile = (fileName, data, fileType) => {
  const file = new Blob([data], { type: 'text/plain' });
  if (window.navigator.msSaveOrOpenBlob) {
    // Required for this to work in IE
    const fullName = `${fileName}.${fileType}`;
    window.navigator.msSaveBlob(file, fullName);
  } else {
    const element = document.createElement('a');
    element.href = URL.createObjectURL(file);
    element.download = `${fileName}.${fileType}`;
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  }
};

export const fileDownload = (fileName, data) => {
  downloadFile(fileName, data, 'csv');
};

// SBS users can only manage core data
export const isSbsUser = roles => {
  return roles && ((roles.length === 2 && _.includes(roles, 705)) || roles.length === 1) && _.includes(roles, 704);
};

// Checks if the user has permissions to manage core data
export const isCoreDataManager = permissions => {
  return _.includes(permissions, 'ITMAPP.COREDATA.APPROVE');
};

export const isHidingProprietaryComments = permissions =>
  _.includes(permissions, 'ITMAPP.COMMENT.RETRIVE_BLOCK_PROPRIETARY');

const setStatusFilters = dataArray => [...dataArray, { param: 'status', val: [0, 20, 40] }];

const setTypeFilters = dataArray => [...dataArray, { param: 'type', val: [4, 8] }];

const setCommentFilters = dataArray => [
  ...dataArray,
  { param: 'comment', val: ['Item Status -> Proprietary Item'], operator: 'not like' }
];

const setOrderBy = () => {
  return { param: 'createdAt', val: 'asc' };
};

const updateDefaultFilters = (defaultFilters, updatedObject) => [...defaultFilters, { ...updatedObject }];

export const getOverallStatusFromText = (status, key) => {
  return _.find(OVERALL_REJECT_STATUSES, { [key]: status });
};

export const getLastElementOfArray = ({ arrayOfObjects, originalValue, comment }) => {
  if (arrayOfObjects && arrayOfObjects.length) {
    const { changedFrom, changedTo } = arrayOfObjects[arrayOfObjects.length - 1];
    return { existingValue: changedFrom, newValue: changedTo };
  }
  return { existingValue: originalValue, newValue: comment };
};

export const getDefaultFiltersForComments = (
  tableFilters,
  isSbs,
  isCoreManager,
  isHidingProprietaryFeedbacksPermission,
  orderByFilter,
  isLoadingFirstTime
) => {
  let defaultFilters = tableFilters.filter(obj => !['status', 'type', 'comment'].includes(obj.param));
  const statusFilters = _.find(tableFilters, obj => obj.param === 'status');
  const typeFilters = _.find(tableFilters, obj => obj.param === 'type');
  const commentFilters = _.find(tableFilters, obj => obj.param === 'comment');
  let defaultOrderBy = orderByFilter;

  // Setting default filters for associates who can update only core data
  if (isSbs) {
    if (isLoadingFirstTime) {
      defaultOrderBy = setOrderBy();
      defaultFilters = [...setStatusFilters(defaultFilters)];
      defaultFilters = [...setTypeFilters(defaultFilters)];
      if (isHidingProprietaryFeedbacksPermission) {
        defaultFilters = [...setCommentFilters(defaultFilters)];
      }
    } else {
      if (!statusFilters) {
        defaultFilters = [...setStatusFilters(defaultFilters)];
      } else {
        defaultFilters = [...updateDefaultFilters(defaultFilters, statusFilters)];
      }

      if (!typeFilters) {
        defaultFilters = [...setTypeFilters(defaultFilters)];
      } else {
        defaultFilters = [...updateDefaultFilters(defaultFilters, typeFilters)];
      }

      if (!commentFilters && isHidingProprietaryFeedbacksPermission) {
        defaultFilters = [...setCommentFilters(defaultFilters)];
      } else if (commentFilters && isHidingProprietaryFeedbacksPermission) {
        defaultFilters = [...updateDefaultFilters(defaultFilters, { ...commentFilters, operator: 'not like' })];
      }

      if (!orderByFilter) defaultOrderBy = setOrderBy();
    }
  }
  // Setting default filters for associates who does not have permission to update core data
  else if (!isCoreManager) {
    if (!typeFilters) {
      defaultFilters.push({ param: 'type', val: [1, 2, 3, 5, 6, 7, 9] });
    }
  }

  return { defaultFilters, defaultOrderBy };
};

export const getChildNutritionData = (pickList, selected) => {
  let isChildNutrition = false;
  let sleHasProductFormulationStmt = false;
  let hasSelectionRemoved = false;
  const [{ value } = { value: null }] = pickList.filter(({ id }) => id === selected);
  if (value === 'Product Formulation Statement') {
    sleHasProductFormulationStmt = true;
  } else if (value === 'Usda Child Nutrition (Cn) Labeling Program') {
    isChildNutrition = true;
  } else {
    hasSelectionRemoved = true;
  }
  return { isChildNutrition, sleHasProductFormulationStmt, hasSelectionRemoved };
};

// skipCnForMassUpdate is to skip CN for mass attribution update
export const devideTaxonomyAndSynchedTaxonomyChanges = (taxonomyChanges, attributes, skipCnForMassUpdate = false) => {
  const nutritionSynchedTaxonomyChangesOnly = [];
  const taxonomyChangesOnly = [];
  if (taxonomyChanges && taxonomyChanges.length) {
    taxonomyChanges.forEach(eachChange => {
      const { attrId } = eachChange;
      const foundAttribute = _.find(
        attributes,
        eachAttribute => Number(eachAttribute.attrId || eachAttribute.id) === Number(attrId)
      );
      if (foundAttribute && foundAttribute.name) {
        const { name } = foundAttribute;
        if (
          name.includes('Diet Type') ||
          name.includes('Allergen') ||
          (name.includes('Claim') && !name.includes('Natural')) ||
          (name.includes('Child Nutrition') && !skipCnForMassUpdate) // to remove cn from mass attribution
        ) {
          nutritionSynchedTaxonomyChangesOnly.push(eachChange);
        } else {
          taxonomyChangesOnly.push(eachChange);
        }
      }
    });
  }
  return [taxonomyChangesOnly, nutritionSynchedTaxonomyChangesOnly];
};

export const getPendingOrExistingCnCheckedValues = ({
  attrType,
  existingIsChildNutrition,
  existingSleHasProductFormulationStmt,
  isChildNutrition,
  sleHasProductFormulationStmt,
  pendingNutritions
}) => {
  if (!attrType && pendingNutritions && pendingNutritions.length) {
    return {
      isChildNutrition: existingIsChildNutrition,
      sleHasProductFormulationStmt: existingSleHasProductFormulationStmt
    };
  }
  return {
    isChildNutrition,
    sleHasProductFormulationStmt
  };
};

const getRejectedFeedbackComment = (review, rejectedFeedbackComment) => {
  if (review && Object.keys(review)) {
    const { status: feedbackStatus, comment: feedbackComment, code } = review;
    if (feedbackStatus === 'REJECT') {
      const { name } = _.find(REJECTED_REASON_CODES, { code });
      return `${name}. ${feedbackComment}`;
    }
  }
  return rejectedFeedbackComment;
};

export const getPfasClaimValue = (attributes, nameToCheck) => {
  const foundObject = attributes.find(({ name }) => name.toLowerCase().includes(nameToCheck));
  if (foundObject) {
    const { selected, picklist } = foundObject;
    if (selected) {
      const { value } = picklist.find(({ id }) => id === selected);
      return value;
    }
  }
  return null;
};

export const getDefaultValuesForPfas = (attributes, pfasClaimValue = false) => {
  let changes = {};
  attributes.forEach(({ attrId, selected, name, picklist }) => {
    let selectedValues = [...selected];
    // set pfas claim value if Product Level has no selections
    if (pfasClaimValue && !selected.length && name === 'Product Level') {
      let picklistValueToCheck = null;
      // get relevant picklist value related to PFAS Claim value
      if (pfasClaimValue.includes(PFAS_CLAIM_MAPPING_NAMES[0])) picklistValueToCheck = PFAS_MAPPING_NAMES[0];
      else if (pfasClaimValue.includes(PFAS_CLAIM_MAPPING_NAMES[1])) picklistValueToCheck = PFAS_MAPPING_NAMES[1];
      // find out the selected picklist
      const selectedPicklist = _.find(picklist, ({ value }) => value.includes(picklistValueToCheck));
      if (selectedPicklist) {
        selectedValues = [selectedPicklist.id];
      }
    }
    changes = {
      ...changes,
      [attrId]: [...selectedValues]
    };
  });
  return changes;
};

export const getDefaultValueForPfasClaim = (attributes, pfasAttributes, changes) => {
  // get Product Level selections
  const productLevelAttribute = _.find(pfasAttributes, ({ name }) => name === 'Product Level');
  if (productLevelAttribute) {
    const { picklist, attrId } = productLevelAttribute;
    const { id: containsId } = _.find(picklist, { value: PFAS_MAPPING_NAMES[0] });
    const notContainsIdList = _.map(
      _.filter(picklist, obj => obj.value !== PFAS_MAPPING_NAMES[0]),
      'id'
    );

    // const { id: notContainsId } = _.find(picklist, { value: PFAS_MAPPING_NAMES[1] });

    let pfasClaimSelectedValue = null;
    if (changes[attrId].includes(containsId)) {
      pfasClaimSelectedValue = PFAS_CLAIM_MAPPING_NAMES[0];
    } else if (_.intersection(changes[attrId], notContainsIdList).length) {
      pfasClaimSelectedValue = PFAS_CLAIM_MAPPING_NAMES[1];
    }
    // get Product Level selections for PFAS Claim
    const pfasClaimLevelAttribute = _.find(attributes, ({ name }) => name === 'PFAS Claim');
    if (pfasClaimLevelAttribute) {
      const pfasAttributeIndex = attributes.findIndex(obj => obj.attrId === pfasClaimLevelAttribute.attrId);
      let selectedPfasPicklistId = null;
      try {
        const { id } = _.find(pfasClaimLevelAttribute.picklist, ({ value }) => value.includes(pfasClaimSelectedValue));
        selectedPfasPicklistId = id;
      } catch (error) {}
      // update selected value in PFAS Claim
      return [
        ...attributes.slice(0, pfasAttributeIndex),
        {
          ...attributes[pfasAttributeIndex],
          pfasSelected: selectedPfasPicklistId
        },
        ...attributes.slice(pfasAttributeIndex + 1)
      ];

      // If the object with the specified ID is not found, return the original attributes
      // return attributes;
    }
  }
  return attributes;
};

const checkValueForNonUrls = (changeType, value) => {
  return IMAGE_CHANGE_TYPE_DESCRIPTIONS[changeType] || value;
};

const checkMetaIsContainingObjectData = meta => meta && meta !== '' && meta.includes('{');

const getStyleChangedImageUrl = imageComment => {
  let imageUrl = null;
  for (let i = 0; i < IMAGE_ORDER.length; i++) {
    if (checkArrayAndLength(imageComment[IMAGE_ORDER[i]])) {
      imageUrl = `${getOtmmBaseUrl()}/otmmstream/productimage/${imageComment[IMAGE_ORDER[i]]}`;
      break;
    }
  }
  return imageUrl;
};

export const getUrlsFromImageComments = (comments, type) => {
  if (comments && comments.length) {
    const imageUrls = [];
    comments.forEach(eachComment => {
      const { field, imgComment, status, comment, value } = eachComment;
      if (imgComment) {
        const { style, reservationId, review, changeType, meta } = imgComment;
        const rejectedFeedbackComment = getRejectedFeedbackComment(review, null);

        if (field === 'IMAGE') {
          if (checkMetaIsContainingObjectData(meta)) {
            try {
              const { mime } = JSON.parse(meta);
              if (dataSet.imageCategories[type].styles.includes(style) && mime && reservationId) {
                const imageUrl = `${getOtmmBaseUrl()}/otmmstream/s3image/${reservationId}.${getFormat(mime)}`;
                imageUrls.push({ url: imageUrl, style, status, rejectedFeedbackComment, comment, value });
              } else if (value && changeType === 'DELETE') {
                imageUrls.push({
                  url: value,
                  style,
                  status,
                  rejectedFeedbackComment,
                  comment,
                  value: checkValueForNonUrls(changeType, value)
                });
              } else if (
                changeType === 'STYLE_CHANGE' &&
                style &&
                dataSet.imageCategories[type].styles.includes(style)
              ) {
                imageUrls.push({
                  url: getStyleChangedImageUrl(imgComment),
                  style,
                  status,
                  rejectedFeedbackComment,
                  comment,
                  value: checkValueForNonUrls(changeType, value)
                });
              }
              // eslint-disable-next-line no-empty
            } catch (error) {
              console.log('error ', error);
            }
          } else if (
            dataSet.imageCategories[type].styles.includes(style) ||
            dataSet.imageCategories[type].styles.includes(style.toLowerCase())
          ) {
            imageUrls.push({
              comment,
              url: value,
              changeType,
              style,
              rejectedFeedbackComment,
              value: checkValueForNonUrls(changeType, value)
            });
          }
        }
      }
    });
    return imageUrls;
  }
  return [];
};

export const checkForRejectedImagesOrPendingImageDeletions = (comments, type) => {
  let disabledStatus = false;
  for (const { field, imgComment, status: commentStatus } of comments) {
    if (imgComment) {
      const { changeType, style } = imgComment;
      if (
        dataSet.imageCategories[type] &&
        dataSet.imageCategories[type].styles &&
        (dataSet.imageCategories[type].styles.includes(style) ||
          dataSet.imageCategories[type].styles.includes(style.toLowerCase())) &&
        field === 'IMAGE' &&
        (commentStatus === 10 || (changeType === 'DELETE' && commentStatus === 0))
      ) {
        disabledStatus = true;
        break;
      }
    }
  }
  return disabledStatus;
};

export const getEachSupcValidateImagesButtonDisabledStatus = changes => {
  let isButtonDisabled = true;
  if (changes) {
    try {
      Object.keys(changes).forEach(eachKey => {
        if (changes[eachKey] && Object.keys(changes[eachKey]).length) {
          const { selectedImagePreview, reservationId, isValidated, imageStyle, ...rest } = changes[eachKey];
          if (!selectedImagePreview) throw new Error();
          else isButtonDisabled = false;
        }
      });
    } catch (error) {
      isButtonDisabled = true;
    }
  }
  return isButtonDisabled;
};

export const calculateAvaregeLatencyForImageUpload = size =>
  uploadRequestAvgLatencyFor100kb * (size / 1000) * 0.01 * 0.5 * 1000;

export const getCoreDataValueOrDescription = (key, value) => {
  if (CORE_DATA_FIELDS[key] && CORE_DATA_FIELDS[key].list) {
    const selectedItem = _.find(CORE_DATA_FIELDS[key].list, { value });
    if (selectedItem) return selectedItem.label;
  }
  return value;
};

export const checkForExistingAndCurrentAttributeValueChangesAreNotEqual = (key, masterData, newValue) => {
  if (masterData && key && masterData[key] && masterData[key].value) {
    if (CORE_LIST_FIELDS.includes(key)) return masterData[key].value.valueId !== newValue;
    return masterData[key].value.value !== newValue;
  }
  if (masterData && !masterData[key]) {
    return true;
  }
  return false;
};

export const parseAsJson = value => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return false;
  }
};

export const isValidHierarchyName = name => {
  return !_.isUndefined(name) && !_.isNull(name);
};

export const isPartialHierarchy = (bc, ag) => {
  return isValidHierarchyName(bc) && !isValidHierarchyName(ag);
};

export const checkIfFilteredValuesExists = filteredValues => !Object.keys(filteredValues).length;

export const getSpecificAttributes = (attributes, specifics) => {
  let fiteredList = [];
  const {
    dietTypes,
    allergens,
    sustainabilityClaims,
    nutritionalClaims,
    otherAttributes,
    sustainabilityCertifications,
    nutritionalCertifications
  } = splitAttributes(attributes);

  if (_.isEmpty(specifics)) return attributes;
  if (_.includes(specifics, 'Diet Types')) fiteredList = [...dietTypes];
  if (_.includes(specifics, 'Allergens')) fiteredList = [...fiteredList, ...allergens];
  if (_.includes(specifics, 'Sustainability Claims')) fiteredList = [...fiteredList, ...sustainabilityClaims];
  if (_.includes(specifics, 'Nutritional Claims')) fiteredList = [...fiteredList, ...nutritionalClaims];
  if (_.includes(specifics, 'Product Attributes')) fiteredList = [...fiteredList, ...otherAttributes];
  if (_.includes(specifics, 'Sustainability Certifications'))
    fiteredList = [...fiteredList, ...sustainabilityCertifications];
  if (_.includes(specifics, 'Nutritional Certifications')) fiteredList = [...fiteredList, ...nutritionalCertifications];

  return fiteredList;
};

export const checkEachPfasAttributeIsDisabled = (appliedValues, attrId, singleSelections, eachPicklist) => {
  return (
    appliedValues[attrId] &&
    appliedValues[attrId].length &&
    !_.every(appliedValues[attrId], element => _.includes(singleSelections, element)) &&
    singleSelections.includes(eachPicklist.id)
  );
};

export const splitAttributes = attributes => {
  const dietTypes = [];
  const allergens = [];
  const sustainabilityClaims = [];
  const nutritionalClaims = [];
  const otherAttributes = [];
  const sustainabilityCertifications = [];
  const nutritionalCertifications = [];

  _.forEach(attributes, attr => {
    if (isDietTypeAttribute({ type: attr.type }, true)) {
      dietTypes.push(attr);
    } else if (isAllergenAttribute({ type: attr.type }, true)) {
      allergens.push(attr);
    } else if (isSustainabilityClaim(attr.type)) {
      sustainabilityClaims.push(attr);
    } else if (isNutritionalClaim(attr.type)) {
      nutritionalClaims.push(attr);
    } else if (isSustainabilityCertifications(attr.type)) {
      sustainabilityCertifications.push(attr);
    } else if (isNutritionalCertifications(attr.type)) {
      nutritionalCertifications.push(attr);
    } else {
      otherAttributes.push(attr);
    }
  });

  return {
    dietTypes: sortArray(dietTypes),
    allergens: sortArray(allergens),
    sustainabilityClaims: sortArray(sustainabilityClaims),
    nutritionalClaims: sortArray(nutritionalClaims),
    otherAttributes: sortArray(otherAttributes),
    sustainabilityCertifications: sortArray(sustainabilityCertifications),
    nutritionalCertifications: sortArray(nutritionalCertifications)
  };
};

export const getHierarchyFilters = ({ bc, ig, ag }) => {
  let filter = [];

  if (bc) filter.push({ param: 'bc', val: bc });
  if (ig) filter.push({ param: 'ig', val: ig });
  if (ag) filter.push({ param: 'ag', val: ag });

  return filter;
};

const getMassAttributionListValues = (values, field, defaultValue) => {
  return _.get(values, field, defaultValue);
};

export const mergeAttributesWithItems = (items, attributesList) => {
  return _.map(items, item => {
    const target = _.find(attributesList, obj => obj.supc === item.supc);
    const attributes = getMassAttributionListValues(target, 'attributes', []);
    const nutritions = getMassAttributionListValues(target, 'nutritions', {});
    const pendingNutrition = getMassAttributionListValues(target, 'pendingNutrition', {});
    const pendingNutritions = getMassAttributionListValues(target, 'pendingNutritions', {});
    const taxonomy = getMassAttributionListValues(target, 'taxonomy', {});

    return {
      ...item,
      attributes,
      nutritions,
      pendingNutrition,
      pendingNutritions,
      taxonomy
    };
  });
};

export const mergePfasWithItems = (items, pfasList) => {
  return _.map(items, item => {
    const target = _.find(pfasList, obj => obj.supc === item.supc);
    const pfasAttributes = getMassAttributionListValues(target, 'attributes', []);
    const taxonomyAttributes = getMassAttributionListValues(target, 'taxonomyAttributes', []);

    return {
      ...item,
      pfasAttributes,
      taxonomyAttributes
    };
  });
};

export const checkPfasChangesIncludesAttrId = (changes, attrId) => {
  if (changes && !changes[attrId]) {
    return { ...changes, [attrId]: [] };
  }
  return changes;
};

export const updateSingleItemDetailsFromTheList = (list, fieldToCheck, valueToCheck, valuesToSet, keyToSetValue) => {
  const indexToUpdate = list.findIndex(obj => obj[fieldToCheck] === valueToCheck);
  if (indexToUpdate !== -1) {
    return [
      ...list.slice(0, indexToUpdate),
      { ...list[indexToUpdate], [keyToSetValue]: valuesToSet },
      ...list.slice(indexToUpdate + 1)
    ];
  }
  return list;
};

export const handlePfasCheckBoxSelection = (changes, attrId, value, singleSelections) => {
  let updatedValues = [...value];
  // get the current checked new value
  const difference = [...value].filter(item => !changes[attrId].includes(item));

  // get the current selected value in singleSelections
  const commonValue = singleSelections.find(value => changes[attrId].includes(value));
  if (commonValue !== undefined) {
    updatedValues = updatedValues.filter(item => item !== commonValue);
  }
  // remove the current selected single selected value
  const [remainingValue] = singleSelections.filter(value => value !== difference[0]);

  // If the remaining value is in the updated values, remove it
  if (remainingValue && updatedValues.includes(remainingValue)) {
    updatedValues = updatedValues.filter(value => value !== remainingValue);
  }
  return updatedValues;
};

export const getCompleteHierarchyIds = ({ bcName, igName, agName, completeTaxonomy, isPartial }) => {
  const bcTarget = _.find(completeTaxonomy, obj => obj.name === bcName);
  const bcId = _.get(bcTarget, 'id', null);

  if (isPartial) {
    const igId = _.get(bcTarget, 'igs[0].id', null);
    const agId = _.get(bcTarget, 'igs[0].ags[0].id', null);
    return { bc: bcId, ig: igId, ag: agId };
  } else {
    const igs = _.get(bcTarget, 'igs', []);
    const igTarget = _.find(igs, obj => obj.name === igName);
    const igId = _.get(igTarget, 'id', null);

    const ags = _.get(igTarget, 'ags', []);
    const agTarget = _.find(ags, obj => obj.name === agName);
    const agId = _.get(agTarget, 'id', null);

    return { bc: bcId, ig: igId, ag: agId };
  }
};

export const getNutritionSourceOid = (nutritionSourceOid, gdsn, syscoBrand) => {
  if ([23, 31].includes(nutritionSourceOid)) return nutritionSourceOid;
  if (syscoBrand) return 23;
  if (gdsn) return 31;
  return nutritionSourceOid;
};

export const getGdsnOrSyscoBrandFlag = (nutritionSourceOid, { gdsn, syscoBrand } = {}) => {
  if (nutritionSourceOid == 31 || gdsn === 'Y') return 'gdsn';
  if (nutritionSourceOid == 23 || syscoBrand === 'Y') return 'sysco';
  return null;
};

export const getFormattedItemTaxonomyForMassAttribution = promisData => {
  let taxonomy = { attributes: [] };
  if (promisData && promisData.data && promisData.data) {
    taxonomy = { ...promisData.data };
  }
  return taxonomy;
};

const checkPromisIsFulfilled = status => status === 'fulfilled';

export const getNutritionsAndPending = (promisData, type, promiseStatus = 'fulfilled') => {
  let nutritions = {};
  let pendingNutrition = {};
  let comments = [];
  let taxonomy = {};
  if (checkPromisIsFulfilled(promiseStatus)) {
    switch (type) {
      case 'NUTRITION':
        nutritions = promisData;
        pendingNutrition = initializeNutritionData(promisData);
        break;
      case 'COMMENT':
        comments = promisData.data ? promisData.data.comments : [];
        break;
      case 'TAXONOMY':
        taxonomy = getFormattedItemTaxonomyForMassAttribution(promisData);
        break;
      default:
        break;
    }
  }
  return { nutritions, pendingNutrition, comments, taxonomy };
};

export const getPendingNutritionsForMassAttribution = (comments, existingPendingNutrition) => {
  let pendingNutrition = { ...existingPendingNutrition };
  if (comments && comments.length) {
    const [{ comment, status: commentStatus }] = comments;
    pendingNutrition = { ...JSON.parse(comment), status: commentStatus };
  }
  return pendingNutrition;
};

export const checkForPendingNutritionValues = (attributionId, pendingNutritions) => {
  let value = '';
  let pendingNutrition = false;
  if (pendingNutritions.length) {
    const { taxonomyChanges: changes } = JSON.parse(pendingNutritions[0].comment);
    if (changes && changes.length) {
      const selectedChange = changes.filter(({ attrId }) => Number(attrId) === Number(attributionId));
      if (selectedChange && selectedChange.length) {
        value = selectedChange[0].selected;
        pendingNutrition = true;
      }
    }
  }
  return { value, pendingNutrition };
};

export const getSelectedValueMassAttriutionField = (attribute, pendingNutritions, newValueId, valueId) => {
  let value = valueId;
  const { value: pendingNutritionValue, pendingNutrition } = checkForPendingNutritionValues(
    attribute,
    pendingNutritions
  );
  if (pendingNutrition) value = pendingNutritionValue || null;
  if (newValueId) value = newValueId;
  return value;
};

export const getMergedChangedOsdAttributes = (pendingNutrition, changedAttributes) => {
  const pendingNutritionOsdChanges = getPendingOsdNutritionChanges(pendingNutrition);
  return mergePendingAndOsdTaxonomyChanges(pendingNutritionOsdChanges, changedAttributes);
};

export const getMergedChangedTaxonomy = (attributes, mergedChangedOsdAttributes) => {
  return attributes.map(eachAttribute => {
    const item2 = mergedChangedOsdAttributes.find(i2 => Number(i2.attrId) === Number(eachAttribute.attrId));
    return item2 ? { ...eachAttribute, selected: Number(item2.selected) || null } : eachAttribute;
  });
};

export const setUpdatedDataForNutritionalFeedback = ({
  pendingNutrition,
  traits,
  nutritionalClaimsDetails,
  organicTradeItemCodes,
  trueVendorName,
  gln,
  suvc,
  updatedChangedFields
}) => {
  return {
    ...pendingNutrition,
    traits,
    nutritionalClaimsDetails,
    organicTradeItemCodes,
    manufacturer: {
      name: trueVendorName,
      gln,
      alternateIdentifier: `${suvc}01` // suvc + sequence Number or 01
    },
    changedFields: [...updatedChangedFields]
  };
};

export const pendingNutritionCommentParams = supc => {
  const params = [
    { param: 'supc', val: [supc], operator: 'like' },
    { param: 'type', val: [5] },
    { param: 'status', val: ['0', '10'] }
  ];

  return `?tablefilter=${encodeURIComponent(JSON.stringify(params))}`;
};

export const getPendingOsdNutritionChanges = pendingNutrition => {
  let pendingNutritionOsdChanges = [];
  if (pendingNutrition.taxonomyChanges) {
    pendingNutritionOsdChanges = [...pendingNutrition.taxonomyChanges];
  }
  return pendingNutritionOsdChanges;
};

export const mergePendingAndOsdTaxonomyChanges = (pendingNutritionOsdChanges, taxonomyChanges) => {
  if (pendingNutritionOsdChanges.length) {
    const mergedDataObject = _.merge(_.keyBy(pendingNutritionOsdChanges, 'attrId'), _.keyBy(taxonomyChanges, 'attrId'));
    return Object.keys(mergedDataObject).map(key => {
      return { ...mergedDataObject[key] };
    });
  }
  return [...taxonomyChanges];
};

export const getExistingUpdatedFieldsEnut = pendingNutrition => {
  let updatedChangedFields = [];
  if (pendingNutrition.changedFields) {
    updatedChangedFields = [...pendingNutrition.changedFields];
  }
  return updatedChangedFields;
};

export const getUpdatedOrganicStatusChangedFields = (organicTradeItemCodes, nutritions, updatedChangedFields) => {
  if (
    organicTradeItemCodes &&
    nutritions.organicTradeItemCodes &&
    organicTradeItemCodes[0] !== nutritions.organicTradeItemCodes[0]
  ) {
    return [...new Set([...updatedChangedFields, 'organicTradeItemCodes'])];
  }
  return updatedChangedFields;
};

// export const checkNutritionalFeedbackCreationOrUpdate = (
//   pendingNutrition,
//   formattedChangedAttributes,
//   taxonomy,
//   nutritions,
//   trueVendorName,
//   gln,
//   suvc,
//   hasNutritionSynchedTaxonomyAttributeChanges,
//   materialDescription,
//   gtin,
//   supc,
//   allAttributes,
//   stepId,
//   pendingNutritions
// ) => {
//   let response = {};
//   const pendingNutritionOsdChanges = getPendingOsdNutritionChanges(pendingNutrition);
//   const mergedChangedOsdAttributes = mergePendingAndOsdTaxonomyChanges(
//     pendingNutritionOsdChanges,
//     formattedChangedAttributes
//   );

//   const mergedChangedTaxonomy = taxonomy.attributes.map(eachAttribute => {
//     const item2 = mergedChangedOsdAttributes.find(i2 => Number(i2.attrId) === Number(eachAttribute.attrId));
//     return item2 ? { ...eachAttribute, selected: Number(item2.selected) || null } : eachAttribute;
//   });
//   const { traits, nutritionalClaimsDetails, organicTradeItemCodes } = osdNutrition.changeTaxonomyToNutrition({
//     nutritions,
//     taxonomy: { attributes: mergedChangedTaxonomy }
//   });

//   let updatedChangedFields = getExistingUpdatedFieldsEnut(pendingNutrition);

//   updatedChangedFields = [
//     ...getUpdatedOrganicStatusChangedFields(organicTradeItemCodes, nutritions, updatedChangedFields)
//   ];

//   let updatedData = {
//     ...pendingNutrition,
//     traits,
//     nutritionalClaimsDetails,
//     organicTradeItemCodes,
//     manufacturer: {
//       name: trueVendorName,
//       gln,
//       alternateIdentifier: `${suvc}01` // suvc + sequence Number or 01
//     },
//     changedFields: [...updatedChangedFields]
//   };

//   if (hasNutritionSynchedTaxonomyAttributeChanges) {
//     updatedData = { ...updatedData, taxonomyChanges: [...mergedChangedOsdAttributes] };
//   }

//   const { sleHasProductFormulationStmt } = pendingNutrition;
//   if (sleHasProductFormulationStmt && !nutritions.sleHasProductFormulationStmt) {
//     updatedData = {
//       ...updatedData,
//       sleProductId: null,
//       changedFields: [...new Set([...updatedData.changedFields, 'sleProductId'])]
//     };
//   }
//   if (!pendingNutrition.oid) {
//     updatedData = {
//       ...updatedData,
//       gtin,
//       gln,
//       alternateIdentifier: supc,
//       name: materialDescription
//     };
//   }

//   const [taxonomyChangesOnly, nutritionSynchedTaxonomyChangesOnly] = devideTaxonomyAndSynchedTaxonomyChanges(
//     formattedChangedAttributes,
//     allAttributes
//   );

//   const comment = {
//     id: null,
//     suvc,
//     stepId,
//     field: 'NUTRITION',
//     type: 'NUTRITION',
//     comment: JSON.stringify({ ...updatedData, taxonomyChanges: nutritionSynchedTaxonomyChangesOnly }),
//     originalValue: ''
//   };

//   const postData = {
//     comments: [comment],
//     stepId,
//     supc,
//     suvc
//   };

//   if (taxonomyChangesOnly && taxonomyChangesOnly.length) {
//     re
//     return patchRequest(`/suvc/${suvc}/items/${supc}/taxonomy`, {
//       comment: '',
//       attributes: taxonomyChangesOnly
//     });
//   }
//   if (pendingNutritions && pendingNutritions.length) {
//     const { id, status } = pendingNutritions[0];
//     return patchRequest(`/dash/comments/${id}/status/${status}`, {
//       commentId: id,
//       comment: JSON.stringify(updatedData),
//       status
//     });
//   }
// };

const isGdsnOrSyscoBrandedItemLevelIndicator = (gdsn, syscoBrand) => gdsn === 'Y' || syscoBrand === 'Y';

export const handleNutritionSynchedAvailability = (item, name, picklist, changedId, attrType) => {
  let hasNutritionSynchedTaxonomyAttributeChanges = false;
  const {
    pendingNutrition: {
      changedFields = [],
      isChildNutrition: existingIsChildNutrition,
      sleHasProductFormulationStmt: existingSleHasProductFormulationStmt
    } = { changedFields: [] },
    nutritions: { nutritionSourceOid, child_or_product_formulation: childNutritionData } = {},
    pendingNutritions,
    gdsn,
    syscoBrand,
    supc
  } = item;
  const isNutritionsSourcedFromQaOrGdsn =
    isDataSourcedFromQaSpecOrGdsn(nutritionSourceOid) || isGdsnOrSyscoBrandedItemLevelIndicator(gdsn, syscoBrand);
  if (name === CHILD_NUTRITION_ELIGIBILITY) {
    const { isChildNutrition, sleHasProductFormulationStmt, hasSelectionRemoved } = getChildNutritionData(
      picklist,
      changedId
    );
    let dataObject = {
      ...getPendingOrExistingCnCheckedValues({
        attrType,
        existingIsChildNutrition,
        existingSleHasProductFormulationStmt,
        isChildNutrition,
        sleHasProductFormulationStmt,
        pendingNutritions,
        supc
      })
    };
    if (hasSelectionRemoved && attrType) {
      dataObject = {
        ...dataObject,
        ...childNutritionData
      };
    }
    if (!isNutritionsSourcedFromQaOrGdsn) {
      dataObject = {
        ...dataObject,
        changedFields
      };
      // if (!(isChildNutrition || sleHasProductFormulationStmt)) {
      //   clearValidationErrorsForK12Information();
      // }
      if (
        (isChildNutrition || sleHasProductFormulationStmt) &&
        (isChildNutrition !== existingIsChildNutrition ||
          sleHasProductFormulationStmt !== existingSleHasProductFormulationStmt)
      ) {
        dataObject = {
          ...dataObject,
          changedFields: [...new Set([...changedFields, 'isChildNutrition', 'sleHasProductFormulationStmt'])]
        };
        // massAttributionNutritionSynchedFieldChangeHandle({ hasNutritionSynchedTaxonomyAttributeChanges: true, supc });
        hasNutritionSynchedTaxonomyAttributeChanges = true;
      }
    }
    // massAttributeItemNutritionFieldChange(dataObject);
  } else if (
    attrType &&
    ATTRIBUTE_TYPES_SYNCHED_WITH_NUTRITIONS.includes(attrType) &&
    NUTRITION_SYNCHED_TAXONOMY_ATTRIBUTE_NAMES_LIST.includes(name) &&
    // name !== 'Natural Claim' &&
    !isNutritionsSourcedFromQaOrGdsn
  ) {
    // massAttributionNutritionSynchedFieldChangeHandle({ hasNutritionSynchedTaxonomyAttributeChanges: true, supc });
    hasNutritionSynchedTaxonomyAttributeChanges = true;
  }
  return hasNutritionSynchedTaxonomyAttributeChanges;
};

export const checkMassAttributionChildNutritionEligibility = (
  { pendingNutrition: { nutritionSourceOid }, pendingNutritions },
  attributeName
) => ([21, 1].includes(nutritionSourceOid) || pendingNutritions.length) && attributeName.includes('Child Nutrition');

export const isNonFoodBc = (taxonomy, bcName) => {
  const target = _.find(taxonomy, obj => obj.name === bcName);
  const bcId = _.get(target, 'id', null);

  return _.includes(NONFOOD_BC_IDS, bcId);
};

export const addBulletOnNewLine = (event, field) => {
  if (field === 'descriptor') return event;

  const bullet = '\u2022';
  const bulletWithSpace = `${bullet} `;

  const { key, target } = event;
  const { selectionStart, value } = target;

  if (key === 'Enter') {
    target.value = [...value].map((c, i) => (i === selectionStart - 1 ? `\n${bulletWithSpace}` : c)).join('');

    target.selectionStart = selectionStart + bulletWithSpace.length;
    target.selectionEnd = selectionStart + bulletWithSpace.length;
  }

  return event;
};

export const getRequiredFnbFields = item => {
  let required = [...dataSet.requiredFnbCommonToFoodAndNonfood];

  if (!isNonFood(item)) {
    required.push('prep');
  }

  return required;
};

export const getDisabledOptionsDetailsForGdsnAttributes = (
  isFieldDisabledForSameValue,
  disabledMainKey,
  attributeName
) => {
  if (
    isFieldDisabledForSameValue &&
    DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.['enabled_same_fields']?.[attributeName]
  ) {
    return DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[disabledMainKey]?.['enabled_same_fields']?.[attributeName];
  }
  return {};
};

export const getUpdatedTaxonomy = (existingIndex, updatedTaxonomyData, attrId, selected) => {
  if (existingIndex > -1) {
    return [
      ...updatedTaxonomyData.slice(0, existingIndex),
      {
        ...updatedTaxonomyData[existingIndex],
        selected,
        attrId
      },
      ...updatedTaxonomyData.slice(existingIndex + 1)
    ];
  }
  return [...updatedTaxonomyData, { attrId: attrId.toString(), selected: selected?.toString() || null }];
};

export const removeFromUpdatedTaxonomy = (existingIndex, updatedTaxonomyData) => {
  const dataArray = [...updatedTaxonomyData];
  if (existingIndex > -1) {
    dataArray.splice(existingIndex, 1);
    return dataArray;
  }
  return dataArray;
};
export const checkValidationForThreeNutrientsInRequiredFields = (shortName, quantityContained) =>
  !(
    (quantityContained === '' || quantityContained === null) &&
    NOT_REQUIRED_NUTRIENTS_VARIED_SHORT_NAMES.includes(shortName)
  );

export const isPicklistOptionDisabledForGdsnAttributes = (
  isPositiveOptionsDisabled,
  enabledSameFieldDetails,
  value
) => {
  return (
    isPositiveOptionsDisabled > 0 &&
    enabledSameFieldDetails.enabledOptions &&
    !enabledSameFieldDetails.enabledOptions.includes(value)
  );
};

export const jsonParseReturnEmptyObject = jsonString => {
  try {
    return JSON.parse(jsonString);
  } catch (error) {
    return { changedFields: [] };
  }
};

export const getNutritionalFeedbackStatus = currentStatus => {
  if (currentStatus === 10) return { newValue: 20, statusText: 'FIXED' };
  return { newValue: currentStatus, statusText: '' };
};

// add default status filters to fnb, image & enut comment types
export const addDefaultStatusFilters = (newFilters, currentFilters, updatedFilters) => {
  let results = [...updatedFilters];
  const newTypeFilters = _.get(newFilters, 'type', []);
  const currentTypeFilters = _.find(currentFilters, obj => obj.param === 'type')?.val || [];

  // Type filters: 1=Image, 2=FnB, 4=overall, 5=Enut
  // Status filters: 0=Pending, 20=Fixed, 40=Review
  _.forEach([1, 2, 4, 5], i => {
    if (_.includes(newTypeFilters, i) && !_.includes(currentTypeFilters, i)) {
      const index = _.findIndex(updatedFilters, obj => obj.param === 'status');

      if (index !== -1) {
        let updatedStatusFilters = [..._.get(updatedFilters, `[${index}].val`, []), 0, 20];
        updatedStatusFilters = _.uniq(updatedStatusFilters);

        results[index] = { param: 'status', val: updatedStatusFilters };
      } else {
        results.push({ param: 'status', val: [0, 20] });
      }

      return false;
    }
  });

  return results;
};

export const formatDate = (date, format = 'MM/DD/YYYY') => {
  return moment(date || null).format(format);
};

export const isCooFieldDisabled = item => {
  const {
    basicData: { gdsn, syscoBrand },
    readOnly
  } = item;
  return readOnly || gdsn === 'Y' || syscoBrand === 'Y';
};

const getCountryName = value => {
  let countryName = '';
  const len = COUNTRY_CODE.length;
  for (let i = 0; i < len; i++) {
    if (value === COUNTRY_CODE[i].value) {
      countryName = COUNTRY_CODE[i].countryName;
      break;
    }
  }
  return countryName;
};

const getSubDivisionName = (countryVal, value) => {
  let ret = '';
  const subArr = COUNTRY_SUBDIVISION[countryVal];
  if (subArr) {
    const len = subArr.length;
    for (let i = 0; i < len; i++) {
      if (value === subArr[i].value) {
        ret = subArr[i].label;
        break;
      }
    }
  }
  return ret;
};

export const getItemIsGdsnOrNot = item => {
  return item && item.basicData && item.basicData.gdsn && item.basicData.gdsn === GDSN_VALUE;
};

export const checkIfPfasAttributeIsDisabled = (
  { bc: { id: businessCenterId } } = { bc: { id: null } },
  { name: pfasAttributeName } = { name: null }
) => {
  if (businessCenterId && pfasAttributeName) {
    if (pfasAttributeName === 'Product Level' && !_.includes(ENUT_EXCLUDED_BUSINESS_CENTERS, businessCenterId))
      return { disabledTooltip: PFAS_DISABLED_TOOLTIP_CONTENT };
    if (
      pfasAttributeName === 'Direct Food Contact Inner Packaging' &&
      _.includes(ENUT_EXCLUDED_BUSINESS_CENTERS, businessCenterId)
    )
      return { disabledTooltip: PFAS_DISABLED_TOOLTIP_CONTENT };
  }
  return { disabledTooltip: false };
};

export const checkIfPfasClaimIsPopulated = ({ attributes = [] }) => {
  const pfasClaimLevelAttribute = _.find(attributes, ({ name }) => name === 'PFAS Claim');
  if (pfasClaimLevelAttribute && pfasClaimLevelAttribute.selected) return true;
};

export const applyMassPfasFilterChanges = ({
  appliedValues: appliedFilters,
  selectedRows,
  changes,
  list: itemsList
}) => {
  let allPfasChanges = { ...changes };
  _.forEach(appliedFilters, (selections, index) => {
    _.forEach(selectedRows, eachSupc => {
      const { brandCode } = _.find(itemsList, { supc: eachSupc });
      if (brandCode !== 'PACKER') {
        allPfasChanges = {
          ...allPfasChanges,
          [eachSupc]: {
            ...allPfasChanges[eachSupc],
            [index]: selections
          }
        };
      }
    });
  });
  return allPfasChanges;
};

const checkSupcIsAvailable = (allChanges, supc) => {
  console.log('all changes ', allChanges);
  if (allChanges[supc]) {
    return allChanges[supc];
  }
  return [];
};

const setChangesOnPfas = (allChanges, supc, attrId, selected) => {
  return {
    ...allChanges,
    [supc]: [
      ...checkSupcIsAvailable(allChanges, supc),
      {
        attrId,
        selected
      }
    ]
  };
};

export const gatherAllMassPfasChangesForGivenItemList = (changedItemList, changes) => {
  let updatedItemsDataList = [];
  _.forEach(Object.keys(changedItemList), eachSupc => {
    const eachItemSelections = changes[eachSupc];
    updatedItemsDataList = {
      ...updatedItemsDataList,
      [eachSupc]: {}
    };
    _.forEach(eachItemSelections, (selection, attrId) => {
      updatedItemsDataList = {
        ...updatedItemsDataList,
        [eachSupc]: {
          ...updatedItemsDataList[eachSupc],
          [attrId]: selection
        }
      };
    });
  });
  return updatedItemsDataList;
};

export const getMassPfasSubmitButtonStatus = (changes, itemsList) => {
  let submittableChanges = {};
  if (itemsList && itemsList.length) {
    _.forEach(changes, (selections, eachIndexSupc) => {
      const { pfasAttributes } = _.find(itemsList, { supc: eachIndexSupc });
      _.forEach(pfasAttributes, ({ attrId, selected }) => {
        if (
          (selections[attrId] && selections[attrId].length != selected.length) ||
          (selections[attrId] &&
            selections[attrId].length === selected.length &&
            !(selected && selections[attrId].every(value => selected.includes(value))))
        ) {
          submittableChanges = { ...setChangesOnPfas(submittableChanges, eachIndexSupc, attrId, selections[attrId]) };
        }
      });
    });
  }
  return submittableChanges;
};

export const getBcIgAgFromScoreCards = scorecards => {
  const taxonomy = _.get(scorecards, 'taxonomy', {});
  const bc = _.get(scorecards.filterForGraphs, 'bc', null);
  const ig = _.get(scorecards.filterForGraphs, 'ig', null);
  const ag = _.get(scorecards.filterForGraphs, 'ag', null);
  return { taxonomy, bc, ig, ag };
};

export const formatPfasRequest = (changes, changedAttributes) => {
  let attributes = [];
  _.forEach(changes, (value, index) => {
    const attrId = Number(index);
    if (changedAttributes.includes(attrId)) {
      attributes = [
        ...attributes,
        {
          attrId: attrId,
          selected: [...value]
        }
      ];
    }
  });
  return attributes;
};

export const checkIfPfasHasChanges = item => {
  const {
    pfas: { attributes, changes }
  } = item;
  let hasPfasChanges = [];

  attributes.forEach(({ attrId, selected }) => {
    if (
      (changes && changes[attrId] && changes[attrId].length != selected.length) ||
      (changes &&
        changes[attrId] &&
        changes[attrId].length == selected.length &&
        !(selected && changes[attrId].every(value => selected.includes(value))))
    )
      hasPfasChanges = [...hasPfasChanges, attrId];
  });
  return hasPfasChanges;
};

export const formatCooStepParam = (supplierName, trueVendorName, cooData) => {
  let params = {
    supplier_username: supplierName,
    data_containers: {
      country_of_origin: []
    }
  };

  cooData.forEach(item => {
    const activityTypeVal = item[ACTIVITY_TYPE_KEY].value;
    const regionDesc = item[REGION_DESC_KEY].value;
    // const source = item.source;
    // const supplier = item.supplier;
    // const country_of_origin_code = item.country_of_origin_code;
    // const country_of_origin_subdivision_code = item.country_of_origin_subdivision_code;
    let countryObj = {};
    item.country.forEach(subItem => {
      const activityCodeVal = subItem[ACTIVITY_CODE_KEY].value;
      const activitySubCodeVal = subItem[ACTIVITY_SUBCODE_KEY].value;
      if (countryObj[activityCodeVal]) {
        countryObj[activityCodeVal].push(activitySubCodeVal);
      } else {
        countryObj[activityCodeVal] = [activitySubCodeVal];
      }
    });

    Object.keys(countryObj).forEach(key => {
      const activityCodeName = getCountryName(key);
      const activitySubCodeName = [];
      countryObj[key].forEach(subKey => {
        if (subKey) activitySubCodeName.push(subKey);
        // const subCodeName = getSubDivisionName(key, subKey);
        // if (subCodeName) activitySubCodeName.push(subCodeName);
      });

      const temp = {};
      if (activityTypeVal) temp.syy_itm_a_coo_productactivitytypecode = activityTypeVal;
      if (regionDesc) temp.syy_itm_a_coo_productactivityregiondesc = regionDesc;
      if (activityCodeName) {
        temp.syy_itm_a_coo_countryofactivitycode = activityCodeName;
        temp.syy_itm_a_coo_countryoforigincode = activityCodeName;
      }
      if (activitySubCodeName && activitySubCodeName.length) {
        temp.syy_itm_a_coo_countryofactivitysubcode = activitySubCodeName;
        temp.syy_itm_a_coo_countryoforiginsubcode = activitySubCodeName;
      }
      // if (source) {
      //   temp.syy_itm_a_coo_source = source;
      // } else {
      //   temp.syy_itm_a_coo_source = 'Supplier Portal';
      // }
      temp.syy_itm_a_coo_source = 'Supplier Portal';
      if (trueVendorName) temp.syy_itm_a_coo_supplier = trueVendorName;
      params.data_containers.country_of_origin.push(temp);
    });
  });

  const result = [];
  const map = new Map();
  for (const item of params.data_containers.country_of_origin) {
    const key = item.syy_itm_a_coo_productactivitytypecode + item.syy_itm_a_coo_countryofactivitycode;
    if (!map.has(key)) {
      if (item.syy_itm_a_coo_productactivityregiondesc) {
        item.syy_itm_a_coo_productactivityregiondesc = [item.syy_itm_a_coo_productactivityregiondesc];
      } else {
        item.syy_itm_a_coo_productactivityregiondesc = [];
      }
      if (item.syy_itm_a_coo_countryofactivitysubcode) {
        // Nothing to do
      } else {
        item.syy_itm_a_coo_countryofactivitysubcode = [];
      }
      map.set(key, item);
    } else {
      const existingItem = map.get(key);
      if (
        item.syy_itm_a_coo_productactivityregiondesc &&
        existingItem.syy_itm_a_coo_productactivityregiondesc.indexOf(item.syy_itm_a_coo_productactivityregiondesc) < 0
      ) {
        existingItem.syy_itm_a_coo_productactivityregiondesc.push(item.syy_itm_a_coo_productactivityregiondesc);
      }
      if (item.syy_itm_a_coo_countryofactivitysubcode && item.syy_itm_a_coo_countryofactivitysubcode.length) {
        existingItem.syy_itm_a_coo_countryofactivitysubcode.push(...item.syy_itm_a_coo_countryofactivitysubcode);
      }
    }
  }
  map.forEach(value => {
    if (value.syy_itm_a_coo_productactivityregiondesc.length) {
      value.syy_itm_a_coo_productactivityregiondesc = value.syy_itm_a_coo_productactivityregiondesc.join(',');
    } else {
      delete value.syy_itm_a_coo_productactivityregiondesc;
    }

    const uniqueArray = [...new Set(value.syy_itm_a_coo_countryofactivitysubcode)];
    if (uniqueArray.length) {
      value.syy_itm_a_coo_countryofactivitysubcode = uniqueArray;
      value.syy_itm_a_coo_countryoforiginsubcode = uniqueArray;
    } else {
      delete value.syy_itm_a_coo_countryofactivitysubcode;
      delete value.syy_itm_a_coo_countryoforiginsubcode;
    }
    result.push(value);
  });
  params.data_containers.country_of_origin = result;

  return params;
};

export const checkIsSyscoBrand = item => item && item.basicData && item.basicData.syscoBrand === 'Y';

export const getEmptyFsmaAttributes = attributes => {
  const emptyList = [];

  _.forEach(attributes, obj => {
    if (_.isEmpty(obj.selected)) {
      emptyList.push(obj.name);
    }
  });

  return emptyList;
};

export const getEmptyPackagingAttributes = attributes => {
  const emptyList = [];
  const heirarchyValue = _.get(attributes, 'packagingProductHierarchyLevel.decidedValue.valueName', null);

  if (_.isEmpty(attributes)) return emptyList;

  if (!heirarchyValue) {
    emptyList.push(attributes.packagingProductHierarchyLevel.name);
  }

  _.forEach(attributes.sections, section => {
    _.forEach(section, attribute => {
      const decidedValue = _.get(attribute, 'decidedValue', null);

      if (_.isEmpty(decidedValue)) {
        emptyList.push(attribute.name);
      }
    });
  });

  return _.uniq(emptyList);
};

export const getEmptyPackagingCertificationAttributes = attributes => {
  const emptyList = [];

  if (_.isEmpty(attributes)) return emptyList;

  _.forEach(attributes, attribute => {
    const decidedValue = _.get(attribute, 'decidedValue', null);

    if (_.isEmpty(decidedValue)) {
      emptyList.push(attribute.name);
    }
  });

  return _.uniq(emptyList);
};

export const checkForPackagingChanges = (originalObject, currentObject) => {
  const hasChanges = !_.isEqual(originalObject, currentObject);
  return { hasChanges };
};
