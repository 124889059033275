import React from 'react';
import { Tabs, Button, Icon, Spin } from 'antd';
import * as _ from 'lodash';
import { ArrowRightOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import {
  FnB_TYPE,
  GDSN_VALUE,
  CORE,
  SUBMIT_FOR_REVIEW_BUTTON_TITLE,
  CN_AND_PFM_KEYS,
  ENUT_EXCLUDED_BUSINESS_CENTERS
} from 'util/Constants';
import ProductInfo from './ProductInfo';
import MarketingInfo from './MarketingInfo';
import TaxonomyInfo from './TaxonomyInfo';
import FnBInfo from './FnBInfo';
import FieldComment from './FieldComment';
import ImageUpload from './ImageUpload';
import NutritionTab from './ItemNutritionTab/Index';
import ItemDatesBar from './ItemDatesBar';
import PackagingMainTab from './Packaging/PackagingMainTab';
import {
  getRejectStatus,
  checkStatusWithTwoAndConditions,
  getValuesFromTwoFields,
  getValueBasedOnTheCondition,
  getFieldKeyOrAssignDefaultValue,
  getTaxonomyDisabledStatusOnChildNutritionValue,
  checkForProductAttributeTabSaveButtonActionChangeNeed,
  willPassCurrentDate,
  checkIfPfasHasChanges,
  checkForPackagingChanges
} from '../util/Util';
import { createIndexedDbConnection } from '../util/Connection';
import { ACTIVITY_CODE_KEY, ACTIVITY_TYPE_KEY, CORE_DATA_REQUIRED_FIELDS } from '../util/CoreDataConstants';
import { PRODUCT_ATTRIBUTE_REJECT_FIELDS } from '../enums/index';
import { imageCategories } from '../util/Data';
import RegulatoryCompliance from './RegulatoryCompliance';
import openNotification from 'components/Notification';
import { PACKAGING_MANDATORY_FIELD_IDS, EMPTY_PACKAGING_POST_DATA } from '../util/Constants';

const indexedDb = createIndexedDbConnection('item-management');

class ItemView extends React.Component {
  constructor() {
    super();
    this.productInfoRef = React.createRef();
    this.productAttributesRef = React.createRef();
  }

  componentDidMount() {
    this.configureIndexedDb('supplier');
  }

  configureIndexedDb = async tableName => {
    try {
      await indexedDb.createObjectStore([{ tableName, indexes: 'supc', indexFieldArray: [] }]);
    } catch (error) {}
  };

  fetchAllSavedData = async tableName => {
    try {
      const allData = await indexedDb.getAllValue('supplier');
      if (allData && allData.length > 0) {
        const allSupcs = allData.map(eachData => {
          return eachData.supc;
        });
        this.props && this.props.actions.loadAutoSavedFnbComments({ autoSavedData: allSupcs });
      }
    } catch (error) {}
  };

  hasRejectedImages = () => {
    const comments = _.get(this.props.pendingComments, 'comments', []);
    let hasRejects = false;

    _.forEach(comments, obj => {
      if (obj.field === 'IMAGE' && obj.status === 10) {
        hasRejects = true;
      }
    });

    return hasRejects;
  };

  hasRejectedCoreData = () => {
    const comments = _.get(this.props.pendingComments, 'comments', []);
    let hasRejects = false;

    _.forEach(comments, obj => {
      // checking if rejected core data or overall rejections (within 30 days)
      if (
        (obj.field === CORE || (obj.type === 'OVERALL' && willPassCurrentDate(obj.updatedAt, 30))) &&
        obj.status === 10
      ) {
        hasRejects = true;
      }
    });

    return hasRejects;
  };

  hasValidationErrorsInCoreData = ({ coreData: { comment }, master }) => {
    let hasErrors = false;
    for (const [key, data] of Object.entries(comment)) {
      if (data.errorMessage) {
        hasErrors = true;
        break;
      }
    }
    for (const eachField of CORE_DATA_REQUIRED_FIELDS) {
      if (
        ((master[eachField] && master[eachField].value && master[eachField].value.value === '') ||
          !master[eachField]) &&
        !comment[eachField]
      ) {
        hasErrors = true;
        break;
      }
    }
    return hasErrors;
  };

  hasErrorsInCooData = ({ cooDatas }) => {
    let hasErrors = false;
    for (const item of cooDatas) {
      for (const [key, data] of Object.entries(item)) {
        if (key === ACTIVITY_TYPE_KEY && data.errMsg) {
          hasErrors = true;
          break;
        }
        for (const country of item.country) {
          for (const [subKey, countryData] of Object.entries(country)) {
            if (countryData.errMsg) {
              hasErrors = true;
              break;
            }
          }
          if (hasErrors) break;
        }
        if (hasErrors) break;
      }
      if (hasErrors) break;
    }

    return hasErrors;
  };

  getBrandCodeValue = item => {
    let value = '';
    if (
      item &&
      item.master &&
      item.master.syy_itm_a_brandcode_calc &&
      item.master.syy_itm_a_brandcode_calc.value &&
      item.master.syy_itm_a_brandcode_calc.value.value
    ) {
      value = item.master.syy_itm_a_brandcode_calc.value.value;
    }
    return value;
  };

  getStatusAndCommentForPendingNutrition = pendingNutrition => {
    const { feedback: { status, comments } = {} } = pendingNutrition || {};
    return { status, comments };
  };

  checkNutritionsRejectedStatus = pendingNutrition =>
    this.getStatusAndCommentForPendingNutrition(pendingNutrition).status === 'REJECT';

  getRejectedNutritionFeedbackForAttributionTab = pendingNutrition => {
    const { status, comments } = this.getStatusAndCommentForPendingNutrition(pendingNutrition);
    if (
      status === 'REJECT' &&
      comments &&
      Object.keys(comments).length &&
      PRODUCT_ATTRIBUTE_REJECT_FIELDS.some(feedbackKey => Object.keys(comments).includes(feedbackKey))
    ) {
      return true;
    }
    return false;
  };

  getRejectedTitleValue = (rejectedMessage, rejectedNutritions, key, supc, pendingNutrition) => {
    let value = '';
    if (
      (key === 2 && rejectedMessage.length > 0) ||
      (key === 3 && this.hasRejectedImages()) ||
      (key === 5 && rejectedNutritions.includes(supc) && this.checkNutritionsRejectedStatus(pendingNutrition)) ||
      // (key === 5 && rejectedNutritions.includes(supc)) ||
      (key === 4 &&
        rejectedNutritions.includes(supc) &&
        this.getRejectedNutritionFeedbackForAttributionTab(pendingNutrition)) ||
      (key === 1 && this.hasRejectedCoreData())
    ) {
      value = '• ';
    }
    return value;
  };

  setDisplayBcIgAg = (bc, ig, ag) => {
    if (bc && ig && ag) {
      return (
        <div className="taxo-label-wrapper">
          <div title="Business Center">{bc}</div>
          <Icon className="taxo-label-arrow" type="right" />
          <div title="Item Group">{ig}</div>
          <Icon className="taxo-label-arrow" type="right" />
          <div title="Attribute Group">{ag}</div>
        </div>
      );
    }
    return <Spin size="small" style={{ marginTop: '4px' }} title="Waiting for Taxonomy..." />;
  };

  getSpecificCommentMessage = (key, dataArray, commentMessage) => {
    let commentMsg = commentMessage;
    if (dataArray.length > 0 && dataArray[0].selected !== '') {
      commentMsg += `\n[ ${key} -> ' + dataArray[0].selected + ' ] `;
    }
    return commentMsg;
  };

  getImageUploadOrFieldCommentFields = (fieldComment, actions, item, gdsnFnb, isGdsnFnbLoading, isGdsnFetchedOnce) => {
    if (['IMAGE_NEW', 'IMAGE_DELETE', 'IMAGE_STYLE', 'IMAGE_FEEDBACK', 'IMAGE_REPLACE'].includes(fieldComment.type)) {
      return <ImageUpload fieldComment={fieldComment} pendingComments={this.props.pendingComments} actions={actions} />;
    }
    return (
      <FieldComment
        isSubmitting={this.props.isSubmitting}
        fieldComment={fieldComment}
        reviewComment={item ? (item.fnb ? item.fnb.review : []) : []}
        actions={actions}
        gdsnFnb={gdsnFnb}
        isGdsnFnbLoading={isGdsnFnbLoading}
        isGdsnFetchedOnce={isGdsnFetchedOnce}
      />
    );
  };

  getTabColor = (key, rejectMessage, rejectedNutritions, supc, pendingNutrition) => {
    return (
      ((key === 2 && rejectMessage.length > 0) ||
        (key === 3 && this.hasRejectedImages()) ||
        (key === 5 && rejectedNutritions.includes(supc) && this.checkNutritionsRejectedStatus(pendingNutrition)) ||
        // (key === 5 && rejectedNutritions.includes(supc)) ||
        (key === 4 &&
          rejectedNutritions.includes(supc) &&
          this.getRejectedNutritionFeedbackForAttributionTab(pendingNutrition)) ||
        (key === 1 && this.hasRejectedCoreData())) &&
      '#c1380f'
    );
  };

  handleNutritionMappingChanges = (actions, taxonomyChanges) => {
    let dataObject = {};
    if (taxonomyChanges) dataObject = { ...dataObject, taxonomyChanges };
    actions.nutritionValuesChanged(dataObject);
  };

  shuffleChangedAttributesWithCurrentAttributes = () => {
    const { actions } = this.props;
    actions.tabChanged({ tab: 5 });
  };

  getTaxonomySaveButtonDetails = (status, taxonomySaveFunction) => {
    let dataObject = {
      buttonCaption: 'SAVE',
      buttonAction: taxonomySaveFunction
    };
    if (status) {
      dataObject = {
        buttonCaption: 'NAVIGATE TO NUTRITION TAB',
        buttonAction: this.shuffleChangedAttributesWithCurrentAttributes
      };
    }
    return dataObject;
  };

  getRemovedChildNutritionChangedFields = pendingNutrition => {
    if (pendingNutrition && pendingNutrition.changedFields) {
      return pendingNutrition.changedFields.filter(changedField => !CN_AND_PFM_KEYS.includes(changedField));
    }
    return [];
  };

  addPreviousCnChangedFieldsIfExists = (changedFields, previousChangedFields) => {
    if (previousChangedFields) {
      if (previousChangedFields.includes(CN_AND_PFM_KEYS[0]) || previousChangedFields.includes(CN_AND_PFM_KEYS[1])) {
        return [...changedFields, ...CN_AND_PFM_KEYS];
      }
    }
    return changedFields;
  };

  resetTabsAndRemovedSynchedEnutValues = resetTabs => {
    const {
      item: { pendingNutritions, pendingNutrition, nutritions },
      actions
    } = this.props;
    let changedTaxonomyValues = [];
    let changedFields = this.getRemovedChildNutritionChangedFields(pendingNutrition);
    let { isChildNutrition, sleHasProductFormulationStmt } = nutritions;
    if (pendingNutritions && pendingNutritions.length) {
      const {
        taxonomyChanges,
        changedFields: previousChangedFields,
        isChildNutrition: childNutrition,
        sleHasProductFormulationStmt: productFormulationStatement
      } = JSON.parse(pendingNutritions[0].comment);
      isChildNutrition = childNutrition;
      sleHasProductFormulationStmt = productFormulationStatement;
      changedFields = [...this.addPreviousCnChangedFieldsIfExists(changedFields, previousChangedFields)];
      if (taxonomyChanges) changedTaxonomyValues = taxonomyChanges;
    }
    actions.nutritionValuesChanged({
      taxonomyChanges: changedTaxonomyValues,
      changedFields,
      isChildNutrition,
      sleHasProductFormulationStmt
    });
    resetTabs();
  };

  getTaxonomyTabFooterButtons = (item, resetTabs, saveTaxonomy) => {
    const status = checkForProductAttributeTabSaveButtonActionChangeNeed(item); // comment this line & set status to false when disabling enut application, to enable enut set status = checkForProductAttributeTabSaveButtonActionChangeNeed(item)
    const { buttonCaption, buttonAction } = this.getTaxonomySaveButtonDetails(status, saveTaxonomy);
    const { hasNutritionSynchedTaxonomyAttributeChanges } = item;
    let title = 'SAVE';
    let style = 'footer-btn btn-confirm';
    let caption = buttonCaption;
    if (status) {
      title = 'Go to Nutrition Tab';
      style = 'footer-btn btn-navigate';
      caption = <ArrowRightOutlined />;
    }
    return (
      item.taxonomyChanges &&
      item.taxonomyChanges.length > 0 &&
      item.activeTab === 4 && (
        <div className="btn-footer">
          <Button
            className="footer-btn btn-cancel"
            onClick={() => this.resetTabsAndRemovedSynchedEnutValues(resetTabs)}
          >
            CANCEL
          </Button>
          <Button
            className={style}
            onClick={() => buttonAction()} // comment below disabled status when disabling enut application
            disabled={
              getTaxonomyDisabledStatusOnChildNutritionValue(item) && !hasNutritionSynchedTaxonomyAttributeChanges
            }
            title={title}
          >
            {caption}
          </Button>
        </div>
      )
    );
  };

  getCoreDataTabFooterButtons = (
    item,
    isCoreDataSubmitable,
    isCoreDataSubmitting,
    isCooDataSubmitable,
    isCooDataSubmitting,
    resetTabs,
    saveFeedback
  ) => {
    return (
      ((item.feedback && item.feedback != null) || isCoreDataSubmitable || isCooDataSubmitable) &&
      item.activeTab === 1 && (
        <div className="btn-footer">
          <Button className="footer-btn btn-cancel" onClick={resetTabs}>
            CANCEL
          </Button>
          <Button
            className="footer-btn btn-confirm"
            onClick={saveFeedback}
            disabled={
              (item.feedback && !item.feedback.validate) ||
              (isCoreDataSubmitable && (isCoreDataSubmitting || this.hasValidationErrorsInCoreData(item))) ||
              (isCooDataSubmitable && (isCooDataSubmitting || this.hasErrorsInCooData(item)))
            }
          >
            {SUBMIT_FOR_REVIEW_BUTTON_TITLE}
          </Button>
        </div>
      )
    );
  };

  getPfasFooterButtons = (item, actions) => {
    if (item.activeTab === 6 && checkIfPfasHasChanges(item).length && item.regulatorySubTab === '1') {
      const {
        pfas: { isLoadingOrSaving }
      } = item;
      const savingButtonText = isLoadingOrSaving ? 'SAVING...' : 'SAVE';
      return (
        <div className="btn-footer">
          <Button
            className="footer-btn btn-cancel"
            disabled={isLoadingOrSaving}
            onClick={() => actions.pfasClearSelections()}
          >
            CANCEL
          </Button>
          <Button
            className="footer-btn btn-confirm"
            disabled={isLoadingOrSaving}
            onClick={() => actions.PfasUpdate(item)}
          >
            {savingButtonText}
          </Button>
        </div>
      );
    }
  };

  getFsmaFooterButtons = (item, actions) => {
    if (item.activeTab === 6 && item.regulatorySubTab === '2' && !_.isEmpty(item.fsma.changes)) {
      const saveButtonText = this.props.isSavingFsma ? 'SAVING...' : 'SAVE';

      return (
        <div className="btn-footer">
          <Button
            className="footer-btn btn-cancel"
            disabled={this.props.isSavingFsma}
            onClick={() => actions.setFsmaChanges([])}
          >
            CANCEL
          </Button>
          <Button
            className="footer-btn btn-confirm"
            disabled={this.props.isSavingFsma}
            onClick={() =>
              actions.saveFsmaChanges({ supc: item.supc, attributes: item.fsma.changes, username: this.props.username })
            }
          >
            {saveButtonText}
          </Button>
        </div>
      );
    }
  };

  getInvalidMandatoryPackagingFields(sections) {
    return _.compact(
      _.flatMap(sections, section =>
        _.map(PACKAGING_MANDATORY_FIELD_IDS, mandatoryFieldId => {
          const field = _.find(section, { stepId: mandatoryFieldId });
          return field && (_.isNil(field.decidedValue) || _.isEmpty(field.decidedValue)) ? field.stepId : null;
        })
      )
    );
  }

  hasPrimaryPackagingLevel(sections) {
    return _.some(sections, section => {
      const packagingLevelField = _.find(section, { stepId: 'packaging_level' });
      return packagingLevelField && _.get(packagingLevelField, 'decidedValue.valueName') === 'Primary';
    });
  }

  checkSpecificPackagingValidation = (conditionFieldId, requiredFieldId) => {
    let checkPassed = true;

    _.forEach(this.props.currentPackaging.sections, section => {
      const conditionField = _.find(section, { stepId: conditionFieldId });
      const conditionValue = _.get(conditionField, 'decidedValue', null);

      if (!_.isEmpty(conditionValue)) {
        const requiredField = _.find(section, { stepId: requiredFieldId });
        const requiredValue = _.get(requiredField, 'decidedValue', null);

        if (_.isEmpty(requiredValue)) {
          checkPassed = false;
          return false;
        }
      }
    });

    return checkPassed;
  };

  checkPackagingTypeOrMaterial = () => {
    let isValid = true;

    _.forEach(this.props.currentPackaging.sections, section => {
      const packagingType = _.find(section, { stepId: 'packaging_type' });
      const packagingMaterialType = _.find(section, { stepId: 'packaging_material_type' });

      const packagingTypeValue = _.get(packagingType, 'decidedValue', null);
      const packagingMaterialTypeValue = _.get(packagingMaterialType, 'decidedValue', null);

      if (_.isEmpty(packagingTypeValue) && _.isEmpty(packagingMaterialTypeValue)) {
        isValid = false;
        return false;
      }
    });

    return isValid;
  };

  isPackagingDataValid = () => {
    const { currentPackaging } = this.props;

    // Packaging Product Hierarchy Level validation
    if (_.isEmpty(currentPackaging?.packagingProductHierarchyLevel?.decidedValue)) {
      openNotification({
        className: 'error',
        message: 'Invalid Input',
        description: 'Packaging Product Hierarchy Level cannot be empty'
      });
      return false;
    }

    // Primary level validation - Commenting out due to a request during UAT
    // const hasPrimaryLevel = this.hasPrimaryPackagingLevel(currentPackaging.sections);
    // if (!hasPrimaryLevel) {
    //   openNotification({
    //     className: 'error',
    //     message: 'Invalid Input',
    //     description: 'There should be at least one packaging primary level'
    //   });
    //   return false;
    // }

    // Mandatory fields validations
    const inValidMandatoryFields = this.getInvalidMandatoryPackagingFields(currentPackaging.sections);
    if (!_.isEmpty(inValidMandatoryFields)) {
      openNotification({
        className: 'error',
        message: 'Invalid Input',
        description: 'Mandatory fields cannot be empty'
      });
      return false;
    }

    // Packaging Type/Packaging Material Type validation - Commenting out since this logic is no longer valid
    // const isPassed1 = this.checkPackagingTypeOrMaterial();

    // if (!isPassed1) {
    //   openNotification({
    //     className: 'error',
    //     message: 'Invalid Input',
    //     description: 'Each packaging level must have a value for either Packaging Type or Packaging Material Type'
    //   });
    //   return false;
    // }

    // Packaging Type/Packaging Weight validation
    const isPassed2 = this.checkSpecificPackagingValidation('packaging_type', 'packaging_weight');

    if (!isPassed2) {
      openNotification({
        className: 'error',
        message: 'Invalid Input',
        description: 'Packaging Weight cannot be empty'
      });
      return false;
    }

    // Packaging Weight/Packaging Weight UOM validation
    const isPassed3 = this.checkSpecificPackagingValidation('packaging_weight', 'packaging_weight_uom');

    if (!isPassed3) {
      openNotification({
        className: 'error',
        message: 'Invalid Input',
        description: 'Packaging Weight UOM cannot be empty'
      });
      return false;
    }

    // Packaging Post Consumer Recyclability validation
    const isPassed4 = this.checkSpecificPackagingValidation(
      'packaging_post_consumer_recyclable_type',
      'packaging_post_consumer_recyclability'
    );

    if (!isPassed4) {
      openNotification({
        className: 'error',
        message: 'Invalid Input',
        description: `Packaging Post Consumer Recyclability (%) cannot be empty`
      });
      return false;
    }

    // Packaging Post Industrial Recyclability validation
    const isPassed5 = this.checkSpecificPackagingValidation(
      'packaging_post_industrial_recyclable_type',
      'packaging_post_industrial_recyclability'
    );

    if (!isPassed5) {
      openNotification({
        className: 'error',
        message: 'Invalid Input',
        description: `Packaging Post Industrial Recyclability (%) cannot be empty`
      });
      return false;
    }

    // Packaging Post Consumer Recyclability/Packaging Component Description validation
    const isPassed6 = this.checkSpecificPackagingValidation(
      'packaging_post_consumer_recyclable_type',
      'packaging_component_description'
    );

    if (!isPassed6) {
      openNotification({
        className: 'error',
        message: 'Invalid Input',
        description: 'Packaging Component Description cannot be empty'
      });
      return false;
    }

    // Packaging Post Industrial Recyclability/Packaging Component Description validation
    const isPassed7 = this.checkSpecificPackagingValidation(
      'packaging_post_industrial_recyclable_type',
      'packaging_component_description'
    );

    if (!isPassed7) {
      openNotification({
        className: 'error',
        message: 'Invalid Input',
        description: 'Packaging Component Description cannot be empty'
      });
      return false;
    }

    return true;
  };

  getFormattedPackagingData = () => {
    const { originalPackaging, currentPackaging, username, deletedSections } = this.props;

    const hierarchyPath = 'packagingProductHierarchyLevel.decidedValue.valueName';
    const hierarchyValue = _.get(currentPackaging, hierarchyPath, null);
    let postData = { supplier_username: username };
    let packaging = [];

    // Format Hierarchy value
    if (hierarchyValue !== _.get(originalPackaging, hierarchyPath, null)) {
      postData = {
        ...postData,
        attributes: {
          syy_itm_a_pkg_producthierarchylevel: hierarchyValue
        }
      };
    }

    // Format attributes of each section
    _.forEach(currentPackaging.sections, section => {
      const result = {};

      _.forEach(section, attribute => {
        const decidedValue = attribute.decidedValue;
        let value = null;

        if (_.isString(decidedValue)) {
          value = decidedValue;
        } else if (_.isArray(decidedValue)) {
          value = _.map(decidedValue, obj => obj.valueName);
        } else if (_.isObject(decidedValue) && !_.isArray(decidedValue)) {
          value = decidedValue.valueName;
        }

        if (!_.isEmpty(value)) result[attribute.mdmAttributeId] = value;
      });

      if (!_.isEmpty(result)) packaging.push(result);
    });

    // Format section deletions
    const packageTypes = _.map(packaging, 'syy_itm_a_pkg_type');
    const actualDeletions = _.filter(deletedSections, deleted => !packageTypes.includes(deleted));

    _.forEach(actualDeletions, deleted => {
      const deletedAttribue = { ...EMPTY_PACKAGING_POST_DATA };
      deletedAttribue['syy_itm_a_pkg_type'] = deleted;
      packaging.push(deletedAttribue);
    });

    if (!_.isEmpty(packaging)) {
      postData = { ...postData, data_containers: { packaging } };
    }

    return postData;
  };

  savePackagingData = () => {
    const { item, actions } = this.props;
    const isValid = this.isPackagingDataValid();

    if (isValid) {
      const postData = this.getFormattedPackagingData();
      actions.savePackageChanges({ supc: item.supc, postData, updateType: 'PACKAGING' });
    }
  };

  getPackagingFooterButtons = (item, actions) => {
    if (item.activeTab === 7 && item.packagingTab.selectedTab === 1 && this.props.hasPackagingChanges) {
      const saveButtonText = this.props.isSavingPackaging ? 'SAVING...' : 'SAVE';

      return (
        <div className="btn-footer">
          <Button
            className="footer-btn btn-cancel"
            disabled={this.props.isSavingPackaging}
            onClick={() => actions.resetPackaging('PACKAGING')}
          >
            CANCEL
          </Button>
          <Button
            className="footer-btn btn-confirm"
            disabled={this.props.isSavingPackaging}
            onClick={this.savePackagingData}
          >
            {saveButtonText}
          </Button>
        </div>
      );
    }
  };

  savePackagingCertificationsData = () => {
    let postData = { supplier_username: this.props.username };
    let attributes = {};

    this.props.currentCertifications.forEach(attribute => {
      const currentValue = _.get(attribute, 'decidedValue.valueName', null);
      const originalAttribute = _.find(this.props.originalCertifications, { stepId: attribute.stepId });
      const originalValue = _.get(originalAttribute, 'decidedValue.valueName', null);

      if (currentValue !== originalValue) {
        attributes[attribute.mdmAttributeId] = attribute.decidedValue.valueName;
      }
    });

    postData.attributes = attributes;
    this.props.actions.savePackageChanges({ supc: this.props.item.supc, postData, updateType: 'CERTIFICATION' });
  };

  getPackagingCertificationFooterButtons = (item, actions) => {
    if (item.activeTab === 7 && item.packagingTab.selectedTab === 2 && this.props.hasCertificationChanges) {
      const saveButtonText = this.props.isSavingPackaging ? 'SAVING...' : 'SAVE';

      return (
        <div className="btn-footer">
          <Button
            className="footer-btn btn-cancel"
            disabled={this.props.isSavingPackaging}
            onClick={() => actions.resetPackaging('CERTIFICATION')}
          >
            CANCEL
          </Button>
          <Button
            className="footer-btn btn-confirm"
            disabled={this.props.isSavingPackaging}
            onClick={this.savePackagingCertificationsData}
          >
            {saveButtonText}
          </Button>
        </div>
      );
    }
  };

  getItemWrapperClassName = (item, fieldComment) => {
    return (
      item &&
      item.basicData &&
      item.basicData.gdsn === GDSN_VALUE &&
      fieldComment.type === FnB_TYPE &&
      'item-view-wrapper-with-gdsn-fnb'
    );
  };

  getStylesForSupcExistance = item => {
    return item && item.supc && item.supc !== '' ? 'block' : 'none';
  };

  getMarginBotttomForTabs = (item, isCoreDataSubmitable, isCooDataSubmitable) => {
    return (item.taxonomyChanges && item.taxonomyChanges.length > 0 && item.activeTab !== 5) ||
      (((item.feedback && item.feedback != null) || isCoreDataSubmitable || isCooDataSubmitable) &&
        item.activeTab === 1)
      ? '100px'
      : '0px';
  };

  getRejectMessage = item => {
    return getValuesFromTwoFields(getRejectStatus(item && item.fnb && item.fnb.review), []);
  };

  checkBcAndAppendNutritionAndPfasTab = (bcId, allTabs, item, actions) => {
    const tabs = [...allTabs];
    if (bcId) {
      tabs.push({
        key: 6,
        title: 'Regulatory Compliance',
        content: <RegulatoryCompliance item={item} actions={actions} />
      });
      if (!_.includes(ENUT_EXCLUDED_BUSINESS_CENTERS, bcId)) {
        tabs.push({
          key: 5,
          title: 'Nutrition',
          content: <NutritionTab />
        });
      }
    }
    return tabs;
  };

  executeTabSpecificAction = (tabSpecificActionsObject, activeTab) => {
    if (tabSpecificActionsObject[activeTab]) {
      tabSpecificActionsObject[activeTab]();
    }
  };

  formatPreferredImageData = (unsavedPreferredImgs, item) => {
    const { supc, suvc, basicData } = item;
    let formatted = [];

    _.forOwn(unsavedPreferredImgs, (assetId, styleBucket) => {
      const imageData = _.find(item.images, img => img.assetId === assetId);
      const styleBucketText = imageCategories[styleBucket]?.caption;
      const requester = item.readOnly ? 'Internal Request' : 'Supplier Request';

      const data = {
        supc,
        suvc: `${suvc}`,
        comment: `Preferred Image - ${styleBucketText} (${requester})`,
        style: _.get(imageData, 'imageStyle', null),
        meta: JSON.stringify({ styleBucket, trigger: item.readOnly ? 'INTERNAL_REQUEST' : 'SUPPLIER_REQUEST' }),
        stepId: basicData.stepId,
        otmmAssetId: assetId,
        otmmThumbnailRenditionId: _.get(imageData, 'renditions.thumbnail.id', null),
        otmmMasterRenditionId: _.get(imageData, 'renditions.master.id', null),
        otmmPreviewRenditionId: _.get(imageData, 'renditions.preview.id', null)
      };

      formatted.push(data);
    });

    return formatted;
  };

  renderSubmitPreferredImgsBtn = () => {
    const { actions, unsavedPreferredImgs, submittingPreferredImgs, item } = this.props;
    const data = this.formatPreferredImageData(unsavedPreferredImgs, item);

    const onCancel = () => actions.setUnsavedPreferredImgs({ preferred: {} });
    const onSubmit = () =>
      actions.submitPreferredImgs({ data, suvc: item.suvc, supc: item.supc, unsavedPreferredImgs });

    if (!_.isEmpty(unsavedPreferredImgs) && item.activeTab === 3) {
      return (
        <div className="btn-footer">
          <Button className="footer-btn btn-cancel" onClick={onCancel} disabled={submittingPreferredImgs}>
            CANCEL
          </Button>
          <Button
            className="footer-btn btn-confirm"
            onClick={onSubmit}
            disabled={submittingPreferredImgs}
            loading={submittingPreferredImgs}
          >
            MARK AS PREFERRED
          </Button>
        </div>
      );
    }
  };

  render() {
    const {
      item,
      actions,
      fieldComment,
      pendingComments,
      rejectedNutritions,
      gdsnFnb,
      isGdsnFnbLoading,
      isGdsnFetchedOnce,
      isCoreDataSubmitable,
      isCoreDataSubmitting,
      nutritionErrors,
      pendingNutrition,
      rejectedPreferredImgs,
      pendingOverallFeedbackHistory,
      userData,
      feeExclusions,
      isCooDataSubmitable,
      isCooDataSubmitting,
      supplierName
    } = this.props;
    const { TabPane } = Tabs;
    const rejectMessage = this.getRejectMessage(item);

    const getRejectTitle = key => {
      return this.getRejectedTitleValue(rejectMessage, rejectedNutritions, key, item.supc, pendingNutrition);
    };

    const unLoadItemDetails = () => {
      actions.unLoadItemDetails();
      this.fetchAllSavedData('supplier');
    };

    const tabSpecificActionsObject = {
      1: this.productInfoRef?.current?.handleCancel,
      4: this.productAttributesRef?.current?.handleCancel
    };

    const resetTabs = () => {
      actions.resetTabs({ tab: parseInt(getValuesFromTwoFields(item.activeTab, 0)) });
      this.executeTabSpecificAction(tabSpecificActionsObject, item.activeTab);
      actions.resetCurrentCoreDataValues();
    };

    const brandCode = () => {
      const value = this.getBrandCodeValue(item);
      return checkStatusWithTwoAndConditions(value === 'PACKER', true, false);
    };

    let tabs = [
      {
        key: 1,
        title: 'Product Core Data',
        content: (
          <ProductInfo
            ref={this.productInfoRef}
            item={item}
            actions={actions}
            brand={brandCode()}
            pendingComments={pendingComments}
            isCoreDataSubmitable={isCoreDataSubmitable}
            pendingOverallFeedbackHistory={pendingOverallFeedbackHistory}
            feeExclusions={feeExclusions}
          />
        )
      },
      {
        key: 2,
        title: 'Features and Benefits',
        content: (
          <FnBInfo
            item={item}
            actions={actions}
            pendingComments={pendingComments}
            rejectMessage={rejectMessage}
            readOnly={item.readOnly}
            isGdsnFetchedOnce={isGdsnFetchedOnce}
          />
        )
      },
      {
        key: 3,
        title: 'Product Images',
        content: (
          <MarketingInfo
            item={item}
            actions={actions}
            pendingComments={pendingComments}
            rejectedPreferredImgs={rejectedPreferredImgs}
          />
        )
      },
      {
        key: 4,
        title: 'Product Attributes',
        content: (
          <TaxonomyInfo
            ref={this.productAttributesRef}
            item={item}
            actions={actions}
            pendingComments={pendingComments}
            nutritionErrors={nutritionErrors}
            pendingNutrition={pendingNutrition}
          />
        )
      },
      {
        key: 7,
        title: 'Packaging Attributes',
        content: <PackagingMainTab readOnly={item.readOnly} />
      }
    ];

    const bcId = _.get(item, 'taxonomy.bc.id', null); // comment these bcId and tabs lines when disabling enut application
    tabs = [...this.checkBcAndAppendNutritionAndPfasTab(bcId, tabs, item, actions)];

    let commentText = '';
    let existingComment = null;
    pendingComments.comments.forEach(comment => {
      if (comment.field === 'overall-item') {
        commentText = comment.comment;
        existingComment = comment;
      }
    });

    const commentsPopup = () => {
      actions.commentsPopup({
        item,
        field: 'overall-item',
        currentValue: '',
        fieldType: 'OVERALL',
        fieldCaption: ' ',
        existingComment,
        hint: 'You can provide an overall feedback for this item.'
      });
    };

    const createOrUpdateCoreDataComment = () => {
      actions.createUpdateCoreData({ ...this.props.item });
    };

    const pushCooDataToStep = () => {
      actions.pushCooData({ ...this.props.item, supplierName });
    };

    const validateCooData = () => {
      let isValidate = true;
      const {
        item: { cooDatas }
      } = this.props;
      cooDatas.forEach((item, activityIndex) => {
        if (!item[ACTIVITY_TYPE_KEY].value) {
          isValidate = false;
          actions.changeCooDataFieldValue({
            key: ACTIVITY_TYPE_KEY,
            activityIndex,
            errMsg: 'Please Select Activity Type'
          });
        } else {
          cooDatas[activityIndex].country.forEach((subItem, countryIndex) => {
            if (!subItem[ACTIVITY_CODE_KEY].value) {
              isValidate = false;
              actions.changeCooDataFieldValue({
                key: ACTIVITY_CODE_KEY,
                activityIndex,
                countryIndex,
                errMsg: 'Please Select Country of Activity Code'
              });
            }
          });
        }
      });
      return isValidate;
    };

    const saveFeedback = () => {
      if (item.feedback) {
        let existingFeedbackComment = null;
        pendingComments.comments.forEach(comment => {
          if (comment.field === 'PRODUCT_STATUS') {
            existingFeedbackComment = comment;
          }
        });
        let commentMessage = '';
        item.feedback.changers.forEach(({ title, selected, name }) => {
          if (name === 'Item Status') {
            commentMessage = `[ ${title} -> ${selected} ] `;
          } else {
            commentMessage += `\n[ ${title} -> ${selected} ] `;
          }
        });

        const comment = {
          field: 'PRODUCT_STATUS',
          originalValue: '',
          type: 'OVERALL',
          comment: commentMessage,
          supc: item.supc,
          suvc: item.suvc,
          stepId: item.basicData.stepId,
          id: getFieldKeyOrAssignDefaultValue(existingFeedbackComment, 'id', null)
        };
        actions.sendFeedbackComment({ comment, pendingOverallFeedbackHistory, userData });
      }
      if (isCoreDataSubmitable) {
        createOrUpdateCoreDataComment();
      }
      if (isCooDataSubmitable && validateCooData()) {
        pushCooDataToStep();
      }
    };

    const saveTaxonomy = () => {
      actions.saveTaxonomy({ ...item });
    };

    const onTabClick = key => {
      const { originalPackaging, currentPackaging, originalCertifications, currentCertifications } = this.props;
      const { hasChanges: hasPackagingChanges } = checkForPackagingChanges(originalPackaging, currentPackaging);
      const { hasChanges: hasCertificationChanges } = checkForPackagingChanges(
        originalCertifications,
        currentCertifications
      );

      if (item.activeTab === 7 && (hasPackagingChanges || hasCertificationChanges)) {
        openNotification({
          className: 'error',
          message: 'Unsaved Changes',
          description: 'Please save your unsaved changes'
        });
      } else {
        actions.tabChanged({ tab: parseInt(key) });
      }
    };

    const bc = _.get(item, 'taxonomy.bc.name', null);
    const ig = _.get(item, 'taxonomy.ig.name', null);
    const ag = _.get(item, 'taxonomy.ag.name', null);

    return (
      <div style={{ display: this.getStylesForSupcExistance(item) }}>
        <div className="item-view-overlay" onClick={unLoadItemDetails}></div>
        {fieldComment && fieldComment.loading && <div className="transparent-screen-lock"></div>}
        <div className={`item-view-wrapper ${this.getItemWrapperClassName(item, fieldComment)}`}>
          {this.getImageUploadOrFieldCommentFields(
            fieldComment,
            actions,
            item,
            gdsnFnb,
            isGdsnFnbLoading,
            isGdsnFetchedOnce
          )}

          <div className="item-view-title-bar">
            <div className="item-view-title-name">
              <div title={item.basicData.materialDescription}>
                {item.basicData.materialDescription}
                &nbsp;
              </div>
              <div className="supc" title="SUPC. This is the product code used in Sysco">
                &nbsp;(
                {item.basicData.supc})
              </div>
              <div
                className={`overall-feedback${checkStatusWithTwoAndConditions(commentText !== '', ' pending', '')}`}
                title="You can provide feedback for this item."
                style={{ pointerEvents: item.readOnly && 'none', display: 'none' }}
                onClick={commentsPopup}
              ></div>
            </div>
            <div className="item-view-title-name sub-title">
              <div title="Brand name">
                {item.basicData.brandName}
                &nbsp;
              </div>
              <div title="Manufacturer product code">
                {getValueBasedOnTheCondition(
                  checkStatusWithTwoAndConditions(
                    item.basicData.manufactProdCode,
                    item.basicData.manufactProdCode !== ''
                  ),
                  ` | ${item.basicData.manufactProdCode}`,
                  ''
                )}
              </div>
            </div>
            {this.setDisplayBcIgAg(bc, ig, ag)}
            <ItemDatesBar />
          </div>

          <Tabs
            activeKey={(item.activeTab || item.defaultTab).toString()}
            tabPosition="top"
            style={{
              height: '100%',
              marginBottom: this.getMarginBotttomForTabs(item, isCoreDataSubmitable, isCooDataSubmitable)
            }}
            animated={false}
            onTabClick={activeTabKey => onTabClick(activeTabKey)}
            className={getValueBasedOnTheCondition(item.activeTab === 5, 'nutrition-tab-active', '')}
          >
            {tabs.map(({ title, key, content }) => (
              <TabPane
                tab={
                  <span
                    style={{
                      color: this.getTabColor(key, rejectMessage, rejectedNutritions, item.supc, pendingNutrition)
                    }}
                  >
                    {`${getRejectTitle(key)} ${title}`}
                  </span>
                }
                key={key}
              >
                {content}
              </TabPane>
            ))}
          </Tabs>
          {this.getTaxonomyTabFooterButtons(item, resetTabs, saveTaxonomy)}
          {this.renderSubmitPreferredImgsBtn()}
          {this.getCoreDataTabFooterButtons(
            item,
            isCoreDataSubmitable,
            isCoreDataSubmitting,
            isCooDataSubmitable,
            isCooDataSubmitting,
            resetTabs,
            saveFeedback
          )}
          {this.getPfasFooterButtons(item, actions)}
          {this.getFsmaFooterButtons(item, actions)}
          {this.getPackagingFooterButtons(item, actions)}
          {this.getPackagingCertificationFooterButtons(item, actions)}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state, ownProps) {
  return {
    rejectedNutritions: state.vendor.rejectedNutritions,
    gdsnFnb: _.get(state.selectedItem, 'fnb.gdsnFnb', { fnb: {} }),
    isGdsnFnbLoading: _.get(state.selectedItem, 'fnb.isFetchingGdsnFnb', false),
    isGdsnFetchedOnce: _.get(state.selectedItem, 'fnb.isGdsnFetchedOnce', false),
    isCoreDataSubmitable: state.selectedItem.isCoreDataSubmitable,
    isCoreDataSubmitting: state.selectedItem.isCoreDataSubmitting,
    coreData: state.selectedItem.coreData,
    nutritionErrors: state.selectedItem.nutritionErrors,
    pendingNutrition: state.selectedItem.pendingNutrition,
    unsavedPreferredImgs: state.selectedItem.imageTab.unsavedPreferredImgs,
    submittingPreferredImgs: state.selectedItem.imageTab.submittingPreferredImgs,
    rejectedPreferredImgs: state.selectedItem.imageTab.rejectedPreferredImgs,
    isSubmitting: _.get(state.selectedItem, 'pending', false),
    pendingOverallFeedbackHistory: state.pendingComments.pendingOverallFeedbackHistory,
    userData: state.user.data,
    feeExclusions: state.selectedItem.feeExclusions,
    isCooDataSubmitable: state.selectedItem.isCooDataSubmitable,
    isCooDataSubmitting: state.selectedItem.isCooDataSubmitting,
    supplierName: state.user.data.name,
    isSavingFsma: state.selectedItem.fsma.isSavingFsma,
    hasPackagingChanges: state.selectedItem.packagingTab.hasPackagingChanges,
    hasCertificationChanges: state.selectedItem.packagingTab.hasCertificationChanges,
    currentCertifications: state.selectedItem.packagingTab.currentCertifications,
    originalCertifications: state.selectedItem.packagingTab.originalCertifications,
    username: state.user.data.username,
    isSavingPackaging: state.selectedItem.packagingTab.isSavingPackaging,
    originalPackaging: state.selectedItem.packagingTab.originalPackaging,
    currentPackaging: state.selectedItem.packagingTab.currentPackaging,
    deletedSections: state.selectedItem.packagingTab.deletedSections
  };
}

export default connect(mapStateToProps, null)(ItemView);
