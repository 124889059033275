import React from 'react';
import { Redirect } from 'react-router-dom';
import * as _ from 'lodash';

class ProtectedRoute extends React.Component {
  getRouteVariables = (type, userPermissions, path) => {
    switch (type) {
      case 'SupplierRoute':
        return this.getVariablesForSupplierRoutes(userPermissions, path);
      case 'AssociateRoute':
        return this.getVariablesForAssociateRoute(userPermissions, path);
      default:
        return [false, '/suite/dashboard'];
    }
  };

  getVariablesForAssociateRoute = (userPermissions, path) => {
    if (path === '/suite/item-management/internal') {
      if (_.includes(userPermissions, 'ITMAPP.INTERNAL.RETRIVE')) return [true, path];
    } else if (path === '/suite/item-management/dashboard' || path === '/suite/item-management/feedback') {
      if (_.includes(userPermissions, 'ITMAPP.FEEDBACK.RETRIVE')) return [true, path];
    } else if (path === '/suite/item-management/itemvisibility') {
      if (_.includes(userPermissions, 'ITMAPP.ITMVISI.RETRIVE')) return [true, path];
    }
    return [false, '/suite/dashboard'];
  };

  getVariablesForSupplierRoutes = (userPermissions, path) => {
    if (_.includes(userPermissions, 'ITMAPP.ITEM.RETRIVE')) {
      return [true, path];
    }
    return [false, '/suite/dashboard'];
  };

  render() {
    const Component = this.props.component;
    let { userPermissions, type, path } = this.props;

    const [isAuthorized, redirectPath] = this.getRouteVariables(type, userPermissions, path);

    return isAuthorized ? <Component /> : <Redirect to={{ pathname: redirectPath }} />;
  }
}

export default ProtectedRoute;
