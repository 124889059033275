import React from 'react';
import { Input } from 'antd';
import { replaceFirstBullets } from '../../util/Util';

const { TextArea } = Input;

export default function FnBInputField({
  supc,
  attribute,
  value,
  onChangeAttribute,
  maxLength,
  isRequired,
  isInValid,
  isDisabled
}) {
  return (
    <div className="fnb-mass-update-cell">
      {isRequired && <div className={`fnb-required ${isInValid ? 'fnb-required-red' : ''}`}>*</div>}
      <TextArea
        className={`fnb-mass-update-value ${isInValid ? 'invalid-field' : ''}`}
        rows={6}
        value={replaceFirstBullets(value)}
        maxLength={maxLength}
        disabled={isDisabled}
        onChange={e => onChangeAttribute(supc, attribute, e.target.value)}
      />
    </div>
  );
}
