import React from 'react';
import { Input } from 'antd';
import { checkForFieldPendingNutrition } from '../../util/Util';
import RejectFeedback from './RejectFeedback';
import CustomTooltip from './Tooltip';

const { TextArea } = Input;

const IngredientStatement = ({
  onFieldChange,
  value,
  isReadOnly,
  isItemReadOnly,
  changedFields,
  pendingNutritions,
  nutritionData,
  isDisabledBySyscoOrGdsnItemFlag
}) => {
  const isInValid = false;

  const handleOnChange = newValue => {
    onFieldChange({
      ingredientStatement: newValue,
      changedFields: [...new Set([...changedFields, 'ingredientStatement'])]
    });
  };

  return (
    <div className="nt-section">
      <div className="nt-title ingre-stat-title with-enut-tooltip">
        Ingredient Statement
        <span className="required-fields">* </span>
        <CustomTooltip tooltipKey="ingredient_statement" />
      </div>
      <RejectFeedback field={'ingredientStatement'} nutritionData={nutritionData} />
      <div className="text-limit-indicator">{value && value.length}/5000</div>
      <TextArea
        className={`txt-ingre-statement ${isInValid ? 'nt-invalid-field' : ''} ${checkForFieldPendingNutrition(
          'ingredientStatement',
          pendingNutritions
        )}`}
        value={value}
        rows={10}
        maxLength={5000}
        disabled={isReadOnly || isItemReadOnly || isDisabledBySyscoOrGdsnItemFlag}
        onChange={e => handleOnChange(e.target.value)}
      />
    </div>
  );
};

export default IngredientStatement;
