import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import FeaturesAndBenifits from './FeaturesAndBenifits';
import { GDSN_VALUE } from 'util/Constants';
import _ from 'lodash';

class FnBInfo extends React.Component {
  constructor(props) {
    super(props);
    this.featuresAndBenefits = React.createRef();
    this.state = {
      commentShow: []
    };
    this.toggleComment = this.toggleComment.bind(this);
    this.sendComments = this.sendComments.bind(this);
    this.collectComments = this.collectComments.bind(this);
  }

  componentDidMount() {
    const gtin = _.get(this.props.item, 'basicData.gtin', null);
    const gdsn = _.get(this.props.item, 'basicData.gdsn', null);

    if (gdsn === GDSN_VALUE && !this.props.isGdsnFetchedOnce) {
      this.props.actions.getGdsnFnb({ gtin });
    }
  }

  toggleComment(key) {
    let commentShow = this.state.commentShow;
    commentShow[key] = !commentShow[key];
    this.setState({
      commentShow: commentShow
    });
  }

  collectComments(attrKey, attrValue, comment, type) {
    let { actions } = this.props;
    actions.collectComment({
      comment: {
        field: attrKey,
        originalValue: attrValue,
        type: type,
        comment: comment
      }
    });
  }

  sendComments() {
    let { actions, item } = this.props;
    actions.sendComments({ ...item });
  }

  onRejectErrorClick = () => {
    this.featuresAndBenefits.current.commentsPopup();
  };

  render() {
    let { item, actions, pendingComments, rejectMessage, readOnly } = this.props;

    let nonEditMode =
      item && item.fnb && !item.fnb.cannotEditComment && item.taxonomy && item.taxonomy.bc && item.taxonomy.bc.name
        ? ''
        : ' non-edit';

    return (
      <ReactResizeDetector
        handleWidth
        handleHeight
        render={({ width, height }) => (
          <div className={`attr-panel marketing${nonEditMode}`} style={{ height: window.innerHeight - 177 + 'px' }}>
            <div className="attr-section">
              {rejectMessage.length > 0 && (
                <div className={`pending-feedback-section ${readOnly ? 'feedback-read-only' : ''}`}>
                  <div className="comment-row reject" onClick={this.onRejectErrorClick}>
                    <div className="type">Feedback Rejected/Denied : </div>
                    <div className="comment" style={{ maxWidth: '90%' }}>
                      {rejectMessage.join(' | ')}
                    </div>
                  </div>
                </div>
              )}
              <div className="attr-section-title">{'Features & Benefits'}</div>
              <div className="attr-section-subtitle">Please review the {'Features & Benefits'} statement.</div>
              <div className="attr-set">
                <div className="attr-wrapper-full">
                  <FeaturesAndBenifits
                    ref={this.featuresAndBenefits}
                    item={item}
                    pendingComments={pendingComments}
                    actions={actions}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      />
    );
  }
}

export default FnBInfo;
