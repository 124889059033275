import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import AppLoader from '../components/AppLoader';
import * as batchesActions from 'actions';
import ItemView from 'components/ItemView';
import ItemList from '../components/ItemList';

function ItemManagementPage({
  state,
  suvcDetails,
  actions,
  selectedItem,
  itemsFetching,
  listFetching,
  suvc,
  list,
  fieldComment,
  pendingComments,
  search,
  subSuvc,
  page,
  recordCount,
  scorecards,
  summaryItems,
  graphFilter,
  rejectedFnbs,
  rejectedImages,
  overallSummary,
  fetchingOverallSummary,
  fnbRejectFilterOn,
  autoSavedFnb,
  imageRejectFilterOn,
  feeFilterOn,
  isAutoSavedFnbFilterOn,
  tableFilters,
  tableSearchData,
  brandGroupFilters,
  orderBy,
  subSuvcFilters,
  isFiltersComplex,
  feeExclusionsSupcs,
  isInternal,
  rejectedNutritions,
  nutritionRejectFilterOn,
  rejectedCoreData,
  coreDataRejectFilterOn,
  rejectedOverall
}) {
  useEffect(() => {
    if (list == null && suvc != null && !listFetching) {
      actions.loadItemList({
        suvc: suvc,
        page: 1,
        search: '',
        graphfilter: null
      });
      actions.loadScores({ suvc: suvc });
      actions.loadRejectedSupcs({ suvc });
      actions.getFeeExclusionsCount(suvc);
    }
  }, [list, actions, listFetching, suvc]);

  if (itemsFetching) {
    return <AppLoader show />;
  }

  return (
    <React.Fragment>
      {itemsFetching && <AppLoader show />}
      {selectedItem && selectedItem.supc && (
        <ItemView
          suvc={suvc}
          item={selectedItem}
          actions={actions}
          fieldComment={fieldComment}
          pendingComments={pendingComments}
        />
      )}
      <ItemList
        suvc={suvc}
        list={list}
        actions={actions}
        selectedSupc={selectedItem && selectedItem.supc ? selectedItem.supc : ''}
        search={search}
        page={page}
        recordCount={recordCount}
        suvcDetails={suvcDetails}
        subSuvc={subSuvc}
        scorecards={scorecards}
        summaryItems={summaryItems}
        graphFilter={graphFilter}
        rejectedFnbs={rejectedFnbs}
        rejectedImages={rejectedImages}
        overallSummary={overallSummary}
        fetchingOverallSummary={fetchingOverallSummary}
        fnbRejectFilterOn={fnbRejectFilterOn}
        autoSavedFnb={autoSavedFnb}
        imageRejectFilterOn={imageRejectFilterOn}
        feeFilterOn={feeFilterOn}
        isAutoSavedFnbFilterOn={isAutoSavedFnbFilterOn}
        tableFilters={tableFilters}
        tableSearchData={tableSearchData}
        brandGroupFilters={brandGroupFilters}
        orderBy={orderBy}
        subSuvcFilters={subSuvcFilters}
        isFiltersComplex={isFiltersComplex}
        feeExclusionsSupcs={feeExclusionsSupcs}
        isInternal={isInternal}
        rejectedNutritions={rejectedNutritions}
        nutritionRejectFilterOn={nutritionRejectFilterOn}
        rejectedCoreData={rejectedCoreData}
        coreDataRejectFilterOn={coreDataRejectFilterOn}
        rejectedOverall={rejectedOverall}
      />
    </React.Fragment>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    user: state.user.data,
    userPermissions: state.user.data.permissions,
    selectedItem: state.selectedItem,
    itemsFetching: state.vendor.fetching || state.selectedItem.fetching || state.vendor.list == null,
    listFetching: state.vendor.fetching,
    list: state.vendor.list,
    suvc: ownProps.isInternal ? state.vendor.selectedSuvc : state.vendor.suvc,
    fieldComment: state.fieldComment,
    pendingComments: state.pendingComments,
    page: state.page.page,
    search: state.page.search,
    subSuvc: state.page.subSuvc,
    recordCount: state.page.recordCount,
    suvcDetails: state.vendor.details,
    scorecards: state.scorecards,
    summaryItems: state.scorecards.summaryItems,
    graphFilter: state.page.graphfilter,
    rejectedFnbs: state.vendor.rejectedFnbs,
    rejectedImages: state.vendor.rejectedImages,
    rejectedNutritions: state.vendor.rejectedNutritions,
    overallSummary: state.scorecards.overallSummary,
    fetchingOverallSummary: state.scorecards.fetchingOverallSummary,
    fnbRejectFilterOn: state.vendor.fnbRejectFilterOn,
    autoSavedFnb: state.vendor.autoSavedFnb,
    imageRejectFilterOn: state.vendor.imageRejectFilterOn,
    feeFilterOn: state.vendor.feeFilterOn,
    isAutoSavedFnbFilterOn: state.vendor.isAutoSavedFnbFilterOn,
    tableFilters: state.vendor.tableFilters,
    tableSearchData: state.vendor.tableSearchData,
    brandGroupFilters: state.vendor.brandGroupFilters,
    orderBy: state.vendor.orderBy,
    subSuvcFilters: state.vendor.details.subSuvcs,
    isFiltersComplex: state.vendor.isFiltersComplex,
    feeExclusionsSupcs: state.vendor.feeExclusionsSupcs,
    nutritionRejectFilterOn: state.vendor.nutritionRejectFilterOn,
    rejectedCoreData: state.vendor.rejectedCoreData,
    rejectedOverall: state.vendor.rejectedOverall,
    coreDataRejectFilterOn: state.vendor.coreDataRejectFilterOn
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(batchesActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(ItemManagementPage));

ItemManagementPage.propTypes = {};
