import React from 'react';
import { DISABLED_SOURCE_IDS } from '../../util/Constants';
import { getNutritionSourceOid } from '../../util/Util';

const DISABLED_SOURCE_ID_MESSAGES = {
  23: (
    <>
      Updates may not be made in this portal to disabled fields. This product is a <b>Sysco Brand private label item</b>
      , please update your product data in the Sysco Stibo QA specification database. For access questions, contact your
      Program Quality Manager or email fnc_ssdatabase_000@corp.sysco.com.
    </>
  ),
  31: (
    <>
      Updates may not be made in this portal to disabled fields. This product is a{' '}
      <b>GDSN-published, national brand item</b>, please update your product data with your data pool partner and be
      sure to publish your changes to Sysco (GLN 0074865000000).
    </>
  )
};

const DisabledNutritionMessage = ({ nutritions: { nutritionSourceOid }, gdsn, syscoBrand }) => {
  if (DISABLED_SOURCE_IDS.includes(nutritionSourceOid) || gdsn || syscoBrand) {
    const sourceId = getNutritionSourceOid(nutritionSourceOid, gdsn, syscoBrand);
    return (
      <div className="nt-disabled-message">
        <span className="nt-disabled-message nt-text-span">{DISABLED_SOURCE_ID_MESSAGES[sourceId]}</span>
      </div>
    );
  }
  return null;
};

export default DisabledNutritionMessage;
