import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTranslation } from 'react-i18next';
import { Select, Input } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import _ from 'lodash';

import * as batchesActions from 'actions';
import ItemManagementPage from './Item';
import FreeItemsListPage from './freeItemsList';
import AppLoader from '../components/AppLoader';
import { removeFromLocalStorage, getValueBasedOnTheCondition } from '../util/Util';

const { Option } = Select;

function InternalItems({ selectedSuvc, allVendors, actions, search, freeSearch, fetching, permissions }) {
  useEffect(() => {
    actions.loadAllVendors();
  }, [actions]);

  const handleSuvcFilter = userSelectedSuvc => {
    let requestData = {
      suvc: userSelectedSuvc,
      page: 1,
      graphfilter: null,
      getHierarchy: true,
      freeSearch: false,
      search: ''
    };

    actions.loadItemList(requestData);
    if (userSelectedSuvc) {
      actions.loadScores({ suvc: userSelectedSuvc });
      actions.loadRejectedSupcs({ suvc: userSelectedSuvc });
      actions.getFeeExclusionsCount(userSelectedSuvc);
    }
    actions.selectedSupplierChanged();
  };

  const searchItems = newSearch => {
    newSearch = newSearch.trim();
    if (!(newSearch.length >= 4)) {
      return false;
    }
    let requestData = {
      suvc: 0,
      page: 1,
      graphfilter: null,
      getHierarchy: false,
      search: newSearch,
      freeSearch: true
    };
    actions.loadItemList(requestData);
  };

  const handlePagination = newPage => {
    let requestData = {
      suvc: 0,
      page: newPage,
      graphfilter: null,
      getHierarchy: false,
      search: search,
      freeSearch: true
    };
    actions.loadItemList(requestData);
  };

  const handleRefreshSuppliers = () => {
    actions.loadAllVendorsSilent();
  };

  const renderTopGridPanel = () => {
    const supplierList = getValueBasedOnTheCondition(
      !_.isEmpty(allVendors),
      allVendors,
      getValueBasedOnTheCondition(!_.isEmpty(allVendors), allVendors, [])
    );
    const { Search } = Input;
    let supplierListDefault = getValueBasedOnTheCondition(!_.isEmpty(supplierList), 'Select Supplier', 'Loading...');
    let selectedSuvcNonZero = getValueBasedOnTheCondition(selectedSuvc, selectedSuvc, null);

    return (
      <div className="grid-filter-panel">
        <div
          className="back-to-dashboard"
          onClick={() => {
            window.location.href = '/suite/dashboard';
          }}
        />
        <div className="bread-crum-seperator"></div>
        <div className="bread-crum-caption">Internal Item Management</div>
        <div className="search-bar">
          {_.includes(permissions, 'ITMAPP.ITMVISI.RETRIVE') && (
            <div
              className="pgm-items-link"
              onClick={() => {
                window.location.href = '/suite/item-management/itemvisibility';
              }}
            >
              Non LA/ISO
            </div>
          )}
          {!selectedSuvc && (
            <Search
              className="search-box"
              defaultValue={search}
              placeholder="Search Items..."
              onSearch={searchItems}
              enterButton
              id="id-search-box"
            />
          )}
          <Select
            dropdownMatchSelectWidth={false}
            dropdownAlign={{ points: ['tr', 'br'] }}
            defaultValue={selectedSuvcNonZero}
            onChange={handleSuvcFilter}
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          >
            <Option key={0} value={null}>
              {supplierListDefault}
            </Option>
            {supplierList.filter(({key}) => !(`${key}`.match(/^1888/i) && `${key}`.length > 6)).map(
              supplier =>
                supplier.suppliername !== null && (
                  <Option key={supplier.key} value={supplier.key} style={{ textTransform: 'uppercase' }}>
                    {supplier.key + ' - ' + supplier.suppliername}
                  </Option>
                )
            )}
          </Select>
          <div className="refresh-icon">
            <ReloadOutlined
              style={{ color: '#298cd1', fontSize: '10px' }}
              title="Reload Suppliers"
              onClick={() => handleRefreshSuppliers()}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderBody = () => {
    if (selectedSuvc) {
      return <ItemManagementPage isInternal={true} />;
    } else if (freeSearch) {
      return <FreeItemsListPage isInternal={true} handlePagination={handlePagination} />;
    }
    return <div className="empty-items-pane"></div>;
  };

  if (fetching) {
    return <AppLoader show />;
  }

  return (
    <div className="internal-item-view">
      {renderTopGridPanel()}
      {renderBody()}
    </div>
  );
}

function mapStateToProps(state) {
  return {
    allVendors: state.vendor.all,
    selectedSuvc: state.vendor.selectedSuvc,
    subSuvc: state.page.subSuvc,
    search: state.page.search,
    freeSearch: state.vendor.freeSearch,
    fetching: state.vendor.fetching,
    suvcDetails: state.vendor.details,
    permissions: state.user.data.permissions
  };
}

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(batchesActions, dispatch) };
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(InternalItems));
