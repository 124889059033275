import _ from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import { FormOutlined } from '@ant-design/icons';

export default function ImageStyleChange({ image, item, actions, imageFn, existingStyles, disableEdit }) {
  const incorrectNamingList = useSelector(state => _.get(state, 'selectedItem.imageTab.incorrectNaming', []));

  if (!image) {
    return <span></span>;
  }

  const { getImageUrl } = imageFn;
  const incorrectName = _.includes(incorrectNamingList, image.assetId);
  const cssStyles = {
    cursor: 'pointer',
    marginLeft: '6px',
    pointerEvents: item.readOnly && 'none',
    color: incorrectName ? 'red' : ''
  };

  const changeImageStyle = () => {
    actions.commentsPopup({
      item: item,
      field: 'Image Style',
      currentValue: image.imageStyle,
      fieldType: 'IMAGE_STYLE',
      fieldCaption: 'Image Style',
      existingComment: null,
      image: { ...image, url: getImageUrl(image) },
      existingStyles: existingStyles
    });
  };

  if (!disableEdit) {
    const title = incorrectName
      ? "Current image name doesn't follow the GDSN naming guidelines. Click here to update the name"
      : null;
    return <FormOutlined title={title} onClick={changeImageStyle} style={cssStyles} />;
  } else {
    return (
      <div title="This option is disabled since there are pending/rejected changes for this image">
        <i
          className="icon fi flaticon-pencil"
          role="button"
          style={{ cursor: 'default', marginLeft: '6px', pointerEvents: item.readOnly && 'none' }}
        />
      </div>
    );
  }
}
