import _ from 'lodash';
import React from 'react';
import { Button } from 'antd';
import PendingComment from '../PendingComment';
import {
  formatBytes,
  getFormat,
  getValueBasedOnTheCondition,
  getFieldDisabledStatusByTwoParams
} from '../../util/Util';

const TEMP_IMG_URL =
  'https://assets.website-files.com/60dd9083125eb44675d01dba/60dd9083125eb482dfd01f1f_dish-hero-2.png';

class ProductImage extends React.Component {
  render() {
    let {
      image,
      item,
      pendingComments,
      actions,
      imageFn,
      disableDelete,
      disableReplace,
      hideDelete,
      hideReplace
    } = this.props;
    let { getImageTitle, getImageUrl, isDefault } = imageFn;

    let commentText = '';
    let existingComment = null;
    pendingComments.comments.forEach(comment => {
      if (comment.field === getImageTitle(image)) {
        commentText = comment.comment;
        existingComment = comment;
      }
    });

    const commentsPopup = () => {
      actions.commentsPopup({
        item: item,
        field: getImageTitle(image),
        currentValue: getImageUrl(image),
        fieldType: 'IMAGE',
        fieldCaption: ' ',
        existingComment: existingComment
      });
    };

    const deleteImage = () => {
      actions.commentsPopup({
        item: item,
        field: 'Delete Image',
        currentValue: getImageUrl(image),
        fieldType: 'IMAGE_DELETE',
        fieldCaption: ' ',
        existingComment: null,
        image: image
      });
    };

    const replaceImage = () => {
      actions.commentsPopup({
        item: item,
        field: 'Replace Image',
        currentValue: getImageUrl(image),
        fieldType: 'IMAGE_REPLACE',
        fieldCaption: ' ',
        existingComment: null,
        image: image
      });
    };

    const imageFeedback = () => {
      actions.commentsPopup({
        item: item,
        field: 'Image Feedback',
        currentValue: getImageUrl(image),
        fieldType: 'IMAGE_FEEDBACK',
        fieldCaption: ' ',
        existingComment: null,
        image: image
      });
    };

    let fileType = image && getValueBasedOnTheCondition(image.mime, getFormat(image.mime), '');
    let byteSize = image && getValueBasedOnTheCondition(image.size, formatBytes(image.size, 1), '');
    let resolution =
      image && image.width && image.height && image.width > 0 && image.height > 0
        ? `${image.width}x${image.height}`
        : '';

    const imageSource = _.get(image, 'imageSource', null);
    const isPublished = _.get(image, 'status', null);

    return (
      <div className={'image-wrapper' + getValueBasedOnTheCondition(isDefault(image), ' default', '')}>
        <img key={getImageTitle(image)} src={getImageUrl(image)} className="image" alt={getImageTitle(image)} />
        {commentText === '' && (
          <div className="image-overlay">
            <Button
              type="danger"
              style={getValueBasedOnTheCondition(hideDelete, { display: 'none' }, {})}
              disabled={getFieldDisabledStatusByTwoParams(item.readOnly, disableDelete)}
              onClick={deleteImage}
              className="btn-delete-image"
              title={getValueBasedOnTheCondition(
                disableDelete,
                'Delete option is disabled since there is a pending/rejected delete request for this image',
                'Delete image.'
              )}
            >
              X
            </Button>
            <Button
              disabled={getFieldDisabledStatusByTwoParams(item.readOnly, disableReplace)}
              onClick={replaceImage}
              className="btn-replace-image"
              style={getValueBasedOnTheCondition(
                hideReplace,
                { display: 'none' },
                getValueBasedOnTheCondition(hideDelete, {}, { top: '32px' })
              )}
              title={getValueBasedOnTheCondition(
                disableReplace,
                'Replace option is disabled since there is a pending/rejected replace request for this image',
                'Delete & Replace. You can replace this image with a more suitable image.'
              )}
            >
              ⤴
            </Button>
            <Button
              onClick={imageFeedback}
              disabled={item.readOnly}
              className="btn-feedback-image"
              title="Send feedback."
              style={{ display: 'none' }}
            ></Button>
            <div className="otmm-info-widget">
              {image.name && (
                <div className="row">
                  <div className="name">Name</div>
                  <div className="value" title={image.name}>
                    {image.name}
                  </div>
                </div>
              )}
              {byteSize.length > 0 && (
                <div className="row">
                  <div className="name">Size</div>
                  <div className="value" title={byteSize}>
                    {byteSize}
                  </div>
                </div>
              )}
              {resolution.length > 0 && (
                <div className="row">
                  <div className="name">Resolution</div>
                  <div className="value" title={resolution}>
                    {resolution}
                  </div>
                </div>
              )}
              {fileType.length > 0 && (
                <div className="row">
                  <div className="name">Format</div>
                  <div className="value" title={fileType}>
                    {fileType}
                  </div>
                </div>
              )}
              <div className="row">
                <div className="name">Mode</div>
                <div className="value" title="RGB">
                  RGB
                </div>
              </div>
              {imageSource && (
                <div className="row">
                  <div className="name">Source</div>
                  <div className="value" title={imageSource}>
                    {imageSource}
                  </div>
                </div>
              )}
              {isPublished && (
                <div className="row">
                  <div className="name">Status</div>
                  <div className="value" title={isPublished}>
                    {isPublished}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {commentText !== '' && (
          <PendingComment
            comment={commentText}
            clickOn={getFieldDisabledStatusByTwoParams(!item.readOnly, commentsPopup)}
          />
        )}
      </div>
    );
  }
}

export default ProductImage;
