import {
  createRequestTypes,
  createCommentsRequestTypes,
  createFnBRequestTypes,
  createImageRequestTypes,
  createFeedbackRequestTypes
} from 'reduxHelpers';

const USER = createRequestTypes('USER');
const ITEMS = createRequestTypes('ITEMS');
const ITEMMASTER = createRequestTypes('ITEMMASTER');
const ITEMUNLOAD = createRequestTypes('ITEMUNLOAD');
const ITEMTAXO = createRequestTypes('ITEMTAXO');
const ITEMIMAGE = createImageRequestTypes('ITEMIMAGE');
const IMAGEDELETE = createImageRequestTypes('IMAGEDELETE');
const ITEMFNB = createFnBRequestTypes('ITEMFNB');
const ATTRCHANGE = createRequestTypes('ATTRCHANGE');
const TAXOSAVE = createRequestTypes('TAXOSAVE');
const MSTRCOMMENT = createRequestTypes('MSTRCOMMENT');
const SENDCOMMENTS = createRequestTypes('SENDCOMMENTS');
const COMMENTS = createCommentsRequestTypes('COMMENTS');
const UPDATECOMMENT = createCommentsRequestTypes('UPDATECOMMENT');
const FIELDCOMMENT = createCommentsRequestTypes('FIELDCOMMENT');
const SENDCOMMENT = createCommentsRequestTypes('SENDCOMMENT');
const ITEMCOMMENTS = createCommentsRequestTypes('ITEMCOMMENTS');
const ITEMSCOUNT = createRequestTypes('ITEMSCOUNT');
const SCORES = createRequestTypes('SCORES');
const CHARTS = createRequestTypes('CHARTS');
const SUMMARY = createRequestTypes('SUMMARY');
const FILTERFORGRAPH = createRequestTypes('FILTERFORGRAPH');
const IMAGESTYLE = createRequestTypes('IMAGESTYLE');
const IMGLOADING = createRequestTypes('IMGLOADING');
const IMGCOMMENT = createRequestTypes('IMGCOMMENT');
const IMGRESERVATION = createRequestTypes('IMGRESERVATION');
const IMAGEFEED = createRequestTypes('IMAGEFEED');
const SUPCBASIC = createRequestTypes('SUPCBASIC');
const VENDORS = createRequestTypes('VENDORS');
const SUBSUVC = createRequestTypes('SUBSUVC');
const SUPPLIERS = createRequestTypes('SUPPLIERS');
const FEEDBACK = createFeedbackRequestTypes('FEEDBACK');
const FNBCOMMENTS = createRequestTypes('FNBCOMMENTS');
const EDITATTRIBUTE = createRequestTypes('EDITATTRIBUTE');
const UPDATEFNBCOMMENT = createRequestTypes('UPDATEFNBCOMMENT');
const UPDATEIMAGECOMMENT = createRequestTypes('UPDATEIMAGECOMMENT');
const RESETTAB = createRequestTypes('RESETTAB');
const TABCHANGED = createRequestTypes('TABCHANGED');
const REJECTEDSUPCS = createRequestTypes('REJECTEDSUPCS');
const OVERALLSUMMARY = createRequestTypes('OVERALLSUMMARY');
const NUTRITIONS = createRequestTypes('NUTRITIONS');
const UPDATENUTRITIONS = createRequestTypes('UPDATENUTRITIONS');
const AUTOSAVE = createRequestTypes('AUTOSAVE');
const UPDATEIMAGESTYLE = createRequestTypes('UPDATEIMAGESTYLE');
const UPDATEFEEDBACKSORTFILTERS = createRequestTypes('UPDATEFEEDBACKSORTFILTERS');
const COMPLEXGRAPHRENDERING = createRequestTypes('COMPLEXGRAPHRENDERING');
const FEEEXCLUSIONSBYSUPC = createRequestTypes('FEEEXCLUSIONSBYSUPC');
const FEEEXCLUSIONSCOUNT = createRequestTypes('FEEEXCLUSIONSCOUNT');
const UPDATENUTRITIONDATA = createRequestTypes('UPDATENUTRITIONDATA');
const UPDATEATTRIBCOMMENT = createRequestTypes('UPDATEATTRIBCOMMENT');
const NUTRITIONQUESTIONS = createRequestTypes('NUTRITIONQUESTIONS');
const NUTRITIONAPPLICABLESTATUS = createRequestTypes('NUTRITIONAPPLICABLESTATUS');
const UPDATENUTRITIONAPPLICABLESTATUS = createRequestTypes('UPDATENUTRITIONAPPLICABLESTATUS');
const PENDINGNUTRITIONS = createRequestTypes('PENDINGNUTRITIONS');
const CANCELNUTRITIONUPDATE = createRequestTypes('CANCELNUTRITIONUPDATE');
const ONNUTRITIONREJECTFIELDSCHANGED = createRequestTypes('ONNUTRITIONREJECTFIELDSCHANGED');
const BLOCKTAXONMYNUTRITIONUPDATE = createRequestTypes('BLOCKTAXONMYNUTRITIONUPDATE');
const TAXOQUITESAVE = createRequestTypes('TAXOQUITESAVE');
const SETSYNCEDNUTRITIONS = createRequestTypes('SETSYNCEDNUTRITIONS');
const FNBMASSEDITINITIALDATA = createRequestTypes('FNBMASSEDITINITIALDATA');
const FNBMASSEDIT = createRequestTypes('FNBMASSEDIT');
const TOGGLEFNBMASSEDITMODAL = createRequestTypes('TOGGLEFNBMASSEDITMODAL');
const VENDORSSILENT = createRequestTypes('VENDORSSILENT');
const GDSNFNBCOMMENTS = createRequestTypes('GDSNFNBCOMMENTS');
const GDSNFNB = createRequestTypes('GDSNFNB');
const COREDATA = createRequestTypes('COREDATA');
const COREDATACHANGED = createRequestTypes('COREDATACHANGED');
const COREDATACOMMENT = createRequestTypes('COREDATACOMMENT');
const COREDATACOMMENTCHANGED = createRequestTypes('COREDATACOMMENTCHANGED');
const SENDCOREDATACOMMENT = createRequestTypes('SENDCOREDATACOMMENT');
const REJECTALLCOREDATAATTRIBUTES = createRequestTypes('REJECTALLCOREDATAATTRIBUTES');
const GETITEMDETAILS = createRequestTypes('GETITEMDETAILS');
const RESETCURRENTCOREDATACHANGES = createRequestTypes('RESETCURRENTCOREDATACHANGES');
const PGMITEMS = createRequestTypes('PGMITEMS');
const UPDATEVISIBILITY = createRequestTypes('UPDATEVISIBILITY');
const CHANGEVISIBILITY = createRequestTypes('CHANGEVISIBILITY');
const FORCEDVISIBLESUPCS = createRequestTypes('FORCEDVISIBLESUPCS');
const NUTRITIONUPDATE = createRequestTypes('NUTRITIONUPDATE');
const UPDATENUTRITIONFIELDERRORS = createRequestTypes('UPDATENUTRITIONFIELDERRORS');
const UPDATETAXONOMYCHANGESWHOLE = createRequestTypes('UPDATETAXONOMYCHANGESWHOLE');
const UPDATENUTRITIONTAXONOMYSYNCHEDCHANGES = createRequestTypes('UPDATENUTRITIONTAXONOMYSYNCHEDCHANGES');
const SELFASSIGNCOMMENT = createRequestTypes('SELFASSIGNCOMMENT');
const COREATTRIBUTEHISTORY = createRequestTypes('COREATTRIBUTEHISTORY');
const TOGGLECOREMODAL = createRequestTypes('TOGGLECOREMODAL');
const MDMITEMMASTER = createRequestTypes('MDMITEMMASTER');
const ITEMSSUMMARY = createRequestTypes('ITEMSSUMMARY');

// Preferred Images
const UNSAVEDPREFERREDIMGS = createRequestTypes('UNSAVEDPREFERREDIMGS');
const SUBMITPREFERREDIMAGES = createRequestTypes('SUBMITPREFERREDIMAGES');
const PREFERREDIMAGECOMMENT = createRequestTypes('PREFERREDIMAGECOMMENT');
const ASSOCIATEPREFERREDIMAGE = createRequestTypes('ASSOCIATEPREFERREDIMAGE');
const PREFERREDIMAGEREVIEWSTATUS = createRequestTypes('PREFERREDIMAGEREVIEWSTATUS');
const APPROVEPREFERREDIMAGE = createRequestTypes('APPROVEPREFERREDIMAGE');
const REJECTPREFERREDIMAGE = createRequestTypes('REJECTPREFERREDIMAGE');

const MASSUPDATETYPECHANGED = createRequestTypes('MASSUPDATETYPECHANGED');
const TOGGLEATTRIBUTEMASSEDITMODAL = createRequestTypes('TOGGLEATTRIBUTEMASSEDITMODAL');
const ALLBUSINESSCENTERS = createRequestTypes('ALLBUSINESSCENTERS');
const ATTRIBUTEMASSEDITFILTERS = createRequestTypes('ATTRIBUTEMASSEDITFILTERS');
const ATTRIBUTEMASSEDITLIST = createRequestTypes('ATTRIBUTEMASSEDITLIST');
const ATTRIBUTESLIST = createRequestTypes('ATTRIBUTESLIST');
const SELECTATTRIBUTESELECTORVALUE = createRequestTypes('SELECTATTRIBUTESELECTORVALUE');
const UPDATEATTRIBUTEFILTERS = createRequestTypes('UPDATEATTRIBUTEFILTERS');
const CLEARATTRIBUTESELECTOR = createRequestTypes('CLEARATTRIBUTESELECTOR');
const APPLYATTRIBUTESELECTIONS = createRequestTypes('APPLYATTRIBUTESELECTIONS');
const ATTRMASSEDITCHECKBOX = createRequestTypes('ATTRMASSEDITCHECKBOX');
const UPDATEINDIVIDUALATTRIBUTE = createRequestTypes('UPDATEINDIVIDUALATTRIBUTE');
const MASSUPDATEATTRIBUTES = createRequestTypes('MASSUPDATEATTRIBUTES');
const ATTRIBUTEMASSUNDO = createRequestTypes('ATTRIBUTEMASSUNDO');
const MASSATTRIBUTEITEMTAXONOMYSYNCHEDFIELDCHANGED = createRequestTypes('MASSATTRIBUTEITEMTAXONOMYSYNCHEDFIELDCHANGED');
const MASSATTRIBUTEITEMTNUTIRTIONFIELDCHANGED = createRequestTypes('MASSATTRIBUTEITEMTNUTIRTIONFIELDCHANGED');
const MASSATTRIBUTIONTAXONOMYFETCHEDAFTEREACHTAXONOMYWITHNUTRITIONALCOMMENT = createCommentsRequestTypes(
  'MASSATTRIBUTIONTAXONOMYFETCHEDAFTEREACHTAXONOMYWITHNUTRITIONALCOMMENT'
);
const MASSATTRIBUTIONUPDATEALLITEMLIST = createRequestTypes('MASSATTRIBUTIONUPDATEALLITEMLIST');

const RETRIEVEMASSIMAGEUPLOADDATA = createRequestTypes('RETRIEVEMASSIMAGEUPLOADDATA');
const CLOSEMASSIMAGEUPLOADOVERLAY = createRequestTypes('CLOSEMASSIMAGEUPLOADOVERLAY');
const CHANGEMASSIMAGELOADINGPERCENTAGE = createRequestTypes('CHANGEMASSIMAGELOADINGPERCENTAGE');
const MASSIMAGEUPLOADITEMDATALOADEDDONE = createRequestTypes('MASSIMAGEUPLOADITEMDATALOADEDDONE');
const UPDATEMASSIMAGEUPLOADFIELDS = createRequestTypes('UPDATEMASSIMAGEUPLOADFIELDS');
const REVERTMASSIMAGEUPLOADSUPCFIELD = createRequestTypes('REVERTMASSIMAGEUPLOADSUPCFIELD');
const MASSIMAGEUPLOADSUBMIT = createRequestTypes('MASSIMAGEUPLOADSUBMIT');
const MASSIMAGEUPLOADEACHRESPONSE = createRequestTypes('MASSIMAGEUPLOADEACHRESPONSE');
const MASSIMAGEUPLOADCOMMENTSRESPONSE = createRequestTypes('MASSIMAGEUPLOADCOMMENTSRESPONSE');
const COMPLETEMASSIMAGEUPLOADJOB = createRequestTypes('COMPLETEMASSIMAGEUPLOADJOB');
const VALIDATEMASSUPLOADIMAGES = createRequestTypes('VALIDATEMASSUPLOADIMAGES');
const VALIDATECOMPLETEDMASSUPLOADIMAGES = createRequestTypes('VALIDATECOMPLETEDMASSUPLOADIMAGES');
const CHECKTIMEOUTFORIMAGES = createRequestTypes('CHECKTIMEOUTFORIMAGES');
const CLEAREACHITEMCHANGESINMASSIMAGES = createRequestTypes('CLEAREACHITEMCHANGESINMASSIMAGES');
const HANDLEAALREADYASSIGNEDCOREDATAISSUE = createRequestTypes('HANDLEAALREADYASSIGNEDCOREDATAISSUE');
const CLEARMASSIMAGESELECTEDVALUESONVALIDATIONFAILURE = createRequestTypes(
  'CLEARMASSIMAGESELECTEDVALUESONVALIDATIONFAILURE'
);
const COODATACHANGED = createRequestTypes('COODATACHANGED');
const COOACTIVITYADD = createRequestTypes('COOACTIVITYADD');
const COOACTIVITYDEL = createRequestTypes('COOACTIVITYDEL');
const COOCOUNTRYADD = createRequestTypes('COOCOUNTRYADD');
const COOCOUNTRYDEL = createRequestTypes('COOCOUNTRYDEL');
const COODATAPUSHED = createRequestTypes('COODATAPUSHED');
const ITEMCOO = createRequestTypes('ITEMCOO');

const HANDLECNREMOVALSILENCE = createRequestTypes('HANDLECNREMOVALSILENCE');

const FETCHOVERALLITEMFEEDBACKHISTORY = createRequestTypes('FETCHOVERALLITEMFEEDBACKHISTORY');
const UPDATEOVERALLFEEDBACK = createRequestTypes('UPDATEOVERALLFEEDBACK');
const SUBMITOVERALLFEDBACK = createRequestTypes('SUBMITOVERALLFEDBACK');
const UPDATEALLOVERALLHISTORY = createRequestTypes('UPDATEALLOVERALLHISTORY');
const FETCHITEMCOMMENTSOVERALLHISTORY = createRequestTypes('FETCHITEMCOMMENTSOVERALLHISTORY');
const FETCHITEMVIEWEXCLUSIONS = createRequestTypes('FETCHITEMVIEWEXCLUSIONS');

// PFAS Single Item View
const PFAS = createRequestTypes('PFAS');
const PFASSELECTIONCHANGED = createRequestTypes('PFASSELECTIONCHANGED');
const PFASCLEARSELECTIONS = createRequestTypes('PFASCLEARSELECTIONS');
const PFASMASSNOTAPPLICABLE = createRequestTypes('PFASMASSNOTAPPLICABLE');
const PFASUPDATE = createRequestTypes('PFASUPDATE');

// PFAS Mass Edit
const TOGGLEPFASMASSEDIT = createRequestTypes('TOGGLEPFASMASSEDIT');
const PFASMASSEDITFILTERS = createRequestTypes('PFASMASSEDITFILTERS');
const ALLBUSINESSCENTERSPFAS = createRequestTypes('ALLBUSINESSCENTERSPFAS');
const PFASMASSEDITLIST = createRequestTypes('PFASMASSEDITLIST');
const PFASMASSEDITCHECKBOX = createRequestTypes('PFASMASSEDITCHECKBOX');
const PFASMASSEDITITEMATTRIBUTESELECTION = createRequestTypes('PFASMASSEDITITEMATTRIBUTESELECTION');
const PFASMASSEDITWHOLESINGLEATTRIBUTESELECTION = createRequestTypes('PFASMASSEDITWHOLESINGLEATTRIBUTESELECTION');
const PFASMASSEDITWHOLESINGLEATTRIBUTESELECTIONCLEAR = createRequestTypes(
  'PFASMASSEDITWHOLESINGLEATTRIBUTESELECTIONCLEAR'
);
const REGULATORYCOMPLIANCESELECTIONCHANGED = createRequestTypes('REGULATORYCOMPLIANCESELECTIONCHANGED');
const REGULATORYCOMPLIANCEAPPLYVALUE = createRequestTypes('REGULATORYCOMPLIANCEAPPLYVALUE');
const CLEARMASSPFASAPPLIEDVALUES = createRequestTypes('CLEARMASSPFASAPPLIEDVALUES');
const APPLYPFASMASSFILTEREDVALUES = createRequestTypes('APPLYPFASMASSFILTEREDVALUES');
const UNDOMASSPFASCHANGES = createRequestTypes('UNDOMASSPFASCHANGES');
const SUBMITMASSPFASCHANGES = createRequestTypes('SUBMITMASSPFASCHANGES');

// FSMA
const FSMA = createRequestTypes('FSMA');
const FSMACHANGES = createRequestTypes('FSMACHANGES');
const REGULATORYSUBTABCHANGE = createRequestTypes('REGULATORYSUBTABCHANGE');
const SAVEFSMACHANGES = createRequestTypes('SAVEFSMACHANGES');

//PACKAGING
const PACKAGE = createRequestTypes('PACKAGE');
const PACKAGECHANGES = createRequestTypes('PACKAGECHANGES');
const SAVEPACKAGECHANGES = createFnBRequestTypes('SAVEPACKAGECHANGES');
const PACKAGINGSUBTABCHANGE = createFnBRequestTypes('PACKAGINGSUBTABCHANGE');
const ADDPACKAGINGLEVEL = createFnBRequestTypes('ADDPACKAGINGLEVEL');
const RESETPACKAGING = createFnBRequestTypes('RESETPACKAGING');
const PACKAGECERTIFICATIONCHANGES = createFnBRequestTypes('PACKAGECERTIFICATIONCHANGES');
const SAVEPACKAGINGCERTIFICATIONS = createFnBRequestTypes('SAVEPACKAGINGCERTIFICATIONS');
const DELETEPACKAGINGLEVEL = createFnBRequestTypes('DELETEPACKAGINGLEVEL');

export {
  USER,
  ITEMS,
  ITEMMASTER,
  ITEMTAXO,
  ITEMUNLOAD,
  ITEMIMAGE,
  IMAGEDELETE,
  ITEMFNB,
  ATTRCHANGE,
  TAXOSAVE,
  MSTRCOMMENT,
  SENDCOMMENTS,
  COMMENTS,
  UPDATECOMMENT,
  FIELDCOMMENT,
  SENDCOMMENT,
  ITEMCOMMENTS,
  ITEMSCOUNT,
  SCORES,
  CHARTS,
  SUMMARY,
  FILTERFORGRAPH,
  IMAGESTYLE,
  IMGLOADING,
  IMGCOMMENT,
  IMGRESERVATION,
  IMAGEFEED,
  SUPCBASIC,
  VENDORS,
  SUBSUVC,
  SUPPLIERS,
  FEEDBACK,
  FNBCOMMENTS,
  EDITATTRIBUTE,
  UPDATEFNBCOMMENT,
  RESETTAB,
  TABCHANGED,
  REJECTEDSUPCS,
  OVERALLSUMMARY,
  NUTRITIONS,
  UPDATENUTRITIONS,
  UPDATEATTRIBCOMMENT,
  AUTOSAVE,
  UPDATEIMAGECOMMENT,
  UPDATEIMAGESTYLE,
  UPDATEFEEDBACKSORTFILTERS,
  COMPLEXGRAPHRENDERING,
  FEEEXCLUSIONSBYSUPC,
  FEEEXCLUSIONSCOUNT,
  UPDATENUTRITIONDATA,
  NUTRITIONQUESTIONS,
  UPDATENUTRITIONAPPLICABLESTATUS,
  NUTRITIONAPPLICABLESTATUS,
  PENDINGNUTRITIONS,
  CANCELNUTRITIONUPDATE,
  ONNUTRITIONREJECTFIELDSCHANGED,
  BLOCKTAXONMYNUTRITIONUPDATE,
  TAXOQUITESAVE,
  SETSYNCEDNUTRITIONS,
  FNBMASSEDITINITIALDATA,
  FNBMASSEDIT,
  TOGGLEFNBMASSEDITMODAL,
  VENDORSSILENT,
  GDSNFNBCOMMENTS,
  GDSNFNB,
  COREDATA,
  COREDATACHANGED,
  COREDATACOMMENT,
  COREDATACOMMENTCHANGED,
  SENDCOREDATACOMMENT,
  REJECTALLCOREDATAATTRIBUTES,
  GETITEMDETAILS,
  RESETCURRENTCOREDATACHANGES,
  PGMITEMS,
  UPDATEVISIBILITY,
  CHANGEVISIBILITY,
  FORCEDVISIBLESUPCS,
  NUTRITIONUPDATE,
  UPDATENUTRITIONFIELDERRORS,
  UPDATETAXONOMYCHANGESWHOLE,
  UPDATENUTRITIONTAXONOMYSYNCHEDCHANGES,
  SELFASSIGNCOMMENT,
  COREATTRIBUTEHISTORY,
  TOGGLECOREMODAL,
  MASSUPDATETYPECHANGED,
  TOGGLEATTRIBUTEMASSEDITMODAL,
  ALLBUSINESSCENTERS,
  ATTRIBUTEMASSEDITFILTERS,
  ATTRIBUTEMASSEDITLIST,
  ATTRIBUTESLIST,
  SELECTATTRIBUTESELECTORVALUE,
  UPDATEATTRIBUTEFILTERS,
  CLEARATTRIBUTESELECTOR,
  APPLYATTRIBUTESELECTIONS,
  RETRIEVEMASSIMAGEUPLOADDATA,
  CLOSEMASSIMAGEUPLOADOVERLAY,
  CHANGEMASSIMAGELOADINGPERCENTAGE,
  MASSIMAGEUPLOADITEMDATALOADEDDONE,
  UPDATEMASSIMAGEUPLOADFIELDS,
  REVERTMASSIMAGEUPLOADSUPCFIELD,
  MASSIMAGEUPLOADSUBMIT,
  MASSIMAGEUPLOADEACHRESPONSE,
  MASSIMAGEUPLOADCOMMENTSRESPONSE,
  COMPLETEMASSIMAGEUPLOADJOB,
  VALIDATEMASSUPLOADIMAGES,
  VALIDATECOMPLETEDMASSUPLOADIMAGES,
  CHECKTIMEOUTFORIMAGES,
  CLEAREACHITEMCHANGESINMASSIMAGES,
  HANDLEAALREADYASSIGNEDCOREDATAISSUE,
  MASSATTRIBUTEITEMTAXONOMYSYNCHEDFIELDCHANGED,
  MASSATTRIBUTEITEMTNUTIRTIONFIELDCHANGED,
  ATTRMASSEDITCHECKBOX,
  MASSUPDATEATTRIBUTES,
  ATTRIBUTEMASSUNDO,
  UPDATEINDIVIDUALATTRIBUTE,
  CLEARMASSIMAGESELECTEDVALUESONVALIDATIONFAILURE,
  MASSATTRIBUTIONTAXONOMYFETCHEDAFTEREACHTAXONOMYWITHNUTRITIONALCOMMENT,
  MASSATTRIBUTIONUPDATEALLITEMLIST,
  MDMITEMMASTER,
  FETCHOVERALLITEMFEEDBACKHISTORY,
  UPDATEOVERALLFEEDBACK,
  SUBMITOVERALLFEDBACK,
  UPDATEALLOVERALLHISTORY,
  FETCHITEMCOMMENTSOVERALLHISTORY,
  FETCHITEMVIEWEXCLUSIONS,
  COODATACHANGED,
  COOACTIVITYADD,
  COOACTIVITYDEL,
  COOCOUNTRYADD,
  COOCOUNTRYDEL,
  COODATAPUSHED,
  ITEMCOO,
  ITEMSSUMMARY,
  HANDLECNREMOVALSILENCE,
  PFAS,
  PFASSELECTIONCHANGED,
  PFASCLEARSELECTIONS,
  PFASMASSNOTAPPLICABLE,
  PFASUPDATE,
  TOGGLEPFASMASSEDIT,
  PFASMASSEDITFILTERS,
  ALLBUSINESSCENTERSPFAS,
  PFASMASSEDITLIST,
  PFASMASSEDITCHECKBOX,
  PFASMASSEDITITEMATTRIBUTESELECTION,
  PFASMASSEDITWHOLESINGLEATTRIBUTESELECTION,
  PFASMASSEDITWHOLESINGLEATTRIBUTESELECTIONCLEAR,
  REGULATORYCOMPLIANCESELECTIONCHANGED,
  REGULATORYCOMPLIANCEAPPLYVALUE,
  CLEARMASSPFASAPPLIEDVALUES,
  APPLYPFASMASSFILTEREDVALUES,
  UNDOMASSPFASCHANGES,
  SUBMITMASSPFASCHANGES,
  UNSAVEDPREFERREDIMGS,
  SUBMITPREFERREDIMAGES,
  PREFERREDIMAGECOMMENT,
  ASSOCIATEPREFERREDIMAGE,
  PREFERREDIMAGEREVIEWSTATUS,
  APPROVEPREFERREDIMAGE,
  REJECTPREFERREDIMAGE,
  FSMA,
  FSMACHANGES,
  REGULATORYSUBTABCHANGE,
  SAVEFSMACHANGES,
  PACKAGE,
  PACKAGECHANGES,
  SAVEPACKAGECHANGES,
  PACKAGINGSUBTABCHANGE,
  ADDPACKAGINGLEVEL,
  RESETPACKAGING,
  PACKAGECERTIFICATIONCHANGES,
  SAVEPACKAGINGCERTIFICATIONS,
  DELETEPACKAGINGLEVEL
};
