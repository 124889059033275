import axios from 'axios';
import qs from 'qs';
import { getIdToken } from '../Authenticator';
import { getOtmmBaseUrl } from '../util/Util';

const baseURL = process.env.REACT_APP_BFF;

const getHeaders = async () => {
  const token = (await getIdToken()).getJwtToken();
  return {
    Authorization: `Bearer ${token}`,
    'Content-Type': 'application/json'
  };
};

const getUploadHeaders = async () => {
  return {
    'Content-Type': 'multipart/form-data'
  };
};

const postRequest = async (url, data, returnError) => {
  returnError = returnError || false;
  if (returnError) {
    return axios
      .post(`${baseURL}${url}`, data, {
        headers: await getHeaders()
      })
      .then(result => {
        return result;
      })
      .catch(error => {
        return error.response;
      });
  } else {
    return axios.post(`${baseURL}${url}`, data, {
      headers: await getHeaders()
    });
  }
};

const imagesUploadRequest = async (url, data) => {
  let baseDomain = getOtmmBaseUrl();
  return axios
    .post(`${baseDomain}${url}`, data, {
      headers: await getUploadHeaders()
    })
    .then(result => {
      return result;
    })
    .catch(error => {
      return error.response;
    });
};

const getRequest = async (url, params = {}) => {
  return axios.get(`${baseURL}${url}`, {
    headers: await getHeaders(),
    params,
    paramsSerializer: () => qs.stringify(params)
  });
};

const putRequest = async (url, data) => {
  return axios.put(`${baseURL}${url}`, data, {
    headers: await getHeaders()
  });
};

const patchRequest = async (url, data) => {
  return axios.patch(`${baseURL}${url}`, data, {
    headers: await getHeaders()
  });
};

const deleteRequest = async url => {
  return axios.delete(`${baseURL}${url}`, {
    headers: await getHeaders()
  });
};

export { postRequest, getRequest, putRequest, deleteRequest, getHeaders, patchRequest, imagesUploadRequest };
