const {
  imageAttributes,
  nonFoodItemBusinessCenterNames,
  foodItemAttributes,
  nonFoodItemAttributes,
  scoreCompletionColors,
  scoreStatus
} = require('../util/Data');
const _ = require('lodash');

const getAveragePercentageOfAttributeCompletion = itemAll => {
  try {
    let { al, cl, dt, pa, ct } = itemAll;
    al = al || 0;
    cl = cl || 0;
    dt = dt || 0;
    pa = pa || 0;
    ct = ct || 0;
    let vals = [al, cl, dt, pa, ct];
    const totalCount = _.sum(vals.map(e => parseInt(e)));
    return Math.round(totalCount / 5);
  } catch (e) {
    return 0;
  }
};

const calculatePercentage = (arrayOfKeys, dataItem) => {
  let total = 0;
  let count = 0;
  arrayOfKeys.forEach(key => {
    if (dataItem[key]) {
      count += 1;
    }
    if (dataItem[key] === '1') {
      total += 1;
    }
  });
  return count === 0 ? 0 : Math.round((total * 100) / count);
};

const getAveragePercentageOfImageCompletion = item => {
  return calculatePercentage(imageAttributes, item);
};

const getAverageFeaturesAndBenifitsCompletion = item => {
  const isNonFoodItem = nonFoodItemBusinessCenterNames.includes(item.bc);
  return calculatePercentage(isNonFoodItem ? nonFoodItemAttributes : foodItemAttributes, item);
};

const getCompletionOfPercentageStyleColor = score => {
  const { green, red } = scoreCompletionColors;
  return score === 100 ? green : red;
};

const getCompletionText = score => {
  const { complete, incomplete } = scoreStatus;
  return score === 100 ? complete : incomplete;
};

module.exports = {
  getAveragePercentageOfAttributeCompletion,
  getAveragePercentageOfImageCompletion,
  getAverageFeaturesAndBenifitsCompletion,
  getCompletionOfPercentageStyleColor,
  getCompletionText
};
