import React from 'react';
import { Tabs, Tooltip } from 'antd';
import _ from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as actionCreators from '../../actions/index';
import PackagingAttributesTab from './PackagingAttributesTab';
import CertificationsTab from './CertificationsTab';
import openNotification from 'components/Notification';
import {
  getEmptyPackagingAttributes,
  getEmptyPackagingCertificationAttributes,
  checkForPackagingChanges
} from '../../util/Util';

const { TabPane } = Tabs;
const basePath = 'selectedItem.packagingTab';

export default function PackagingMainTab({ readOnly }) {
  const dispatch = useDispatch();
  const { changePackagingSubTab } = bindActionCreators(actionCreators, dispatch);

  const selectedTab = useSelector(state => _.get(state, 'selectedItem.packagingTab.selectedTab', 1));
  const originalPackaging = useSelector(state => _.get(state, `${basePath}.originalPackaging`, {}));
  const currentPackaging = useSelector(state => _.get(state, `${basePath}.currentPackaging`, {}));
  const originalCertifications = useSelector(state => _.get(state, `${basePath}.originalCertifications`, {}));
  const currentCertifications = useSelector(state => _.get(state, `${basePath}.currentCertifications`, {}));
  const packagingTabKey = useSelector(state => _.get(state, `${basePath}.packagingTabKey`, 1));
  const certificationTabKey = useSelector(state => _.get(state, `${basePath}.certificationTabKey`, 1));

  const renderFsmaTabTitle = title => {
    let emptyList;

    if (title === 'Packaging Attributes') {
      emptyList = getEmptyPackagingAttributes(originalPackaging);
    } else {
      emptyList = getEmptyPackagingCertificationAttributes(originalCertifications);
    }

    const content = (
      <div>
        <div>Please select values for the unfilled attributes</div>
        <ul className="tooltip-content">
          {_.map(emptyList, attributeName => (
            <li key={attributeName}>{attributeName}</li>
          ))}
        </ul>
      </div>
    );

    return (
      <div className="subtab-title">
        <div>{title}</div>
        <Tooltip overlayClassName="incomplete-attr-tool-tip" title={content} placement="bottom">
          {emptyList.length > 0 && <div className="incomplete-count">{emptyList.length}</div>}
        </Tooltip>
      </div>
    );
  };

  let tabs = [
    {
      key: 1,
      title: renderFsmaTabTitle('Packaging Attributes'),
      content: <PackagingAttributesTab key={packagingTabKey} readOnly={readOnly} />
    },
    {
      key: 2,
      title: renderFsmaTabTitle('Packaging Certifications & Standards'),
      content: <CertificationsTab key={certificationTabKey} readOnly={readOnly} />
    }
  ];

  const onTabClick = key => {
    const { hasChanges: hasPackagingChanges } = checkForPackagingChanges(originalPackaging, currentPackaging);
    const { hasChanges: hasCertificationChanges } = checkForPackagingChanges(
      originalCertifications,
      currentCertifications
    );

    if (hasPackagingChanges || hasCertificationChanges) {
      openNotification({
        className: 'error',
        message: 'Unsaved Changes',
        description: 'Please save your unsaved changes'
      });
    } else {
      changePackagingSubTab(parseInt(key));
    }
  };

  return (
    <div className="attr-panel taxonomy taxonomy-sub-tabs" style={{ height: `${window.innerHeight - 200}px` }}>
      <Tabs
        tabPosition="top"
        animated={false}
        activeKey={`${selectedTab}`}
        onTabClick={activeTabKey => onTabClick(activeTabKey)}
      >
        {tabs.map(({ title, key, content }) => {
          return (
            <TabPane tab={<span className="inner-tab-taxonomy">{title}</span>} key={key}>
              {content}
            </TabPane>
          );
        })}
      </Tabs>
    </div>
  );
}
