/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-wrap-multilines */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Select, Tooltip, Upload, Button, message, Icon, Progress, Alert, Modal } from 'antd';
import * as _ from 'lodash';
import { CloseCircleFilled } from '@ant-design/icons';
import {
  IMAGE_FIELDS_NAMES,
  MASS_IMAGE_UPLOAD_ACCEPTED_FORMATS,
  MASS_IMAGE_UPLOAD_DISABLED_MESSAGE
} from '../../../util/Constants';
import {
  mainCategoriesList,
  imageCategories,
  massImageNameTypes,
  imageNameFacings,
  imageNameAngles,
  imageNonFoodStyles,
  imageFoodStyles
} from '../../../util/Data';
import {
  getValuesFromTwoFields,
  getOtmmBaseUrl,
  getFileNamePart,
  getValueBasedOnTheCondition,
  isNonFood,
  getUrlsFromImageComments,
  getEachSupcValidateImagesButtonDisabledStatus,
  checkForRejectedImagesOrPendingImageDeletions
} from '../../../util/Util';
import {
  handleMassImageUploadFieldValueChanges,
  revertMassImageUploadFieldChange,
  handleMassImageValidation,
  clearAllChangesForItemInMassImages
} from '../../../actions';
import DynamicRequiredField from './DynamicRequiredField';

const { Option } = Select;

const IMAGE_FIELDS_WITH_TOOLTIPS = {
  primary: (
    <>
      Upload an image for OUT OF PACKAGING OR RAW <br></br>
      <br></br>
      What is OUT OF PACKAGIN OR RAW? <br></br>
      Out of packaging (i.e., the product as it first arrives "out of packaging" not how it appears after it has been
      processed or prepared). <br></br>
      <br></br>
      Raw/uncooked - A shoot of a product that has not been cooked or processed or that needs to be cooked or further
      prepared before it is considered edible.
    </>
  ),
  inPacking: (
    <>
      Upload an image for IN PACKAGING/ INNERPACK <br></br>
      <br></br>
      What is IN PACKAGING/ INNERPACK? <br></br>
      Innerpack - A shot of the product as it would appear inside its packaging inside the case.
    </>
  ),
  openCase: (
    <>
      Upload an image for OPEN CASE <br></br>
      <br></br>
      What is OPEN CASE? <br></br>
      Open Case - A shot of a case, flaps open, that shows how the product(s) would look when an operator receives the
      product opens the case.
    </>
  ),
  case: (
    <>
      Upload an image for CASE <br></br>
      <br></br>
      What is CASE? <br></br>
      Case - A shot of the product in its case as it would appear to the operator upon delivery.
    </>
  ),
  lifeStyle: (
    <>
      Upload an image for LifeStyle <br></br>
      <br></br>
      What is LIFESTYLE? <br></br>
      LIFESTYLE is a mandatory product image type. An image of following styles will be considered as a LIFESTYLE image.
      <br></br>
      <br></br>
      Image styles
      <ul style={{ listStyleType: 'circle', marginLeft: '25px' }}>
        <li>STYLED</li>
        <li>PREPARED</li>
        <li>PLATED</li>
        <li>STAGED</li>
        <li>FAMILY</li>
      </ul>
    </>
  )
};

const syscoImagePath = `${getOtmmBaseUrl()}/otmmstream/productimage/`;

const ExpandedContent = React.memo(function ChildComponent({ record }) {
  const isMassUploading = useSelector(state => _.get(state.massUpdate, 'isUploading', false));
  const [selectedFeedback, setSelectedFeedback] = useState({});
  const [invalidFileErrors, setInvalidFileErrors] = useState({});
  const dispatch = useDispatch();
  const { images, supc, changes, taxonomy, gtin, comments, isValidating } = record;

  const handleFieldValueChange = dataObject => {
    dispatch(handleMassImageUploadFieldValueChanges(dataObject));
  };

  const revertFieldChanges = fieldType => {
    dispatch(revertMassImageUploadFieldChange({ supc, fieldType }));
  };

  const handleItemImagesValidation = () => {
    dispatch(handleMassImageValidation({ supc, changes, isValidating: true }));
  };

  const clearItemChanges = () => {
    dispatch(clearAllChangesForItemInMassImages({ supc }));
  };

  const dataForTable = [];
  const shownAssets = [];
  mainCategoriesList.forEach(mainCategory => {
    const { styles } = imageCategories[mainCategory];
    let image = null;
    if (images) {
      image = _.find(images, img => {
        return img.imageStyle && styles.indexOf(img.imageStyle.toLowerCase()) !== -1;
      });
    }
    if (image) shownAssets.push(image.assetId);
    const isDisabled = checkForRejectedImagesOrPendingImageDeletions(comments, mainCategory);
    dataForTable.push({ image: getValuesFromTwoFields(image, null), type: mainCategory, isDisabled });
  });
  dataForTable.push({ type: null, isEmptyRow: true });

  const setPreviewImageForChanges = type => {
    let preview = null;
    if (changes[type] && changes[type].selectedImagePreview) {
      preview = /* URL.createObjectURL(changes[type].selectedImage); */ changes[type].selectedImagePreview;
    }
    return preview;
  };

  const FormattedImageName = ({ type }) => {
    if (
      changes[type] &&
      changes[type].formattedImageName &&
      changes[type].formattedImageName !== '' &&
      changes[type].selectedImagePreview
    ) {
      const name = changes[type].formattedImageName;
      return (
        <div className="mass-image-attr-name-select mass-image-file-name-green" title={`Formatted Name: ${name}`}>
          <span>{name}</span>
        </div>
      );
    }
    return null;
  };

  const getTitleBasedOnDisabledStatus = (type, defaultTitle) => {
    if (checkForRejectedImagesOrPendingImageDeletions(comments, type)) {
      return MASS_IMAGE_UPLOAD_DISABLED_MESSAGE;
    }
    return defaultTitle;
  };

  const getDropDownSelectedValue = (type, field) => {
    if (changes[type] && changes[type][field]) return changes[type][field];
  };

  const handleChangesInSelectsAndImageUPload = dataObject => {
    handleFieldValueChange({ ...dataObject, gtin });
  };

  const ImageStyleSelect = ({ type }) => {
    if (type) {
      let updatedImageStyles = type ? imageCategories[type].styles : [];

      updatedImageStyles = _.intersection(
        updatedImageStyles,
        getValueBasedOnTheCondition(isNonFood({ taxonomy }), imageNonFoodStyles, imageFoodStyles)
      );
      const title = getTitleBasedOnDisabledStatus(type, 'Image Style');
      const disabledStatus = !setPreviewImageForChanges(type) || isMassUploading;
      return (
        <div title={title}>
          <div>
            <DynamicRequiredField type={type} changes={changes} />
            <Select
              dropdownMatchSelectWidth={false}
              title={title}
              className={`mass-upload-image-style-select ${disabledStatus ? `disabled-selection-mass-image` : ''}`}
              onChange={value =>
                handleChangesInSelectsAndImageUPload({ fieldType: type, supc, imageStyle: value, imageFacing: '' })
              }
              disabled={disabledStatus}
              value={getDropDownSelectedValue(type, 'imageStyle')}
              placeholder="Select image style..."
            >
              {updatedImageStyles.map(imageStyle => (
                <Option key={imageStyle} value={imageStyle}>
                  {imageStyle.toUpperCase()}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      );
    }
    return null;
  };

  const ImageTypeSelect = ({ type }) => {
    if (type) {
      const title = getTitleBasedOnDisabledStatus(type, 'Image type');
      const disabledStatus = !setPreviewImageForChanges(type) || isMassUploading;
      return (
        <div title={title}>
          <div>
            <DynamicRequiredField type={type} changes={changes} />
            <Select
              dropdownMatchSelectWidth={false}
              title={title}
              className={`mass-upload-image-style-select ${disabledStatus ? `disabled-selection-mass-image` : ''}`}
              onChange={value => handleChangesInSelectsAndImageUPload({ fieldType: type, supc, imageType: value })}
              disabled={disabledStatus}
              value={getDropDownSelectedValue(type, 'imageType')}
              placeholder="Select image type..."
            >
              {massImageNameTypes.map(imageNameType => (
                <Option key={imageNameType} value={getFileNamePart(imageNameType)}>
                  {imageNameType}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      );
    }
    return null;
  };

  const isFacingOptionDisabled = (type, option) => {
    const { primary, inPacking, openCase, lifeStyle } = imageCategories;
    const currentImageStyle = _.get(changes, `${type}.imageStyle`, null);
    const condition1 = _.includes(
      [...imageCategories.case.styles, ...openCase.styles, ...inPacking.styles],
      currentImageStyle
    );
    const condition2 = option != '1: Front';
    const condition3 = _.includes([...primary.styles, ...lifeStyle.styles], currentImageStyle);
    const condition4 = !_.includes(['1: Front', '3: Top'], option);
    return !currentImageStyle || (condition1 && condition2) || (condition3 && condition4);
  };

  const ImageFacingSelect = ({ type }) => {
    if (type) {
      const title = getTitleBasedOnDisabledStatus(type, 'Image facing');
      const disabledStatus = !setPreviewImageForChanges(type) || isMassUploading;
      let value = getDropDownSelectedValue(type, 'imageFacing');

      if (value === '') value = undefined;

      return (
        <div title={title}>
          <div>
            <DynamicRequiredField type={type} changes={changes} />
            <Select
              dropdownMatchSelectWidth={false}
              title={title}
              className={`mass-upload-image-style-select ${disabledStatus ? `disabled-selection-mass-image` : ''}`}
              onChange={value => handleChangesInSelectsAndImageUPload({ fieldType: type, supc, imageFacing: value })}
              disabled={disabledStatus}
              value={value}
              placeholder="Select image facing..."
            >
              {imageNameFacings.map(imageNameType => (
                <Option
                  key={imageNameType}
                  value={getFileNamePart(imageNameType)}
                  disabled={isFacingOptionDisabled(type, imageNameType)}
                >
                  {imageNameType}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      );
    }
    return null;
  };

  const ImageAngleSelect = ({ type }) => {
    if (type) {
      const title = getTitleBasedOnDisabledStatus(type, 'Image angle');
      const disabledStatus = !setPreviewImageForChanges(type) || isMassUploading;
      return (
        <div title={title}>
          <div>
            <DynamicRequiredField type={type} changes={changes} />
            <Select
              dropdownMatchSelectWidth={false}
              title={title}
              className={`mass-upload-image-style-select ${disabledStatus ? `disabled-selection-mass-image` : ''}`}
              onChange={value => handleChangesInSelectsAndImageUPload({ fieldType: type, supc, imageAngle: value })}
              disabled={disabledStatus}
              value={getDropDownSelectedValue(type, 'imageAngle')}
              placeholder="Select image angle..."
            >
              {imageNameAngles.map(imageNameType => (
                <Option key={imageNameType} value={getFileNamePart(imageNameType)}>
                  {imageNameType}
                </Option>
              ))}
            </Select>
          </div>
        </div>
      );
    }
    return null;
  };

  const handleImageValidation = file => {
    let flag = true;
    if (MASS_IMAGE_UPLOAD_ACCEPTED_FORMATS.indexOf(file.type) === -1) {
      flag = false;
    }
    return flag;
  };

  const getFileListForItem = type => {
    if (type && changes[type] && changes[type].selectedImage && changes[type].selectedImage.fileList)
      return changes[type].selectedImage.fileList;
    return [];
  };

  const handleDisplayWarningMessage = type => {
    const { [type]: removingType, ...rest } = invalidFileErrors;
    setInvalidFileErrors(rest);
  };

  const renderColumnsImageUpload = ({ type, isDisabled }) => {
    if (type) {
      const { fieldUploadPercentage, isUploading } = changes[type];
      const uploadProps = {
        beforeUpload: file => {
          const isValidImage = MASS_IMAGE_UPLOAD_ACCEPTED_FORMATS.includes(file.type);
          if (!isValidImage) {
            setInvalidFileErrors({
              ...invalidFileErrors,
              [type]: { error: `${file.name} is not a png/ jpg/ jpeg file` }
            });
            setTimeout(() => {
              handleDisplayWarningMessage(type);
            }, 10000);
            return Upload.LIST_IGNORE;
          }
          return isValidImage;
        },
        onChange: info => {
          if (handleImageValidation(info.file) && info.file && info.file.status !== 'removed') {
            let fileList = [...info.fileList];
            fileList = fileList.slice(-1);
            fileList = fileList.map(file => {
              file.status = 'done';
              const reader = new FileReader();
              reader.readAsDataURL(file.originFileObj);
              reader.onload = e => {
                file.base64 = e.target.result;
                handleFieldValueChange({
                  selectedImagePreview: e.target.result,
                  fieldType: type,
                  supc,
                  gtin,
                  isValidated: false
                });
              };
              return file;
            });
            handleChangesInSelectsAndImageUPload({
              selectedImage: { fileList, value: info.file, error: '' },
              fieldType: type,
              supc
            });
          }
        },
        onRemove: () => {
          revertFieldChanges(type);
        },
        fileList: getFileListForItem(type),
        multiple: false
      };
      const isFieldDisabled = isMassUploading || isValidating || isDisabled;
      return (
        <div className="mass-image-upload-selector" title={getTitleBasedOnDisabledStatus(type, 'Select Image')}>
          <Upload {...uploadProps} disabled={isFieldDisabled}>
            <Button disabled={isFieldDisabled}>
              <Icon type="upload" />
              Select Image
            </Button>
          </Upload>
          <Modal
            title={
              <div>
                <CloseCircleFilled style={{ color: 'red' }} />
                {` Invalid File Type`}
              </div>
            }
            visible={invalidFileErrors[type] && invalidFileErrors[type].error}
            onCancel={() => handleDisplayWarningMessage(type)}
            footer={null}
          >
            {invalidFileErrors[type] ? invalidFileErrors[type].error : null}
          </Modal>
          <FormattedImageName type={type} />
          {isUploading && <Progress percent={fieldUploadPercentage} size="small" status="active" />}
        </div>
      );
    }
    return null;
  };

  const getRenditionInfo = renditions => {
    let rendition = null;
    if (renditions.thumbnail) {
      rendition = renditions.thumbnail;
    } else if (renditions.preview) {
      rendition = renditions.preview;
    } else if (renditions.master) {
      rendition = renditions.master;
    }
    return rendition;
  };

  const getImageUrl = imageObject => {
    if (imageObject && imageObject.renditions) {
      const rendition = getRenditionInfo(imageObject.renditions);
      return rendition ? syscoImagePath + rendition.id : null;
    }
    return null;
  };

  const renderImagePreview = ({ image, type, isEmptyRow }) => {
    const isDisabled = checkForRejectedImagesOrPendingImageDeletions(comments, type);
    if (type) {
      const imageUrl = setPreviewImageForChanges(type) || getImageUrl(image);
      const errors = changes[type].errors || [];
      const { isValidated } = changes[type];
      return (
        <div>
          <div className="preview-image-container">
            {imageUrl && (
              <div>
                <div className="preview-upload-or-existing-image" style={{ backgroundImage: `url(${imageUrl})` }}></div>
                <span>
                  <img alt="Preview" src={imageUrl} />
                </span>
              </div>
            )}
            <div className="mass-image-attr-validation-errors">
              {isDisabled && (
                <span className={`${!imageUrl ? 'empty-space' : ''}`} title={MASS_IMAGE_UPLOAD_DISABLED_MESSAGE}>
                  {MASS_IMAGE_UPLOAD_DISABLED_MESSAGE}
                </span>
              )}
              <ul className="circle-list" style={{ position: 'absolute' }}>
                {errors.map((eachError, index) => {
                  return <li key={index}>{`• ${eachError}`}</li>;
                })}
              </ul>
            </div>
            {isValidated && (
              <div className="mass-image-attr-validation-success">
                <span>Valid ✓</span>
              </div>
            )}
          </div>
        </div>
      );
    }
    if (isEmptyRow) {
      return (
        <Button
          type="primary"
          disabled={getEachSupcValidateImagesButtonDisabledStatus(changes) || isMassUploading || isValidating}
          title={`Validate Images for ${supc}`}
          onClick={() => handleItemImagesValidation()}
        >
          {`${isValidating ? `...Validating` : 'Validate Images'}`}
        </Button>
      );
    }
  };

  const renderColumnImageTypeDescription = field => {
    if (field) {
      const { url, comment, rejectedFeedbackComment, value } = selectedFeedback;
      const isDisabled = checkForRejectedImagesOrPendingImageDeletions(comments, field);
      const getStatusAndTitle = status => {
        const title = 'Click to see';
        if (status === 10) {
          return { className: 'rejected-pending-image', title: `${title} rejected image` };
        }
        return { className: undefined, title: `${title} pendng image` };
      };
      const pendingImageComments = getUrlsFromImageComments(comments, field);
      const setSelectedFeedbackForItem = (index, data) => {
        setSelectedFeedback({ index, ...data, type: field });
      };
      const getClassNameForSelectedPendingFeedbacks = index => {
        if (selectedFeedback) {
          const { type, index: selectedIndex } = selectedFeedback;
          if (type === field && selectedIndex === index) return 'selected';
        }
        return 'undefined';
      };
      const getFeedbackMessage = () => {
        return rejectedFeedbackComment ? 'REJECTED REASON' : value.toUpperCase();
      };
      const getListOfPendingImages = () => {
        if (pendingImageComments.length) {
          return (
            <div className="pending-mass-image-comment-link-container" title="Pending feedbacks">
              <div className="header-pending">Pending Approval...</div>
              <ul className="circle-list flex-ui">
                {pendingImageComments.map(
                  (
                    {
                      url: imageUrl,
                      style,
                      status,
                      comment: feedbackComment,
                      rejectedFeedbackComment,
                      value: imageCommentValue
                    },
                    index
                  ) => {
                    const { title, className } = getStatusAndTitle(status);
                    return (
                      <li
                        key={index}
                        className={`list-item-pending-mass-images ${getClassNameForSelectedPendingFeedbacks(index)}`}
                        onClick={() =>
                          setSelectedFeedbackForItem(index, {
                            url: imageUrl,
                            status,
                            rejectedFeedbackComment,
                            comment: feedbackComment,
                            value: imageCommentValue
                          })
                        }
                      >
                        <a
                          href={url}
                          target="_blank"
                          title={title}
                          rel="noopener noreferrer"
                          className={`pending-images-in-mass-image-list ${className}`}
                        >
                          &nbsp;
                        </a>
                        {rejectedFeedbackComment && (
                          <Tooltip
                            overlayClassName="image-upload-type-container-title"
                            title={
                              <div className="fnb-gdsn-tooltip-text">
                                {`Feedback: ${rejectedFeedbackComment || comment}`}
                              </div>
                            }
                          >
                            i
                          </Tooltip>
                        )}
                      </li>
                    );
                  }
                )}
              </ul>
              {selectedFeedback && selectedFeedback.type === field && (
                <div className="comment-info">
                  <div className="inner-info">
                    <div className="type-text">{getFeedbackMessage()}</div>
                    {url && (
                      <div className="image-link" onClick={() => window.open(url, '_blank')}>
                        IMAGE LINK
                      </div>
                    )}
                  </div>
                  <div className="comment-text">{rejectedFeedbackComment || comment}</div>
                </div>
              )}
            </div>
          );
        }
        return null;
      };
      return (
        <div>
          <div className="mass-image-upload-each-item-with-tooltip-container">
            <Tooltip
              overlayClassName="image-upload-type-container-title"
              title={<div className="fnb-gdsn-tooltip-text">{IMAGE_FIELDS_WITH_TOOLTIPS[field]}</div>}
            >
              i
            </Tooltip>
            <div className="mass-image-upload-tooltip-text">{IMAGE_FIELDS_NAMES[field]}</div>
            {/* {isDisabled && (
              <div className="mass-image-disabled-indicator" title={MASS_IMAGE_UPLOAD_DISABLED_MESSAGE}>
                <Tag icon={<MinusCircleOutlined />} color="default">
                  ?
                </Tag>
              </div>
            )} */}
          </div>
          {getListOfPendingImages()}
        </div>
      );
    }
    return null;
  };

  const getRevertIconDisabledStatus = type => {
    let disabledStatus = true;
    if (changes[type] && Object.keys(changes[type]).length) disabledStatus = false;
    return disabledStatus;
  };

  const columns = [
    {
      title: '',
      key: 'type',
      dataIndex: 'type',
      width: 265,
      render: field => renderColumnImageTypeDescription(field)
    },
    {
      title: '',
      width: 125,
      render: field => renderColumnsImageUpload(field)
    },
    {
      title: 'Preview',
      width: 130,
      render: field => renderImagePreview(field)
    },
    {
      title: 'Image Style',
      width: 175,
      render: ({ type }) => <ImageStyleSelect type={type} />
    },
    {
      title: 'Image Type',
      width: 175,
      render: ({ type }) => <ImageTypeSelect type={type} />
    },
    {
      title: 'Image Facing',
      width: 175,
      render: ({ type }) => <ImageFacingSelect type={type} />
    },
    {
      title: 'Image Angle',
      width: 175,
      render: ({ type }) => <ImageAngleSelect type={type} />
    },
    {
      title: 'Delete Selection',
      width: 150,
      render: ({ type, isEmptyRow, ...rest }) => {
        const isFieldDisabled = isMassUploading || checkForRejectedImagesOrPendingImageDeletions(comments, type);
        if (type) {
          const title = getTitleBasedOnDisabledStatus(type, 'Revert Changes');
          return (
            <div className={`${isFieldDisabled ? `mass-image-upload-disabled-revert` : undefined}`}>
              <CloseCircleFilled
                onClick={() => revertFieldChanges(type)}
                disabled={getRevertIconDisabledStatus(type)}
                className={`icon-remove-image-and-selection ${
                  isFieldDisabled ? `disabled-mass-image-remover` : undefined
                }`}
                title={title}
              />
            </div>
          );
        }
        if (isEmptyRow) {
          return (
            <Button
              className="clear-all-mass-changes-in-item"
              title={`Clear all changes for ${supc}`}
              danger="true"
              onClick={() => clearItemChanges()}
            >
              CLEAR ALL
            </Button>
          );
        }
        return null;
      }
    }
  ];

  return (
    <div className="row-expand">
      <div className="mass-upload-image-expand-row-table">
        <Table
          rowKey="type"
          columns={columns}
          dataSource={dataForTable}
          pagination={false}
          rowClassName={recordData =>
            checkForRejectedImagesOrPendingImageDeletions(comments, recordData.type) ? 'disabled-image-row' : ''
          }
        />
      </div>
    </div>
  );
});

export default ExpandedContent;
