import React from 'react';
import { Input, Tooltip, Select, Tag, Modal } from 'antd';
import PendingComment from './PendingComment';
import {
  getCoreDataTagsStyles,
  getCoreDataTagText,
  getCoreDataTagTooltip,
  getItemIsGdsnOrNot,
  getValueBasedOnTheCondition
} from '../util/Util';
import _ from 'lodash';

import {
  CORE_DATA_PENDING_TOOLTIP,
  CORE_DATA_FIELDS,
  VOLUME_KEY,
  VOLUME_CALCULATED_FIELDS,
  MAXIMUM_TIME_OUT_FIELDS,
  TARE_WEIGHT_FIELDS
} from '../util/CoreDataConstants';

class TextAttribute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayedPopUps: [],
      tareWeigtPopUpAlreadyDisplayed: false
    };
  }
  handleCheckValidity = (value, condition, checkAgainstField, item, changedFieldValueToCheck = null) => {
    if (
      (!condition({ value, brandCode: this.getBrandCode(item) }) && !checkAgainstField && !changedFieldValueToCheck) ||
      (checkAgainstField &&
        !condition({
          value,
          secondValue:
            changedFieldValueToCheck !== null
              ? changedFieldValueToCheck
              : this.getValueForField(checkAgainstField, item)
        }))
    )
      return true;
    return false;
  };

  getBrandCode = item => item?.basicData?.brandCode;

  getExistingValue = (item, field) => {
    return item && item.master && item.master[field] && item.master[field].value
      ? item.master[field].value.value
      : null;
  };

  setErrorMessage = (data, message) => {
    return {
      ...data,
      errorMessage: message
    };
  };

  checkValidityBasedOnCondition = (value, condition, item, checkAgainstField, changedFieldValueToCheck = null) => {
    return this.handleCheckValidity(value, condition, checkAgainstField, item, changedFieldValueToCheck);
  };

  checkValidationNeedsBreakInFor = (isCheckingSynchedValidations, synchedField, checkAgainstField) =>
    !isCheckingSynchedValidations || (!synchedField && !checkAgainstField);

  isFieldValidated = (field, item, value, currentField, changedValue, isCheckingSynchedValidations = false) => {
    let isValid = true;
    let validationErrorMessage = '';
    if (CORE_DATA_FIELDS[field].validations) {
      loop: for (const {
        condition,
        validateError,
        checkAgainstField,
        synchedField,
        isCheckOneSideOnly
      } of CORE_DATA_FIELDS[field].validations) {
        this.handleReverseValidityWithSynchedField(
          field,
          item,
          synchedField,
          value,
          isCheckingSynchedValidations,
          isCheckOneSideOnly
        );

        if (
          !isCheckOneSideOnly &&
          ((isCheckingSynchedValidations &&
            this.checkValidityBasedOnCondition(value, condition, item, checkAgainstField, changedValue)) ||
            (!isCheckingSynchedValidations &&
              this.checkValidityBasedOnCondition(value, condition, item, currentField, changedValue)))
        ) {
          isValid = false;
          validationErrorMessage = validateError;
          if (this.checkValidationNeedsBreakInFor(isCheckingSynchedValidations, synchedField, checkAgainstField))
            break loop;
        }
      }
    }
    return { isValid, validationErrorMessage };
  };

  handleReverseValidityWithSynchedField = (
    field,
    item,
    synchedField,
    changedFieldValueToCheck,
    isCheckingSynchedValidations,
    isCheckOneSideOnly
  ) => {
    if (isCheckingSynchedValidations && synchedField && CORE_DATA_FIELDS[synchedField].validations) {
      const { actions } = this.props;
      const existingValue = this.getExistingValue(item, synchedField);
      const fieldValue = this.getValueForField(synchedField, item);
      let data = {
        value: fieldValue,
        field: synchedField,
        existingValue
      };
      const { isValid, validationErrorMessage } = this.isFieldValidated(
        synchedField,
        item,
        fieldValue,
        field,
        changedFieldValueToCheck
      );
      if (!isValid && validationErrorMessage !== '') {
        data = { ...this.setErrorMessage(data, validationErrorMessage) };
      }

      if (isCheckOneSideOnly) {
        data = { ...data, isChangedByValidation: true };
      }
      actions.changeCoreDataFieldValue({ ...data });
    }
  };

  checkPopus = (item, field, value) => {
    const previousValue = this.getValueForField(field, item, true);
    const commentValue = this.getCoreDataCommentValue(item, field);
    if (CORE_DATA_FIELDS[field].popUps && CORE_DATA_FIELDS[field].popUps.length) {
      for (const { condition, titleText, checkAgainstField, isOneTimeCheck } of CORE_DATA_FIELDS[field].popUps) {
        let againstFieldValue = null;
        if (checkAgainstField) {
          againstFieldValue = this.getValueForField(checkAgainstField, item);
        }
        const [isPending, isRejected] = this.getCoreDataCommentFeedbackStatuses(item, field);
        if (
          condition({
            value: document.getElementById(field).value,
            previousValue,
            commentValue,
            againstFieldValue,
            isPending,
            isRejected
          }) &&
          value !== '' &&
          document.getElementById(field).value !== '' &&
          ((!this.state.displayedPopUps.includes(field) && isOneTimeCheck) || !isOneTimeCheck)
        ) {
          if (isOneTimeCheck) {
            this.setState({
              displayedPopUps: [...this.state.displayedPopUps, field]
            });
          }
          if (!TARE_WEIGHT_FIELDS.includes(field) || !this.state.tareWeigtPopUpAlreadyDisplayed) {
            if (TARE_WEIGHT_FIELDS.includes(field)) {
              this.setState(
                {
                  tareWeigtPopUpAlreadyDisplayed: true
                },
                () => {
                  Modal.confirm({
                    title: titleText,
                    okText: 'Confirm',
                    cancelButtonProps: { style: { display: 'none' } },
                    onOk: () => {
                      this.setState({
                        tareWeigtPopUpAlreadyDisplayed: false
                      });
                    }
                  });
                }
              );
            } else {
              Modal.confirm({
                title: titleText,
                okText: 'Confirm',
                cancelButtonProps: { style: { display: 'none' } },
                onOk: () => {}
              });
            }
          }
        }
      }
    }
  };

  getTimeOutValue = field => {
    if (MAXIMUM_TIME_OUT_FIELDS[field]) return 2500;
    return 2000;
  };

  handleFieldChange = (event, field, item, isDropdown = false) => {
    const existingValue = this.getExistingValue(item, field);
    if (isDropdown) {
      this.props.actions.changeCoreDataFieldValue({ value: event, field, existingValue });
      this.checkPopus(item, field, event || '');
    } else {
      const targetValue = event.target.value;
      setTimeout(() => {
        this.checkPopus(item, field, targetValue || '');
      }, this.getTimeOutValue(field));
      let data = {
        value: event.target.value || '',
        field,
        existingValue
      };

      const { isValid, validationErrorMessage } = this.isFieldValidated(
        field,
        item,
        event?.target?.value,
        null,
        null,
        true
      );
      if (!isValid) {
        data = { ...this.setErrorMessage(data, validationErrorMessage) };
      }
      this.props.actions.changeCoreDataFieldValue({ ...data });
    }
  };

  isFieldDisabled = key => {
    const {
      item: {
        basicData: { gdsn, syscoBrand }
      }
    } = this.props;
    return (
      (gdsn === 'Y' && CORE_DATA_FIELDS[key].gdsnDisabled) ||
      this.props.item.readOnly ||
      CORE_DATA_FIELDS[key].isDisabled ||
      (key === 'syy_itm_a_gtin' && syscoBrand === 'Y')
    );
  };

  isAttributeHasPendingValue = key => {
    let status = false;
    const { item } = this.props;
    let itemComment = _.get(item, `coreData.comment[${key}]`, false);
    if (itemComment && itemComment.isPending) status = true;
    return status;
  };

  getRejectionMessage = (item, key) => {
    let message = null;
    let itemComment = _.get(item, `coreData.comment[${key}]`, false);
    if (
      itemComment &&
      (itemComment.isRejected ||
        (itemComment.isFixed && itemComment.isRejectedFixed) ||
        (itemComment.isFixed && !itemComment.isRejectedFixed))
    ) {
      let feedback = _.get(itemComment, 'feedback', '');
      message = _.isArray(feedback) ? _.get(_.last(feedback), 'text', '') : feedback;
    }
    return message;
  };

  getErrorMessage = (item, field) => {
    let itemComment = _.get(item, `coreData.comment[${field.key}]`, false);
    return itemComment && itemComment.errorMessage;
  };

  calculateVolume = (length, width, height, uom) => {
    let volume;
    switch (uom) {
      case 'Foot':
        volume = length * width * height;
        break;
      case 'Inch':
        volume = (length * width * height) / 1728;
        break;
      case 'Centimeter':
        volume = length * width * height * Math.pow(0.033, 3);
        break;
      case 'Decimeter':
        volume = length * width * height * Math.pow(0.0328, 3);
        break;
      case 'Kilometer':
        volume = length * width * height * Math.pow(3280.84, 3);
        break;
      case 'Meter':
        volume = length * width * height * Math.pow(3.28, 3);
        break;
      case 'Millimeter':
        volume = length * width * height * Math.pow(0.0033, 3);
        break;
      case 'Yard':
        volume = length * width * height * Math.pow(3, 3);
        break;
      case 'Micrometer':
        volume = length * width * height * Math.pow(0.0000033, 3);
        break;
      default:
        volume = (length * width * height) / 1728;
        break;
    }
    return volume.toFixed(3);
  };

  calculateVolumeBasedOnLengthWidthHeight = (item, currentValue) => {
    const { length, width, height, uom } = VOLUME_CALCULATED_FIELDS;
    const lengthValue = this.getValueForField(length, item);
    const widthValue = this.getValueForField(width, item);
    const heightValue = this.getValueForField(height, item);
    const uomValue = this.getValueForField(uom, item);
    if (lengthValue && widthValue && heightValue) {
      return this.calculateVolume(lengthValue, widthValue, heightValue, uomValue);
    }
    return '';
  };

  getCoreDataField = (item, key) => _.get(item, `coreData.comment[${key}]`, false);

  getCoreDataCommentValue = (item, key, value = '') => {
    const itemComment = this.getCoreDataField(item, key);
    if (itemComment) {
      return itemComment.value || '';
    }
    return value;
  };

  getCoreDataCommentFeedbackStatuses = (item, key) => {
    const itemComment = this.getCoreDataField(item, key);
    if (itemComment) {
      const { isPending, isRejected } = itemComment;
      return [isPending, isRejected];
    }
    return [false, false];
  };

  getValueForField = (key, item, isOnlyMasterValue = false) => {
    let value = '';
    if (item && item.master && item.master[key] && item.master[key].value && item.master[key].value.value) {
      value = item.master[key].value.value;
    }
    if (isOnlyMasterValue) return value;
    value = this.getCoreDataCommentValue(item, key, value);
    const field = CORE_DATA_FIELDS[key];
    if (value === '' && field && field.defaultValue) return field.defaultValue;
    if (key === VOLUME_KEY) return this.calculateVolumeBasedOnLengthWidthHeight(item, value);
    return value;
  };

  getIfFieldIsRequired = field => field.isRequired && '*';

  render() {
    const { Option } = Select;
    let { item, field, actions, pendingComments } = this.props;
    let commentText = '';
    let existingComment = null;
    pendingComments.comments.forEach(comment => {
      if (comment.field === field.key) {
        commentText = comment.comment;
        existingComment = comment;
      }
    });

    let isGdsn = getItemIsGdsnOrNot(item);

    const getFieldValue = key => {
      return this.getValueForField(key, item);
    };

    const isRejectedMessage = key => {
      return this.getRejectionMessage(item, key);
    };

    const commentsPopup = () => {
      actions.commentsPopup({
        item: item,
        field: field.key,
        currentValue: getFieldValue(field.key),
        fieldType: 'COREDATA',
        fieldCaption: field.caption,
        hint: getValueBasedOnTheCondition(field.hint, field.hint, ''),
        existingComment: existingComment
      });
    };

    const hasPendingValues = this.isAttributeHasPendingValue(field.key);
    const title = hasPendingValues ? `${field.caption} - ${CORE_DATA_PENDING_TOOLTIP}` : undefined;
    const errorMessage = this.getErrorMessage(item, field);
    return (
      <div className={`attr-wrapper ${getValueBasedOnTheCondition(hasPendingValues, 'has-pending', undefined)}`}>
        <div className="attr-label" title={title}>
          {field.caption}{' '}
          <div className="required-indicator">{this.getIfFieldIsRequired(CORE_DATA_FIELDS[field.key])}</div>{' '}
          {hasPendingValues && ' ⟳'}
          {isGdsn && field.tooltip && field.tooltip.length > 0 && (
            <Tooltip
              title={
                <div
                  style={{ paddingLeft: '15px', paddingRight: '15px' }}
                  className="gdsn-info"
                >{`GDSN Field: ${field.tooltip}. To make changes, please do so via GDSN.`}</div>
              }
            >
              i
            </Tooltip>
          )}
        </div>
        {CORE_DATA_FIELDS[field.key].list ? (
          <Select
            defaultValue={getFieldValue(field.key)}
            className={`attr-taxonomy ${getValueBasedOnTheCondition(
              this.isFieldDisabled(field.key),
              'disabled-content',
              undefined
            )} ${getValueBasedOnTheCondition(hasPendingValues, 'has-pending', undefined)}`}
            disabled={this.isFieldDisabled(field.key)}
            value={getFieldValue(field.key)}
            onSelect={value => this.handleFieldChange(value, field.key, item, true)}
            id={field.key}
          >
            {CORE_DATA_FIELDS[field.key].list.map(({ value, label }, index) => (
              <Option key={index} value={label}>
                {label}
              </Option>
            ))}
          </Select>
        ) : (
          <>
            <Input
              className={`attr-textbox ${getValueBasedOnTheCondition(
                hasPendingValues,
                'has-pending',
                undefined
              )} ${getValueBasedOnTheCondition(this.isFieldDisabled(field.key), 'disabled-content', undefined)}`}
              title={getValueBasedOnTheCondition(
                this.isFieldDisabled(field.key),
                'Feedback locked.',
                getFieldValue(field.key)
              )}
              value={getFieldValue(field.key)}
              disabled={this.isFieldDisabled(field.key)}
              onChange={event => this.handleFieldChange(event, field.key, item)}
              id={field.key}
            />
          </>
        )}
        {errorMessage && <span className="validation-error">{errorMessage}</span>}
        {commentText !== '' && <PendingComment comment={commentText} clickOn={!item.readOnly && commentsPopup} />}
        {isRejectedMessage(field.key) && (
          <div className="rejected-denied feedback-text">
            <Tag
              style={{ ...getCoreDataTagsStyles(item?.coreData?.comment[field.key]) }}
              color="warning"
              title={getCoreDataTagTooltip(item?.coreData?.comment[field.key])}
            >
              {getCoreDataTagText(item?.coreData?.comment[field.key])}
            </Tag>
            : <span className="feedback-text">{isRejectedMessage(field.key)}</span>
          </div>
        )}
      </div>
    );
  }
}

export default TextAttribute;
