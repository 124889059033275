export const COUNTRY_CODE = [
  { value: '004', label: 'Afghanistan (004)', countryName: 'Afghanistan' },
  { value: '008', label: 'Albania (008)', countryName: 'Albania' },
  { value: '012', label: 'Algeria (012)', countryName: 'Algeria' },
  { value: '016', label: 'American Samoa (016)', countryName: 'American Samoa' },
  { value: '020', label: 'Andorra (020)', countryName: 'Andorra' },
  { value: '024', label: 'Angola (024)', countryName: 'Angola' },
  { value: '660', label: 'Anguilla (660)', countryName: 'Anguilla' },
  { value: '010', label: 'Antarctica (010)', countryName: 'Antarctica' },
  { value: '028', label: 'Antigua and Barbuda (028)', countryName: 'Antigua and Barbuda' },
  { value: '032', label: 'Argentina (032)', countryName: 'Argentina' },
  { value: '051', label: 'Armenia (051)', countryName: 'Armenia' },
  { value: '533', label: 'Aruba (533)', countryName: 'Aruba' },
  { value: '036', label: 'Australia (036)', countryName: 'Australia' },
  { value: '040', label: 'Austria (040)', countryName: 'Austria' },
  { value: '031', label: 'Azerbaijan (031)', countryName: 'Azerbaijan' },
  { value: '044', label: 'Bahamas (the) (044)', countryName: 'Bahamas (the)' },
  { value: '048', label: 'Bahrain (048)', countryName: 'Bahrain' },
  { value: '050', label: 'Bangladesh (050)', countryName: 'Bangladesh' },
  { value: '052', label: 'Barbados (052)', countryName: 'Barbados' },
  { value: '112', label: 'Belarus (112)', countryName: 'Belarus' },
  { value: '056', label: 'Belgium (056)', countryName: 'Belgium' },
  { value: '084', label: 'Belize (084)', countryName: 'Belize' },
  { value: '204', label: 'Benin (204)', countryName: 'Benin' },
  { value: '060', label: 'Bermuda (060)', countryName: 'Bermuda' },
  { value: '064', label: 'Bhutan (064)', countryName: 'Bhutan' },
  { value: '068', label: 'Bolivia (Plurinational State of) (068)', countryName: 'Bolivia (Plurinational State of)' },
  { value: '535', label: 'Bonaire, Sint Eustatius and Saba (535)', countryName: 'Bonaire, Sint Eustatius and Saba' },
  { value: '070', label: 'Bosnia and Herzegovina (070)', countryName: 'Bosnia and Herzegovina' },
  { value: '072', label: 'Botswana (072)', countryName: 'Botswana' },
  { value: '074', label: 'Bouvet Island (074)', countryName: 'Bouvet Island' },
  { value: '076', label: 'Brazil (076)', countryName: 'Brazil' },
  {
    value: '086',
    label: 'British Indian Ocean Territory (the) (086)',
    countryName: 'British Indian Ocean Territory (the)'
  },
  { value: '096', label: 'Brunei Darussalam (096)', countryName: 'Brunei Darussalam' },
  { value: '100', label: 'Bulgaria (100)', countryName: 'Bulgaria' },
  { value: '854', label: 'Burkina Faso (854)', countryName: 'Burkina Faso' },
  { value: '108', label: 'Burundi (108)', countryName: 'Burundi' },
  { value: '132', label: 'Cabo Verde (132)', countryName: 'Cabo Verde' },
  { value: '116', label: 'Cambodia (116)', countryName: 'Cambodia' },
  { value: '120', label: 'Cameroon (120)', countryName: 'Cameroon' },
  { value: '124', label: 'Canada (124)', countryName: 'Canada' },
  { value: '136', label: 'Cayman Islands (the) (136)', countryName: 'Cayman Islands (the)' },
  { value: '140', label: 'Central African Republic (the) (140)', countryName: 'Central African Republic (the)' },
  { value: '148', label: 'Chad (148)', countryName: 'Chad' },
  { value: '152', label: 'Chile (152)', countryName: 'Chile' },
  { value: '156', label: 'China (156)', countryName: 'China' },
  { value: '162', label: 'Christmas Island (162)', countryName: 'Christmas Island' },
  { value: '166', label: 'Cocos (Keeling) Islands (the) (166)', countryName: 'Cocos (Keeling) Islands (the)' },
  { value: '170', label: 'Colombia (170)', countryName: 'Colombia' },
  { value: '174', label: 'Comoros (the) (174)', countryName: 'Comoros (the)' },
  {
    value: '180',
    label: 'Congo (the Democratic Republic of the) (180)',
    countryName: 'Congo (the Democratic Republic of the)'
  },
  { value: '178', label: 'Congo (the) (178)', countryName: 'Congo (the)' },
  { value: '184', label: 'Cook Islands (the) (184)', countryName: 'Cook Islands (the)' },
  { value: '188', label: 'Costa Rica (188)', countryName: 'Costa Rica' },
  { value: '191', label: 'Croatia (191)', countryName: 'Croatia' },
  { value: '192', label: 'Cuba (192)', countryName: 'Cuba' },
  { value: '531', label: 'Curaçao (531)', countryName: 'Curaçao' },
  { value: '196', label: 'Cyprus (196)', countryName: 'Cyprus' },
  { value: '203', label: 'Czechia (203)', countryName: 'Czechia' },
  { value: '384', label: `Côte d'Ivoire (384)`, countryName: `Côte d'Ivoire` },
  { value: '208', label: 'Denmark (208)', countryName: 'Denmark' },
  { value: '262', label: 'Djibouti (262)', countryName: 'Djibouti' },
  { value: '212', label: 'Dominica (212)', countryName: 'Dominica' },
  { value: '214', label: 'Dominican Republic (the) (214)', countryName: 'Dominican Republic (the)' },
  { value: '218', label: 'Ecuador (218)', countryName: 'Ecuador' },
  { value: '818', label: 'Egypt (818)', countryName: 'Egypt' },
  { value: '222', label: 'El Salvador (222)', countryName: 'El Salvador' },
  { value: '226', label: 'Equatorial Guinea (226)', countryName: 'Equatorial Guinea' },
  { value: '232', label: 'Eritrea (232)', countryName: 'Eritrea' },
  { value: '233', label: 'Estonia (233)', countryName: 'Estonia' },
  { value: '748', label: 'Eswatini (748)', countryName: 'Eswatini' },
  { value: '231', label: 'Ethiopia (231)', countryName: 'Ethiopia' },
  { value: '238', label: 'Falkland Islands (the) [Malvinas] (238)', countryName: 'Falkland Islands (the) [Malvinas]' },
  { value: '234', label: 'Faroe Islands (the) (234)', countryName: 'Faroe Islands (the)' },
  { value: '242', label: 'Fiji (242)', countryName: 'Fiji' },
  { value: '246', label: 'Finland (246)', countryName: 'Finland' },
  { value: '250', label: 'France (250)', countryName: 'France' },
  { value: '254', label: 'French Guiana (254)', countryName: 'French Guiana' },
  { value: '258', label: 'French Polynesia (258)', countryName: 'French Polynesia' },
  { value: '260', label: 'French Southern Territories (the) (260)', countryName: 'French Southern Territories (the)' },
  { value: '266', label: 'Gabon (266)', countryName: 'Gabon' },
  { value: '270', label: 'Gambia (the) (270)', countryName: 'Gambia (the)' },
  { value: '268', label: 'Georgia (268)', countryName: 'Georgia' },
  { value: '276', label: 'Germany (276)', countryName: 'Germany' },
  { value: '288', label: 'Ghana (288)', countryName: 'Ghana' },
  { value: '292', label: 'Gibraltar (292)', countryName: 'Gibraltar' },
  { value: '300', label: 'Greece (300)', countryName: 'Greece' },
  { value: '304', label: 'Greenland (304)', countryName: 'Greenland' },
  { value: '308', label: 'Grenada (308)', countryName: 'Grenada' },
  { value: '312', label: 'Guadeloupe (312)', countryName: 'Guadeloupe' },
  { value: '316', label: 'Guam (316)', countryName: 'Guam' },
  { value: '320', label: 'Guatemala (320)', countryName: 'Guatemala' },
  { value: '831', label: 'Guernsey (831)', countryName: 'Guernsey' },
  { value: '324', label: 'Guinea (324)', countryName: 'Guinea' },
  { value: '624', label: 'Guinea-Bissau (624)', countryName: 'Guinea-Bissau' },
  { value: '328', label: 'Guyana (328)', countryName: 'Guyana' },
  { value: '332', label: 'Haiti (332)', countryName: 'Haiti' },
  { value: '334', label: 'Heard Island and McDonald Islands (334)', countryName: 'Heard Island and McDonald Islands' },
  { value: '336', label: 'Holy See (the) (336)', countryName: 'Holy See (the)' },
  { value: '340', label: 'Honduras (340)', countryName: 'Honduras' },
  { value: '344', label: 'Hong Kong (344)', countryName: 'Hong Kong' },
  { value: '348', label: 'Hungary (348)', countryName: 'Hungary' },
  { value: '352', label: 'Iceland (352)', countryName: 'Iceland' },
  { value: '356', label: 'India (356)', countryName: 'India' },
  { value: '360', label: 'Indonesia (360)', countryName: 'Indonesia' },
  { value: '364', label: 'Iran (Islamic Republic of) (364)', countryName: 'Iran (Islamic Republic of)' },
  { value: '368', label: 'Iraq (368)', countryName: 'Iraq' },
  { value: '372', label: 'Ireland (372)', countryName: 'Ireland' },
  { value: '833', label: 'Isle of Man (833)', countryName: 'Isle of Man' },
  { value: '376', label: 'Israel (376)', countryName: 'Israel' },
  { value: '380', label: 'Italy (380)', countryName: 'Italy' },
  { value: '388', label: 'Jamaica (388)', countryName: 'Jamaica' },
  { value: '392', label: 'Japan (392)', countryName: 'Japan' },
  { value: '832', label: 'Jersey (832)', countryName: 'Jersey' },
  { value: '400', label: 'Jordan (400)', countryName: 'Jordan' },
  { value: '398', label: 'Kazakhstan (398)', countryName: 'Kazakhstan' },
  { value: '404', label: 'Kenya (404)', countryName: 'Kenya' },
  { value: '296', label: 'Kiribati (296)', countryName: 'Kiribati' },
  {
    value: '408',
    label: `Korea (the Democratic People's Republic of) (408)`,
    countryName: `Korea (the Democratic People's Republic of)`
  },
  { value: '410', label: 'Korea (the Republic of) (410)', countryName: 'Korea (the Republic of)' },
  { value: '414', label: 'Kuwait (414)', countryName: 'Kuwait' },
  { value: '417', label: 'Kyrgyzstan (417)', countryName: 'Kyrgyzstan' },
  {
    value: '418',
    label: `Lao People's Democratic Republic (the) (418)`,
    countryName: `Lao People's Democratic Republic (the)`
  },
  { value: '428', label: 'Latvia (428)', countryName: 'Latvia' },
  { value: '422', label: 'Lebanon (422)', countryName: 'Lebanon' },
  { value: '426', label: 'Lesotho (426)', countryName: 'Lesotho' },
  { value: '430', label: 'Liberia (430)', countryName: 'Liberia' },
  { value: '434', label: 'Libya (434)', countryName: 'Libya' },
  { value: '438', label: 'Liechtenstein (438)', countryName: 'Liechtenstein' },
  { value: '440', label: 'Lithuania (440)', countryName: 'Lithuania' },
  { value: '442', label: 'Luxembourg (442)', countryName: 'Luxembourg' },
  { value: '446', label: 'Macao (446)', countryName: 'Macao' },
  { value: '450', label: 'Madagascar (450)', countryName: 'Madagascar' },
  { value: '454', label: 'Malawi (454)', countryName: 'Malawi' },
  { value: '458', label: 'Malaysia (458)', countryName: 'Malaysia' },
  { value: '462', label: 'Maldives (462)', countryName: 'Maldives' },
  { value: '466', label: 'Mali (466)', countryName: 'Mali' },
  { value: '470', label: 'Malta (470)', countryName: 'Malta' },
  { value: '584', label: 'Marshall Islands (the) (584)', countryName: 'Marshall Islands (the)' },
  { value: '474', label: 'Martinique (474)', countryName: 'Martinique' },
  { value: '478', label: 'Mauritania (478)', countryName: 'Mauritania' },
  { value: '480', label: 'Mauritius (480)', countryName: 'Mauritius' },
  { value: '175', label: 'Mayotte (175)', countryName: 'Mayotte' },
  { value: '484', label: 'Mexico (484)', countryName: 'Mexico' },
  { value: '583', label: 'Micronesia (Federated States of) (583)', countryName: 'Micronesia (Federated States of)' },
  { value: '498', label: 'Moldova (the Republic of) (498)', countryName: 'Moldova (the Republic of)' },
  { value: '492', label: 'Monaco (492)', countryName: 'Monaco' },
  { value: '496', label: 'Mongolia (496)', countryName: 'Mongolia' },
  { value: '499', label: 'Montenegro (499)', countryName: 'Montenegro' },
  { value: '500', label: 'Montserrat (500)', countryName: 'Montserrat' },
  { value: '504', label: 'Morocco (504)', countryName: 'Morocco' },
  { value: '508', label: 'Mozambique (508)', countryName: 'Mozambique' },
  { value: '104', label: 'Myanmar (104)', countryName: 'Myanmar' },
  { value: '516', label: 'Namibia (516)', countryName: 'Namibia' },
  { value: '520', label: 'Nauru (520)', countryName: 'Nauru' },
  { value: '524', label: 'Nepal (524)', countryName: 'Nepal' },
  { value: '528', label: 'Netherlands (Kingdom of the) (528)', countryName: 'Netherlands (Kingdom of the)' },
  { value: '540', label: 'New Caledonia (540)', countryName: 'New Caledonia' },
  { value: '554', label: 'New Zealand (554)', countryName: 'New Zealand' },
  { value: '558', label: 'Nicaragua (558)', countryName: 'Nicaragua' },
  { value: '562', label: 'Niger (the) (562)', countryName: 'Niger (the)' },
  { value: '566', label: 'Nigeria (566)', countryName: 'Nigeria' },
  { value: '570', label: 'Niue (570)', countryName: 'Niue' },
  { value: '574', label: 'Norfolk Island (574)', countryName: 'Norfolk Island' },
  { value: '807', label: 'North Macedonia (807)', countryName: 'North Macedonia' },
  { value: '580', label: 'Northern Mariana Islands (the) (580)', countryName: 'Northern Mariana Islands (the)' },
  { value: '578', label: 'Norway (578)', countryName: 'Norway' },
  { value: '512', label: 'Oman (512)', countryName: 'Oman' },
  { value: '586', label: 'Pakistan (586)', countryName: 'Pakistan' },
  { value: '585', label: 'Palau (585)', countryName: 'Palau' },
  { value: '275', label: 'Palestine, State of (275)', countryName: 'Palestine, State of' },
  { value: '591', label: 'Panama (591)', countryName: 'Panama' },
  { value: '598', label: 'Papua New Guinea (598)', countryName: 'Papua New Guinea' },
  { value: '600', label: 'Paraguay (600)', countryName: 'Paraguay' },
  { value: '604', label: 'Peru (604)', countryName: 'Peru' },
  { value: '608', label: 'Philippines (the) (608)', countryName: 'Philippines (the)' },
  { value: '612', label: 'Pitcairn (612)', countryName: 'Pitcairn' },
  { value: '616', label: 'Poland (616)', countryName: 'Poland' },
  { value: '620', label: 'Portugal (620)', countryName: 'Portugal' },
  { value: '630', label: 'Puerto Rico (630)', countryName: 'Puerto Rico' },
  { value: '634', label: 'Qatar (634)', countryName: 'Qatar' },
  { value: '642', label: 'Romania (642)', countryName: 'Romania' },
  { value: '643', label: 'Russian Federation (the) (643)', countryName: 'Russian Federation (the)' },
  { value: '646', label: 'Rwanda (646)', countryName: 'Rwanda' },
  { value: '638', label: 'Réunion (638)', countryName: 'Réunion' },
  { value: '652', label: 'Saint Barthélemy (652)', countryName: 'Saint Barthélemy' },
  {
    value: '654',
    label: 'Saint Helena, Ascension and Tristan da Cunha (654)',
    countryName: 'Saint Helena, Ascension and Tristan da Cunha'
  },
  { value: '659', label: 'Saint Kitts and Nevis (659)', countryName: 'Saint Kitts and Nevis' },
  { value: '662', label: 'Saint Lucia (662)', countryName: 'Saint Lucia' },
  { value: '663', label: 'Saint Martin (French part) (663)', countryName: 'Saint Martin (French part)' },
  { value: '666', label: 'Saint Pierre and Miquelon (666)', countryName: 'Saint Pierre and Miquelon' },
  { value: '670', label: 'Saint Vincent and the Grenadines (670)', countryName: 'Saint Vincent and the Grenadines' },
  { value: '882', label: 'Samoa (882)', countryName: 'Samoa' },
  { value: '674', label: 'San Marino (674)', countryName: 'San Marino' },
  { value: '678', label: 'Sao Tome and Principe (678)', countryName: 'Sao Tome and Principe' },
  { value: '682', label: 'Saudi Arabia (682)', countryName: 'Saudi Arabia' },
  { value: '686', label: 'Senegal (686)', countryName: 'Senegal' },
  { value: '688', label: 'Serbia (688)', countryName: 'Serbia' },
  { value: '690', label: 'Seychelles (690)', countryName: 'Seychelles' },
  { value: '694', label: 'Sierra Leone (694)', countryName: 'Sierra Leone' },
  { value: '702', label: 'Singapore (702)', countryName: 'Singapore' },
  { value: '534', label: 'Sint Maarten (Dutch part) (534)', countryName: 'Sint Maarten (Dutch part)' },
  { value: '703', label: 'Slovakia (703)', countryName: 'Slovakia' },
  { value: '705', label: 'Slovenia (705)', countryName: 'Slovenia' },
  { value: '090', label: 'Solomon Islands (090)', countryName: 'Solomon Islands' },
  { value: '706', label: 'Somalia (706)', countryName: 'Somalia' },
  { value: '710', label: 'South Africa (710)', countryName: 'South Africa' },
  {
    value: '239',
    label: 'South Georgia and the South Sandwich Islands (239)',
    countryName: 'South Georgia and the South Sandwich Islands'
  },
  { value: '728', label: 'South Sudan (728)', countryName: 'South Sudan' },
  { value: '724', label: 'Spain (724)', countryName: 'Spain' },
  { value: '144', label: 'Sri Lanka (144)', countryName: 'Sri Lanka' },
  { value: '729', label: 'Sudan (the) (729)', countryName: 'Sudan (the)' },
  { value: '740', label: 'Suriname (740)', countryName: 'Suriname' },
  { value: '744', label: 'Svalbard and Jan Mayen (744)', countryName: 'Svalbard and Jan Mayen' },
  { value: '752', label: 'Sweden (752)', countryName: 'Sweden' },
  { value: '756', label: 'Switzerland (756)', countryName: 'Switzerland' },
  { value: '760', label: 'Syrian Arab Republic (the) (760)', countryName: 'Syrian Arab Republic (the)' },
  { value: '158', label: 'Taiwan (Province of China) (158)', countryName: 'Taiwan (Province of China)' },
  { value: '762', label: 'Tajikistan (762)', countryName: 'Tajikistan' },
  { value: '834', label: 'Tanzania, the United Republic of (834)', countryName: 'Tanzania, the United Republic of' },
  { value: '764', label: 'Thailand (764)', countryName: 'Thailand' },
  { value: '626', label: 'Timor-Leste (626)', countryName: 'Timor-Leste' },
  { value: '768', label: 'Togo (768)', countryName: 'Togo' },
  { value: '772', label: 'Tokelau (772)', countryName: 'Tokelau' },
  { value: '776', label: 'Tonga (776)', countryName: 'Tonga' },
  { value: '780', label: 'Trinidad and Tobago (780)', countryName: 'Trinidad and Tobago' },
  { value: '788', label: 'Tunisia (788)', countryName: 'Tunisia' },
  { value: '795', label: 'Turkmenistan (795)', countryName: 'Turkmenistan' },
  { value: '796', label: 'Turks and Caicos Islands (the) (796)', countryName: 'Turks and Caicos Islands (the)' },
  { value: '798', label: 'Tuvalu (798)', countryName: 'Tuvalu' },
  { value: '792', label: 'Türkiye (792)', countryName: 'Türkiye' },
  { value: '800', label: 'Uganda (800)', countryName: 'Uganda' },
  { value: '804', label: 'Ukraine (804)', countryName: 'Ukraine' },
  { value: '784', label: 'United Arab Emirates (the) (784)', countryName: 'United Arab Emirates (the)' },
  {
    value: '826',
    label: 'United Kingdom (the) (826)',
    countryName: 'United Kingdom (the)'
  },
  {
    value: '581',
    label: 'United States Minor Outlying Islands (the) (581)',
    countryName: 'United States Minor Outlying Islands (the)'
  },
  { value: '840', label: 'United States (the) (840)', countryName: 'United States (the)' },
  { value: '858', label: 'Uruguay (858)', countryName: 'Uruguay' },
  { value: '860', label: 'Uzbekistan (860)', countryName: 'Uzbekistan' },
  { value: '548', label: 'Vanuatu (548)', countryName: 'Vanuatu' },
  {
    value: '862',
    label: 'Venezuela (Bolivarian Republic of) (862)',
    countryName: 'Venezuela (Bolivarian Republic of)'
  },
  { value: '704', label: 'Viet Nam (704)', countryName: 'Viet Nam' },
  { value: '092', label: 'Virgin Islands (British) (092)', countryName: 'Virgin Islands (British)' },
  { value: '850', label: 'Virgin Islands (U.S.) (850)', countryName: 'Virgin Islands (U.S.)' },
  { value: '876', label: 'Wallis and Futuna (876)', countryName: 'Wallis and Futuna' },
  { value: '732', label: 'Western Sahara* (732)', countryName: 'Western Sahara*' },
  { value: '887', label: 'Yemen (887)', countryName: 'Yemen' },
  { value: '894', label: 'Zambia (894)', countryName: 'Zambia' },
  { value: '716', label: 'Zimbabwe (716)', countryName: 'Zimbabwe' },
  { value: '248', label: 'Åland Islands (248)', countryName: 'Åland Islands' }
];

export const COUNTRY_SUBDIVISION = {
  '124': [
    { textVal: 'CA-AB', value: '124-AB', label: 'Alberta' },
    { textVal: 'CA-BC', value: '124-BC', label: 'British Columbia' },
    { textVal: 'CA-MB', value: '124-MB', label: 'Manitoba' },
    { textVal: 'CA-NB', value: '124-NB', label: 'New Brunswick' },
    { textVal: 'CA-NL', value: '124-NL', label: 'Newfoundland and Labrador' },
    { textVal: 'CA-NT', value: '124-NT', label: 'Northwest Territories' },
    { textVal: 'CA-NS', value: '124-NS', label: 'Nova Scotia' },
    { textVal: 'CA-NU', value: '124-NU', label: 'Nunavut' },
    { textVal: 'CA-ON', value: '124-ON', label: 'Ontario' },
    { textVal: 'CA-PE', value: '124-PE', label: 'Prince Edward Island' },
    { textVal: 'CA-QC', value: '124-QC', label: 'Quebec' },
    { textVal: 'CA-SK', value: '124-SK', label: 'Saskatchewan' },
    { textVal: 'CA-YT', value: '124-YT', label: 'Yukon' }
  ],
  '484': [
    { textVal: 'MX-AGU', value: '484-AGU', label: 'Aguascalientes' },
    { textVal: 'MX-BCN', value: '484-BCN', label: 'Baja California' },
    { textVal: 'MX-BCS', value: '484-BCS', label: 'Baja California Sur' },
    { textVal: 'MX-CAM', value: '484-CAM', label: 'Campeche' },
    { textVal: 'MX-CHP', value: '484-CHP', label: 'Chiapas' },
    { textVal: 'MX-CHH', value: '484-CHH', label: 'Chihuahua' },
    { textVal: 'MX-CMX', value: '484-CMX', label: 'Ciudad de México' },
    { textVal: 'MX-COA', value: '484-COA', label: 'Coahuila de Zaragoza' },
    { textVal: 'MX-COL', value: '484-COL', label: 'Colima' },
    { textVal: 'MX-DUR', value: '484-DUR', label: 'Durango' },
    { textVal: 'MX-GUA', value: '484-GUA', label: 'Guanajuato' },
    { textVal: 'MX-GRO', value: '484-GRO', label: 'Guerrero' },
    { textVal: 'MX-HID', value: '484-HID', label: 'Hidalgo' },
    { textVal: 'MX-JAL', value: '484-JAL', label: 'Jalisco' },
    { textVal: 'MX-MIC', value: '484-MIC', label: 'Michoacán de Ocampo' },
    { textVal: 'MX-MOR', value: '484-MOR', label: 'Morelos' },
    { textVal: 'MX-MEX', value: '484-MEX', label: 'México' },
    { textVal: 'MX-NAY', value: '484-NAY', label: 'Nayarit' },
    { textVal: 'MX-NLE', value: '484-NLE', label: 'Nuevo León' },
    { textVal: 'MX-OAX', value: '484-OAX', label: 'Oaxaca' },
    { textVal: 'MX-PUE', value: '484-PUE', label: 'Puebla' },
    { textVal: 'MX-QUE', value: '484-QUE', label: 'Querétaro' },
    { textVal: 'MX-ROO', value: '484-ROO', label: 'Quintana Roo' },
    { textVal: 'MX-SLP', value: '484-SLP', label: 'San Luis Potosí' },
    { textVal: 'MX-SIN', value: '484-SIN', label: 'Sinaloa' },
    { textVal: 'MX-SON', value: '484-SON', label: 'Sonora' },
    { textVal: 'MX-TAB', value: '484-TAB', label: 'Tabasco' },
    { textVal: 'MX-TAM', value: '484-TAM', label: 'Tamaulipas' },
    { textVal: 'MX-TLA', value: '484-TLA', label: 'Tlaxcala' },
    { textVal: 'MX-VER', value: '484-VER', label: 'Veracruz de Ignacio de la Llave' },
    { textVal: 'MX-YUC', value: '484-YUC', label: 'Yucatán' },
    { textVal: 'MX-ZAC', value: '484-ZAC', label: 'Zacatecas' }
  ],
  '581': [
    { textVal: 'UM-81', value: '581-81', label: 'Baker Island' },
    { textVal: 'UM-84', value: '581-84', label: 'Howland Island' },
    { textVal: 'UM-86', value: '581-86', label: 'Jarvis Island' },
    { textVal: 'UM-67', value: '581-67', label: 'Johnston Atoll' },
    { textVal: 'UM-89', value: '581-89', label: 'Kingman Reef' },
    { textVal: 'UM-71', value: '581-71', label: 'Midway Islands' },
    { textVal: 'UM-76', value: '581-76', label: 'Navassa Island' },
    { textVal: 'UM-95', value: '581-95', label: 'Palmyra Atoll' },
    { textVal: 'UM-79', value: '581-79', label: 'Wake Island' }
  ],
  '840': [
    { textVal: 'US-AL', value: '840-AL', label: 'Alabama' },
    { textVal: 'US-AK', value: '840-AK', label: 'Alaska' },
    { textVal: 'US-AS', value: '840-AS', label: 'American Samoa (see also separate country code entry under AS)' },
    { textVal: 'US-AZ', value: '840-AZ', label: 'Arizona' },
    { textVal: 'US-AR', value: '840-AR', label: 'Arkansas' },
    { textVal: 'US-CA', value: '840-CA', label: 'California' },
    { textVal: 'US-CO', value: '840-CO', label: 'Colorado' },
    { textVal: 'US-CT', value: '840-CT', label: 'Connecticut' },
    { textVal: 'US-DE', value: '840-DE', label: 'Delaware' },
    { textVal: 'US-DC', value: '840-DC', label: 'District of Columbia' },
    { textVal: 'US-FL', value: '840-FL', label: 'Florida' },
    { textVal: 'US-GA', value: '840-GA', label: 'Georgia' },
    { textVal: 'US-GU', value: '840-GU', label: 'Guam (see also separate country code entry under GU)' },
    { textVal: 'US-HI', value: '840-HI', label: 'Hawaii' },
    { textVal: 'US-ID', value: '840-ID', label: 'Idaho' },
    { textVal: 'US-IL', value: '840-IL', label: 'Illinois' },
    { textVal: 'US-IN', value: '840-IN', label: 'Indiana' },
    { textVal: 'US-IA', value: '840-IA', label: 'Iowa' },
    { textVal: 'US-KS', value: '840-KS', label: 'Kansas' },
    { textVal: 'US-KY', value: '840-KY', label: 'Kentucky' },
    { textVal: 'US-LA', value: '840-LA', label: 'Louisiana' },
    { textVal: 'US-ME', value: '840-ME', label: 'Maine' },
    { textVal: 'US-MD', value: '840-MD', label: 'Maryland' },
    { textVal: 'US-MA', value: '840-MA', label: 'Massachusetts' },
    { textVal: 'US-MI', value: '840-MI', label: 'Michigan' },
    { textVal: 'US-MN', value: '840-MN', label: 'Minnesota' },
    { textVal: 'US-MS', value: '840-MS', label: 'Mississippi' },
    { textVal: 'US-MO', value: '840-MO', label: 'Missouri' },
    { textVal: 'US-MT', value: '840-MT', label: 'Montana' },
    { textVal: 'US-NE', value: '840-NE', label: 'Nebraska' },
    { textVal: 'US-NV', value: '840-NV', label: 'Nevada' },
    { textVal: 'US-NH', value: '840-NH', label: 'New Hampshire' },
    { textVal: 'US-NJ', value: '840-NJ', label: 'New Jersey' },
    { textVal: 'US-NM', value: '840-NM', label: 'New Mexico' },
    { textVal: 'US-NY', value: '840-NY', label: 'New York' },
    { textVal: 'US-NC', value: '840-NC', label: 'North Carolina' },
    { textVal: 'US-ND', value: '840-ND', label: 'North Dakota' },
    {
      textVal: 'US-MP',
      value: '840-MP',
      label: 'Northern Mariana Islands (see also separate country code entry under MP)'
    },
    { textVal: 'US-OH', value: '840-OH', label: 'Ohio' },
    { textVal: 'US-OK', value: '840-OK', label: 'Oklahoma' },
    { textVal: 'US-OR', value: '840-OR', label: 'Oregon' },
    { textVal: 'US-PA', value: '840-PA', label: 'Pennsylvania' },
    { textVal: 'US-PR', value: '840-PR', label: 'Puerto Rico (see also separate country code entry under PR)' },
    { textVal: 'US-RI', value: '840-RI', label: 'Rhode Island' },
    { textVal: 'US-SC', value: '840-SC', label: 'South Carolina' },
    { textVal: 'US-SD', value: '840-SD', label: 'South Dakota' },
    { textVal: 'US-TN', value: '840-TN', label: 'Tennessee' },
    { textVal: 'US-TX', value: '840-TX', label: 'Texas' },
    {
      textVal: 'US-UM',
      value: '840-UM',
      label: 'United States Minor Outlying Islands (see also separate country code entry under UM)'
    },
    { textVal: 'US-UT', value: '840-UT', label: 'Utah' },
    { textVal: 'US-VT', value: '840-VT', label: 'Vermont' },
    {
      textVal: 'US-VI',
      value: '840-VI',
      label: 'Virgin Islands, U.S. (see also separate country code entry under VI)'
    },
    { textVal: 'US-VA', value: '840-VA', label: 'Virginia' },
    { textVal: 'US-WA', value: '840-WA', label: 'Washington' },
    { textVal: 'US-WV', value: '840-WV', label: 'West Virginia' },
    { textVal: 'US-WI', value: '840-WI', label: 'Wisconsin' },
    { textVal: 'US-WY', value: '840-WY', label: 'Wyoming' }
  ]
  /*'004': [
    { textVal: 'AF-BDS*', value: '004-BDS*', label: 'Badakhshān' },
    { textVal: 'AF-BGL*', value: '004-BGL*', label: 'Baghlān' },
    { textVal: 'AF-BAL*', value: '004-BAL*', label: 'Balkh' },
    { textVal: 'AF-BDG*', value: '004-BDG*', label: 'Bādghīs' },
    { textVal: 'AF-BAM*', value: '004-BAM*', label: 'Bāmyān' },
    { textVal: 'AF-DAY*', value: '004-DAY*', label: 'Dāykundī' },
    { textVal: 'AF-FRA*', value: '004-FRA*', label: 'Farāh' },
    { textVal: 'AF-FYB*', value: '004-FYB*', label: 'Fāryāb' },
    { textVal: 'AF-GHA*', value: '004-GHA*', label: 'Ghaznī' },
    { textVal: 'AF-GHO*', value: '004-GHO*', label: 'Ghōr' },
    { textVal: 'AF-HEL*', value: '004-HEL*', label: 'Helmand' },
    { textVal: 'AF-HER*', value: '004-HER*', label: 'Herāt' },
    { textVal: 'AF-JOW*', value: '004-JOW*', label: 'Jowzjān' },
    { textVal: 'AF-KAN*', value: '004-KAN*', label: 'Kandahār' },
    { textVal: 'AF-KHO*', value: '004-KHO*', label: 'Khōst' },
    { textVal: 'AF-KNR*', value: '004-KNR*', label: 'Kunaṟ' },
    { textVal: 'AF-KDZ*', value: '004-KDZ*', label: 'Kunduz' },
    { textVal: 'AF-KAB*', value: '004-KAB*', label: 'Kābul' },
    { textVal: 'AF-KAP*', value: '004-KAP*', label: 'Kāpīsā' },
    { textVal: 'AF-LAG*', value: '004-LAG*', label: 'Laghmān' },
    { textVal: 'AF-LOG*', value: '004-LOG*', label: 'Lōgar' },
    { textVal: 'AF-NAN*', value: '004-NAN*', label: 'Nangarhār' },
    { textVal: 'AF-NIM*', value: '004-NIM*', label: 'Nīmrōz' },
    { textVal: 'AF-NUR*', value: '004-NUR*', label: 'Nūristān' },
    { textVal: 'AF-PIA*', value: '004-PIA*', label: 'Paktiyā' },
    { textVal: 'AF-PKA*', value: '004-PKA*', label: 'Paktīkā' },
    { textVal: 'AF-PAN*', value: '004-PAN*', label: 'Panjshayr' },
    { textVal: 'AF-PAR*', value: '004-PAR*', label: 'Parwān' },
    { textVal: 'AF-SAM*', value: '004-SAM*', label: 'Samangān' },
    { textVal: 'AF-SAR*', value: '004-SAR*', label: 'Sar-e Pul' },
    { textVal: 'AF-TAK*', value: '004-TAK*', label: 'Takhār' },
    { textVal: 'AF-URU*', value: '004-URU*', label: 'Uruzgān' },
    { textVal: 'AF-WAR*', value: '004-WAR*', label: 'Wardak' },
    { textVal: 'AF-ZAB*', value: '004-ZAB*', label: 'Zābul' }
  ],
  '008': [
    { textVal: 'AL-01', value: '008-01', label: 'Berat' },
    { textVal: 'AL-09', value: '008-09', label: 'Dibër' },
    { textVal: 'AL-02', value: '008-02', label: 'Durrës' },
    { textVal: 'AL-03', value: '008-03', label: 'Elbasan' },
    { textVal: 'AL-04', value: '008-04', label: 'Fier' },
    { textVal: 'AL-05', value: '008-05', label: 'Gjirokastër' },
    { textVal: 'AL-06', value: '008-06', label: 'Korçë' },
    { textVal: 'AL-07', value: '008-07', label: 'Kukës' },
    { textVal: 'AL-08', value: '008-08', label: 'Lezhë' },
    { textVal: 'AL-10', value: '008-10', label: 'Shkodër' },
    { textVal: 'AL-11', value: '008-11', label: 'Tiranë' },
    { textVal: 'AL-12', value: '008-12', label: 'Vlorë' }
  ],
  '012': [
    { textVal: 'DZ-01', value: '012-01', label: 'Adrar' },
    { textVal: 'DZ-16', value: '012-16', label: 'Alger' },
    { textVal: 'DZ-23', value: '012-23', label: 'Annaba' },
    { textVal: 'DZ-44', value: '012-44', label: 'Aïn Defla' },
    { textVal: 'DZ-46', value: '012-46', label: 'Aïn Témouchent' },
    { textVal: 'DZ-05', value: '012-05', label: 'Batna' },
    { textVal: 'DZ-07', value: '012-07', label: 'Biskra' },
    { textVal: 'DZ-09', value: '012-09', label: 'Blida' },
    { textVal: 'DZ-50*', value: '012-50*', label: 'Bordj Badji Mokhtar' },
    { textVal: 'DZ-34', value: '012-34', label: 'Bordj Bou Arréridj' },
    { textVal: 'DZ-10', value: '012-10', label: 'Bouira' },
    { textVal: 'DZ-35', value: '012-35', label: 'Boumerdès' },
    { textVal: 'DZ-08', value: '012-08', label: 'Béchar' },
    { textVal: 'DZ-06', value: '012-06', label: 'Béjaïa' },
    { textVal: 'DZ-52*', value: '012-52*', label: 'Béni Abbès' },
    { textVal: 'DZ-02', value: '012-02', label: 'Chlef' },
    { textVal: 'DZ-25', value: '012-25', label: 'Constantine' },
    { textVal: 'DZ-56*', value: '012-56*', label: 'Djanet' },
    { textVal: 'DZ-17', value: '012-17', label: 'Djelfa' },
    { textVal: 'DZ-32', value: '012-32', label: 'El Bayadh' },
    { textVal: 'DZ-57*', value: '012-57*', label: 'El Meghaier' },
    { textVal: 'DZ-58*', value: '012-58*', label: 'El Meniaa' },
    { textVal: 'DZ-39', value: '012-39', label: 'El Oued' },
    { textVal: 'DZ-36', value: '012-36', label: 'El Tarf' },
    { textVal: 'DZ-47', value: '012-47', label: 'Ghardaïa' },
    { textVal: 'DZ-24', value: '012-24', label: 'Guelma' },
    { textVal: 'DZ-33', value: '012-33', label: 'Illizi' },
    { textVal: 'DZ-54*', value: '012-54*', label: 'In Guezzam' },
    { textVal: 'DZ-53*', value: '012-53*', label: 'In Salah' },
    { textVal: 'DZ-18', value: '012-18', label: 'Jijel' },
    { textVal: 'DZ-40', value: '012-40', label: 'Khenchela' },
    { textVal: 'DZ-03', value: '012-03', label: 'Laghouat' },
    { textVal: 'DZ-28', value: '012-28', label: `M'sila` },
    { textVal: 'DZ-29', value: '012-29', label: 'Mascara' },
    { textVal: 'DZ-43', value: '012-43', label: 'Mila' },
    { textVal: 'DZ-27', value: '012-27', label: 'Mostaganem' },
    { textVal: 'DZ-26', value: '012-26', label: 'Médéa' },
    { textVal: 'DZ-45', value: '012-45', label: 'Naama' },
    { textVal: 'DZ-31', value: '012-31', label: 'Oran' },
    { textVal: 'DZ-30', value: '012-30', label: 'Ouargla' },
    { textVal: 'DZ-51*', value: '012-51*', label: 'Ouled Djellal' },
    { textVal: 'DZ-04', value: '012-04', label: 'Oum el Bouaghi' },
    { textVal: 'DZ-48', value: '012-48', label: 'Relizane' },
    { textVal: 'DZ-20', value: '012-20', label: 'Saïda' },
    { textVal: 'DZ-22', value: '012-22', label: 'Sidi Bel Abbès' },
    { textVal: 'DZ-21', value: '012-21', label: 'Skikda' },
    { textVal: 'DZ-41', value: '012-41', label: 'Souk Ahras' },
    { textVal: 'DZ-19', value: '012-19', label: 'Sétif' },
    { textVal: 'DZ-11', value: '012-11', label: 'Tamanrasset' },
    { textVal: 'DZ-14', value: '012-14', label: 'Tiaret' },
    { textVal: 'DZ-49*', value: '012-49*', label: 'Timimoun' },
    { textVal: 'DZ-37', value: '012-37', label: 'Tindouf' },
    { textVal: 'DZ-42', value: '012-42', label: 'Tipaza' },
    { textVal: 'DZ-38', value: '012-38', label: 'Tissemsilt' },
    { textVal: 'DZ-15', value: '012-15', label: 'Tizi Ouzou' },
    { textVal: 'DZ-13', value: '012-13', label: 'Tlemcen' },
    { textVal: 'DZ-55*', value: '012-55*', label: 'Touggourt' },
    { textVal: 'DZ-12', value: '012-12', label: 'Tébessa' }
  ],
  '016': [],
  '020': [
    { textVal: 'AD-07*', value: '020-07*', label: 'Andorra la Vella' },
    { textVal: 'AD-02*', value: '020-02*', label: 'Canillo' },
    { textVal: 'AD-03*', value: '020-03*', label: 'Encamp' },
    { textVal: 'AD-08*', value: '020-08*', label: 'Escaldes-Engordany' },
    { textVal: 'AD-04*', value: '020-04*', label: 'La Massana' },
    { textVal: 'AD-05*', value: '020-05*', label: 'Ordino' },
    { textVal: 'AD-06*', value: '020-06*', label: 'Sant Julià de Lòria' }
  ],
  '024': [
    { textVal: 'AO-BGO*', value: '024-BGO*', label: 'Bengo' },
    { textVal: 'AO-BGU*', value: '024-BGU*', label: 'Benguela' },
    { textVal: 'AO-BIE*', value: '024-BIE*', label: 'Bié' },
    { textVal: 'AO-CAB*', value: '024-CAB*', label: 'Cabinda' },
    { textVal: 'AO-CCU*', value: '024-CCU*', label: 'Cuando Cubango' },
    { textVal: 'AO-CNO*', value: '024-CNO*', label: 'Cuanza-Norte' },
    { textVal: 'AO-CUS*', value: '024-CUS*', label: 'Cuanza-Sul' },
    { textVal: 'AO-CNN*', value: '024-CNN*', label: 'Cunene' },
    { textVal: 'AO-HUA*', value: '024-HUA*', label: 'Huambo' },
    { textVal: 'AO-HUI*', value: '024-HUI*', label: 'Huíla' },
    { textVal: 'AO-LUA*', value: '024-LUA*', label: 'Luanda' },
    { textVal: 'AO-LNO*', value: '024-LNO*', label: 'Lunda-Norte' },
    { textVal: 'AO-LSU*', value: '024-LSU*', label: 'Lunda-Sul' },
    { textVal: 'AO-MAL*', value: '024-MAL*', label: 'Malange' },
    { textVal: 'AO-MOX*', value: '024-MOX*', label: 'Moxico' },
    { textVal: 'AO-NAM*', value: '024-NAM*', label: 'Namibe' },
    { textVal: 'AO-UIG*', value: '024-UIG*', label: 'Uíge' },
    { textVal: 'AO-ZAI*', value: '024-ZAI*', label: 'Zaire' }
  ],
  '660': [],
  '010': [],
  '028': [
    { textVal: 'AG-10*', value: '028-10*', label: 'Barbuda' },
    { textVal: 'AG-11*', value: '028-11*', label: 'Redonda' },
    { textVal: 'AG-03*', value: '028-03*', label: 'Saint George' },
    { textVal: 'AG-04*', value: '028-04*', label: 'Saint John' },
    { textVal: 'AG-05*', value: '028-05*', label: 'Saint Mary' },
    { textVal: 'AG-06*', value: '028-06*', label: 'Saint Paul' },
    { textVal: 'AG-07*', value: '028-07*', label: 'Saint Peter' },
    { textVal: 'AG-08*', value: '028-08*', label: 'Saint Philip' }
  ],
  '032': [
    { textVal: 'AR-B', value: '032-B', label: 'Buenos Aires' },
    { textVal: 'AR-K', value: '032-K', label: 'Catamarca' },
    { textVal: 'AR-H', value: '032-H', label: 'Chaco' },
    { textVal: 'AR-U', value: '032-U', label: 'Chubut' },
    { textVal: 'AR-C', value: '032-C', label: 'Ciudad Autónoma de Buenos Aires' },
    { textVal: 'AR-W', value: '032-W', label: 'Corrientes' },
    { textVal: 'AR-X', value: '032-X', label: 'Córdoba' },
    { textVal: 'AR-E', value: '032-E', label: 'Entre Ríos' },
    { textVal: 'AR-P', value: '032-P', label: 'Formosa' },
    { textVal: 'AR-Y', value: '032-Y', label: 'Jujuy' },
    { textVal: 'AR-L', value: '032-L', label: 'La Pampa' },
    { textVal: 'AR-F', value: '032-F', label: 'La Rioja' },
    { textVal: 'AR-M', value: '032-M', label: 'Mendoza' },
    { textVal: 'AR-N', value: '032-N', label: 'Misiones' },
    { textVal: 'AR-Q', value: '032-Q', label: 'Neuquén' },
    { textVal: 'AR-R', value: '032-R', label: 'Río Negro' },
    { textVal: 'AR-A', value: '032-A', label: 'Salta' },
    { textVal: 'AR-J', value: '032-J', label: 'San Juan' },
    { textVal: 'AR-D', value: '032-D', label: 'San Luis' },
    { textVal: 'AR-Z', value: '032-Z', label: 'Santa Cruz' },
    { textVal: 'AR-S', value: '032-S', label: 'Santa Fe' },
    { textVal: 'AR-G', value: '032-G', label: 'Santiago del Estero' },
    { textVal: 'AR-V', value: '032-V', label: 'Tierra del Fuego' },
    { textVal: 'AR-T', value: '032-T', label: 'Tucumán' }
  ],
  '051': [
    { textVal: 'AM-AG', value: '051-AG', label: 'Aragac̣otn' },
    { textVal: 'AM-AR', value: '051-AR', label: 'Ararat' },
    { textVal: 'AM-AV', value: '051-AV', label: 'Armavir' },
    { textVal: 'AM-ER', value: '051-ER', label: 'Erevan' },
    { textVal: 'AM-GR', value: '051-GR', label: `Geġark'unik'` },
    { textVal: 'AM-KT', value: '051-KT', label: `Kotayk'` },
    { textVal: 'AM-LO', value: '051-LO', label: 'Loṙi' },
    { textVal: 'AM-SU', value: '051-SU', label: `Syunik'` },
    { textVal: 'AM-TV', value: '051-TV', label: 'Tavuš' },
    { textVal: 'AM-VD', value: '051-VD', label: 'Vayoć Jor' },
    { textVal: 'AM-SH', value: '051-SH', label: 'Širak' }
  ],
  '533': [],
  '036': [
    { textVal: 'AU-ACT', value: '036-ACT', label: 'Australian Capital Territory' },
    { textVal: 'AU-NSW', value: '036-NSW', label: 'New South Wales' },
    { textVal: 'AU-NT', value: '036-NT', label: 'Northern Territory' },
    { textVal: 'AU-QLD', value: '036-QLD', label: 'Queensland' },
    { textVal: 'AU-SA', value: '036-SA', label: 'South Australia' },
    { textVal: 'AU-TAS', value: '036-TAS', label: 'Tasmania' },
    { textVal: 'AU-VIC', value: '036-VIC', label: 'Victoria' },
    { textVal: 'AU-WA', value: '036-WA', label: 'Western Australia' }
  ],
  '040': [
    { textVal: 'AT-1', value: '040-1', label: 'Burgenland' },
    { textVal: 'AT-2', value: '040-2', label: 'Kärnten' },
    { textVal: 'AT-3', value: '040-3', label: 'Niederösterreich' },
    { textVal: 'AT-4', value: '040-4', label: 'Oberösterreich' },
    { textVal: 'AT-5', value: '040-5', label: 'Salzburg' },
    { textVal: 'AT-6', value: '040-6', label: 'Steiermark' },
    { textVal: 'AT-7', value: '040-7', label: 'Tirol' },
    { textVal: 'AT-8', value: '040-8', label: 'Vorarlberg' },
    { textVal: 'AT-9', value: '040-9', label: 'Wien' }
  ]*/
};
