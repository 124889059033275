import _ from 'lodash';
import React from 'react';
import { Radio } from 'antd';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';

import ProductImage from './ProductImage';
import * as actionCreators from '../../actions';
import ImageStyleChange from '../ImageStyleChange';
import { checkIsSyscoBrand } from '../../util/Util';
import { getDisabledOptions } from '../../util/ImageUtils';

export default function ImageBlock({ image, category, existingStyles, readOnly, imageFn }) {
  const dispatch = useDispatch();
  const actions = bindActionCreators(actionCreators, dispatch);

  const item = useSelector(state => _.get(state, 'selectedItem', {}));
  const pendingComments = useSelector(state => _.get(state, 'pendingComments', {}));
  const unsavedPreferredImgs = useSelector(state => _.get(state, 'selectedItem.imageTab.unsavedPreferredImgs', {}));
  const pendingPreferredImgs = useSelector(state => _.get(state, 'selectedItem.imageTab.pendingPreferredImgs', {}));
  const permissions = useSelector(state => _.get(state, 'user.data.permissions', []));

  const isUnsavedPreferred = unsavedPreferredImgs[category] === image.assetId;
  const isPendingPreferred = pendingPreferredImgs[category] === image.assetId;
  const isPreferred = isUnsavedPreferred || isPendingPreferred;
  let isSyscoBrand = checkIsSyscoBrand(item);
  const pendingImageComments = getPendingImageComments();
  let { disableDelete, disableEdit } = getDisabledOptions(image.assetId, pendingImageComments);

  function getPendingImageComments() {
    let results = [];
    let pending = _.get(pendingComments, 'comments', []);

    _.forEach(pending, cmt => {
      if (!_.isEmpty(cmt.imgComment)) {
        results.push({
          assetId: cmt.imgComment.otmmAssetId,
          changeType: cmt.imgComment.changeType
        });
      }
    });
    return results;
  }

  const isCorrectFacing = () => {
    const { name, imageStyle } = image;
    const match = name.match(/^[^_]+_([A-Za-z0-9]{4})\..*$/);

    if (!match) return false;

    const charactersArray = match ? match[1].split('') : null;
    const facing = charactersArray[1];
    let isCorrect = true;

    if (_.includes(['case', 'openCase', 'inPacking'], category) && facing !== '1') {
      isCorrect = false;
    } else if ((category === 'lifeStyle' || imageStyle === 'RAW OR UNCOOKED') && !_.includes(['1', '3'], facing)) {
      isCorrect = false;
    }

    return isCorrect;
  };

  const updatePreferred = () => {
    let preferred = { ...unsavedPreferredImgs };

    if (pendingPreferredImgs[category] === image.assetId) return;

    if (preferred[category] === image.assetId) {
      delete preferred[category];
    } else {
      preferred[category] = image.assetId;
    }

    actions.setUnsavedPreferredImgs({ preferred });
  };

  const getTooltip = () => {
    let tooltip = 'Mark this image as preferred image';

    if (isPendingPreferred) {
      tooltip = 'Awaiting approval';
    } else if (!isCorrectFacing()) {
      tooltip = 'This image cannot be marked as the preferred image since it does not have the preferred facing';
    }

    return tooltip;
  };

  const isAllowedToMarkAsPreferred = () => {
    return !readOnly || (readOnly && _.includes(permissions, 'INITMAPP.PREFERREDIMAGE.UPDATE', false));
  };

  return (
    <div className="attr-main-img-style-wrapper">
      <div className="attr-main-img">
        <ProductImage
          item={item}
          image={image}
          pendingComments={pendingComments}
          actions={actions}
          imageFn={imageFn}
          disableDelete={disableDelete}
          hideDelete={isSyscoBrand}
          hideReplace={false}
        />
      </div>
      <div className="attr-main-img-style-title">
        {image.imageStyle}
        <ImageStyleChange
          item={item}
          image={image}
          pendingComments={pendingComments}
          actions={actions}
          imageFn={imageFn}
          existingStyles={existingStyles}
          disableEdit={disableEdit}
        />
      </div>
      <div className={`preferred-img-radio ${isPendingPreferred ? 'pending' : ''}`} title={getTooltip()}>
        <Radio
          onClick={updatePreferred}
          checked={isPreferred}
          disabled={!isCorrectFacing() || !isAllowedToMarkAsPreferred()}
        >
          {isPendingPreferred ? 'Marked as Preferred' : 'Select this image'}
        </Radio>
      </div>
    </div>
  );
}
