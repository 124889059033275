import React from 'react';
import { Tooltip } from 'antd';
import { NUTRITION_TAB_TOOLTIPS } from '../../util/Constants';

const CustomTooltip = ({ tooltipKey, isCustom = false }) => {
  return (
    <div className={`enut-attr-label ${isCustom ? 'custom-label' : ''} `}>
      <Tooltip
        title={
          <div style={{ paddingLeft: '15px', paddingRight: '15px' }} className="gdsn-info">
            {NUTRITION_TAB_TOOLTIPS[tooltipKey]}
          </div>
        }
      >
        i
      </Tooltip>
    </div>
  );
};

export default CustomTooltip;
