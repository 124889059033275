import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import _ from 'lodash';
import { Checkbox } from 'antd';
import TooltipInfo from './TooltipInfo';
import {
  checkIfPfasAttributeIsDisabled,
  handlePfasCheckBoxSelection,
  checkEachPfasAttributeIsDisabled
} from '../../../util/Util';
import { PFAS_SINGLE_SELECTIONS } from '../../../util/Constants';

const PfasSelectCheckBox = React.memo(
  ({ attribute: { attrId, name, picklist, tooltip }, actions, isDisabled, changes, taxonomy }) => {
    const [singleSelections, setSingleSelections] = useState([]);
    const dispatch = useDispatch();

    const { disabledTooltip } = checkIfPfasAttributeIsDisabled(taxonomy, { name });

    // handle the single & multiple selections here
    const handleSelectionChange = useCallback(
      value => {
        dispatch(
          actions.pfasSelectionChanged({
            attrId,
            value: [...handlePfasCheckBoxSelection(changes, attrId, value, singleSelections)]
          })
        );
      },
      [dispatch, attrId, singleSelections, changes]
    );

    useEffect(() => {
      // Update single selections when picklist changes
      const updatedSingleSelections = picklist
        .filter(eachPicklist => PFAS_SINGLE_SELECTIONS.includes(eachPicklist.value))
        .map(eachPicklist => eachPicklist.id);
      setSingleSelections(updatedSingleSelections);
    }, [picklist]);

    const updatedPicklistValues = picklist.map(eachPicklist => {
      return {
        ...eachPicklist,
        label: eachPicklist.value,
        value: eachPicklist.id,
        disabled: checkEachPfasAttributeIsDisabled(changes, attrId, singleSelections, eachPicklist)
      };
    });

    const highlightedClassName = changes[attrId] && changes[attrId].length ? 'normal-border' : 'highlighted-border';

    return (
      <div key={`f ${attrId}`} className="attr-wrapper pfas-wrapper" style={{ width: '100% !important' }}>
        <div className="attr-label">
          {name}
          <TooltipInfo tooltip={tooltip} className="non-sustain" />
        </div>
        <div style={{ display: 'grid' }}>
          <span className="pfas-always-on-tooltip-description">
            If your product or packaging/packaging components do not contain intentionally added PFAS, you can select
            one or more of the last three options
          </span>
          {disabledTooltip && <span className="disabled-pfas-label">{disabledTooltip}</span>}
        </div>
        <div style={{ display: 'grid' }}>
          <Checkbox.Group
            style={{ display: 'contents' }}
            disabled={isDisabled || Boolean(disabledTooltip)}
            // options={updatedPicklistValues}
            value={changes[attrId]}
            // selected={changes}
            onChange={handleSelectionChange}
          >
            {updatedPicklistValues.map(({ value, label, disabled }) => (
              <Checkbox
                key={value}
                value={value}
                disabled={disabled}
                selected={changes[attrId] && changes[attrId].includes(value)}
                className={highlightedClassName}
              >
                {label}
              </Checkbox>
            ))}
          </Checkbox.Group>
        </div>
      </div>
    );
  }
);

export default PfasSelectCheckBox;
