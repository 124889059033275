import { openDB } from 'idb';

class IndexedDb {
  database;
  db;

  constructor(database) {
    this.database = database;
  }

  async createObjectStore(tableNames) {
    try {
      this.db = await openDB(this.database, 1, {
        upgrade(db) {
          for (let tableData of tableNames) {
            const { tableName, indexes, indexFieldArray = 'id' } = tableData;
            if (db.objectStoreNames.contains(tableName)) {
              continue;
            }
            const store = db.createObjectStore(tableName, {
              autoIncrement: indexFieldArray === 'id' ? true : false,
              keyPath: indexes
            });
            store.createIndex(indexes, indexes, { unique: true });
          }
        }
      });
    } catch (error) {
      return false;
    }
  }

  async getValue(tableName, conditions, id = null) {
    const tx = this.db.transaction(tableName, 'readonly');
    const store = tx.objectStore(tableName);
    const result = await store.get(conditions || id);
    return result;
  }

  async getAllValue(tableName) {
    const tx = this.db.transaction(tableName, 'readonly');
    const store = tx.objectStore(tableName);
    const result = await store.getAll();
    return result;
  }

  async putValue(tableName, value, conditions = null) {
    const tx = this.db.transaction([tableName], 'readwrite');
    const store = tx.objectStore(tableName);
    let isExistingData = false;
    if (conditions) {
      let previousValue = await store.get(conditions);
      previousValue = {
        ...previousValue,
        ...value
      };
      return await store.put(previousValue);
    }
    if (!isExistingData) {
      const result = await store.put(value);
      return result;
    }
  }

  async putBulkValue(tableName, values) {
    const tx = this.db.transaction(tableName, 'readwrite');
    const store = tx.objectStore(tableName);
    for (const value of values) {
      await store.put(value);
    }
    return this.getAllValue(tableName);
  }

  async deleteValue(tableName, id) {
    const tx = this.db.transaction([tableName], 'readwrite');
    const store = tx.objectStore(tableName);
    const result = await store.get(id);
    if (!result) {
      return result;
    }
    await store.delete(id);
    return id;
  }
}

export default IndexedDb;
