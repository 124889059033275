import React from 'react';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { massUpdateSelectionChanged, closeMassUpdateImageOverlay } from '../../actions';
import { MASS_UPDATE_TYPES } from '../../util/Constants';
import MassImageUploadOverlay from './MassImageUploadOverlay';

const { IMAGE } = MASS_UPDATE_TYPES;

const MassImageUpdate = React.memo(function ChildComponent({ supcs }) {
  const dispatch = useDispatch();
  const massUpdateType = useSelector(state => _.get(state.massUpdate, 'updateType', null));

  const handleClick = type => {
    dispatch(massUpdateSelectionChanged({ massUpdateType: type }));
    dispatch(closeMassUpdateImageOverlay({}));
  };

  return (
    <div className="mass-update-btn-wrapper">
      {massUpdateType === IMAGE && <MassImageUploadOverlay supcs={supcs} onCloseModal={handleClick} />}
    </div>
  );
});

export default MassImageUpdate;
