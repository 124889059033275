import React from 'react';
import { Input, Select, Menu, Icon, Dropdown, Button } from 'antd';
import _ from 'lodash';
import {
  getReadableUom,
  isValidNutritionHeaderField,
  checkForFieldPendingNutrition,
  validateForThreeDecimalsNutritionsQuantity
} from '../../../util/Util';
import { HEADER_INDEX } from '../../../util/Constants';

const { Option } = Select;

const OptionalNutritions = ({
  nutritionData,
  isReadOnly,
  isItemReadOnly,
  onFieldChange,
  nutritionErrors,
  updateNutritionErrors,
  pendingNutritions,
  isDisabledBySyscoOrGdsnItemFlag
}) => {
  const nutritionAnalysisHeader = _.get(nutritionData, 'nutritionAnalysisHeader', []);
  const allNutritions = _.get(nutritionData, 'nutritionAnalysisHeader[0].nutritionAnalysisDetail', []);
  const changedFields = _.get(nutritionData, 'changedFields', []);

  const getNutritionIndex = nutritionShortName => {
    return nutritionAnalysisHeader[HEADER_INDEX].nutritionAnalysisDetail.findIndex(
      ({ shortName }) => shortName === nutritionShortName
    );
  };

  const updateHeaderNutritionArray = (data, index, hasSelected, hasRemoved, valueObject = {}) => {
    return [
      ...data.slice(0, index),
      {
        ...data[index],
        ...valueObject,
        hasSelected,
        hasRemoved
      },
      ...data.slice(index + 1)
    ];
  };

  const previousNutrition = index => allNutritions[index];

  const handleAdditionalNutritionSelect = e => {
    const selectedShortName = _.get(e.item.props, 'nutrition', null);
    const nutritionIndex = getNutritionIndex(selectedShortName);
    const updatedHeader = [
      ...nutritionAnalysisHeader.slice(0, 0),
      {
        ...nutritionAnalysisHeader[0],
        nutritionAnalysisDetail: updateHeaderNutritionArray(
          nutritionAnalysisHeader[HEADER_INDEX].nutritionAnalysisDetail,
          nutritionIndex,
          true,
          false
        )
      },
      ...nutritionAnalysisHeader.slice(0 + 1)
    ];
    onFieldChange({
      nutritionAnalysisHeader: updatedHeader,
      changedFields: [...new Set([...changedFields, selectedShortName])]
    });
  };

  const onChangeAdditionalNutrition = (shortName, previousShortName) => {
    const nutritionIndex = getNutritionIndex(shortName);
    const previousNutritionIndex = getNutritionIndex(previousShortName);
    const previousData = {
      quantityContained: previousNutrition(previousNutritionIndex).quantityContained
    };
    const updatedHeader = [
      ...nutritionAnalysisHeader.slice(0, 0),
      {
        ...nutritionAnalysisHeader[0],
        nutritionAnalysisDetail: updateHeaderNutritionArray(
          updateHeaderNutritionArray(
            nutritionAnalysisHeader[HEADER_INDEX].nutritionAnalysisDetail,
            nutritionIndex,
            true,
            false,
            previousData
          ),
          previousNutritionIndex,
          false,
          true,
          {
            quantityContained: null
          }
        )
      },
      ...nutritionAnalysisHeader.slice(0 + 1)
    ];
    onFieldChange({
      nutritionAnalysisHeader: updatedHeader,
      changedFields: [...new Set([...changedFields, shortName])]
    });
  };

  const checkChangedValueExistance = shortName => {
    if (changedFields && changedFields.length) {
      return changedFields.filter(changedShortName => changedShortName !== shortName);
    }
    return changedFields;
  };

  const onRemoveOptionalNutrition = shortName => {
    const nutritionIndex = getNutritionIndex(shortName);
    const updatedHeader = [
      ...nutritionAnalysisHeader.slice(0, 0),
      {
        ...nutritionAnalysisHeader[0],
        nutritionAnalysisDetail: updateHeaderNutritionArray(
          nutritionAnalysisHeader[HEADER_INDEX].nutritionAnalysisDetail,
          nutritionIndex,
          false,
          true,
          { quantityContained: null }
        )
      },
      ...nutritionAnalysisHeader.slice(0 + 1)
    ];
    if (nutritionErrors[shortName]) {
      let updatedErrors = { ...nutritionErrors };
      delete updatedErrors[shortName];
      updateNutritionErrors({ updatedErrors });
    }
    onFieldChange({
      nutritionAnalysisHeader: updatedHeader,
      changedFields: [...checkChangedValueExistance(shortName), `remove-${shortName}`]
    });
  };

  const handleFieldChange = (field, shortName, value, currentValue) => {
    let updatedErrors = { ...nutritionErrors };
    const nutritionIndex = getNutritionIndex(shortName);
    let updatedValue = value;
    if (field === 'quantityContained') {
      updatedValue = validateForThreeDecimalsNutritionsQuantity(value, currentValue);
    }
    const updatedHeader = [
      ...nutritionAnalysisHeader.slice(0, 0),
      {
        ...nutritionAnalysisHeader[0],
        nutritionAnalysisDetail: [
          ...nutritionAnalysisHeader[HEADER_INDEX].nutritionAnalysisDetail.slice(0, nutritionIndex),
          {
            ...nutritionAnalysisHeader[HEADER_INDEX].nutritionAnalysisDetail[nutritionIndex],
            [field]: updatedValue
          },
          ...nutritionAnalysisHeader[HEADER_INDEX].nutritionAnalysisDetail.slice(nutritionIndex + 1)
        ]
      },
      ...nutritionAnalysisHeader.slice(0 + 1)
    ];
    if (!isValidNutritionHeaderField(updatedValue)) {
      updatedErrors = {
        ...updatedErrors,
        [shortName]: 'Invalid Value'
      };
    } else {
      delete updatedErrors[shortName];
    }
    onFieldChange({
      nutritionAnalysisHeader: updatedHeader,
      changedFields: [...new Set([...changedFields, shortName])]
    });
    updateNutritionErrors({ updatedErrors });
  };

  const menu = (
    <Menu onClick={handleAdditionalNutritionSelect}>
      {allNutritions.map((elem, i) => {
        if (!elem.isRequiredNutrition) {
          const index = _.findIndex(allNutritions, item => elem.shortName === item.shortName && item.hasSelected);
          return (
            <Menu.Item
              key={`${elem.shortName} = ${i}`}
              nutrition={elem.shortName}
              disabled={index !== -1 || isItemReadOnly}
            >
              {elem.nutritionName}
            </Menu.Item>
          );
        }
        return undefined;
      })}
    </Menu>
  );

  const hasFieldErrors = field => {
    if (Object.keys(nutritionErrors).includes(field)) return `nt-invalid-field`;
    return ``;
  };

  const checkDisableStatus = () => {
    return isReadOnly || isItemReadOnly || isDisabledBySyscoOrGdsnItemFlag;
  };

  const gdsnSyscoAndReadOnlyStatus = isReadOnly || isDisabledBySyscoOrGdsnItemFlag;

  return (
    <div className="nt-section">
      <div className="nt-addition-nutrition-title-container">
        <div className="nutri-header-title nt-title">Additional Nutrient Types</div>
        <Dropdown overlay={menu} trigger={['click']} disabled={checkDisableStatus()}>
          <Button className={`add-opt-nutri ${checkDisableStatus() ? 'optional-nutritions-disabled' : undefined}`}>
            + Add Nutrition <Icon type="down" />
          </Button>
        </Dropdown>
      </div>
      {allNutritions.map((obj, i) => {
        if (!obj.isRequiredNutrition && obj.hasSelected) {
          const shortName = _.get(obj, 'shortName', '');
          const uom = getReadableUom(_.get(obj, 'quantityContainedUofm', null));
          const quantityContained = _.get(obj, 'quantityContained', null);

          return (
            <div key={`${shortName}-${i}`}>
              <div className="daily-nutri-row">
                <div className="addition-nutrition-select-container">
                  <Select
                    className={`additional-nutrition-select-type`}
                    placeholder={'Please select...'}
                    value={shortName}
                    disabled={checkDisableStatus()}
                    onSelect={value => onChangeAdditionalNutrition(value, shortName)}
                  >
                    {allNutritions.map(obj => {
                      if (!obj.isRequiredNutrition) {
                        const index = _.findIndex(
                          allNutritions,
                          item => obj.shortName === item.shortName && item.hasSelected
                        );
                        return (
                          <Option
                            disabled={index !== -1 || isItemReadOnly || isDisabledBySyscoOrGdsnItemFlag}
                            value={obj.shortName}
                            key={obj.shortName}
                          >
                            {obj.nutritionName}
                          </Option>
                        );
                      }
                      return undefined;
                    })}
                  </Select>
                </div>
                <div className="additional-nutrition-data-container">
                  <Input
                    className={`daily-nutri-qty ${hasFieldErrors(shortName)} ${checkForFieldPendingNutrition(
                      shortName,
                      pendingNutritions
                    )}`}
                    placeholder="Qty"
                    type={'number'}
                    min={'0'}
                    title={quantityContained}
                    value={quantityContained}
                    disabled={checkDisableStatus()}
                    onChange={e => handleFieldChange('quantityContained', shortName, e.target.value, quantityContained)}
                  />
                  <div className="daily-nutri-uom with-space">{uom}</div>
                  <Icon
                    type="close-circle-o"
                    title={'Remove'}
                    style={{
                      fontSize: 18,
                      color: gdsnSyscoAndReadOnlyStatus ? '#c6c6c6' : '#e95c56',
                      marginLeft: '10px',
                      marginTop: '2px',
                      cursor: gdsnSyscoAndReadOnlyStatus ? 'not-allowed' : 'pointer',
                      pointerEvents: gdsnSyscoAndReadOnlyStatus ? 'none' : 'auto'
                    }}
                    onClick={() => onRemoveOptionalNutrition(shortName)}
                    disabled={gdsnSyscoAndReadOnlyStatus}
                  />
                </div>
              </div>
              <hr className="nt-divider-sm" />
            </div>
          );
        }

        return undefined;
      })}
    </div>
  );
};

export default OptionalNutritions;
