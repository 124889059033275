import _ from 'lodash';
import { INT_IDENTIFIERS, NUM_LEADING_CHARS, NUM_TRAILING_CHARS } from './../util/Constants';

const removeExtraSpaces = text => {
  if (text) {
    return text.replace(/[ \t\r]+/g, ' ').trim();
  } else {
    return text;
  }
};

const formatDecimals = (text, attribute) => {
  let strs = text.split(' ');

  const formattedArray = _.map(strs, (str, index) => {
    let formatted = str;

    formatted = ripSpecialCharacters(formatted);

    const { remainingChars, firstChar, lastChar } = ripLeadingAndTrailingChars(
      NUM_LEADING_CHARS,
      NUM_TRAILING_CHARS,
      formatted
    );

    if (!_.isEmpty(firstChar) || !_.isEmpty(lastChar)) {
      formatted = remainingChars;
    }

    if (isNumeric(formatted)) {
      if (isDecimalNumber(formatted)) {
        formatted = formatDecimalNumber(formatted);
      } else if (attribute === 'additional') {
        formatted = formatIntegerNumber(index, lastChar, formatted, strs);
      }
    }

    formatted = apppendLeadingAndTrailingChars(firstChar, lastChar, formatted);
    formatted = appendSpecialCharacters(formatted, str);

    return formatted;
  });

  return formattedArray.join(' ');
};

const ripLeadingAndTrailingChars = (leadingChars, trailingChars, str) => {
  let lastChar = null;
  let firstChar = null;

  if (_.includes(leadingChars, str.slice(0, 1))) {
    firstChar = str.slice(0, 1);
    str = str.slice(1);
  }

  if (_.includes(trailingChars, str.slice(-1))) {
    lastChar = str.slice(-1);
    str = str.slice(0, -1);
  }

  return { remainingChars: str, firstChar, lastChar };
};

const ripSpecialCharacters = str => {
  if (str.slice(-2) === `\n•`) {
    return str.slice(0, -2);
  }

  if (str.slice(-1) === `\n`) {
    return str.slice(0, -1);
  }

  return str;
};

const appendSpecialCharacters = (formattedStr, originalStr) => {
  if (originalStr.slice(-2) === `\n•`) {
    return `${formattedStr}\n•`;
  }

  if (originalStr.slice(-1) === `\n`) {
    return `${formattedStr}\n`;
  }

  return formattedStr;
};

const apppendLeadingAndTrailingChars = (firstChar, lastChar, str) => {
  if (!_.isEmpty(lastChar)) {
    str = `${str}${lastChar}`;
  }

  if (!_.isEmpty(firstChar)) {
    str = `${firstChar}${str}`;
  }

  return str;
};

const isNumeric = str => {
  if (typeof str != 'string') return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

const isDecimalNumber = str => {
  return _.includes(str, '.');
};

const formatDecimalNumber = val => {
  let index = val.indexOf('.');
  let formatted = `${val}00`.substring(0, index + 3);
  return formatted;
};

const formatIntegerNumber = (index, nextChar, val, strs) => {
  if (!nextChar) {
    nextChar = _.get(strs, `[${index + 1}]`, null);
  }

  if (_.includes(INT_IDENTIFIERS, _.toLower(nextChar))) {
    return `${val}.00`;
  }

  return val;
};

const capitalizeFirstLetter = (str, attribute) => {
  if (attribute === 'descriptor') {
    return str;
  }

  let indices = [];
  for (var i = 0; i < str.length; i++) {
    if (str[i] === '•') indices.push(i);
  }

  _.forEach(indices, i => {
    let opt1 = str[i + 1];
    let opt2 = str[i + 2];

    if (opt1 && isCharacterALetter(opt1)) {
      str = setCharAt(str, i + 1, _.toUpper(opt1));
    } else if (opt2 && isCharacterALetter(opt2)) {
      str = setCharAt(str, i + 2, _.toUpper(opt2));
    }
  });

  str = setCharAt(str, 0, _.toUpper(str[0]));
  return str;
};

const setCharAt = (str, index, chr) => {
  if (index > str.length - 1) return str;
  return str.substring(0, index) + chr + str.substring(index + 1);
};

const isCharacterALetter = char => {
  return /[a-zA-Z]/.test(char);
};

// This should run only in the auto approval job, not in the frontend
const addBulletPoints = (text, attribute, isCronjob = false) => {
  if (!isCronjob) return text;

  if (attribute === 'descriptor') {
    return text;
  } else {
    let x = text.replace(new RegExp('\n(?!•)', 'g'), '\n• '); // Replaces line breaks with a line break and •
    x = x.replace(new RegExp('•(?! )', 'g'), '• '); // Removes space between a • and a letter
    return x;
  }
};

export const formatFnb = fnbs => {
  let clone = { ...fnbs };
  let formatted = {};

  _.forOwn(clone, (val, key) => {
    val = addBulletPoints(val, key);
    val = formatDecimals(val, key);
    val = removeExtraSpaces(val);
    val = capitalizeFirstLetter(val, key);

    formatted[key] = val;
  });

  return formatted;
};

export const findRepeatedFnb = fnbs => {
  let fnbArray = _.values(fnbs);
  const duplicateAttributes = [];
  const duplicateValues = fnbArray.filter((val, index) => !_.isEmpty(val) && fnbArray.indexOf(val) !== index);

  _.forEach(fnbs, (val, key) => {
    if (_.includes(duplicateValues, val)) {
      duplicateAttributes.push(key);
    }
  });

  return duplicateAttributes;
};
