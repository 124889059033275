import React from 'react';
import TaxonomyAttribute from './TaxonomyAttribute';
import { GDSN_DISABLED_CLAIMS, GDSN_DISABLED_GENERAL_ATTRIBUTES, SYSCO_DISABLED_CLAIMS } from '../util/Constants';

class TaxonomyAttributeTab extends React.Component {
  constructor() {
    super();
    this.state = {
      filteredArrayValues: []
    };
  }

  handleCancel = () => {
    this.state.filteredArrayValues &&
      this.state.filteredArrayValues.forEach((value, index) => {
        this[`taxonomyAttributeRef${index}`]?.current?.handleCancel() || console.log();
      });
  };

  componentDidMount() {
    const { item, filter } = this.props;
    if (item.taxonomy && item.taxonomy.attributes && item.taxonomy.attributes.length > 0) {
      const filteredValues = item.taxonomy.attributes.filter(filter);
      this.setState({ filteredArrayValues: filteredValues });
      filteredValues &&
        filteredValues.forEach((value, index) => {
          this[`taxonomyAttributeRef${index}`] = React.createRef();
        });
    }
  }

  checkFieldsForClaimsAndChildNutrition = (nutritionSourceOid, attrType, name) => {
    if (nutritionSourceOid === 31) {
      if (attrType === 'Claim') {
        return GDSN_DISABLED_CLAIMS.includes(name);
      }
      if (attrType === 'General') {
        return GDSN_DISABLED_GENERAL_ATTRIBUTES.includes(name);
      }
      return true;
    }
    return false;
  };

  checkDisabledFieldsForSyscoBrandInClaims = (nutritionSourceOid, attrType, name, avoidForOtherValidations) => {
    if (nutritionSourceOid === 23) {
      if (attrType === 'Claim') {
        return SYSCO_DISABLED_CLAIMS.includes(name);
      }
      if (!avoidForOtherValidations) return true;
    }
    return false;
  };

  checkForFieldDisablingStatusOnSyscoOrGdsnFlag = ({ name }) => {
    let isForDisable = false;
    const {
      isAvailableForSyscoOrGdsnDisabled,
      avoidForOtherValidations,
      item: { nutritions: { nutritionSourceOid } } = { nutritions: {} },
      attrType
    } = this.props;
    if (isAvailableForSyscoOrGdsnDisabled) {
      if (
        this.checkFieldsForClaimsAndChildNutrition(nutritionSourceOid, attrType, name) ||
        this.checkDisabledFieldsForSyscoBrandInClaims(nutritionSourceOid, attrType, name, avoidForOtherValidations)
      )
        isForDisable = true;
    }
    return isForDisable;
  };

  render() {
    const { item, actions, filter, attrType, pendingComments, nutritionErrors, subType, nonCaption } = this.props;
    let filteredAttr = [];

    if (item.taxonomy && item.taxonomy.attributes && item.taxonomy.attributes.length > 0) {
      filteredAttr = item.taxonomy.attributes.filter(element => filter(element, true) && (!subType || (subType && `${element.type}`.includes(subType))) );
    }

    return (
      <div className="attr-set">
        {filteredAttr.length > 0 ? (
          filteredAttr.map((attribute, index) => {
            return (
              <TaxonomyAttribute
                key={index}
                actions={actions}
                item={item}
                attribute={attribute}
                pendingComments={pendingComments}
                attrType={attrType}
                ref={this[`taxonomyAttributeRef${index}`]}
                isDisabledForGdsnOrSysco={this.checkForFieldDisablingStatusOnSyscoOrGdsnFlag(attribute)}
                nutritionErrors={nutritionErrors}
              />
            );
          })
        ) : item.pending ? (
          <div className="attr-field-info">Loading...</div>
        ) : (
          <div className="attr-field-info">{`No ${nonCaption || attrType} attributes found for the item type`}</div>
        )}
      </div>
    );
  }
}

export default TaxonomyAttributeTab;
