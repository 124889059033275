import React from 'react';

const DISABLED_COO_MESSAGES = {
  syscoBrand: (
    <>
      Updates may not be made in this portal to disabled fields. This product is a <b>Sysco Brand private label item</b>
      , please update your product data in the Sysco Stibo QA specification database. For access questions, contact your
      Program Quality Manager or email fnc_ssdatabase_000@corp.sysco.com.
    </>
  ),
  gdsn: (
    <>
      Updates may not be made in this portal to disabled fields. This product is a{' '}
      <b>GDSN-published, national brand item</b>, please update your product data with your data pool partner and be
      sure to publish your changes to Sysco (GLN 0074865000000).
    </>
  )
};

const DisabledCooMessage = ({ gdsn, syscoBrand }) => {
  if (syscoBrand) {
    return (
      <div className="coo-disabled-message">
        <span className="coo-text-span">{DISABLED_COO_MESSAGES['syscoBrand']}</span>
      </div>
    );
  }
  if (gdsn) {
    return (
      <div className="coo-disabled-message">
        <span className="coo-text-span">{DISABLED_COO_MESSAGES['gdsn']}</span>
      </div>
    );
  }
  return null;
};

export default DisabledCooMessage;
