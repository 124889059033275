import React from 'react';
import { Input } from 'antd';
import _ from 'lodash';
import ServingDetails from './ServingDetails';
import {
  getReadableUom,
  isValidNutritionHeaderField,
  checkForFieldPendingNutrition,
  validateForThreeDecimalsNutritionsQuantity,
  checkValidationForThreeNutrientsInRequiredFields
} from '../../../util/Util';
import { HEADER_INDEX, NOT_REQUIRED_NUTRIENTS_VARIED_SHORT_NAMES } from '../../../util/Constants';

const RequiredNutritions = ({
  nutritionData,
  isReadOnly,
  isItemReadOnly,
  onFieldChange,
  nutritionErrors,
  updateNutritionErrors,
  pendingNutritions,
  isDisabledBySyscoOrGdsnItemFlag
}) => {
  const nutritionAnalysisHeader = _.get(nutritionData, 'nutritionAnalysisHeader', []);
  const allNutritions = _.get(nutritionData, 'nutritionAnalysisHeader[0].nutritionAnalysisDetail', []);
  const caloryNutrition = _.find(allNutritions, ({ shortName }) => shortName === 'KCAL');
  const caloryShortName = _.get(caloryNutrition, 'shortName', '');
  const quantityContained = _.get(caloryNutrition, 'quantityContained', null);
  const changedFields = _.get(nutritionData, 'changedFields', []);

  const handleFieldChange = (field, nutritionShortName, value, previousValue) => {
    let updatedErrors = { ...nutritionErrors };
    let updatedValue = value;
    if (field === 'quantityContained') {
      updatedValue = validateForThreeDecimalsNutritionsQuantity(value, previousValue);
    }
    const nutritionIndex = nutritionAnalysisHeader[HEADER_INDEX].nutritionAnalysisDetail.findIndex(
      ({ shortName }) => shortName === nutritionShortName
    );
    const updatedHeader = [
      ...nutritionAnalysisHeader.slice(0, 0),
      {
        ...nutritionAnalysisHeader[0],
        nutritionAnalysisDetail: [
          ...nutritionAnalysisHeader[HEADER_INDEX].nutritionAnalysisDetail.slice(0, nutritionIndex),
          {
            ...nutritionAnalysisHeader[HEADER_INDEX].nutritionAnalysisDetail[nutritionIndex],
            [field]: updatedValue
          },
          ...nutritionAnalysisHeader[HEADER_INDEX].nutritionAnalysisDetail.slice(nutritionIndex + 1)
        ]
      },
      ...nutritionAnalysisHeader.slice(0 + 1)
    ];
    if (
      !isValidNutritionHeaderField(updatedValue) &&
      checkValidationForThreeNutrientsInRequiredFields(nutritionShortName, updatedValue)
    ) {
      updatedErrors = {
        ...updatedErrors,
        [nutritionShortName]: 'Invalid Value'
      };
    } else {
      delete updatedErrors[nutritionShortName];
    }
    onFieldChange({
      nutritionAnalysisHeader: updatedHeader,
      changedFields: [...new Set([...changedFields, nutritionShortName])]
    });
    updateNutritionErrors({ updatedErrors });
  };

  const hasFieldErrors = field => {
    if (Object.keys(nutritionErrors).includes(field)) return `nt-invalid-field`;
    return ``;
  };

  return (
    <div className="nt-section nutrition-facts">
      <ServingDetails
        nutritionData={nutritionData}
        isReadOnly={isReadOnly}
        isItemReadOnly={isItemReadOnly}
        onFieldChange={onFieldChange}
        nutritionErrors={nutritionErrors}
        updateNutritionErrors={updateNutritionErrors}
        pendingNutritions={pendingNutritions}
        isDisabledBySyscoOrGdsnItemFlag={isDisabledBySyscoOrGdsnItemFlag}
      />
      <div className="nutrition-facts-container free-space-bottom" style={{ display: 'inline-grid' }}>
        <div className="nutrition-header-each-fact-panel">
          <div className="left-panel nutrition-fact-sub-title">
            Calories
            <span className="required-fields custom-fonts">* </span>
          </div>
          <div className="right-panel calories-fields">
            <Input
              className={`nt-calories-input ${hasFieldErrors(caloryShortName)} ${checkForFieldPendingNutrition(
                caloryShortName,
                pendingNutritions
              )}`}
              placeholder="Qty"
              title={quantityContained}
              value={quantityContained}
              type="number"
              disabled={isReadOnly || isItemReadOnly || isDisabledBySyscoOrGdsnItemFlag}
              required
              onChange={e => handleFieldChange('quantityContained', caloryShortName, e.target.value, quantityContained)}
            />
            <div className="calory-uom">kcal</div>
          </div>
        </div>
        <hr className="bold-seperator" />
      </div>
      <div className="daily-val-header"></div>
      {allNutritions.map(obj => {
        const shortName = _.get(obj, 'shortName', '');
        let element = null;
        if (shortName !== 'KCAL' && obj.isRequiredNutrition) {
          const nutritionName = _.get(obj, 'nutritionName', '');
          const uom = shortName === 'CHOL' ? 'mg' : getReadableUom(_.get(obj, 'quantityContainedUofm', null));
          const quantityContainedValue = _.get(obj, 'quantityContained', null);

          element = (
            <div key={shortName}>
              <div className="daily-nutri-row">
                <div className="nutri-row-title long left-panel" title={nutritionName}>
                  {nutritionName}
                  {!NOT_REQUIRED_NUTRIENTS_VARIED_SHORT_NAMES.includes(shortName) && (
                    <span className="required-fields">*</span>
                  )}
                </div>
                <div className="right-panel">
                  <Input
                    className={`daily-nutri-qty ${hasFieldErrors(shortName)} ${checkForFieldPendingNutrition(
                      shortName,
                      pendingNutritions
                    )}`}
                    type="number"
                    placeholder="Qty"
                    min={0}
                    title={quantityContainedValue}
                    value={quantityContainedValue}
                    disabled={isReadOnly || isItemReadOnly || isDisabledBySyscoOrGdsnItemFlag}
                    onChange={e =>
                      handleFieldChange('quantityContained', shortName, e.target.value, quantityContainedValue)
                    }
                  />
                  <div className="daily-nutri-uom">{uom}</div>
                </div>
              </div>
            </div>
          );
        }
        return element;
      })}
    </div>
  );
};

export default RequiredNutritions;
