import React from 'react';
import _ from 'lodash';
import { Select, Input, InputNumber } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { PlusCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { bindActionCreators } from 'redux';
import 'antd/dist/antd.css';

import * as actionCreators from '../../actions/index';
import TooltipInfo from './PackageTooltipInfo';
import {
  PACKAGING_MULTIVALUE_FIELD_IDS,
  PACKAGING_MANDATORY_FIELD_IDS,
  PACKAGING_PERCENTAGE_FIELD_IDS,
  GDSN_VALUE,
  PACKAGING_GDSN_FIELD_IDS
} from '../../util/Constants';

const { Option } = Select;
const basePath = 'selectedItem.packagingTab';

export default function PackagingAttributesTab({ readOnly }) {
  const dispatch = useDispatch();
  const { addPackagingLevel, setPackageChanges, deletePackagingLevel } = bindActionCreators(actionCreators, dispatch);

  const sections = useSelector(state => _.get(state, `${basePath}.currentPackaging.sections`, []));
  const originalSections = useSelector(state => _.get(state, `${basePath}.originalPackaging.sections`, []));
  const gdsnFlag = useSelector(state => _.get(state, `selectedItem.basicData.gdsn`, null));
  const isSavingPackaging = useSelector(state =>
    _.get(state, `${basePath}.originalPackaging.isSavingPackaging`, false)
  );
  const packagingProductHierarchyLevel = useSelector(state =>
    _.get(state, `${basePath}.currentPackaging.packagingProductHierarchyLevel`, {})
  );

  const isDisabled = readOnly || isSavingPackaging;

  const onBlur = (stepId, e, sectionIndex) => {
    const trimmedValue = e.target.value.trim();

    if (trimmedValue === '') {
      e.target.value = '';
    } else {
      e.target.value = trimmedValue;
    }

    setPackageChanges({ stepId, sectionIndex, value: trimmedValue });
  };

  const onChange = (stepId, value, sectionIndex) => {
    setPackageChanges({ stepId, value, sectionIndex });
  };

  const asterisk = stepId => {
    if (_.includes(PACKAGING_MANDATORY_FIELD_IDS, stepId)) {
      return <div className="red-asterisk">*</div>;
    }
  };

  const percentageMark = stepId => {
    if (_.includes(PACKAGING_PERCENTAGE_FIELD_IDS, stepId)) {
      return <div className="percentage-mark">{`(%)`}</div>;
    }
  };

  const isDependentFieldDisabled = (stepId, attributes) => {
    if (stepId === 'packaging_weight_uom') {
      const attribute = _.find(attributes, { stepId: 'packaging_weight' });
      const value = _.get(attribute, 'decidedValue', null);
      return _.isEmpty(value);
    }

    if (stepId === 'packaging_post_consumer_recyclability') {
      const attribute = _.find(attributes, { stepId: 'packaging_post_consumer_recyclable_type' });
      const value = _.get(attribute, 'decidedValue', null);
      return _.isEmpty(value);
    }

    if (stepId === 'packaging_post_industrial_recyclability') {
      const attribute = _.find(attributes, { stepId: 'packaging_post_industrial_recyclable_type' });
      const value = _.get(attribute, 'decidedValue', null);
      return _.isEmpty(value);
    }

    // Below is no longer valid since the packaging_type is mandatory
    // if (stepId === 'packaging_type') {
    //   const attribute = _.find(attributes, { stepId: 'packaging_feature' });
    //   const value = _.get(attribute, 'decidedValue', null);
    //   return !_.isEmpty(value);
    // }

    // if (stepId === 'packaging_feature') {
    //   const attribute = _.find(attributes, { stepId: 'packaging_type' });
    //   const value = _.get(attribute, 'decidedValue', null);
    //   return !_.isEmpty(value);
    // }
  };

  const isDropdownOptionDisabled = (stepId, option) => {
    if (stepId === 'packaging_type') {
      const decidedValues = [];

      _.forEach(sections, attributesGroup => {
        _.forEach(attributesGroup, attribute => {
          if (attribute.stepId === 'packaging_type') {
            const decidedValue = _.get(attribute, 'decidedValue.valueId', null);
            if (decidedValue) {
              decidedValues.push(decidedValue);
            }
          }
        });
      });

      return _.includes(decidedValues, option);
    }
  };

  const isGdsnField = stepId => {
    // return _.includes(PACKAGING_GDSN_FIELD_IDS, stepId) && gdsnFlag === GDSN_VALUE;
    return gdsnFlag === GDSN_VALUE;
  };

  const addDefaultOption = stepId => {
    if (_.includes(['packaging_type', 'packaging_feature'], stepId)) {
      return (
        <Option value={null} key={null}>
          Please Select...
        </Option>
      );
    }
  };

  const addSection = () => {
    addPackagingLevel();

    setTimeout(() => {
      const scrollPane = document.querySelector('.ant-tabs');
      if (scrollPane) {
        scrollPane.scrollTo({
          top: scrollPane.scrollHeight - 800,
          behavior: 'smooth'
        });
      }
    }, 300);
  };

  const removeSection = (index, attributes) => {
    const packagingTypeObject = _.find(attributes, { stepId: 'packaging_type' });
    const packagingTypeValue = _.get(packagingTypeObject, 'decidedValue.valueName', null);
    const deleted = []; // decidedValue.valueName of deleted packaging_type

    if (!_.isEmpty(packagingTypeValue)) {
      const target = _.find(originalSections.flat(), obj => {
        return obj.stepId === 'packaging_type' && _.get(obj, 'decidedValue.valueName') === packagingTypeValue;
      });

      if (target) {
        deleted.push(target.decidedValue.valueName);
      }
    }

    deletePackagingLevel({ index, deleted });
  };

  const getInputLabel = (stepId, name) =>
    ({
      packaging_post_consumer_recyclability: 'PCR',
      packaging_post_industrial_recyclability: 'PIR',
      packaging_weight_uom: 'Unit of Measure'
    }[stepId] || name);

  const renderTextFields = (stepId, defaultValue, disabled, isEmpty, index, obj) => {
    if (stepId === 'packaging_weight') {
      return (
        <InputNumber
          className={`packaging-attribute ${isEmpty && 'empty-field'}`}
          defaultValue={defaultValue}
          onBlur={e => onBlur(obj.stepId, e, index)}
          disabled={disabled}
          maxLength={9}
          max={99999.999}
          precision={3}
          min={0}
          placeholder="00000.000"
        ></InputNumber>
      );
    } else if (_.includes(PACKAGING_PERCENTAGE_FIELD_IDS, obj.stepId)) {
      return (
        <InputNumber
          className={`packaging-attribute ${isEmpty && 'empty-field'}`}
          defaultValue={defaultValue}
          onBlur={e => onBlur(obj.stepId, e, index)}
          disabled={disabled}
          min={0}
          max={100}
          precision={0}
        ></InputNumber>
      );
    } else {
      return (
        <Input
          className={`packaging-attribute ${isEmpty && 'empty-field'}`}
          defaultValue={defaultValue}
          onBlur={e => onBlur(obj.stepId, e, index)}
          disabled={disabled}
          maxLength={obj.stepId === 'packaging_component_description' ? 80 : null}
        ></Input>
      );
    }
  };

  const renderTextInputBox = (obj, defaultValue, isInputDisabled, isEmpty, index, className = 'attribute-wrapper') => {
    const isPercentage = _.includes(PACKAGING_PERCENTAGE_FIELD_IDS, obj.stepId);

    return (
      <React.Fragment key={obj.stepId}>
        <div key={obj.stepId} className={`${className} ${isPercentage ? 'short-input' : ''}`}>
          <div className="attribute-label-wrapper">
            {asterisk(obj.stepId)}
            <div className="attribute-label" title={obj.name}>
              {getInputLabel(obj.stepId, obj.name)}
            </div>
            {percentageMark(obj.stepId)}
            <TooltipInfo stepId={obj.stepId} />
          </div>
          {renderTextFields(obj.stepId, defaultValue, isInputDisabled, isEmpty, index, obj)}
        </div>
        {isPercentage && <div className="break" />}
      </React.Fragment>
    );
  };

  const renderMultiValueInputBox = (obj, defaultValue, isInputDisabled, isEmpty, index) => {
    return (
      <div key={obj.stepId} className="attribute-wrapper">
        <div className="attribute-label-wrapper">
          {asterisk(obj.stepId)}
          <div className="attribute-label" title={obj.name}>
            {obj.name}
          </div>
          <TooltipInfo stepId={obj.stepId} />
        </div>
        <Select
          className={`packaging-attribute ${isEmpty && 'empty-field'}`}
          defaultValue={defaultValue}
          onChange={val => onChange(obj.stepId, val, index)}
          disabled={isInputDisabled}
          mode={'multiple'}
          maxTagCount={1}
        >
          {_.map(obj.picklist, opt => (
            <Option value={opt.valueId} key={opt.valueId}>
              {opt.valueName}
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  const renderDropdownInputBox = (
    obj,
    defaultValue,
    isInputDisabled,
    isEmpty,
    index,
    className = 'attribute-wrapper'
  ) => {
    return (
      <React.Fragment key={obj.stepId}>
        <div key={obj.stepId} className={className}>
          <div className="attribute-label-wrapper">
            {asterisk(obj.stepId)}
            <div className="attribute-label" title={obj.name}>
              {getInputLabel(obj.stepId, obj.name)}
            </div>
            <TooltipInfo stepId={obj.stepId} />
          </div>
          <Select
            className={`packaging-attribute ${isEmpty && 'empty-field'}`}
            defaultValue={defaultValue}
            onChange={val => onChange(obj.stepId, val, index)}
            disabled={isInputDisabled}
            dropdownMatchSelectWidth={false}
          >
            {/* {addDefaultOption(obj.stepId)} */}
            {_.map(obj.picklist, opt => {
              const isOptionDisabled = isDropdownOptionDisabled(obj.stepId, opt.valueId);

              return (
                <Option value={opt.valueId} key={opt.valueId} disabled={isOptionDisabled}>
                  {opt.valueName}
                </Option>
              );
            })}
          </Select>
        </div>
        {_.includes(['packaging_level'], obj.stepId) && <div className="break" />}
      </React.Fragment>
    );
  };

  const renderWeightBlock = (obj, attributes, index) => {
    const decidedValue = obj.decidedValue;
    const isEmpty = _.isEmpty(decidedValue);
    const isDependentDisabled = isDependentFieldDisabled(obj.stepId, attributes);
    const isGdsn = isGdsnField(obj.stepId);
    const isInputDisabled = isDependentDisabled || isDisabled || isGdsn;

    if (obj.stepId === 'packaging_weight') {
      const defaultValue = decidedValue;
      return renderTextInputBox(obj, defaultValue, isInputDisabled, isEmpty, index, 'weight-attribute');
    } else {
      const defaultValue = _.get(obj, 'decidedValue.valueId', null);
      return renderDropdownInputBox(obj, defaultValue, isInputDisabled, isEmpty, index, 'weight-uom-attribute');
    }
  };

  const renderWeightFields = (obj, index, attributes) => {
    const uom = _.find(attributes, { stepId: 'packaging_weight_uom' });

    return (
      <div className="attribute-wrapper">
        <div className="weights-attr-wrapper">
          {renderWeightBlock(obj, attributes, index)}
          {renderWeightBlock(uom, attributes, index)}
        </div>
      </div>
    );
  };

  const renderInputFields = (attributes, index) => {
    return (
      <div className="attributes-set">
        {_.map(attributes, obj => {
          const decidedValue = obj.decidedValue;
          const isEmpty = _.isEmpty(decidedValue);
          const isDependentDisabled = isDependentFieldDisabled(obj.stepId, attributes);
          const isGdsn = isGdsnField(obj.stepId);
          const isInputDisabled = isDependentDisabled || isDisabled || isGdsn;

          if (obj.stepId === 'packaging_weight_uom') {
            return null;
          } else if (obj.stepId === 'packaging_weight') {
            return renderWeightFields(obj, index, attributes);
          }
          // Text Fields
          else if (_.isEmpty(obj.picklist)) {
            const defaultValue = decidedValue;
            return renderTextInputBox(obj, defaultValue, isInputDisabled, isEmpty, index);
          }
          // Multivalue
          else if (_.includes(PACKAGING_MULTIVALUE_FIELD_IDS, obj.stepId)) {
            const defaultValue = _.map(obj.decidedValue, val => val.valueId);
            return renderMultiValueInputBox(obj, defaultValue, isInputDisabled, isEmpty, index);
          }
          // Dropdowns
          else {
            const defaultValue = _.get(obj, 'decidedValue.valueId', null);
            return renderDropdownInputBox(obj, defaultValue, isInputDisabled, isEmpty, index);
          }
        })}
      </div>
    );
  };

  const renderPackagingSections = () => {
    const isCloseBtnVisible = sections.length !== 1;

    return _.map(sections, (attributes, index) => {
      return (
        <div key={index} className="packaging-section">
          {isCloseBtnVisible && (
            <CloseCircleOutlined
              className={`section-close-btn ${isDisabled ? 'disabled' : ''}`}
              title="Remove this packaging section"
              onClick={() => removeSection(index, attributes)}
            />
          )}
          {renderInputFields(attributes, index)}
        </div>
      );
    });
  };

  const renderProductHierarchy = () => {
    const { stepId, name, picklist, decidedValue } = packagingProductHierarchyLevel;
    const isEmpty = _.isEmpty(decidedValue);
    const isGdsn = isGdsnField(stepId);

    return (
      <div className="product-hie-level-section">
        <div className="main-title">{name}</div>
        <div className="hie-level-label-wrapper">
          <div className="attribute-desc">Describes the hierarchical level of the trade item</div>
          <TooltipInfo stepId={stepId} />
        </div>
        <Select
          className={`packaging-product-hierarchy ${isEmpty && 'empty-field'}`}
          defaultValue={decidedValue.valueId}
          onChange={valueId => onChange(stepId, valueId)}
          disabled={isDisabled || isGdsn}
        >
          {_.map(picklist, opt => (
            <Option value={opt.valueId} key={opt.valueId}>
              {opt.valueName}
            </Option>
          ))}
        </Select>
      </div>
    );
  };

  const renderPackagingPartsHeader = () => {
    return (
      <div className="pack-parts-header">
        <div className="main-title">Packaging Parts</div>
        <div className="attribute-desc">Repeat for each part of packaging to be detailed</div>
        <div className="add-packaging-details">
          <PlusCircleOutlined className={`add-packaging-icon ${isDisabled ? 'disabled' : ''}`} onClick={addSection} />
          <div>Add/Repat this section for each part of the product's packaging to be detailed</div>
        </div>
      </div>
    );
  };

  const renderGdsnBanner = () => {
    const BANNER_TEXT = (
      <>
        Updates may not be made in this portal to disabled fields. This product is a{' '}
        <b>GDSN-published, national brand item</b>, please update your product data with your data pool partner and be
        sure to publish your changes to Sysco (GLN 0074865000000).
      </>
    );

    if (gdsnFlag === GDSN_VALUE) {
      return <div className="packaging-gdsn-banner">{BANNER_TEXT}</div>;
    }
  };

  if (!_.isEmpty(packagingProductHierarchyLevel)) {
    return (
      <div className="packaging-sub-tab">
        {renderGdsnBanner()}
        {renderProductHierarchy()}
        {renderPackagingPartsHeader()}
        {renderPackagingSections()}
      </div>
    );
  } else {
    return <div className="packaging-sub-tab">Loading...</div>;
  }
}
