import React, { useState, useEffect } from 'react';
import { Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { PFAS_SINGLE_SELECTIONS } from '../../util/Constants';
import {
  handlePfasCheckBoxSelection,
  checkPfasChangesIncludesAttrId,
  checkIfPfasAttributeIsDisabled,
  checkEachPfasAttributeIsDisabled
} from '../../util/Util';

const PfasFilterCheckBoxForTopRow = ({ pfasAttribute: { picklist, name, attrId }, onChange }) => {
  const appliedValues = useSelector(state => _.get(state.massUpdate.pfas, 'appliedValues', {}));
  const [singleSelections, setSingleSelections] = useState([]);
  const [disabledTooltip, setDisabledTooltip] = useState(false);
  const bcName = useSelector(state => _.get(state.massUpdate.pfas, 'filters.bc', null));
  const bc = useSelector(state => _.find(_.get(state.massUpdate.pfas, 'completeTaxonomy', []), { name: bcName }));

  useEffect(() => {
    if (bc && bc.id) {
      const { disabledTooltip } = checkIfPfasAttributeIsDisabled({ bc: { id: bc.id.toString() } }, { name });
      setDisabledTooltip(Boolean(disabledTooltip));
    }
  }, [bc, name]);

  const handleCheckBoxChange = (data, attrId) => {
    const formattedAndFinalizedData = [
      ...handlePfasCheckBoxSelection(
        checkPfasChangesIncludesAttrId(appliedValues, attrId),
        attrId,
        data,
        singleSelections
      )
    ];
    onChange(formattedAndFinalizedData, attrId);
  };

  useEffect(() => {
    // Update single selections when picklist changes
    const updatedSingleSelections = picklist
      .filter(eachPicklist => PFAS_SINGLE_SELECTIONS.includes(eachPicklist.value))
      .map(eachPicklist => eachPicklist.id);
    setSingleSelections(updatedSingleSelections);
  }, [picklist]);

  const configuredPicklist = picklist.map(eachPicklist => {
    return {
      ...eachPicklist,
      label: eachPicklist.value,
      value: eachPicklist.id,
      disabled: checkEachPfasAttributeIsDisabled(appliedValues, attrId, singleSelections, eachPicklist)
    };
  });
  return (
    <>
      <div className="pfas-filter-header-label">{name}</div>
      <Checkbox.Group
        style={{ display: 'contents' }}
        disabled={disabledTooltip}
        options={configuredPicklist}
        value={appliedValues[attrId] || []}
        onChange={data => handleCheckBoxChange(data, attrId)}
      />
    </>
  );
};

export default PfasFilterCheckBoxForTopRow;
