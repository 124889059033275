import React from 'react';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import PfasFilterCheckBoxForTopRow from './PfasFilterCheckBoxForTopRow';

import * as actionCreators from '../../actions';

export default function PfasAttributeFilter() {
  const dispatch = useDispatch();
  const { updateRegulatoryComplianceApplyValueChange } = bindActionCreators(actionCreators, dispatch);

  const pfasAttributes = useSelector(state => _.get(state.massUpdate.pfas, 'filters.attributes.PFAS', []));

  const PfasCheckBox = ({ pfasAttributes }) => {
    const handleSelectionChange = (data, attrId) => {
      updateRegulatoryComplianceApplyValueChange({ data, attrId });
    };
    return pfasAttributes.map(pfasAttribute => {
      return <PfasFilterCheckBoxForTopRow pfasAttribute={pfasAttribute} onChange={handleSelectionChange} />;
    });
  };

  return <PfasCheckBox pfasAttributes={pfasAttributes} />;
}
