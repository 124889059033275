import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { Table, Tooltip } from 'antd';
import _ from 'lodash';

import FnBInputField from './FnBInputField';
import {
  fnbCommentAttributes,
  featuresAndBenifitsCommentKeys,
  nonFoodItemBusinessCenterNames
} from './../../util/Data';
import { filterCollectFnBComments, getValueBasedOnTheCondition, getConditionStatus } from '../../util/Util';
import {
  FNB_CHARACTER_LIMIT,
  PRODUCT_DESCRIPTOR_CHARACTER_LIMIT,
  OVERALL_FNB_CHARACTER_LIMIT
} from './../../util/Constants';

function FnBMassUpdateTable({ suvc, data, savingFnbMassUpdate, indexedDb, hasFnBRejects }, ref) {
  const windowHeight = window.innerHeight;
  const tooltips = useSelector(state => _.get(state.selectedItem, 'fnb.tooltip', []));

  const [tableData, setTableData] = useState(data);
  const [isAllFieldsValid, setIsAllFieldsValid] = useState(true);

  useEffect(() => {
    setTableData(prevTableData => {
      let tableClone = [...prevTableData];
      let updated = _.filter(tableClone, row => {
        const index = _.findIndex(data, obj => obj.supc === row.supc);
        return index !== -1;
      });

      return updated;
    });
  }, [data, data.length]);

  useImperativeHandle(ref, () => ({
    getTableData: () => tableData,
    validateAllFields
  }));

  const handleAttributeValueChange = (supc, attribute, value) => {
    let existing = existingTotalCharCount(supc, attribute);
    let remaining = OVERALL_FNB_CHARACTER_LIMIT - existing;
    let subString = value.substring(0, remaining);

    setTableData(prevTableData => {
      let tableClone = [...prevTableData];
      let index = _.findIndex(tableClone, obj => supc === obj.supc);

      if (index !== -1) {
        let itemClone = { ...tableClone[index] };
        itemClone[attribute] = subString;
        tableClone[index] = itemClone;
      }

      saveUnsavedFnb(supc, tableClone);

      return tableClone;
    });
  };

  const saveUnsavedFnb = async (supc, tableClone) => {
    let fnb = {};
    const keys = [suvc, `${supc}`];
    const row = _.find(tableClone, row => supc === row.supc);
    const bcName = _.get(row, 'taxonomy.bc.name', '');

    if (row) {
      _.forEach(fnbCommentAttributes, attr => {
        fnb[attr] = {
          value: row[attr],
          error: '',
          required: isRequired(attr, row.taxonomy)
        };
      });
    }

    fnb.isNonFoodItem = _.includes(nonFoodItemBusinessCenterNames, bcName);
    fnb.visible = false;
    fnb.msg = '';
    fnb.loading = false;
    fnb.genFnb = { value: filterCollectFnBComments(fnb, {}, true), error: '' };

    let data = {
      fnb,
      suvc,
      supc: `${supc}`
    };

    await indexedDb.putValue('supplier', data, keys);
  };

  const existingTotalCharCount = (supc, attribute) => {
    let count = 0;
    let target = _.find(tableData, obj => supc === obj.supc);

    if (target) {
      _.forEach(fnbCommentAttributes, attr => {
        if (attr !== attribute) {
          count = count + target[attr].length;
        }
      });
    }
    return count;
  };

  const isRequired = (attribute, taxonomy) => {
    let isRequired = true;
    let bcName = _.get(taxonomy, 'bc.name', '');

    if (_.includes(['marketing', 'culinary'], attribute)) {
      isRequired = false;
    } else if (getConditionStatus(attribute === 'prep', _.includes(nonFoodItemBusinessCenterNames, bcName))) {
      isRequired = false;
    }

    return isRequired;
  };

  const isFieldValid = (value, isRequired) => {
    if (isRequired) {
      if (getConditionStatus(_.isNull(value), _.isUndefined(value))) {
        return false;
      } else if (value.trim() === '') {
        return false;
      }
    }

    return true;
  };

  const validateAllFields = () => {
    let isValid = true;

    _.forEach(tableData, row => {
      _.forEach(fnbCommentAttributes, attr => {
        const isFieldRequired = isRequired(attr, row.taxonomy);
        const value = row[attr];

        if (!isFieldValid(value, isFieldRequired)) {
          isValid = false;
        }
      });
    });

    setIsAllFieldsValid(isValid);
    return isValid;
  };

  const getAttributeColumnHeader = attribute => {
    const target = _.find(tooltips, obj => attribute === obj.field);
    const tooltip = _.get(target, 'tooltip', null);
    const foodExample = _.get(target, 'example_food[0]', null);
    const nonFoodExample = _.get(target, 'example_nonfood[0]', null);

    return (
      <div className="fnb-mass-col-header">
        <div>{featuresAndBenifitsCommentKeys[attribute].name}</div>
        <Tooltip
          overlayClassName="fnb-mass-edit-tooltip"
          title={
            <div className="fnb-mass-edit-tooltip-wrapper">
              <div>{tooltip}</div>
              {foodExample && (
                <React.Fragment>
                  <div className="fnb-mass-edit-ex-title">Food Examples:</div>
                  <li>{foodExample}</li>
                </React.Fragment>
              )}
              {nonFoodExample && (
                <React.Fragment>
                  <div className="fnb-mass-edit-ex-title">Non-Food Examples:</div>
                  <li>{nonFoodExample}</li>
                </React.Fragment>
              )}
            </div>
          }
        >
          i
        </Tooltip>
      </div>
    );
  };

  const columns = [
    {
      title: 'SUPC',
      dataIndex: 'supc',
      key: 'supc',
      width: 90,
      fixed: true,
      render: (text, record) => {
        if (record.hasPending) {
          return (
            <Tooltip
              overlayClassName="fnb-mass-pending-tooltip"
              title="This item has pending F&B data for approval"
              placement="right"
            >
              <div className="fnb-mass-pending">{text}</div>
            </Tooltip>
          );
        }
        return <div>{text}</div>;
      }
    },
    {
      title: 'Item Description',
      dataIndex: 'materialDescription',
      key: 'materialDescription',
      width: 200,
      fixed: true
    },
    {
      title: 'Brand Name',
      dataIndex: 'brandName',
      key: 'brandName',
      width: 150,
      fixed: true
    },
    {
      title: 'MPC',
      dataIndex: 'manufactProdCode',
      key: 'manufactProdCode',
      width: 140,
      fixed: true
    },
    {
      title: 'GTIN',
      dataIndex: 'gtin',
      key: 'gtin',
      width: 140,
      fixed: true
    },
    {
      title: 'Char. Limit',
      key: 'limit',
      width: 100,
      fixed: true,
      render: (text, record) => {
        const maxed = getConditionStatus(existingTotalCharCount(record.supc) === OVERALL_FNB_CHARACTER_LIMIT);
        return (
          <div className={`${getValueBasedOnTheCondition(maxed, 'maxed-char-limit', '')}`}>{`${existingTotalCharCount(
            record.supc
          )}/${OVERALL_FNB_CHARACTER_LIMIT}`}</div>
        );
      }
    }
  ];

  _.forEach(fnbCommentAttributes, attribute => {
    columns.push({
      title: getAttributeColumnHeader(attribute),
      dataIndex: attribute,
      key: attribute,
      width: 500,
      render: (text, record) => {
        const isFieldRequired = isRequired(attribute, record.taxonomy);

        return (
          <FnBInputField
            supc={record.supc}
            attribute={attribute}
            value={text}
            isInValid={!isAllFieldsValid && !isFieldValid(text, isFieldRequired)}
            maxLength={getValueBasedOnTheCondition(
              attribute === 'descriptor',
              PRODUCT_DESCRIPTOR_CHARACTER_LIMIT,
              FNB_CHARACTER_LIMIT
            )}
            isRequired={isFieldRequired}
            isDisabled={savingFnbMassUpdate}
            onChangeAttribute={handleAttributeValueChange}
          />
        );
      }
    });
  });

  let yScroll = getValueBasedOnTheCondition(hasFnBRejects, windowHeight - 235, windowHeight - 200);

  return (
    <Table
      id="fnb-mass-update-table"
      rowKey={'supc'}
      columns={columns}
      dataSource={tableData}
      pagination={false}
      scroll={{ x: 2000, y: yScroll }}
    />
  );
}

export default forwardRef(FnBMassUpdateTable);
