import React from 'react';
import { Select, Button } from 'antd';
import { bindActionCreators } from 'redux';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';

import openNotification from 'components/Notification';
import * as actionCreators from '../../actions';
import { getSpecificAttributes } from '../../util/Util';
import { InfoToolTip } from './AttributeMassEditModal';

const { Option } = Select;

export default function AttributeValueSelector({ hightlight }) {
  const dispatch = useDispatch();
  const { pickValueForAttributeSelector, clearAttributeSelector, applyAttributeSelectorValues } = bindActionCreators(
    actionCreators,
    dispatch
  );

  const attributes = useSelector(state => _.get(state.massUpdate.attribute, 'attributes', []));
  const selectedValues = useSelector(state => _.get(state.massUpdate.attribute, 'selectedValues', []));
  const attributeFilters = useSelector(state => _.get(state.massUpdate.attribute, 'filters.attributeFilters', []));
  const selectedRows = useSelector(state => _.get(state.massUpdate.attribute, 'selectedRows', []));

  const filteredAttributes = getSpecificAttributes(attributes, attributeFilters);
  const count = selectedRows.length;
  const countMessage = `Changes will be applied to ${count} ${count === 1 ? 'item' : 'items'}.`;
  const isBtnsDisabled = _.isEmpty(filteredAttributes);

  const onSelectAttribute = (id, value) => {
    pickValueForAttributeSelector({ id, value });
  };

  const onClickApply = () => {
    if (selectedRows.length > 0) {
      applyAttributeSelectorValues();
    } else {
      openNotification({
        className: 'error',
        message: 'NO ITEMS SELECTED',
        description: 'Please select the items before applying the changes'
      });
    }
  };

  const onClickClear = () => {
    clearAttributeSelector();
  };

  const renderBody = () => {
    if (_.isEmpty(filteredAttributes))
      return <div className="no-attr-for-filters-msg">No attributes found for the selected filters</div>;

    return _.map(filteredAttributes, attr => {
      const selected = _.find(selectedValues, obj => obj.id === attr.id);

      return (
        <div key={attr.id}>
          <div className="attr-selector-header">
            <div className="attr-selector-label" title={attr.name}>
              {attr.name}
            </div>
            <InfoToolTip type={attr.type} name={attr.name} />
          </div>
          <Select value={_.get(selected, 'value', null)} onChange={val => onSelectAttribute(attr.id, val)}>
            <Option value={null} key="0">
              Please select
            </Option>
            {_.map(attr.picklist, pick => {
              return (
                <Option value={pick.id} key={pick.id} title={pick.value}>
                  {pick.value}
                </Option>
              );
            })}
          </Select>
        </div>
      );
    });
  };

  return (
    <div className="mass-attr-selector-wrapper">
      <div className="mass-attr-selector-bar">
        <div className="selector-message">
          {`Any changes made here will be applied to all selected items on the current page. ${countMessage}`}
        </div>
        <div className={`mass-attr-selector ${hightlight ? 'attr-selector-highlight' : ''}`}>{renderBody()}</div>
      </div>
      <div>
        <Button className="attr-selector-action-btns submit-btn" disabled={isBtnsDisabled} onClick={onClickApply}>
          Apply
        </Button>
        <Button className="attr-selector-action-btns clear-btn" disabled={isBtnsDisabled} onClick={onClickClear}>
          Clear
        </Button>
      </div>
    </div>
  );
}
