import React from 'react';
import { Select } from 'antd';
import _ from 'lodash';

import { sortObjectByKeys } from '../util/Util';

const { Option } = Select;

export default function TaxonomyFilter({
  className,
  bc = null,
  ig = null,
  ag = null,
  businessCenters,
  itemGroups,
  attributeGroups,
  attributeFilters,
  onChangeFilter
}) {
  const handleBcFilter = newBc => {
    onChangeFilter({
      bc: newBc,
      ig: null,
      ag: null,
      attributeFilters: _.filter(attributeFilters, obj => obj != 'Product Attributes')
    });
  };

  const handleIgFilter = newIg => {
    onChangeFilter({
      bc,
      ig: newIg,
      ag: null,
      attributeFilters: _.filter(attributeFilters, obj => obj != 'Product Attributes')
    });
  };

  const handleAgFilter = newAg => {
    onChangeFilter({
      bc,
      ig,
      ag: newAg
    });
  };

  businessCenters = sortObjectByKeys(businessCenters);
  itemGroups = sortObjectByKeys(itemGroups);
  attributeGroups = sortObjectByKeys(attributeGroups);

  return (
    <div className={`taxonomy-filters-row ${className}`}>
      <Select value={bc} onChange={newBc => handleBcFilter(newBc)}>
        <Option value={null} key="0">
          All Business Centers
        </Option>
        {_.map(businessCenters, (val, key) => {
          return (
            <Option value={key} key={key} title={key}>
              {key}
            </Option>
          );
        })}
      </Select>
      <Select value={ig} disabled={_.isEmpty(itemGroups)} onChange={newIg => handleIgFilter(newIg)}>
        <Option value={null} key="0">
          All Item Groups
        </Option>
        {_.map(itemGroups, (val, key) => {
          return (
            <Option value={key} key={key} title={key}>
              {key}
            </Option>
          );
        })}
      </Select>
      <Select value={ag} disabled={_.isEmpty(attributeGroups)} onChange={newAg => handleAgFilter(newAg)}>
        <Option value={null} key="0">
          All Attribute Groups
        </Option>
        {_.map(attributeGroups, (val, key) => {
          return (
            <Option value={key} key={key} title={key}>
              {key}
            </Option>
          );
        })}
      </Select>
    </div>
  );
}
