import React from 'react';
import ReactResizeDetector from 'react-resize-detector';
import _ from 'lodash';
import { Spin, Tabs, Tooltip } from 'antd';
import { REGULATORY_TITLES } from '../../util/Constants';
import PFas from './PFas';
import FsmaTab from './FSMA/FsmaTab';
import {
  checkIfPfasAttributeIsDisabled,
  checkIfPfasClaimIsPopulated,
  isNonFood,
  getEmptyFsmaAttributes
} from '../../util/Util';
import { useSelector, useDispatch } from 'react-redux';

const RegulatoryCompliance = ({ actions, item }) => {
  const selectedTab = useSelector(state => _.get(state, 'selectedItem.regulatorySubTab', '1'));
  const fsmaAttributes = useSelector(state => _.get(state, 'selectedItem.fsma.attributes', []));

  const { TabPane } = Tabs;
  const {
    pfas: { attributes },
    isLoadingPfas,
    basicData: { brandCode } = { brandCode: null },
    taxonomy
  } = item;

  const renderTabTitles = type => {
    const title = REGULATORY_TITLES[type];
    let incompleteAttributes = [];
    let productLevelPfasClaimFilled = 0;

    if (!['PACKER', 'packer'].includes(brandCode)) {
      _.forEach(attributes, ({ selected, name }) => {
        const { disabledTooltip } = checkIfPfasAttributeIsDisabled(taxonomy, { name });
        if (!selected.length) incompleteAttributes.push({ attributeName: name, disabledTooltip });
        const pfasClaimFilled = checkIfPfasClaimIsPopulated(taxonomy);
        if (name === 'Product Level' && !disabledTooltip && !selected.length && pfasClaimFilled) {
          productLevelPfasClaimFilled = -1;
        }
      });
    }

    const count = incompleteAttributes.length - 1 + productLevelPfasClaimFilled; // deducting 1 because either one of attributes will be disabled based on food or non-food type and add productLevelPfasClaimFilled if PFAS claim is populated on attribute tab

    const content = (
      <div>
        <div>Please select values for the below unfilled attributes</div>
        <ul className="tooltip-content">
          {_.map(
            incompleteAttributes,
            ({ attributeName, disabledTooltip }) => !disabledTooltip && <li key={attributeName}>{attributeName}</li>
          )}
        </ul>
      </div>
    );

    return (
      <div className="subtab-title">
        <div>{title}</div>
        <Tooltip overlayClassName="incomplete-attr-tool-tip" title={content} placement="bottom">
          {count > 0 && <div className="incomplete-count">{count}</div>}
        </Tooltip>
      </div>
    );
  };

  const renderFsmaTabTitle = () => {
    const emptyList = getEmptyFsmaAttributes(fsmaAttributes);

    const content = (
      <div>
        <div>Please select values for the unfilled attributes</div>
        <ul className="tooltip-content">
          {_.map(emptyList, attributeName => (
            <li key={attributeName}>{attributeName}</li>
          ))}
        </ul>
      </div>
    );

    return (
      <div className="subtab-title">
        <div>FSMA 204</div>
        <Tooltip overlayClassName="incomplete-attr-tool-tip" title={content} placement="bottom">
          {emptyList.length > 0 && <div className="incomplete-count">{emptyList.length}</div>}
        </Tooltip>
      </div>
    );
  };

  let tabs = [
    {
      key: 1,
      title: renderTabTitles('PFAS'),
      content: <PFas item={item} actions={actions} />
    }
  ];

  if (!isNonFood(item)) {
    tabs.push({
      key: 2,
      title: renderFsmaTabTitle(),
      content: <FsmaTab readOnly={_.get(item, 'readOnly', false)} />
    });
  }

  const onTabClick = key => {
    actions.changeRegulatorySubTab(key);
  };

  return (
    <ReactResizeDetector
      handleWidth
      handleHeight
      render={({ width, height }) => (
        <Spin tip="Loading..." spinning={isLoadingPfas} style={{ height: window.innerHeight + 'px' }}>
          <div className="attr-panel taxonomy taxonomy-sub-tabs" style={{ height: `${window.innerHeight - 177}px` }}>
            <Tabs
              tabPosition="top"
              animated={false}
              activeKey={`${selectedTab}`}
              onTabClick={activeTabKey => onTabClick(activeTabKey)}
            >
              {tabs.map(({ title, key, content }) => {
                return (
                  <TabPane tab={<span className="inner-tab-taxonomy">{title}</span>} key={key}>
                    {content}
                  </TabPane>
                );
              })}
            </Tabs>
            <div className="attr-wrapper" style={{ height: '50px' }}></div>
          </div>
        </Spin>
      )}
    />
  );
};

export default RegulatoryCompliance;
