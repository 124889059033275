import IndexedDb from '../util/IndexedDb';

let connection = {};

const createIndexedDbConnection = dbName => {
  if (!connection[dbName]) {
    connection[dbName] = new IndexedDb(dbName);
  }
  return connection[dbName];
};

export { createIndexedDbConnection };
