import React from 'react';
import { Input, Divider, Skeleton } from 'antd';
import PendingComment from './PendingComment';
import { filterCollectFnBComments, getFieldCommentWithoutCulinaryAndMarketing, formatDate } from '../util/Util';
import { fnbCommentAttributes, featuresAndBenifitsCommentKeys } from '../util/Data';
import { GDSN_VALUE, THREE_DOTS } from '../util/Constants';
import _ from 'lodash';
import moment from 'moment';

class FeaturesAndBenifits extends React.Component {
  collectFnBComments(fnbComment) {
    return filterCollectFnBComments(fnbComment);
  }

  commentsPopup = () => {
    const { item, actions } = this.props;
    const loading = 'Loading...';
    const fnbValue =
      item.fnb && item.fnb.fnb && this.collectFnBComments(item.fnb.fnb)
        ? this.collectFnBComments(item.fnb.fnb)
        : item.pending
        ? loading
        : 'No Features & Benefits statement.';
    if (
      !item ||
      !item.fnb ||
      item.fnb.cannotEditComment ||
      !item.taxonomy ||
      !item.taxonomy.bc ||
      !item.taxonomy.bc.name
    ) {
      return;
    }

    if (item && item.fnb && item.fnb.pending) {
      actions.commentsPopup({
        item: item,
        field: 'fnb',
        currentValue: fnbValue,
        fieldType: 'FnB',
        fieldCaption: 'Features & Benefits',
        existingComment: this.collectFnBComments(item.fnb.pending),
        pending: item.fnb.pending
      });
    } else {
      actions.commentsPopup({
        item: item,
        field: 'fnb',
        currentValue: fnbValue,
        fieldType: 'FnB',
        fieldCaption: 'Features & Benefits',
        existingComment: this.collectFnBComments(item.fnb.fnb),
        pending: item.fnb.fnb
      });
    }
  };

  renderGdsnFnbForInternalAssociates = () => {
    if (this.props.item.readOnly) {
      const isFetchingGdsnFnb = _.get(this.props.item, 'fnb.isFetchingGdsnFnb', false);
      const lastUpdated = _.get(this.props.item, 'fnb.gdsnFnb.lastUpdated', null);
      const gdsn = _.get(this.props.item, 'basicData.gdsn', null);
      const gdsnFnbs = filterCollectFnBComments(
        getFieldCommentWithoutCulinaryAndMarketing(_.get(this.props.item, 'fnb.gdsnFnb.fnb', {}))
      );
      if (isFetchingGdsnFnb) {
        return (
          <React.Fragment>
            <Skeleton active />
            <Divider style={{ backgroundColor: 'white' }} />
          </React.Fragment>
        );
      } else if (gdsn === GDSN_VALUE) {
        return (
          <React.Fragment>
            <div className="inter-gdsn-fnb-wrapper">
              <div className="inter-gdsn-fnb-title">GDSN F&B</div>
              <div className="gdsn-fnb-timestamp">{`GDSN last syndication: ${
                lastUpdated ? formatDate(lastUpdated, 'MM/DD/YYYY hh:mm A') : THREE_DOTS
              }`}</div>
            </div>
            <div className="inter-gdsn-fnb">{!_.isEmpty(gdsnFnbs) ? `${gdsnFnbs}` : 'No GDSN values found'}</div>
            <Divider style={{ backgroundColor: 'white' }} />
          </React.Fragment>
        );
      }
    }
  };

  render() {
    let { item } = this.props;
    const { TextArea } = Input;
    const loading = 'Loading...';
    const fnbValue =
      item.fnb && item.fnb.fnb && this.collectFnBComments(item.fnb.fnb)
        ? this.collectFnBComments(item.fnb.fnb)
        : item.pending
        ? loading
        : 'No Features & Benefits statement.';

    let fnbLoading,
      fnbGuidLineLoading = true;
    if (item && item.fnb && item.fnb.fnb && !item.fnb.fnbFetching) {
      fnbLoading = false;
    }
    if (item && item.fnb && item.fnb.tooltip && !item.fnb.tooltipFetching) {
      fnbGuidLineLoading = false;
    }

    return (
      <div>
        {fnbLoading ? (
          <Skeleton active />
        ) : (
          <div className="fnb-wrapper">
            <TextArea
              className={'attr-textarea disable commentable'}
              value={fnbValue}
              rows={4}
              onClick={this.commentsPopup}
              disabled={item.readOnly}
            />
            {item && item.fnb && item.fnb.pending && this.collectFnBComments(item.fnb.pending) !== '' && (
              <PendingComment
                comment={this.collectFnBComments(item.fnb.pending)}
                clickOn={!item.readOnly && this.commentsPopup}
              />
            )}
            <Divider style={{ backgroundColor: 'white' }} />

            {this.renderGdsnFnbForInternalAssociates()}

            {fnbGuidLineLoading ? (
              <Skeleton active />
            ) : (
              <div>
                <div className="attr-section-title">Guideline for Features & Benefits</div>
                {fnbCommentAttributes.map(element => {
                  const tooltip = _.find(item.fnb.tooltip, { field: element });
                  const { tooltip: fieldTooltip, field } = tooltip || { tooltip: '', field: '' };
                  const fieldName = featuresAndBenifitsCommentKeys[field].name;
                  return (
                    <div key={fieldName}>
                      <div className="attr-section-label" style={{ marginBottom: '0' }}>
                        {fieldName}
                      </div>
                      <div className="attr-section-guideline">{fieldTooltip}</div>
                    </div>
                  );
                })}
                {/* {item.fnb.tooltip.map(tooltip => (
                  <div key={tooltip.field}>
                    <div className="attr-section-label">{tooltip.field}</div>
                    <div className="attr-section-guideline">{tooltip.tooltip}</div>
                  </div>
                ))} */}
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default FeaturesAndBenifits;
