import React, { useState } from 'react';
import { Button, Modal, Table, Icon } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Papa from 'papaparse';
import _ from 'lodash';

import openNotification from 'components/Notification';
import { fileDownload, formatDate, getOverallFeedbackStatusValue } from '../util/Util';

import * as actionCreators from './../actions';

export default function OverallItemStatusFeedbackHistory({ type, caption, buttonClasses, historyData, range }) {
  const dispatch = useDispatch();
  const { fetchOverallHistoryForComment } = bindActionCreators(actionCreators, dispatch);

  const isOverallHistoryLoading = useSelector(state =>
    _.get(state.comments.overallItemFeedbackHistory, 'isOverallHistoryLoading', false)
  );
  const allHistory = useSelector(state => _.get(state.comments.overallItemFeedbackHistory, 'allHistory', []));

  const windowHeight = window.innerHeight;
  let buttonTitle = type === 'ITEM_HISTORY' ? 'Item History' : 'Attribute History';
  const className = type === 'ITEM_HISTORY' ? 'core-attr-item-history' : 'core-attr-history';
  if (caption) {
    buttonTitle = caption;
  }

  const getDataSet = () => {
    if (range && allHistory.length) return allHistory;
    if (historyData && historyData.length) return historyData;
    return [];
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    if (showModal) {
      setShowModal(false);
    } else {
      fetchOverallHistoryForComment({ range });
      setShowModal(true);
    }
  };

  const exportHistory = () => {
    const exportData = _.map(_.reverse(getDataSet()), record => {
      const statusFrom = getOverallFeedbackStatusValue(record.statusFrom, record.changedBy);
      const statusTo = getOverallFeedbackStatusValue(record.statusTo, record.changedBy);

      return {
        ...record,
        suvc: `"${record.suvc}"`,
        statusFrom,
        statusTo
      };
    });

    try {
      fileDownload('history', Papa.unparse(exportData));
    } catch (error) {
      openNotification({
        className: 'error',
        message: 'ERROR',
        description: 'An error occoured while exporting history'
      });
    }
  };

  const renderModal = () => {
    return (
      <Modal
        className="core-attr-history-modal"
        visible={showModal}
        maskClosable={false}
        closable={true}
        width={'95%'}
        bodyStyle={{ height: windowHeight - 200 }}
        onCancel={() => toggleModal()}
        footer={
          <div style={{ width: '20%', display: 'flex', alignItems: 'flex-end' }}>
            <Button key="export" disabled={_.isEmpty(getDataSet())} onClick={exportHistory}>
              Export
            </Button>
          </div>
        }
      >
        {renderTable()}
      </Modal>
    );
  };

  const renderTable = () => {
    const columns = [
      {
        title: 'SUPC',
        dataIndex: 'supc',
        key: 'supc',
        width: 90
      },
      {
        title: 'Date',
        dataIndex: 'changedAt',
        key: 'changedAt',
        width: 180,
        render: text => <div>{formatDate(text, 'MM/DD/YYYY hh:mm A')}</div>
      },
      {
        title: 'User',
        dataIndex: 'changedBy',
        key: 'changedBy',
        width: 200
      },
      {
        title: 'Changed From',
        dataIndex: 'changedFrom',
        key: 'changedFrom'
        // render: renderValueColumns
      },
      {
        title: 'Changed To',
        dataIndex: 'changedTo',
        key: 'changedTo'
        // render: renderValueColumns
      },
      {
        title: 'Status From',
        dataIndex: 'statusFrom',
        key: 'statusFrom',
        width: 120,
        render: (text, record) => {
          return <div>{getOverallFeedbackStatusValue(text, record.changedBy)}</div>;
        }
      },
      {
        title: 'Status To',
        dataIndex: 'statusTo',
        key: 'statusTo',
        width: 120,
        render: (text, record) => {
          return <div>{getOverallFeedbackStatusValue(text, record.changedBy)}</div>;
        }
      }
    ];

    if (isOverallHistoryLoading) {
      return <Icon className="core-attr-history-loader" type="loading" />;
    } else {
      return (
        <Table
          id="core-attr-history-table"
          rowKey={'changedAt'}
          columns={columns}
          dataSource={getDataSet()}
          pagination={getDataSet().length > 5000}
          scroll={{ y: windowHeight - 290 }}
        />
      );
    }
  };

  return (
    <React.Fragment>
      {renderModal()}
      <div
        className={buttonClasses ? buttonClasses : `core-data-history-btn ${className}`}
        onClick={() => toggleModal()}
      >
        {buttonTitle}
      </div>
    </React.Fragment>
  );
}
