import React from 'react';
import { Tooltip } from 'antd';

class PendingComment extends React.Component {

  render() {
    let { comment, clickOn } = this.props;

    const toolTipOnclick = () => {
      if (clickOn) {
        clickOn();
      }
    };

    return (
        <Tooltip
        className="attr-comment"
        placement="leftBottom"
        onClick={toolTipOnclick}
        mouseLeaveDelay={0}
        title={
          <div>
            <div className="comment-tooltip-header">Pending comment:</div>
            <div className="comment-tooltip-body">{comment}</div>
          </div>
        }></Tooltip>
    );
  }
}

export default PendingComment;
