import React from 'react';
import { Checkbox } from 'antd';
import _ from 'lodash';
import { DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS } from '../../util/Constants';
import { getGdsnOrSyscoBrandFlag, getUpdatedTaxonomy, removeFromUpdatedTaxonomy } from '../../util/Util';

const MassUpdateCheckBox = ({
  description,
  isDisabled,
  item: {
    taxonomy,
    taxonomyChanges,
    hasNutritionSynchedTaxonomyAttributeChanges: currentState,
    previousHasNutritionSynchedTaxonomyAttributeChanges: previousState,
    nutritions,
    basicData
  },
  filter,
  textToFilter,
  actions
}) => {
  const getRelatedAttributes = () => {
    let filteredAttr = [];
    if (taxonomy && taxonomy.attributes && taxonomy.attributes.length > 0) {
      filteredAttr = taxonomy.attributes.filter(filter);
    }
    return filteredAttr;
  };

  const filterPickListValues = () => {
    const attributes = getRelatedAttributes();
    let updatedDataArray = [];
    _.forEach(attributes, ({ picklist, attrId, name, source }) => {
      const filteredPicklist = picklist.filter(({ value }) => value.indexOf(textToFilter) > -1);
      if (filteredPicklist && filteredPicklist.length) {
        updatedDataArray = [...updatedDataArray, { attrId, selected: filteredPicklist[0].id, name, source }];
      }
    });
    return updatedDataArray;
  };

  const checkForGdsnOrSyscoNutritionItem = () => {
    const { nutritionSourceOid } = nutritions || {};
    return [23, 31].includes(nutritionSourceOid);
  };

  const checkBoxChanged = value => {
    let updatedTaxonomyData = [...taxonomyChanges];
    let hasSynchedObject = {
      hasNutritionSynchedTaxonomyAttributeChanges: false,
      previousHasNutritionSynchedTaxonomyAttributeChanges: false
    };
    const { nutritionSourceOid } = nutritions || {};
    const gdsnOrSyscoFlagKey = getGdsnOrSyscoBrandFlag(nutritionSourceOid, basicData);
    filterPickListValues().forEach(({ attrId: id, selected, source, name }) => {
      if (!DISABLED_ATTRIBUTION_FIELDS_WITH_NUTRITIONS[gdsnOrSyscoFlagKey]?.[source]?.[name]) {
        const existingFieldIndex = updatedTaxonomyData.findIndex(({ attrId }) => attrId === id.toString());
        if (value.target.checked) {
          updatedTaxonomyData = [
            ...getUpdatedTaxonomy(existingFieldIndex, updatedTaxonomyData, id.toString(), selected?.toString() || null)
          ];
          hasSynchedObject = {
            hasNutritionSynchedTaxonomyAttributeChanges: true,
            previousHasNutritionSynchedTaxonomyAttributeChanges: currentState
          };
        } else {
          hasSynchedObject = {
            hasNutritionSynchedTaxonomyAttributeChanges: previousState,
            previousHasNutritionSynchedTaxonomyAttributeChanges: false
          };
          updatedTaxonomyData = [...removeFromUpdatedTaxonomy(existingFieldIndex, updatedTaxonomyData)];
        }
      }
    });
    actions.updateWholeTaxonomyAttributeChanges({ updatedTaxonomyData });
    // actions.updateNutritionTaxonomySynchedChanges(hasSynchedObject); // uncomment this line when disabling fields for GDSN or Sysco brand items when nutrtion enabled
    if (!checkForGdsnOrSyscoNutritionItem()) {
      // remove this condition when disabling fields for GDSN or Sysco brand items when nutrtion enabled
      actions.updateNutritionTaxonomySynchedChanges(hasSynchedObject);
    }
  };
  return (
    <Checkbox className="mass-free-from-checkbox" disabled={isDisabled} onChange={checkBoxChanged}>
      {description}
    </Checkbox>
  );
};

export default MassUpdateCheckBox;
