import React from 'react';
import { NUTRITION_FEEDBACK_KEY_VALUES } from '../../enums/index';

export default function NutritionPendingsIndicationBar({ feedback }) {
  const NutritionFeedbackList = () => {
    if (feedback && feedback.comments) {
      return (
        <div className="pending-enut-comment">
          {Object.keys(feedback.comments)
            .map(key => NUTRITION_FEEDBACK_KEY_VALUES[key])
            .join(' | ')}
        </div>
      );
    }
    return null;
  };
  return (
    <React.Fragment>
      <div className="pending-enut-row red-row">
        <div className="pending-enut-title">Feedback Rejected :-</div>
        <NutritionFeedbackList />
      </div>
    </React.Fragment>
  );
}
