export const UNIVERSAL_FIELDS = {
  dt_halal: { isDisabled: false, caption: 'Diet Type Halal' },
  dt_kosher: { isDisabled: false, caption: 'Diet Type Kosher' },
  dt_vegan: { isDisabled: false, caption: 'Diet Type Vegan' },
  dt_vegetarian: { isDisabled: false, caption: 'Diet Type Vegetarian' },
  cl_hfcs: { isDisabled: false, caption: 'Claims Hfcs' },
  cl_fat: { isDisabled: false, caption: 'Claims Fat Nutrient' },
  cl_gluten: { isDisabled: false, caption: 'Claims Gluten' },
  cl_msg: { isDisabled: false, caption: 'Claims Msg Status' },
  cl_organic: { isDisabled: false, caption: 'Organic Claims Status' },
  cl_sodium: { isDisabled: false, caption: 'Claims Sodium Nutrient Content' },
  cl_sugar: { isDisabled: false, caption: 'Claims Sugar Nutrient' },
  cl_natural: { isDisabled: false, caption: 'Claims Natural' },
  cl_bio: { isDisabled: false, caption: 'Bio-Engineered (gmo)' },
  cl_bio2: { isDisabled: false, caption: 'Bioengineered (gmo)' },
  al_egg: { isDisabled: false, caption: 'Allergen Egg' },
  al_fish: { isDisabled: false, caption: 'Allergen Fish' },
  al_milk: { isDisabled: false, caption: 'Allergen Milk' },
  al_peanut: { isDisabled: false, caption: 'Allergen Peanut' },
  al_sesame: { isDisabled: false, caption: 'Allergen Sesame' },
  al_soybean: { isDisabled: false, caption: 'Allergen Soybean' },
  al_treenuts: { isDisabled: false, caption: 'Allergen Tree Nuts' },
  al_wheat: { isDisabled: false, caption: 'Allergen Wheat' },
  al_crstsn: { isDisabled: false, caption: 'Crustacean Shellfish Allergen' }
};

export const UNIVERSAL_REJECT_STATUS_DROPDOWNS = ['REJECT', 'FIXED'];
